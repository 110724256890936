import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddPatientBody, PatientBody } from './types';
import { MyKnownError } from '~/models/Error.model';
import { postRequest, getRequest, putRequest, deleteRequest } from '~/store/apiHelper';

export const fetchAllPatients = createAsyncThunk<any[], undefined, { rejectValue: MyKnownError }>(
  'patient/all',
  async (_, thunkApi) => {
    return getRequest('/patient', true, thunkApi);
  }
);

export const archivePatient = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'patient/archive',
  async (data, thunkApi) => {
    return putRequest(`/patient/archive/${data}`, null, true, thunkApi);
  }
);

export const unarchivePatient = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'patient/unarchive',
  async (data, thunkApi) => {
    return putRequest(`/patient/unarchive/${data}`, null, true, thunkApi);
  }
);

export const updatePatient = createAsyncThunk<any, PatientBody, { rejectValue: MyKnownError }>(
  'patient/update',
  async (data, thunkApi) => {
    return putRequest(`/patient`, data, true, thunkApi);
  }
);

export const deletePatient = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'patient/delete',
  async (id, thunkApi) => {
    return deleteRequest(`/patient/${id}`, null, true, thunkApi);
  }
);

export const fetchPatientById = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'patient/id',
  async (id, thunkApi) => {
    return getRequest(`/patient/${id}`, true, thunkApi);
  }
);

export const addNewPatient = createAsyncThunk<any, AddPatientBody, { rejectValue: MyKnownError }>(
  'patient/add',
  async (data, thunkApi) => {
    return postRequest('/patient', data, true, thunkApi);
  }
);
