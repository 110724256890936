import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import Empty from '~/components/Common/Empty';
import Loader from '~/components/Common/Loader';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { setSecondaryBarTrue, toggle } from '~/store/reducers/sideBar';
import user, { resetDeletedUser, resetGetUser } from '~/store/reducers/user';
import { deleteUser, GetUsers } from '~/store/reducers/user/thunks';

import { UserBody } from '~/store/reducers/user/types';
import Async from '~/utils/Async';
import { AUTHORITY_ROLE } from '~/utils/constants';
import {
  convertServerDateFormatToDateFormatWithoutHyphen,
  getAgeFromDate
} from '~/utils/dateUtility';

import { errorToast, successToast } from '~/utils/toast';
import { getRole } from '~/utils/utilMethods';
import { ReactComponent as AddIcon } from '../../assets/icons/add-circle.svg';
import AddUserModal from './AddUser';
import ResetPasswordModal from './ResetPassword';

const UserManagement: FC = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReset, setIsOpenReset] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [selectedUser, setSelectedUser] = useState<UserBody>();
  const dispatch = useAppDispatch();
  const {
    //fetch all users
    userLoading,
    userSuccess,
    userError,
    users: reduxUsers,
    isLoadingDeleteUser,
    errorDeleteUser,
    successDeleteUser
  } = useAppSelector((state) => state.UserReducer);
  const { collapsed } = useAppSelector((state) => state.sideBarReducer);

  const [users, setUsers] = useState([]);
  //GET ALL USERS
  useEffect(() => {
    if (!userLoading && userError) {
      errorToast(userError);
      dispatch(resetGetUser());
    }

    if (!userLoading && userSuccess && reduxUsers?.length > 0) {
      setUsers(reduxUsers);
      dispatch(resetGetUser());
    }
  }, [userLoading, userSuccess, userError, reduxUsers]);

  //DELETE USER
  useEffect(() => {
    if (!isLoadingDeleteUser && successDeleteUser) {
      successToast('User deleted successfully !');
      dispatch(resetDeletedUser());
      dispatch(GetUsers());
    }
    if (!isLoadingDeleteUser && errorDeleteUser) {
      errorToast('Error adding patient');
      dispatch(resetDeletedUser());
    }
  }, [isLoadingDeleteUser, successDeleteUser, errorDeleteUser]);

  useEffect(() => {
    dispatch(GetUsers());
    window.addEventListener('resize', HandleWidth);
    return () => {
      window.removeEventListener('resize', HandleWidth);
    };
  }, []);

  useEffect(() => {
    const body: HTMLElement = document.body;
    dispatch(setSecondaryBarTrue());
    if (!collapsed) {
      dispatch(toggle(true));
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }, []);

  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };

  const toggleUserModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleReset = () => {
    setIsOpenReset(!isOpenReset);
  };
  return (
    <>
      <AddUserModal
        isOpen={isOpen}
        toggle={toggleUserModal}
        modalType={modalType}
        userData={selectedUser}
      />
      <ResetPasswordModal isOpen={isOpenReset} toggle={toggleReset} userData={selectedUser} />
      <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
        <Container fluid>
          <Row>
            <Col lg="3">
              <h2 className="m-0 align-self-center text-nowrap">
                <b className="text-nowrap sbl24">User management</b>
              </h2>
            </Col>
            <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
            <Col lg="4" className="d-flex justify-content-end text-nowrap">
              <BlueButton
                Icon={
                  <AddIcon
                    color={'#ffffff'}
                    style={{ height: '16px', marginBottom: '4px', marginRight: '6px' }}
                  />
                }
                HandleClick={() => {
                  toggleUserModal();
                  setModalType('add');
                }}
                width="100px"
                text={'Add user'}
              />
            </Col>
          </Row>
          <div className="horizontal-line mt-3"></div>
          <div
            className="mt-4"
            style={{
              maxHeight: `${height - 170}px`,
              overflowX: 'hidden',
              scrollbarWidth: 'thin'
              // marginTop: '-40px'
            }}
          >
            {!userLoading && users !== null && user.length !== 0 && (
              <span className="col-md-12 d-flex">
                <div className="col-md-3 d-flex justify-content-center flex-column">
                  <h2 className="ms-3 patient-details">Name</h2>
                </div>
                <div className="col-md-3 d-flex justify-content-center flex-column">
                  <h2 className="ms-3 patient-details">Password</h2>
                </div>
                <div className="col-md-3 d-flex justify-content-center flex-column">
                  <h2 className="patient-details" style={{ marginLeft: '-8px' }}>
                    Role
                  </h2>
                </div>
              </span>
            )}
            {!userLoading ? (
              users !== null && users.length !== 0 ? (
                users.map((user: UserBody) => (
                  <Accordion key={user.id}>
                    <AccordionItem
                      dropDownstyle={{
                        right: '175px'
                      }}
                      dropdownData={[
                        { id: 1, title: 'Edit details', color: 'default' },
                        { id: 2, title: 'Remove', color: 'danger' }
                      ]}
                      onClickDropdownItem={[
                        {
                          id: 1,
                          title: 'Edit details',
                          event: () => {
                            setSelectedUser(user);
                            setModalType('edit');
                            toggleUserModal();
                          }
                        },
                        {
                          id: 2,
                          title: 'Remove',
                          event: () => {
                            dispatch(deleteUser(user.id));
                          }
                        }
                      ]}
                      accordionHeaderClasses={'justify-content-between'}
                      AccordionHeader={
                        <>
                          <span className="col-md-12 d-flex">
                            <div className="col-md-3 d-flex justify-content-center flex-column">
                              <span className="patient-name">
                                {user.firstName + ' ' + user.lastName}
                              </span>
                            </div>
                            <div className="col-md-3 d-flex justify-content-center flex-column">
                              <h2 className="im14n m-0">
                                <span
                                  className="rounded-pill p-1 px-2"
                                  style={{
                                    color: '#2A45CD',
                                    backgroundColor: '#E9ECFA',
                                    marginLeft: '-5px'
                                  }}
                                  onClick={() => {
                                    if (
                                      Async.getItem('@auth')?.role === AUTHORITY_ROLE.ADMIN ||
                                      user.username === Async.getItem('@auth')?.username
                                    ) {
                                      setSelectedUser(user);
                                      toggleReset();
                                    } else {
                                      errorToast('You donot have the permission for this');
                                    }
                                  }}
                                >
                                  Reset password {' >'}
                                </span>
                              </h2>
                            </div>
                            <div className="col-md-3 d-flex justify-content-center flex-column">
                              <h2 className="patient-name m-0">{getRole(user.role)}</h2>
                            </div>
                          </span>
                        </>
                      }
                      AccordionBody={
                        <div className="col-md-12 p-3 d-flex">
                          <div className="col-md-4">
                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Username
                              </Col>
                              <Col className="value-text">{user.username ?? '-'}</Col>
                            </span>

                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Age
                              </Col>
                              <Col className="value-text">
                                {user.dateOfBirth
                                  ? `${getAgeFromDate(
                                      user.dateOfBirth
                                    )} (${convertServerDateFormatToDateFormatWithoutHyphen(
                                      user.dateOfBirth
                                    )})`
                                  : '-'}
                              </Col>
                            </span>
                            <span className="d-flex py-2">
                              <Col className="key-text" sm={3}>
                                Sex{' '}
                              </Col>
                              <Col className="value-text">{user.gender ?? '-'}</Col>
                            </span>
                          </div>
                          <div className="col-md-4">
                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Email
                              </Col>
                              <Col className="value-text">
                                {user.email?.length !== 0 ? user.email : '-'}
                              </Col>
                            </span>

                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Phone
                              </Col>
                              <Col className="value-text">
                                {user.phoneNumber?.length !== 0 && user.phoneNumber !== null
                                  ? user.phoneNumber
                                  : '-'}
                              </Col>
                            </span>
                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Address
                              </Col>
                              <Col className="value-text">
                                {user.address?.length !== 0 && user.address !== null
                                  ? user.address
                                  : '-'}
                              </Col>
                            </span>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      }
                    />
                  </Accordion>
                ))
              ) : (
                <div
                  className="d-flex justify-content-center w-100"
                  style={{
                    width: '100%',
                    height: '60vh',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Empty />
                </div>
              )
            ) : (
              <div className="d-flex justify-content-center w-100" style={{ minHeight: '500px' }}>
                <Loader />
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default UserManagement;
