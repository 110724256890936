import { createSlice } from '@reduxjs/toolkit';
import { AddPanel, DeletePanel, GetInvestigations, GetPanel, UpdatePanel } from './thunks';
import { PanelManagement } from './types';

const initialState: PanelManagement = {
  loading: false,
  success: false,
  error: '',
  investigations: [],
  //get panel
  panelLoading: false,
  panelSuccess: false,
  panelError: '',
  panels: [],
  //add panel
  addedPanel: null,
  isLoadingAddPanel: false,
  errorAddPanel: '',
  successAddPanel: false,
  //delete panel
  deletedPanel: null,
  isLoadingDeletePanel: false,
  errorDeletePanel: '',
  successDeletePanel: false,
  //update panel
  updatedPanel: null,
  isLoadingUpdatePanel: false,
  errorUpdatePanel: '',
  successUpdatePanel: false
};
const panelManagementSlice = createSlice({
  name: 'panelManagement',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(GetInvestigations.pending, (state) => {
      state.loading = true;
      state.error = '';
      state.success = false;
    });
    builder.addCase(GetInvestigations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = '';
      state.investigations = payload;
      state.success = true;
    });
    builder.addCase(GetInvestigations.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      if (action.error) {
        state.error = action.error.message as string;
      } else {
        state.error = 'Error fetching investigations';
      }
    });
    //panel get
    builder.addCase(GetPanel.pending, (state) => {
      state.panelLoading = true;
      state.panelError = '';
      state.panelSuccess = false;
    });
    builder.addCase(GetPanel.fulfilled, (state, { payload }) => {
      state.panelLoading = false;
      state.panelError = '';
      state.panels = payload;
      state.panelSuccess = true;
    });
    builder.addCase(GetPanel.rejected, (state, action) => {
      state.panelLoading = false;
      state.panelSuccess = false;
      if (action.error) {
        state.panelError = action.error.message as string;
      } else {
        state.panelError = 'Error fetching panels';
      }
    });
    //add panel
    builder.addCase(AddPanel.pending, (state) => {
      state.isLoadingAddPanel = true;
      state.errorAddPanel = '';
      state.successAddPanel = false;
    });
    builder.addCase(AddPanel.fulfilled, (state, { payload }) => {
      state.isLoadingAddPanel = false;
      state.errorAddPanel = '';
      state.addedPanel = payload;
      state.successAddPanel = true;
    });
    builder.addCase(AddPanel.rejected, (state, action) => {
      state.isLoadingAddPanel = false;
      state.successAddPanel = false;
      if (action.error) {
        state.errorAddPanel = action.error.message as string;
      } else {
        state.errorAddPanel = 'Error Adding panels';
      }
    });
    //delete panel
    builder.addCase(DeletePanel.pending, (state) => {
      state.isLoadingDeletePanel = true;
      state.errorDeletePanel = '';
      state.successDeletePanel = false;
    });
    builder.addCase(DeletePanel.fulfilled, (state, { payload }) => {
      state.isLoadingDeletePanel = false;
      state.errorDeletePanel = '';
      state.deletedPanel = payload;
      state.successDeletePanel = true;
    });
    builder.addCase(DeletePanel.rejected, (state, action) => {
      state.isLoadingDeletePanel = false;
      state.successDeletePanel = false;
      if (action.error) {
        state.errorDeletePanel = action.error.message as string;
      } else {
        state.errorDeletePanel = 'Error Deleting Panel';
      }
    });
    //update panel
    builder.addCase(UpdatePanel.pending, (state) => {
      state.isLoadingUpdatePanel = true;
      state.errorUpdatePanel = '';
      state.successUpdatePanel = false;
    });
    builder.addCase(UpdatePanel.fulfilled, (state, { payload }) => {
      state.isLoadingUpdatePanel = false;
      state.errorUpdatePanel = '';
      state.updatedPanel = payload;
      state.successUpdatePanel = true;
    });
    builder.addCase(UpdatePanel.rejected, (state, action) => {
      state.isLoadingUpdatePanel = false;
      state.successUpdatePanel = false;
      if (action.error) {
        state.errorUpdatePanel = action.error.message as string;
      } else {
        state.errorUpdatePanel = 'Error Updating Panel';
      }
    });
  },
  reducers: {
    resetGetInvestigationState(state) {
      state.loading = false;
      state.error = '';
      state.success = false;
    },
    resetGetPanel(state) {
      state.panelLoading = false;
      state.panelError = '';
      state.panelSuccess = false;
    },
    resetAddPanel(state) {
      state.isLoadingAddPanel = false;
      state.errorAddPanel = '';
      state.successAddPanel = false;
    },
    resetDeletePanel(state) {
      state.isLoadingDeletePanel = false;
      state.errorDeletePanel = '';
      state.successDeletePanel = false;
    },
    resetUpdatePanel(state) {
      state.isLoadingUpdatePanel = false;
      state.errorUpdatePanel = '';
      state.successUpdatePanel = false;
    }
  }
});

// // Action creators are generated for each case reducer function
export const {
  resetGetInvestigationState,
  resetGetPanel,
  resetAddPanel,
  resetDeletePanel,
  resetUpdatePanel
} = panelManagementSlice.actions;
export default panelManagementSlice.reducer;
