import React, { FC } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { WithRouterProps } from '~/models/WithRouterProps.model';

const SecondarySidebarContent: FC<WithRouterProps> = ({ location }) => {
  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li>
          <Link
            to="/userManagement"
            className={location.pathname === '/userManagement' ? 'mm-active' : ''}
            style={{
              textDecoration: 'none'
            }}
          >
            <span>
              <span className="sb13">User management</span>
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/investigationManagement"
            className={location.pathname === '/investigationManagement' ? 'mm-active' : ''}
            style={{
              textDecoration: 'none'
            }}
          >
            <span>
              <span className="sb13" style={{ whiteSpace: 'nowrap' }}>
                Investigation management
              </span>
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/panelManagement"
            className={location.pathname === '/panelManagement' ? 'mm-active' : ''}
            style={{
              textDecoration: 'none'
            }}
          >
            <span>
              <span className="sb13">Panel management</span>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(SecondarySidebarContent) as any;
