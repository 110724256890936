import React from 'react';
import { Container } from 'reactstrap';

const InvestigationManagement = () => {
  return (
    <div className="page-content bg-white">
      <Container fluid>
        <h2 className="m-0 align-self-center text-nowrap">
          <b className="text-nowrap sbl24">Coming soon...</b>
        </h2>
      </Container>
    </div>
  );
};

export default InvestigationManagement;
