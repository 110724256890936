import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Authentication';
import Patients from '~/pages/Patients';
import PanelManagement from '~/pages/PanelManagement';
import AddPatient from '~/pages/Patients/AddPatient';
import NewEncounter from '~/pages/Patients/NewEncounter';
import CreateEncounter from '~/pages/Patients/CreateEncounter';
import EncounterHistory from '~/pages/Patients/EncounterHistory';
import InvestigationManagement from '~/pages/InvestigationManagement';
import UserManagement from '~/pages/UserManagement';
import Doctor from '~/pages/Doctor';
import AddDoctor from '~/pages/Doctor/AddDoctor';
import Inventory from '~/pages/Inventory';

const authProtectedRoutes: RouteProps[] = [
  { path: '/dashboard', component: Dashboard },
  { path: '/patients', component: Patients },
  { path: '/doctors', component: Doctor },
  { path: '/inventory/:routeFilter', component: Inventory },
  { path: '/panelManagement', component: PanelManagement },
  { path: '/investigationManagement', component: InvestigationManagement },
  { path: '/userManagement', component: UserManagement },
  { path: '/addPatient', component: AddPatient },
  { path: '/addDoctor', component: AddDoctor },
  { path: '/patient/:id/encounterHistory', component: EncounterHistory },
  { path: '/newEncounter', component: NewEncounter },
  { path: '/patient/:pId/encounter/:id/details', component: CreateEncounter },
  { path: '/', exact: true, component: () => <Redirect to={'/dashboard'} /> }
];

const publicRoutes: RouteProps[] = [{ path: '/login', component: Login }];

export { authProtectedRoutes, publicRoutes };
