export const ENCOUNTER_STATUS = {
  INPROGRESS: 0,
  COMPLETED: 1
};

export const INVESTIGATION_FLAG_STATUS = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2
};

export const ENCOUNTER_INVESTIGATION_STATUS = {
  PENDING: 0,
  COMPLETE: 1,
  ARRIVED: 2
};

export const AUTHORITY_ROLE = {
  ADMIN: 'ROLE_ADMIN',
  LAB_MANAGER: 'ROLE_LAB_MANAGER',
  LAB_TECH: 'ROLE_LAB_TECH',
  DOCTOR: 'ROLE_DOCTOR',
  PHLEBOTOMIST: 'ROLE_PHLEBOTOMIST'
};

export const NOTIFICATION_TYPE = {
  EXPIRED: 'EXPIRED',
  WILL_EXPIRE: 'WILL_EXPIRE',
  FIRST_WARNING: 'FIRST_WARNING',
  SECOND_WARNING: 'SECOND_WARNING'
};

export const INVENTORY_USED_BY = {
  INVESTIGATION: 0,
  ENCOUNTER: 1
};
