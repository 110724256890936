import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import {
  PanelManagement,
  sideBarReducer,
  loaderReducer,
  AuthReducer,
  PatientReducer,
  DoctorReducer,
  EncounterReducer,
  UserReducer,
  InventoryReducer,
  PureRedux,
  NotificationReducer
} from './reducers';

const combinedReducer = combineReducers({
  sideBarReducer,
  loaderReducer,
  AuthReducer,
  PatientReducer,
  PanelManagement,
  DoctorReducer,
  EncounterReducer,
  UserReducer,
  InventoryReducer,
  PureRedux,
  NotificationReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/clearState') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store: any = configureStore({
  reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
