import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError } from '~/models/Error.model';
import { deleteRequest, getRequest, postRequest, putRequest } from '~/store/apiHelper';
import { AddUserBody, ResetUserPasswordProps, UserBody } from './types';

export const GetUsers = createAsyncThunk<any, undefined, { rejectValue: MyKnownError }>(
  'users/GetUsers',
  async (_, thunkApi) => {
    return getRequest('/user/all', true, thunkApi);
  }
);

export const registerUser = createAsyncThunk<any, AddUserBody, { rejectValue: MyKnownError }>(
  'user/add',
  async (data, thunkApi) => {
    return postRequest(`/user/register`, data, true, thunkApi);
  }
);

export const updateUser = createAsyncThunk<any, UserBody, { rejectValue: MyKnownError }>(
  'user/update',
  async (data, thunkApi) => {
    return putRequest(`/user`, data, true, thunkApi);
  }
);

export const resetUserPassword = createAsyncThunk<
  any,
  ResetUserPasswordProps,
  { rejectValue: MyKnownError }
>('user/reset_password', async (data, thunkApi) => {
  return putRequest(`/user/reset_password`, data, true, thunkApi);
});

export const deleteUser = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'user/delete',
  async (id, thunkApi) => {
    return deleteRequest(`/user/${id}`, null, true, thunkApi);
  }
);
