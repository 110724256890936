import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError } from '~/models/Error.model';
import { getRequest } from '~/store/apiHelper';

export const fetchAllNotifications = createAsyncThunk<
  any[],
  { timeframe: number; read: boolean },
  { rejectValue: MyKnownError }
>('notification/all', async (data, thunkApi) => {
  const url = `notification?timeframe=${data.timeframe}&read=${data.read}`;
  return getRequest(url, true, thunkApi);
});

export const fetchUnreadNotificationCount = createAsyncThunk<
  number,
  undefined,
  { rejectValue: MyKnownError }
>('notification/unread/count', async (_, thunkApi) => {
  const url = `notification/unread`;
  return getRequest(url, true, thunkApi);
});
