import React, { FC, useEffect } from 'react';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown
} from 'reactstrap';

interface MultiSelectInputProps {
  label: string;
  placeholder: string;
  invalid?: any;
  options: any;
  setOptions: any;
  errors?: string;
  setError?: any;
  setChecked?: any;
  checkedOptions?: any;
  modalType?: string;
  screen?: string;
}
const MultiSelectInput: FC<MultiSelectInputProps> = (props) => {
  const {
    label,
    placeholder,
    invalid,
    options,
    setOptions,
    errors,
    setChecked,
    checkedOptions,
    screen
  } = props;

  useEffect(() => {
    const tempData3: any = [];

    options?.length > 0 &&
      options?.forEach((val: any) => {
        if (val.check === true) {
          tempData3.push(val.data);
        }
      });
    setChecked(tempData3);
  }, [options]);

  const onChangeCheckedInvestigations = (data: any) => {
    const tempData = JSON.parse(JSON.stringify(checkedOptions));

    const tempData2 = data
      ?.filter((val: any) => val.check === true)
      .map((val: any) => {
        return val.data;
      });

    const array1 = tempData.concat(tempData2);

    const tempData3: any = [];
    options?.length > 0 &&
      options?.forEach((val: any) => {
        if (val.check === true && !array1.find((val2: any) => val2.id === val.data.id)) {
          tempData3.push(val.data);
        }
      });
    const array4 = array1.concat(tempData3);

    setChecked(array4);
  };

  return (
    <>
      <UncontrolledDropdown className="me-2" direction="down">
        <DropdownToggle data-toggle="dropdown" tag="span">
          <label className="col-md-5 col-form-label im gray">{label}</label>
          <Input
            type="select"
            className={'form-control placeholder-color pointer-none'}
            invalid={invalid ? true : false}
          >
            <option value="">{placeholder}</option>
          </Input>
        </DropdownToggle>
        <DropdownMenu className="w-100 multiselect-dropdown">
          {options?.length > 0 && options[0]?.data.name === 'CBC' ? (
            <div className="ms-2">
              <FormGroup key={1} check className={'mx-3'}>
                <Input
                  type="checkbox"
                  checked={options[0].check}
                  onChange={(e) => {
                    const { checked } = e.target;
                    const data = JSON.parse(JSON.stringify(options));
                    if (screen !== 'inventory') {
                      options.forEach((val: any, index: number) => {
                        data[index].check = checked;
                        setOptions(data);
                        //set checked
                        onChangeCheckedInvestigations(data);
                      });
                    } else {
                      data[0].check = checked;
                      setOptions(data);
                      //set checked
                      onChangeCheckedInvestigations(data);
                    }
                  }}
                />

                <Label check className="font-family-inter">
                  {options[0]?.data?.name}
                </Label>
              </FormGroup>
            </div>
          ) : (
            options?.length > 0 &&
            options?.map((val: any, index: number) => (
              <div className="ms-2" key={index}>
                <FormGroup key={1} check className={'mx-3'}>
                  <Input
                    type="checkbox"
                    checked={val.check}
                    onChange={(e) => {
                      const { checked } = e.target;
                      const data = JSON.parse(JSON.stringify(options));
                      data[index].check = checked;
                      setOptions(data);
                      //set checked
                      onChangeCheckedInvestigations(data);
                    }}
                  />

                  <Label check className="font-family-inter">
                    {val.data?.name}
                  </Label>
                </FormGroup>
              </div>
            ))
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
      {invalid && errors ? <h4 className="error-msg mt-1">{errors}</h4> : null}
    </>
  );
};

export default MultiSelectInput;
