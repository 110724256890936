import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Container } from 'reactstrap';
import { PatientDataProps } from '~/models/User.model';
import useWindowSize from '~/utils/hooks/useWindowSize';
import AddPatientForm from './AddPatient';

const AddPatient = () => {
  const { height } = useWindowSize();
  const location = useLocation();
  const [patient, setPatient] = useState<PatientDataProps>();
  useEffect(() => {
    if (location?.state) {
      setPatient(location?.state as PatientDataProps);
    }
  }, [location]);

  return (
    <div
      className="page-content ms-2"
      style={{
        minHeight: `${height - 10}px`,
        background: '#ffffff'
      }}
    >
      <Container fluid>
        <AddPatientForm patientData={patient} />
      </Container>
    </div>
  );
};

export default AddPatient;
