import clsx from 'clsx';
import React, { FC, useEffect } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { EncounterBody } from '~/store/reducers/encounter/types';
import { INVESTIGATION_FLAG_STATUS } from '~/utils/constants';
import { getAgeFromDate } from '~/utils/dateUtility';
import { errorToast } from '~/utils/toast';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';
import './CreateEncounter.styles.scss';
interface OtherValues {
  general: string;
  nestedFirst: string;
  nestedSecond: string;
}
interface RowProps {
  val?: any;
  medicalDevice?: any;
  encounterDetails?: any;
  setEncounterDetails?: any;
  indexMain?: any;
  patientData?: any;

  anionGapVal?: OtherValues;
  setAnionGapVal?: any;
  sodiumValforAnionGap?: OtherValues;
  setSodiumValforAnionGap?: any;
  potassiumValforAnionGap?: OtherValues;
  setPotassiumValforAnionGap?: any;
  chlorideValforAnionGap?: OtherValues;
  setChlorideValforAnionGap?: any;
  bicarbonateValforAnionGap?: OtherValues;
  setBicarbonateValforAnionGap?: any;

  firstPanelGFRFemaleWeight?: OtherValues;
  setFirstPanelGFRFemaleWeight?: any;
  firstPanelGFRMaleWeight?: OtherValues;
  setFirstPanelGFRMaleWeight?: any;
  firstPanelGFRFemaleVal?: OtherValues;
  setFirstPanelGFRFemaleVal?: any;
  firstPanelGFRMaleVal?: OtherValues;
  setFirstPanelGFRMaleVal?: any;
  serumCretValforGFRF?: OtherValues;
  setSerumCretValforGFRF?: any;

  apolipoRatioVal?: OtherValues;
  setApolipoRatioVal?: any;
  apolipoAVal?: OtherValues;
  setApolipoAVal?: any;
  apolipoBVal?: OtherValues;
  setApolipoBVal?: any;

  correctedCalciumVal?: OtherValues;
  setCorrectedCalciumVal?: any;
  albuminValforcorrCal?: OtherValues;
  setAlbuminValforcorrCal?: any;
  calciumValforcorrCal?: OtherValues;
  setCalciumValforcorrCal?: any;

  edit?: boolean;
}
const RenderInvestigation: FC<RowProps> = ({
  val,
  medicalDevice,
  encounterDetails,
  setEncounterDetails,
  indexMain,
  patientData,

  anionGapVal,
  setAnionGapVal,
  sodiumValforAnionGap,
  setSodiumValforAnionGap,
  potassiumValforAnionGap,
  setPotassiumValforAnionGap,
  chlorideValforAnionGap,
  setChlorideValforAnionGap,
  bicarbonateValforAnionGap,
  setBicarbonateValforAnionGap,

  firstPanelGFRFemaleWeight,
  setFirstPanelGFRFemaleWeight,
  firstPanelGFRMaleWeight,
  setFirstPanelGFRMaleWeight,
  firstPanelGFRFemaleVal,
  setFirstPanelGFRFemaleVal,
  firstPanelGFRMaleVal,
  setFirstPanelGFRMaleVal,
  serumCretValforGFRF,
  setSerumCretValforGFRF,

  apolipoRatioVal,
  setApolipoRatioVal,
  apolipoAVal,
  setApolipoAVal,
  apolipoBVal,
  setApolipoBVal,

  correctedCalciumVal,
  setCorrectedCalciumVal,
  albuminValforcorrCal,
  setAlbuminValforcorrCal,
  calciumValforcorrCal,
  setCalciumValforcorrCal,

  edit
}) => {
  // const checkNumberPositiveOrDecimal = (e: any) => {
  //   const regex = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/;
  //   if (e.target.value === '' || regex.test(e.target.value)) {
  //     return true;
  //   }
  //   return false;
  // };

  const CustomPill = ({ flag }: { flag: string | null }) => {
    return (
      <span
        className={clsx(flag !== '-' && 'rounded-pill px-2 text-danger')}
        style={flag !== '-' ? { backgroundColor: '#FFEBEE', fontWeight: '600' } : {}}
      >
        {flag}
      </span>
    );
  };

  const getApolipoFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === 0 ? (
        'Below Standard/Optimal'
      ) : flag === 1 ? (
        'Optimal'
      ) : flag === 2 ? (
        'Above Optimal'
      ) : flag === 3 ? (
        'Above Standard'
      ) : (
        <CustomPill flag={'H'} />
      );
    }
    return '-';
  };

  const getVitaminDFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === 0 ? (
        <CustomPill flag={'L'} />
      ) : flag === 1 ? (
        'Deficiency'
      ) : flag === 2 ? (
        'Partial Deficiency'
      ) : flag === 3 ? (
        'Optimal level'
      ) : (
        'Toxicity'
      );
    }
    return '-';
  };

  const validateFlag = (range: string, value: number) => {
    if (range.includes('Before') || range.includes('Foll')) {
      return 1;
    } else if (range.includes('Ages')) {
      //age btw 16 - 24
      if (getAgeInYear() >= 16 && getAgeInYear() <= 24) {
        if (value <= 182) {
          return 0;
        } else if (value > 780) {
          return 2;
        } else {
          return 1;
        }
      } //age btw 24 - 39
      else if (getAgeInYear() >= 25 && getAgeInYear() <= 39) {
        if (value <= 114) {
          return 0;
        } else if (value > 492) {
          return 2;
        } else {
          return 1;
        }
      } //age btw 40 - 54
      else if (getAgeInYear() >= 40 && getAgeInYear() <= 54) {
        if (value <= 90) {
          return 0;
        } else if (value > 360) {
          return 2;
        } else {
          return 1;
        }
      } //age ? 55
      else if (getAgeInYear() >= 55) {
        if (value <= 71) {
          return 0;
        } else if (value > 290) {
          return 2;
        } else {
          return 1;
        }
      }
    } else if (range.includes('=')) {
      const rangeArray = range.split(' ');
      if (rangeArray[0] === 'Deficiency') {
        if (value < parseFloat(rangeArray[2])) {
          return 0;
        } else if (value >= parseFloat(rangeArray[2]) && value <= parseFloat(rangeArray[4])) {
          return 1;
        } else if (value > parseFloat(rangeArray[10]) && value <= parseFloat(rangeArray[12])) {
          return 2;
        } else if (value > parseFloat(rangeArray[19]) && value <= parseFloat(rangeArray[21])) {
          return 3;
        } else {
          return 4;
        }
      } else {
        if (value <= parseFloat(rangeArray[3])) {
          return 0;
        } else if (value > parseFloat(rangeArray[10]) && value <= parseFloat(rangeArray[12])) {
          return 1;
        } else if (value > parseFloat(rangeArray[17]) && value <= parseFloat(rangeArray[19])) {
          return 2;
        } else if (value > parseFloat(rangeArray[24]) && value <= parseFloat(rangeArray[26])) {
          return 3;
        } else {
          return 4;
        }
      }
    } else if (range.includes('greater or equal')) {
      const rangeArray = range.split('to');
      if (value >= parseFloat(rangeArray[1])) {
        return 1;
      } else {
        return 0;
      }
    } else if (range.includes('>')) {
      const rangeArray = range.split(' ');
      if (value < parseFloat(rangeArray[1])) {
        return 0;
      } else {
        return 1;
      }
    } else if (range.includes('<')) {
      const rangeArray = range.split(' ');
      if (value > parseFloat(rangeArray[1])) {
        return 2;
      } else {
        return 1;
      }
    } else if (range.includes('-')) {
      const rangeArray = range.split('-');
      if (value < parseFloat(rangeArray[0])) {
        return 0;
      }
      if (value > parseFloat(rangeArray[1])) {
        return 2;
      } else {
        return 1;
      }
    } else if (range.includes('to')) {
      const rangeArray = range.split('to');
      const low = rangeArray[0];
      const high = rangeArray[1].split(' ');
      if (value < parseFloat(low)) {
        return 0;
      }
      if (value > parseFloat(high[1])) {
        return 2;
      } else {
        return 1;
      }
    }
  };

  const getFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === INVESTIGATION_FLAG_STATUS.LOW
        ? 'L'
        : flag === INVESTIGATION_FLAG_STATUS.MEDIUM
        ? '-'
        : flag === INVESTIGATION_FLAG_STATUS.HIGH
        ? 'H'
        : null;
    }
    return '-';
  };

  const rerunInvestigation = () => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    //bk1000
    if (medicalDevice === 'bk1000') {
      const num = data.investigations.bk1000[indexMain].tracker;
      data.investigations.bk1000[indexMain].tracker = num + 1;
    }
    //bk200
    else if (medicalDevice === 'bk200') {
      const num = data.investigations.bk200[indexMain].tracker;
      data.investigations.bk200[indexMain].tracker = num + 1;
    }
    //bk6190
    else if (medicalDevice === 'bk6190') {
      const num = data.investigations.bk6190[indexMain].tracker;
      data.investigations.bk6190[indexMain].tracker = num + 1;
    }
    //cbs300
    else if (medicalDevice === 'cbs300') {
      const num = data.investigations.cbs300[indexMain].tracker;
      data.investigations.cbs300[indexMain].tracker = num + 1;
    }
    //other
    else if (medicalDevice === 'other') {
      const num = data.investigations.other[indexMain].tracker;
      data.investigations.other[indexMain].tracker = num + 1;
    }
    setEncounterDetails(data);
    toggleRerun();
  };

  const onChangeInvestigationInput = (e: any) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    //bk1000
    if (medicalDevice === 'bk1000') {
      if (val.id === 63) {
        const a = { ...apolipoAVal };
        setApolipoAVal({ ...a, general: e.target.value });
      } else if (val.id === 64) {
        setApolipoBVal({ ...apolipoBVal, general: e.target.value });
      }
      data.investigations.bk1000[indexMain].result = Number(e.target.value);
      //set flag
      data.investigations.bk1000[indexMain].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.bk1000[indexMain].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.bk1000[indexMain].maleRange
          : '',
        Number(e.target.value)
      );
    }
    //bk200
    else if (medicalDevice === 'bk200') {
      if (val.id === 33) {
        setBicarbonateValforAnionGap({
          ...bicarbonateValforAnionGap,
          general: e.target.value
        });
      } else if (val.id === 42) {
        setAlbuminValforcorrCal({ ...albuminValforcorrCal, general: e.target.value });
      } else if (val.id === 46) {
        setCalciumValforcorrCal({ ...calciumValforcorrCal, general: e.target.value });
      } else if (val.id === 47) {
        setSerumCretValforGFRF({ ...serumCretValforGFRF, general: e.target.value });
      }
      data.investigations.bk200[indexMain].result = Number(e.target.value);

      //set flag
      data.investigations.bk200[indexMain].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.bk200[indexMain].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.bk200[indexMain].maleRange
          : '',
        Number(e.target.value)
      );
    }
    //bk6190
    else if (medicalDevice === 'bk6190') {
      data.investigations.bk6190[indexMain].result = Number(e.target.value);
      //set flag
      data.investigations.bk6190[indexMain].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.bk6190[indexMain].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.bk6190[indexMain].maleRange
          : '',
        Number(e.target.value)
      );
    }
    //cbs300
    else if (medicalDevice === 'cbs300') {
      if (val?.id === 34) {
        setChlorideValforAnionGap({ ...chlorideValforAnionGap, general: e.target.value });
      } else if (val?.id === 35) {
        setPotassiumValforAnionGap({ ...potassiumValforAnionGap, general: e.target.value });
      } else if (val?.id === 36) {
        setSodiumValforAnionGap({ ...sodiumValforAnionGap, general: e.target.value });
      }
      data.investigations.cbs300[indexMain].result = Number(e.target.value);
      //set flag
      data.investigations.cbs300[indexMain].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.cbs300[indexMain].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.cbs300[indexMain].maleRange
          : '',
        Number(e.target.value)
      );
    }
    // other
    else if (medicalDevice === 'other') {
      data.investigations.other[indexMain].result = Number(e.target.value);
      //set flag
      data.investigations.other[indexMain].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.other[indexMain].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.other[indexMain].maleRange
          : '',
        Number(e.target.value)
      );
    }
    setEncounterDetails(data);
  };

  const checkLenghtOfAllPreReqsForAnionGap = () => {
    if (
      sodiumValforAnionGap?.general?.length !== 0 &&
      bicarbonateValforAnionGap?.general?.length !== 0 &&
      potassiumValforAnionGap?.general?.length !== 0 &&
      chlorideValforAnionGap?.general?.length !== 0
    ) {
      return true;
    }
    return false;
  };
  //Anion Gap
  useEffect(() => {
    if (medicalDevice === 'other') {
      const data = JSON.parse(JSON.stringify(encounterDetails));
      if (val.id === 37) {
        if (checkLenghtOfAllPreReqsForAnionGap()) {
          const calculation =
            Number(sodiumValforAnionGap?.general) +
            Number(potassiumValforAnionGap?.general) -
            (Number(chlorideValforAnionGap?.general) + Number(bicarbonateValforAnionGap?.general));
          setAnionGapVal({ ...anionGapVal, general: String(calculation) });
          data.investigations.other[indexMain].result = Number(calculation);
          //set flag
          data.investigations.other[indexMain].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.other[indexMain].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.other[indexMain].maleRange
              : '',
            Number(calculation)
          );
          setEncounterDetails(data);
        }
      }
    }
  }, [
    sodiumValforAnionGap,
    potassiumValforAnionGap,
    chlorideValforAnionGap,
    bicarbonateValforAnionGap
  ]);

  //Apolipoprotein B : Apolipoprotein A1 ratio
  useEffect(() => {
    if (medicalDevice === 'other') {
      const data: EncounterBody = JSON.parse(JSON.stringify(encounterDetails));
      if (val.id === 84) {
        if (apolipoBVal?.general?.length !== 0 && apolipoAVal?.general?.length !== 0) {
          const calculation = Number(apolipoBVal?.general) / Number(apolipoAVal?.general);
          setApolipoRatioVal({ ...apolipoRatioVal, general: String(calculation) });
          data.investigations.other[indexMain].result = Number(Number(calculation).toFixed(2));
          //set flag
          data.investigations.other[indexMain].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.other[indexMain].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.other[indexMain].maleRange
              : '',
            Number(Number(calculation).toFixed(2))
          );
          setEncounterDetails(data);
        }
      }
    }
  }, [apolipoAVal, apolipoBVal]);

  //Corrected Calcium
  useEffect(() => {
    if (medicalDevice === 'other') {
      const data = JSON.parse(JSON.stringify(encounterDetails));
      if (val.id === 38) {
        if (
          calciumValforcorrCal?.general?.length !== 0 &&
          albuminValforcorrCal?.general?.length !== 0
        ) {
          const calculation =
            0.02 * (40 - Number(albuminValforcorrCal?.general)) +
            Number(calciumValforcorrCal?.general);
          setCorrectedCalciumVal({
            ...correctedCalciumVal,
            general: String(calculation.toFixed(2))
          });

          data.investigations.other[indexMain].result = Number(Number(calculation).toFixed(2));

          //set flag
          data.investigations.other[indexMain].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.other[indexMain].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.other[indexMain].maleRange
              : '',
            Number(Number(calculation).toFixed(2))
          );
          setEncounterDetails(data);
        }
      }
    }
  }, [albuminValforcorrCal, calciumValforcorrCal]);

  const getAgeInYear = () => {
    const age = getAgeFromDate(patientData.dateOfBirth);
    const patientAge = age.split(' ');
    return Number(patientAge[0]);
  };

  useEffect(() => {
    if (medicalDevice === 'other') {
      const data = JSON.parse(JSON.stringify(encounterDetails));
      if (val.id === 39 || val.id === 40) {
        if (
          getAgeInYear() &&
          serumCretValforGFRF?.general.length !== 0 &&
          (firstPanelGFRFemaleWeight?.general.length !== 0 ||
            firstPanelGFRMaleWeight?.general.length !== 0)
        ) {
          const weight =
            val.id === 39
              ? Number(firstPanelGFRFemaleWeight?.general)
              : Number(firstPanelGFRMaleWeight?.general);
          const a = Number(
            (
              0.85 *
              ((140 - getAgeInYear()) / (Number(serumCretValforGFRF?.general) * 100)) *
              (weight / 72)
            ).toFixed(1)
          );
          val.id === 39
            ? setFirstPanelGFRFemaleVal({ ...firstPanelGFRFemaleVal, general: String(a) })
            : setFirstPanelGFRMaleVal({ ...firstPanelGFRMaleVal, general: String(a) });

          data.investigations.other[indexMain].result =
            val.id === 39
              ? Number(firstPanelGFRFemaleWeight?.general)
              : Number(firstPanelGFRMaleWeight?.general);
          //set flag
          data.investigations.other[indexMain].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.other[indexMain].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.other[indexMain].maleRange
              : '',
            val.id === 39
              ? Number(firstPanelGFRFemaleWeight?.general)
              : Number(firstPanelGFRMaleWeight?.general)
          );
          setEncounterDetails(data);
        }
      }
    }
  }, [serumCretValforGFRF, firstPanelGFRFemaleWeight, firstPanelGFRMaleWeight]);

  const onChangeGRF = (e: any, id: number) => {
    // if (checkNumberPositiveOrDecimal(e)) {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    if (serumCretValforGFRF?.general.length !== 0) {
      if (id === 39) {
        setFirstPanelGFRFemaleWeight({ ...firstPanelGFRFemaleWeight, general: e.target.value });
      } else {
        setFirstPanelGFRMaleWeight({ ...firstPanelGFRMaleWeight, general: e.target.value });
      }
      data.investigations.other[indexMain].result = Number(e.target.value);
      //set flag
      data.investigations.other[indexMain].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.other[indexMain].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.other[indexMain].maleRange
          : '',
        Number(e.target.value)
      );
      setEncounterDetails(data);
    } else {
      errorToast('Enter a value for Serum Creatinine first');
    }
  };

  const toggleRerun = () => {
    const id = `drop-${medicalDevice}-${val?.name === 'CBC' ? val?.parameter : val?.name}-${
      val.id
    }`;
    const body: HTMLElement | null = document.getElementById(id);
    if (body) {
      const check = body.classList.contains('d-none');
      if (check) {
        body.classList.remove('d-none');
      } else {
        body.classList.add('d-none');
      }
    }
  };
  return (
    <Row className="align-items-center my-2">
      <Col xs={4} className="font-size-14">
        {val?.name === 'CBC' ? val?.parameter : val?.name}
      </Col>
      <Col xs={2} className={'text-center'}>
        {
          //Anion Gap
          val?.id === 37 ? (
            <Input
              type="text"
              className={!edit ? '' : 'bg-white'}
              disabled
              style={{ color: '#2c3242' }}
              value={
                val?.result
                // nestedLevel === 0
                //   ? anionGapVal?.general
                //   : nestedLevel === 1
                //   ? anionGapVal?.nestedFirst
                //   : nestedLevel === 2
                //   ? anionGapVal?.nestedSecond
                //   : ''
              }
            />
          ) : val?.id === 38 ? (
            <Input
              type="number"
              className={!edit ? ' ' : 'bg-white'}
              disabled
              value={
                val?.result
                // nestedLevel === 0
                //   ? correctedCalciumVal?.general
                //   : nestedLevel === 1
                //   ? correctedCalciumVal?.nestedFirst
                //   : nestedLevel === 2
                //   ? correctedCalciumVal?.nestedSecond
                //   : ''
              }
            />
          ) : //GFR
          val?.id === 39 || val?.id === 40 ? (
            <div
              className="d-flex flex-row"
              style={!edit ? { border: 'none' } : { border: '1px solid #e3e3e3' }}
            >
              <Input
                type="number"
                className={!edit ? 'other-input ' : 'other-input  bg-white'}
                style={
                  !edit
                    ? {
                        border: 'none',
                        color: '#2c3242',
                        backgroundColor: 'inherit',
                        width: '120px'
                      }
                    : { backgroundColor: 'white', width: '120px' }
                }
                name="gfr"
                // style={{ width: '120px' }}
                min={0}
                onChange={(e) => {
                  val.id === 39 ? onChangeGRF(e, 39) : onChangeGRF(e, 40);
                }}
                disabled={!edit}
                value={
                  val?.result
                  // nestedLevel === 0
                  //   ? firstPanelGFRFemaleWeight?.general
                  //   : nestedLevel === 1
                  //   ? firstPanelGFRFemaleWeight?.nestedFirst
                  //   : nestedLevel === 2
                  //   ? firstPanelGFRFemaleWeight?.nestedSecond
                  //   : ''
                }
              />
              <span
                className="align-items-center justify-content-center"
                style={
                  !edit
                    ? { display: 'none' }
                    : {
                        width: '40px',
                        borderRight: '1px solid #e3e3e3',
                        display: 'flex'
                      }
                }
              >
                Kg
              </span>
              <Input
                type="number"
                style={
                  !edit
                    ? {
                        width: '80px',
                        border: 'none',
                        color: '#818287',
                        backgroundColor: 'inherit'
                      }
                    : { width: '80px', color: '#818287' }
                }
                disabled
                value={
                  // val?.result
                  val?.id === 39 ? firstPanelGFRFemaleVal?.general : firstPanelGFRMaleVal?.general
                }
              />
            </div>
          ) : //Apolipo
          val?.id === 84 ? (
            <Input
              type="number"
              style={
                !edit
                  ? { border: 'none', color: '#2c3242', backgroundColor: 'inherit' }
                  : { backgroundColor: 'white' }
              }
              disabled
              value={val?.result}
            />
          ) : (
            <Input
              type="number"
              style={
                !edit
                  ? { border: 'none', color: '#2c3242', backgroundColor: 'inherit' }
                  : { backgroundColor: 'white' }
              }
              disabled={!edit}
              onChange={onChangeInvestigationInput}
              value={val?.result}
            />
          )
        }
      </Col>
      <Col className="text-center font-size-14"> {val?.tracker}</Col>
      <Col className="text-center font-size-14">{val?.unit}</Col>
      <Col className="text-center font-size-14">
        {patientData?.gender === 'F'
          ? val?.femaleRange
          : patientData?.gender === 'M'
          ? val?.maleRange
          : ''}
      </Col>
      <Col className="text-center font-size-14">
        {val?.id === 84 ? (
          getApolipoFlag(val?.flag)
        ) : val?.id === 82 ? (
          getVitaminDFlag(val?.flag)
        ) : (
          <CustomPill flag={getFlag(val?.flag)} />
        )}
      </Col>
      <Col className="d-flex justify-content-end">
        <ThreeDotsIcon
          color="#696E7C"
          className={!edit ? 'cursor-pointer disable-pointer' : 'cursor-pointer '}
          onClick={toggleRerun}
        />
        <div
          id={`drop-${medicalDevice}-${val?.name === 'CBC' ? val?.parameter : val?.name}-${val.id}`}
          className="d-none"
          style={{ position: 'relative' }}
        >
          <div className="drop-down-container cursor-pointer" onClick={rerunInvestigation}>
            <h4 className="re-run mt-2">Re-run</h4>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default RenderInvestigation;
