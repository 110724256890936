import { createSlice } from '@reduxjs/toolkit';
import { fetchAllNotifications, fetchUnreadNotificationCount } from './thunks';
import { userManagement } from './types';

const initialState: userManagement = {
  notifications: [],
  isLoadingFetchAllNotifications: false,
  errorFetchAllNotifications: null,
  successFetchAllNotifications: false,
  unreadNotificationCount: 0,
  isLoadingUnreadNotificationCount: false,
  errorUnreadNotificationCount: null,
  successUnreadNotificationCount: false
};
const userSlice = createSlice({
  name: 'userManagement',
  initialState,
  extraReducers: (builder) => {
    //get all
    builder.addCase(fetchAllNotifications.pending, (state) => {
      state.isLoadingFetchAllNotifications = true;
      state.errorFetchAllNotifications = '';
      state.successFetchAllNotifications = false;
    });
    builder.addCase(fetchAllNotifications.fulfilled, (state, { payload }) => {
      state.isLoadingFetchAllNotifications = false;
      state.errorFetchAllNotifications = '';
      state.notifications = payload;
      state.successFetchAllNotifications = true;
    });
    builder.addCase(fetchAllNotifications.rejected, (state, action) => {
      state.isLoadingFetchAllNotifications = false;
      state.successFetchAllNotifications = false;
      if (action.error) {
        state.errorFetchAllNotifications = action.error.message as string;
      } else {
        state.errorFetchAllNotifications = 'Error fetching notifications';
      }
    });
    //get unread count
    builder.addCase(fetchUnreadNotificationCount.pending, (state) => {
      state.isLoadingUnreadNotificationCount = true;
      state.errorUnreadNotificationCount = '';
      state.successUnreadNotificationCount = false;
    });
    builder.addCase(fetchUnreadNotificationCount.fulfilled, (state, { payload }) => {
      state.isLoadingUnreadNotificationCount = false;
      state.errorUnreadNotificationCount = '';
      state.unreadNotificationCount = payload;
      state.successUnreadNotificationCount = true;
    });
    builder.addCase(fetchUnreadNotificationCount.rejected, (state, action) => {
      state.isLoadingUnreadNotificationCount = false;
      state.successUnreadNotificationCount = false;
      if (action.error) {
        state.errorUnreadNotificationCount = action.error.message as string;
      } else {
        state.errorUnreadNotificationCount = 'Error fetching unread notification';
      }
    });
  },
  reducers: {
    resetFetchAllNotifications: (state) => {
      state.isLoadingFetchAllNotifications = false;
      state.errorFetchAllNotifications = null;
      state.successFetchAllNotifications = false;
    },
    resetFetchUnreadNotificationCount: (state) => {
      state.isLoadingUnreadNotificationCount = false;
      state.errorUnreadNotificationCount = null;
      state.successUnreadNotificationCount = false;
    }
  }
});

// // Action creators are generated for each case reducer function
export const { resetFetchAllNotifications, resetFetchUnreadNotificationCount } = userSlice.actions;
export default userSlice.reducer;
