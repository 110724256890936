import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError } from '~/models/Error.model';
import { deleteRequest, getRequest, postRequest, putRequest } from '~/store/apiHelper';
import { AddItemBody, UpdateItemBody } from './types';
export const GetItems = createAsyncThunk<any, string, { rejectValue: MyKnownError }>(
  'inventory/GetItems',
  async (filter, thunkApi) => {
    return getRequest(`/inventory/${filter}`, true, thunkApi);
  }
);
//
export const AddItems = createAsyncThunk<any, AddItemBody, { rejectValue: MyKnownError }>(
  'inventory/AddItem',
  async (data, thunkApi) => {
    return postRequest(`/inventory`, data, true, thunkApi);
  }
);
export const UpdateItems = createAsyncThunk<any, UpdateItemBody, { rejectValue: MyKnownError }>(
  'inventory/UpdateItem',
  async (data, thunkApi) => {
    return putRequest(`/inventory`, data, true, thunkApi);
  }
);

export const DeleteItem = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'item/delete',
  async (id, thunkApi) => {
    return deleteRequest(`/inventory/${id}`, null, true, thunkApi);
  }
);
export const DeleteBatch = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'batch/delete',
  async (id, thunkApi) => {
    return deleteRequest(`/batch/${id}`, null, true, thunkApi);
  }
);
