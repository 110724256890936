import { Instance } from '../utils/axios';
import { AxiosError } from 'axios';
import { MyKnownError } from '~/models/Error.model';

export const postRequest = async (url: string, data: any, authorized = false, thunkApi: any) => {
  const headers = {
    Authorization: ''
  };
  if (authorized) {
    headers['Authorization'] = await getToken();
  }
  try {
    const response = await Instance.post(url, data, { headers: headers });
    if (response.status !== 200) {
      return thunkApi.rejectWithValue(response.data as MyKnownError);
    }
    return response.data.data;
  } catch (err: any) {
    const error: AxiosError<MyKnownError> = err.response.data.message;
    if (err.response.status === 401 || err.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (!error.response) {
      throw error;
    }

    return thunkApi.rejectWithValue(error);
  }
};

export const getRequest = async (url: string, authorized = false, thunkApi: any) => {
  const headers = {
    Authorization: ''
  };
  if (authorized) {
    headers['Authorization'] = await getToken();
  }

  try {
    const response = await Instance.get(url, { headers: headers });
    if (response.status !== 200) {
      return thunkApi.rejectWithValue(response.data as MyKnownError);
    }

    return response.data.data;
  } catch (err: any) {
    const error: AxiosError<MyKnownError> = err.response.data.message;
    if (err.response.status === 401 || err.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (!error.response) {
      throw error;
    }

    return thunkApi.rejectWithValue(error);
  }
};

export const putRequest = async (url: string, data: any, authorized = false, thunkApi: any) => {
  const headers = {
    Authorization: ''
  };
  if (authorized) {
    headers['Authorization'] = await getToken();
  }
  try {
    const response = await Instance.put(url, data, { headers: headers });
    if (response.status !== 200) {
      return thunkApi.rejectWithValue(response.data as MyKnownError);
    }

    return response.data.data;
  } catch (err: any) {
    const error: AxiosError<MyKnownError> = err.response.data.message;
    if (err.response.status === 401 || err.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    if (!error.response) {
      throw error;
    }

    return thunkApi.rejectWithValue(error);
  }
};

export const deleteRequest = async (url: string, data: any, authorized = false, thunkApi: any) => {
  const headers = {
    Authorization: ''
  };
  if (authorized) {
    headers['Authorization'] = await getToken();
  }
  try {
    const response = await Instance.delete(url, { headers: headers });
    if (response.status !== 200) {
      return thunkApi.rejectWithValue(response.data as MyKnownError);
    }
    return response.data.data;
  } catch (err: any) {
    const error: AxiosError<MyKnownError> = err.response.data.message;
    if (err.response.status === 401 || err.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (!error.response) {
      throw error;
    }

    return thunkApi.rejectWithValue(error);
  }
};
const getToken = () => {
  const data = localStorage.getItem('@auth');
  if (data) {
    const token = JSON.parse(data).token;
    return 'Bearer ' + token;
  }
  return '';
};
