import React, { FC, useEffect, useState } from 'react';
import { Investigation, PanelInvestigationBody } from '~/models/modalsInterfaces.model';
import { getNumberOfRequests } from '~/utils/utilMethods';
import './CreateEncounter.styles.scss';
import RenderRow from './RenderRow';
interface OtherValues {
  general: string;
  nestedFirst: string;
  nestedSecond: string;
}
const RenderPanel: FC<any> = ({
  panel,
  indexMain,
  encounterDetails,
  setEncounterDetails,
  patientData,
  edit
}) => {
  const [anionGapVal, setAnionGapVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [sodiumValforAnionGap, setSodiumValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [potassiumValforAnionGap, setPotassiumValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [chlorideValforAnionGap, setChlorideValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [bicarbonateValforAnionGap, setBicarbonateValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [firstPanelGFRMaleWeight, setFirstPanelGFRMaleWeight] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [firstPanelGFRFemaleWeight, setFirstPanelGFRFemaleWeight] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [serumCretValforGFRF, setSerumCretValforGFRF] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [apolipoRatioVal, setApolipoRatioVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [apolipoAVal, setApolipoAVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [apolipoBVal, setApolipoBVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [correctedCalciumVal, setCorrectedCalciumVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [albuminValforcorrCal, setAlbuminValforcorrCal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [calciumValforcorrCal, setCalciumValforcorrCal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  useEffect(() => {
    if (encounterDetails) {
      //GENERAL
      //GFR
      let serumTemp: OtherValues = {
        general: '',
        nestedFirst: '',
        nestedSecond: ''
      };
      let gfrfTemp: OtherValues = {
        general: '',
        nestedFirst: '',
        nestedSecond: ''
      };
      let gfrmTemp: OtherValues = {
        general: '',
        nestedFirst: '',
        nestedSecond: ''
      };

      panel?.investigations &&
        panel?.investigations?.other?.length > 0 &&
        panel?.investigations?.other?.forEach((other: Investigation) => {
          //GFR
          if (other?.id === 39 || other?.id === 40) {
            if (serumCretValforGFRF?.general === '') {
              const serum =
                encounterDetails?.investigations?.panelInvestigation[
                  indexMain
                ]?.investigations?.bk200?.findIndex((bk200: Investigation) => bk200.id === 47) ??
                -1;
              if (serum !== -1)
                serumTemp = {
                  ...serumCretValforGFRF,
                  general: String(
                    encounterDetails?.investigations?.panelInvestigation[indexMain]?.investigations
                      ?.bk200[serum]?.result ?? ''
                  )
                };
              setSerumCretValforGFRF(serumTemp);
            }
            if (firstPanelGFRFemaleWeight?.general === '') {
              const gfrf =
                encounterDetails?.investigations?.panelInvestigation[
                  indexMain
                ]?.investigations?.other?.findIndex((other: Investigation) => other.id === 39) ??
                -1;
              if (gfrf !== -1)
                gfrfTemp = {
                  ...firstPanelGFRFemaleWeight,
                  general: String(
                    encounterDetails?.investigations?.panelInvestigation[indexMain]?.investigations
                      ?.other[gfrf]?.result ?? ''
                  )
                };
              setFirstPanelGFRFemaleWeight(gfrfTemp);
            }
            if (firstPanelGFRMaleWeight?.general === '') {
              const gfrm =
                encounterDetails?.investigations?.panelInvestigation[
                  indexMain
                ]?.investigations?.other?.findIndex((other: Investigation) => other.id === 40) ??
                -1;
              if (gfrm !== -1)
                gfrmTemp = {
                  ...firstPanelGFRMaleWeight,
                  general: String(
                    encounterDetails?.investigations?.panelInvestigation[indexMain]?.investigations
                      ?.other[gfrm].result ?? ''
                  )
                };
              setFirstPanelGFRMaleWeight(gfrmTemp);
            }
          }
        });
      //NESTED FIRST

      panel?.investigations &&
        panel.investigations?.panelInvestigation?.length > 0 &&
        panel?.investigations?.panelInvestigation.forEach(
          (nestedFirstPanel: PanelInvestigationBody, firstPanelIndex: number) => {
            nestedFirstPanel?.investigations &&
              nestedFirstPanel.investigations?.other?.length > 0 &&
              nestedFirstPanel?.investigations?.other?.forEach((other: Investigation) => {
                if (other.id === 39 || other.id === 40) {
                  if (serumCretValforGFRF?.nestedFirst === '') {
                    const serum =
                      encounterDetails?.investigations?.panelInvestigation[
                        indexMain
                      ]?.investigations?.panelInvestigation[
                        firstPanelIndex
                      ]?.investigations?.bk200?.findIndex(
                        (bk200: Investigation) => bk200.id === 47
                      ) ?? -1;
                    if (serum !== -1) {
                      serumTemp = {
                        ...serumTemp,
                        nestedFirst: String(
                          encounterDetails?.investigations?.panelInvestigation[indexMain]
                            ?.investigations?.panelInvestigation[firstPanelIndex]?.investigations
                            ?.bk200[serum]?.result ?? ''
                        )
                      };
                      setSerumCretValforGFRF(serumTemp);
                    }
                  }
                  if (firstPanelGFRFemaleWeight?.nestedFirst === '') {
                    const gfrf =
                      encounterDetails?.investigations?.panelInvestigation[
                        indexMain
                      ]?.investigations?.panelInvestigation[
                        firstPanelIndex
                      ]?.investigations?.other?.findIndex(
                        (other: Investigation) => other.id === 39
                      ) ?? -1;
                    if (gfrf !== -1) {
                      gfrfTemp = {
                        ...gfrfTemp,
                        nestedFirst: String(
                          encounterDetails?.investigations?.panelInvestigation[indexMain]
                            .investigations?.panelInvestigation[firstPanelIndex]?.investigations
                            .other[gfrf]?.result ?? ''
                        )
                      };
                      setFirstPanelGFRFemaleWeight(gfrfTemp);
                    }
                  }
                  if (firstPanelGFRMaleWeight?.nestedFirst === '') {
                    const gfrm =
                      encounterDetails?.investigations?.panelInvestigation[
                        indexMain
                      ]?.investigations?.panelInvestigation[
                        firstPanelIndex
                      ]?.investigations?.other?.findIndex(
                        (other: Investigation) => other.id === 40
                      ) ?? -1;
                    if (gfrm !== -1) {
                      gfrmTemp = {
                        ...gfrmTemp,
                        nestedFirst: String(
                          encounterDetails?.investigations?.panelInvestigation[indexMain]
                            ?.investigations?.panelInvestigation[firstPanelIndex]?.investigations
                            ?.other[gfrm]?.result ?? ''
                        )
                      };
                      setFirstPanelGFRMaleWeight(gfrmTemp);
                    }
                  }
                }
              });
            //NESTED SECOND
            nestedFirstPanel?.investigations &&
              nestedFirstPanel.investigations?.panelInvestigation?.length > 0 &&
              nestedFirstPanel?.investigations?.panelInvestigation?.forEach(
                (nestedSecondPanel: PanelInvestigationBody, secondPanelIndex: number) => {
                  nestedSecondPanel?.investigations &&
                    nestedSecondPanel.investigations?.other?.length > 0 &&
                    nestedSecondPanel?.investigations?.other?.forEach((other: Investigation) => {
                      if (other.id === 39 || other.id === 40) {
                        if (serumCretValforGFRF?.nestedSecond === '') {
                          const serum =
                            encounterDetails?.investigations?.panelInvestigation[
                              indexMain
                            ]?.investigations?.panelInvestigation[
                              firstPanelIndex
                            ]?.investigations?.panelInvestigation[
                              secondPanelIndex
                            ]?.investigations?.bk200?.findIndex(
                              (bk200: Investigation) => bk200.id === 47
                            ) ?? -1;
                          if (serum !== -1)
                            setSerumCretValforGFRF({
                              ...serumTemp,
                              nestedSecond: String(
                                encounterDetails?.investigations?.panelInvestigation[indexMain]
                                  ?.investigations?.panelInvestigation[firstPanelIndex]
                                  ?.investigations?.panelInvestigation[secondPanelIndex]
                                  ?.investigations?.bk200[serum]?.result ?? ''
                              )
                            });
                        }
                        if (firstPanelGFRFemaleWeight?.nestedSecond === '') {
                          const gfrf =
                            encounterDetails?.investigations?.panelInvestigation[
                              indexMain
                            ]?.investigations?.panelInvestigation[
                              firstPanelIndex
                            ]?.investigations?.panelInvestigation[
                              secondPanelIndex
                            ]?.investigations?.other?.findIndex(
                              (other: Investigation) => other.id === 39
                            ) ?? -1;
                          if (gfrf !== -1)
                            setFirstPanelGFRFemaleWeight({
                              ...gfrfTemp,
                              nestedSecond: String(
                                encounterDetails?.investigations?.panelInvestigation[indexMain]
                                  ?.investigations?.panelInvestigation[firstPanelIndex]
                                  ?.investigations?.panelInvestigation[secondPanelIndex]
                                  ?.investigations?.other[gfrf]?.result ?? ''
                              )
                            });
                        }
                        if (firstPanelGFRMaleWeight?.nestedSecond === '') {
                          const gfrm =
                            encounterDetails?.investigations?.panelInvestigation[
                              indexMain
                            ]?.investigations?.panelInvestigation[
                              firstPanelIndex
                            ]?.investigations?.panelInvestigation[
                              secondPanelIndex
                            ]?.investigations?.other?.findIndex(
                              (other: Investigation) => other.id === 40
                            ) ?? -1;
                          if (gfrm !== -1)
                            setFirstPanelGFRMaleWeight({
                              ...gfrmTemp,
                              nestedSecond: String(
                                encounterDetails?.investigations?.panelInvestigation[indexMain]
                                  ?.investigations?.panelInvestigation[firstPanelIndex]
                                  ?.investigations?.panelInvestigation[secondPanelIndex]
                                  ?.investigations?.other[gfrm]?.result ?? ''
                              )
                            });
                        }
                      }
                    });
                }
              );
          }
        );
    }
  }, [encounterDetails]);

  return (
    <div>
      {/* 1st Sub panel */}
      {panel?.investigations &&
        panel.investigations?.panelInvestigation?.length > 0 &&
        panel?.investigations?.panelInvestigation?.map((val2: any, index2: number) => (
          <div key={index2}>
            <p className="small-label mt-4">{val2?.name}:</p>

            {/* Bk1000 */}
            {val2?.investigations &&
              val2?.investigations?.bk1000?.length > 0 &&
              val2?.investigations?.bk1000?.map((val3: any, index3: number) => (
                <RenderRow
                  val={val3}
                  firstPanelInvestigationIndex={index3}
                  firstPanelIndex={index2}
                  key={index3}
                  medicalDevice="bk1000"
                  encounterDetails={encounterDetails}
                  setEncounterDetails={setEncounterDetails}
                  indexMain={indexMain}
                  patientData={patientData}
                  nestedLevel={1}
                  setApolipoAVal={setApolipoAVal}
                  setApolipoBVal={setApolipoBVal}
                  apolipoAVal={apolipoAVal}
                  apolipoBVal={apolipoBVal}
                  edit={edit}
                />
              ))}
            {/* Bk200 */}
            {val2?.investigations &&
              val2?.investigations?.bk200?.length > 0 &&
              val2?.investigations?.bk200?.map((val3: any, index3: number) => (
                <RenderRow
                  val={val3}
                  firstPanelInvestigationIndex={index3}
                  firstPanelIndex={index2}
                  key={index3}
                  medicalDevice="bk200"
                  encounterDetails={encounterDetails}
                  setEncounterDetails={setEncounterDetails}
                  indexMain={indexMain}
                  patientData={patientData}
                  nestedLevel={1}
                  bicarbonateValforAnionGap={bicarbonateValforAnionGap}
                  setBicarbonateValforAnionGap={setBicarbonateValforAnionGap}
                  serumCretValforGFRF={serumCretValforGFRF}
                  setSerumCretValforGFRF={setSerumCretValforGFRF}
                  albuminValforcorrCal={albuminValforcorrCal}
                  setAlbuminValforcorrCal={setAlbuminValforcorrCal}
                  calciumValforcorrCal={calciumValforcorrCal}
                  setCalciumValforcorrCal={setCalciumValforcorrCal}
                  edit={edit}
                />
              ))}
            {/* bk6190 */}
            {val2?.investigations &&
              val2?.investigations?.bk6190?.length > 0 &&
              val2?.investigations?.bk6190?.map((val3: any, index3: number) => (
                <RenderRow
                  val={val3}
                  firstPanelInvestigationIndex={index3}
                  firstPanelIndex={index2}
                  key={index3}
                  medicalDevice="bk6190"
                  encounterDetails={encounterDetails}
                  setEncounterDetails={setEncounterDetails}
                  indexMain={indexMain}
                  patientData={patientData}
                  nestedLevel={1}
                  edit={edit}
                />
              ))}
            {/* cbs300 */}
            {val2?.investigations &&
              val2?.investigations?.cbs300?.length > 0 &&
              val2?.investigations?.cbs300?.map((val3: any, index3: number) => (
                <RenderRow
                  val={val3}
                  firstPanelInvestigationIndex={index3}
                  firstPanelIndex={index2}
                  key={index3}
                  medicalDevice="cbs300"
                  encounterDetails={encounterDetails}
                  setEncounterDetails={setEncounterDetails}
                  indexMain={indexMain}
                  patientData={patientData}
                  nestedLevel={1}
                  sodiumValforAnionGap={sodiumValforAnionGap}
                  setSodiumValforAnionGap={setSodiumValforAnionGap}
                  potassiumValforAnionGap={potassiumValforAnionGap}
                  setPotassiumValforAnionGap={setPotassiumValforAnionGap}
                  chlorideValforAnionGap={chlorideValforAnionGap}
                  setChlorideValforAnionGap={setChlorideValforAnionGap}
                  edit={edit}
                />
              ))}
            {/* others */}
            {val2?.investigations &&
              val2?.investigations?.other?.length > 0 &&
              val2?.investigations?.other?.map((val3: any, index3: number) => (
                <RenderRow
                  val={val3}
                  firstPanelInvestigationIndex={index3}
                  firstPanelIndex={index2}
                  key={index3}
                  medicalDevice="other"
                  encounterDetails={encounterDetails}
                  setEncounterDetails={setEncounterDetails}
                  indexMain={indexMain}
                  patientData={patientData}
                  nestedLevel={1}
                  //anion gap
                  anionGapVal={anionGapVal}
                  setAnionGapVal={setAnionGapVal}
                  sodiumValforAnionGap={sodiumValforAnionGap}
                  potassiumValforAnionGap={potassiumValforAnionGap}
                  chlorideValforAnionGap={chlorideValforAnionGap}
                  bicarbonateValforAnionGap={bicarbonateValforAnionGap}
                  //gfr
                  firstPanelGFRFemaleWeight={firstPanelGFRFemaleWeight}
                  setFirstPanelGFRFemaleWeight={setFirstPanelGFRFemaleWeight}
                  firstPanelGFRMaleWeight={firstPanelGFRMaleWeight}
                  setFirstPanelGFRMaleWeight={setFirstPanelGFRMaleWeight}
                  // setFirstPanelGFRFemaleVal={setFirstPanelGFRFemaleVal}
                  // firstPanelGFRFemaleVal={firstPanelGFRFemaleVal}
                  // firstPanelGFRMaleVal={firstPanelGFRMaleVal}
                  // setFirstPanelGFRMaleVal={setFirstPanelGFRMaleVal}
                  serumCretValforGFRF={serumCretValforGFRF}
                  setSerumCretValforGFRF={setSerumCretValforGFRF}
                  //apolipo ratio
                  apolipoRatioVal={apolipoRatioVal}
                  setApolipoRatioVal={setApolipoRatioVal}
                  apolipoAVal={apolipoAVal}
                  apolipoBVal={apolipoBVal}
                  //corrected calcium
                  correctedCalciumVal={correctedCalciumVal}
                  setCorrectedCalciumVal={setCorrectedCalciumVal}
                  albuminValforcorrCal={albuminValforcorrCal}
                  calciumValforcorrCal={calciumValforcorrCal}
                  edit={edit}
                />
              ))}
            {/* 2nd sub panel*/}
            {val2?.investigations &&
              val2.investigations?.panelInvestigation?.length > 0 &&
              val2?.investigations?.panelInvestigation?.map((val4: any, index4: number) => (
                <div key={index4}>
                  <p className="small-label mt-4">{val4?.name}:</p>
                  {/* Bk1000 */}
                  {val4?.investigations &&
                    val4?.investigations?.bk1000?.length > 0 &&
                    val4?.investigations?.bk1000?.map((val5: any, index5: number) => (
                      <RenderRow
                        val={val5}
                        secondPanelInvestigationIndex={index5}
                        secondPanelIndex={index4}
                        firstPanelIndex={index2}
                        key={index5}
                        medicalDevice="bk1000"
                        encounterDetails={encounterDetails}
                        setEncounterDetails={setEncounterDetails}
                        indexMain={indexMain}
                        patientData={patientData}
                        nestedLevel={2}
                        setApolipoAVal={setApolipoAVal}
                        setApolipoBVal={setApolipoBVal}
                        apolipoAVal={apolipoAVal}
                        apolipoBVal={apolipoBVal}
                        edit={edit}
                      />
                    ))}
                  {/* Bk200 */}
                  {val4?.investigations &&
                    val4?.investigations?.bk200?.length > 0 &&
                    val4?.investigations?.bk200?.map((val5: any, index5: number) => (
                      <RenderRow
                        val={val5}
                        secondPanelInvestigationIndex={index5}
                        secondPanelIndex={index4}
                        firstPanelIndex={index2}
                        key={index5}
                        medicalDevice="bk200"
                        encounterDetails={encounterDetails}
                        setEncounterDetails={setEncounterDetails}
                        indexMain={indexMain}
                        patientData={patientData}
                        nestedLevel={2}
                        bicarbonateValforAnionGap={bicarbonateValforAnionGap}
                        setBicarbonateValforAnionGap={setBicarbonateValforAnionGap}
                        serumCretValforGFRF={serumCretValforGFRF}
                        setSerumCretValforGFRF={setSerumCretValforGFRF}
                        albuminValforcorrCal={albuminValforcorrCal}
                        setAlbuminValforcorrCal={setAlbuminValforcorrCal}
                        calciumValforcorrCal={calciumValforcorrCal}
                        setCalciumValforcorrCal={setCalciumValforcorrCal}
                        edit={edit}
                      />
                    ))}
                  {/* bk6190 */}
                  {val4?.investigations &&
                    val4?.investigations?.bk6190?.length > 0 &&
                    val4?.investigations?.bk6190?.map((val5: any, index5: number) => (
                      <RenderRow
                        val={val5}
                        secondPanelInvestigationIndex={index5}
                        secondPanelIndex={index4}
                        firstPanelIndex={index2}
                        key={index5}
                        medicalDevice="bk6190"
                        encounterDetails={encounterDetails}
                        setEncounterDetails={setEncounterDetails}
                        indexMain={indexMain}
                        patientData={patientData}
                        nestedLevel={2}
                        edit={edit}
                      />
                    ))}

                  {/* cbs300 */}
                  {val4?.investigations &&
                    val4?.investigations?.cbs300?.length > 0 &&
                    val4?.investigations?.cbs300?.map((val5: any, index5: number) => (
                      <RenderRow
                        val={val5}
                        secondPanelInvestigationIndex={index5}
                        secondPanelIndex={index4}
                        firstPanelIndex={index2}
                        key={index5}
                        medicalDevice="cbs300"
                        encounterDetails={encounterDetails}
                        setEncounterDetails={setEncounterDetails}
                        indexMain={indexMain}
                        patientData={patientData}
                        nestedLevel={2}
                        sodiumValforAnionGap={sodiumValforAnionGap}
                        setSodiumValforAnionGap={setSodiumValforAnionGap}
                        potassiumValforAnionGap={potassiumValforAnionGap}
                        setPotassiumValforAnionGap={setPotassiumValforAnionGap}
                        chlorideValforAnionGap={chlorideValforAnionGap}
                        setChlorideValforAnionGap={setChlorideValforAnionGap}
                        edit={edit}
                      />
                    ))}
                  {/* others */}
                  {val4?.investigations &&
                    val4?.investigations?.other?.length > 0 &&
                    val4?.investigations?.other?.map((val5: any, index5: number) => (
                      <RenderRow
                        val={val5}
                        secondPanelInvestigationIndex={index5}
                        secondPanelIndex={index4}
                        firstPanelIndex={index2}
                        key={index5}
                        medicalDevice="other"
                        encounterDetails={encounterDetails}
                        setEncounterDetails={setEncounterDetails}
                        indexMain={indexMain}
                        patientData={patientData}
                        nestedLevel={2} //anion gap
                        anionGapVal={anionGapVal}
                        setAnionGapVal={setAnionGapVal}
                        sodiumValforAnionGap={sodiumValforAnionGap}
                        potassiumValforAnionGap={potassiumValforAnionGap}
                        chlorideValforAnionGap={chlorideValforAnionGap}
                        bicarbonateValforAnionGap={bicarbonateValforAnionGap}
                        //gfr
                        firstPanelGFRFemaleWeight={firstPanelGFRFemaleWeight}
                        setFirstPanelGFRFemaleWeight={setFirstPanelGFRFemaleWeight}
                        firstPanelGFRMaleWeight={firstPanelGFRMaleWeight}
                        setFirstPanelGFRMaleWeight={setFirstPanelGFRMaleWeight}
                        // setFirstPanelGFRFemaleVal={setFirstPanelGFRFemaleVal}
                        // firstPanelGFRFemaleVal={firstPanelGFRFemaleVal}
                        // firstPanelGFRMaleVal={firstPanelGFRMaleVal}
                        // setFirstPanelGFRMaleVal={setFirstPanelGFRMaleVal}
                        serumCretValforGFRF={serumCretValforGFRF}
                        setSerumCretValforGFRF={setSerumCretValforGFRF}
                        //apolipo ratio
                        apolipoRatioVal={apolipoRatioVal}
                        setApolipoRatioVal={setApolipoRatioVal}
                        apolipoAVal={apolipoAVal}
                        apolipoBVal={apolipoBVal}
                        //corrected calcium
                        correctedCalciumVal={correctedCalciumVal}
                        setCorrectedCalciumVal={setCorrectedCalciumVal}
                        albuminValforcorrCal={albuminValforcorrCal}
                        calciumValforcorrCal={calciumValforcorrCal}
                        edit={edit}
                      />
                    ))}
                </div>
              ))}
          </div>
        ))}
      {getNumberOfRequests(
        0,
        panel?.investigations?.bk1000.length,
        panel?.investigations?.bk200.length,
        panel?.investigations?.bk6190.length,
        panel?.investigations?.cbs300.length,
        panel?.investigations?.other.length
      ) !== 0 && <p className="small-label mt-4">general investigations:</p>}
      {/* //bk1000 */}
      {panel?.investigations &&
        panel?.investigations?.bk1000?.length > 0 &&
        panel?.investigations?.bk1000?.map((val: any, index: number) => (
          <RenderRow
            val={val}
            encounterInvestigationIndex={index}
            key={index}
            medicalDevice="bk1000"
            encounterDetails={encounterDetails}
            setEncounterDetails={setEncounterDetails}
            indexMain={indexMain}
            patientData={patientData}
            nestedLevel={0}
            setApolipoAVal={setApolipoAVal}
            setApolipoBVal={setApolipoBVal}
            apolipoAVal={apolipoAVal}
            apolipoBVal={apolipoBVal}
            edit={edit}
          />
        ))}
      {/* bk200 */}
      {panel?.investigations &&
        panel?.investigations?.bk200?.length > 0 &&
        panel?.investigations?.bk200?.map((val: any, index: number) => (
          <RenderRow
            val={val}
            encounterInvestigationIndex={index}
            key={index}
            medicalDevice="bk200"
            encounterDetails={encounterDetails}
            setEncounterDetails={setEncounterDetails}
            indexMain={indexMain}
            patientData={patientData}
            nestedLevel={0}
            bicarbonateValforAnionGap={bicarbonateValforAnionGap}
            setBicarbonateValforAnionGap={setBicarbonateValforAnionGap}
            serumCretValforGFRF={serumCretValforGFRF}
            setSerumCretValforGFRF={setSerumCretValforGFRF}
            albuminValforcorrCal={albuminValforcorrCal}
            setAlbuminValforcorrCal={setAlbuminValforcorrCal}
            calciumValforcorrCal={calciumValforcorrCal}
            setCalciumValforcorrCal={setCalciumValforcorrCal}
            edit={edit}
          />
        ))}
      {/* //bk6190 */}
      {panel?.investigations &&
        panel?.investigations?.bk6190?.length > 0 &&
        panel?.investigations?.bk6190?.map((val: any, index: number) => (
          <RenderRow
            val={val}
            encounterInvestigationIndex={index}
            key={index}
            medicalDevice="bk6190"
            encounterDetails={encounterDetails}
            setEncounterDetails={setEncounterDetails}
            indexMain={indexMain}
            patientData={patientData}
            nestedLevel={0}
            edit={edit}
          />
        ))}
      {/* cbs300 */}
      {panel?.investigations &&
        panel?.investigations?.cbs300?.length > 0 &&
        panel?.investigations?.cbs300?.map((val: any, index: number) => (
          <RenderRow
            val={val}
            encounterInvestigationIndex={index}
            key={index}
            medicalDevice="cbs300"
            encounterDetails={encounterDetails}
            setEncounterDetails={setEncounterDetails}
            indexMain={indexMain}
            patientData={patientData}
            nestedLevel={0}
            sodiumValforAnionGap={sodiumValforAnionGap}
            setSodiumValforAnionGap={setSodiumValforAnionGap}
            potassiumValforAnionGap={potassiumValforAnionGap}
            setPotassiumValforAnionGap={setPotassiumValforAnionGap}
            chlorideValforAnionGap={chlorideValforAnionGap}
            setChlorideValforAnionGap={setChlorideValforAnionGap}
            edit={edit}
          />
        ))}

      {/* others */}
      {panel?.investigations &&
        panel?.investigations?.other?.length > 0 &&
        panel?.investigations?.other?.map((val: any, index: number) => (
          <RenderRow
            val={val}
            encounterInvestigationIndex={index}
            key={index}
            medicalDevice="other"
            encounterDetails={encounterDetails}
            setEncounterDetails={setEncounterDetails}
            indexMain={indexMain}
            patientData={patientData}
            nestedLevel={0}
            //anion gap
            anionGapVal={anionGapVal}
            setAnionGapVal={setAnionGapVal}
            sodiumValforAnionGap={sodiumValforAnionGap}
            potassiumValforAnionGap={potassiumValforAnionGap}
            chlorideValforAnionGap={chlorideValforAnionGap}
            bicarbonateValforAnionGap={bicarbonateValforAnionGap}
            //gfr
            firstPanelGFRFemaleWeight={firstPanelGFRFemaleWeight}
            setFirstPanelGFRFemaleWeight={setFirstPanelGFRFemaleWeight}
            firstPanelGFRMaleWeight={firstPanelGFRMaleWeight}
            setFirstPanelGFRMaleWeight={setFirstPanelGFRMaleWeight}
            // setFirstPanelGFRFemaleVal={setFirstPanelGFRFemaleVal}
            // firstPanelGFRFemaleVal={firstPanelGFRFemaleVal}
            // firstPanelGFRMaleVal={firstPanelGFRMaleVal}
            // setFirstPanelGFRMaleVal={setFirstPanelGFRMaleVal}
            serumCretValforGFRF={serumCretValforGFRF}
            setSerumCretValforGFRF={setSerumCretValforGFRF}
            //apolipo ratio
            apolipoRatioVal={apolipoRatioVal}
            setApolipoRatioVal={setApolipoRatioVal}
            apolipoAVal={apolipoAVal}
            apolipoBVal={apolipoBVal}
            //corrected calcium
            correctedCalciumVal={correctedCalciumVal}
            setCorrectedCalciumVal={setCorrectedCalciumVal}
            albuminValforcorrCal={albuminValforcorrCal}
            calciumValforcorrCal={calciumValforcorrCal}
            edit={edit}
          />
        ))}
    </div>
  );
};

export default RenderPanel;
