export type keyItem = string;
const Async = {
  setItem(item: keyItem, data: any) {
    const parsedData = JSON.stringify(data);
    localStorage.setItem(item, parsedData);
  },

  getItem(item: keyItem) {
    const data = localStorage.getItem(item);
    return data ? JSON.parse(data) : null;
  },

  removeItem(item: keyItem) {
    return localStorage.removeItem(item);
  }
};

export default Async;
