import React, { FC, useEffect } from 'react';
import { Switch, RouteProps, BrowserRouter as Router } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from '~/routes';
import AuthMiddleWare from '~/routes/route';
import NonAuthLayout from '~/components/NonAuthLayout';
import VerticalLayout from '~/components/VerticalLayout';
import { ToastContainer } from 'react-toastify';
import { setSearchBarList } from './store/reducers/PureRedux';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { fetchAllPatients } from './store/reducers/patient/thunks';
import { resetFetchAllPatients } from './store/reducers/patient';
import { PatientBody } from './store/reducers/patient/types';
import { fetchAllEncounters } from './store/reducers/encounter/thunks';
import { resetFetchPatientEncounters } from './store/reducers/encounter';
import { EncounterBody } from './store/reducers/encounter/types';
import { GetItems } from './store/reducers/inventory/thunks';
import { resetGetItems } from './store/reducers/inventory';
import { InventoryBody } from './store/reducers/inventory/types';

export const App: FC = () => {
  const dispatch = useAppDispatch();

  const {
    //fetch all patients
    patients: reduxPatients,
    isLoadingFetchAllPatients,
    errorFetchAllPatients,
    successFetchAllPatients
  } = useAppSelector((state) => state.PatientReducer);
  const { searchBarList } = useAppSelector((state) => state.PureRedux);
  const {
    //fetch all encounters
    patientEncounters: encounters,
    isLoadingFetchPatientEncounters,
    successFetchPatientEncounters,
    errorFetchPatientEncounters
  } = useAppSelector((state) => state.EncounterReducer);
  const {
    //fetch all users
    isLoadingItems,
    isSuccessItems,
    isErrorItems,
    items: reduxItems
  } = useAppSelector((state) => state.InventoryReducer);
  useEffect(() => {
    if (!window.location.href.includes('/login')) {
      dispatch(fetchAllPatients());
      dispatch(fetchAllEncounters({ patientId: undefined, timeframe: undefined }));
      dispatch(GetItems('All'));
    }
  }, []);

  //ENCOUNTERS
  useEffect(() => {
    if (!isLoadingFetchPatientEncounters && errorFetchPatientEncounters) {
      dispatch(resetFetchPatientEncounters());
    }

    if (!isLoadingFetchPatientEncounters && successFetchPatientEncounters) {
      dispatch(resetFetchPatientEncounters());
    }
  }, [isLoadingFetchPatientEncounters, successFetchPatientEncounters, errorFetchPatientEncounters]);

  //PATIENTS
  useEffect(() => {
    if (!isLoadingFetchAllPatients && errorFetchAllPatients) {
      dispatch(resetFetchAllPatients());
    }
    if (!isLoadingFetchAllPatients && successFetchAllPatients) {
      dispatch(resetFetchAllPatients());
    }
  }, [isLoadingFetchAllPatients, errorFetchAllPatients, successFetchAllPatients]);

  //INVENTORY
  useEffect(() => {
    if (!isLoadingItems && isErrorItems) {
      dispatch(resetGetItems());
    }
    if (!isLoadingItems && isSuccessItems) {
      dispatch(resetGetItems());
    }
  }, [isLoadingItems, isErrorItems]);

  //SET SEARCH LIST
  useEffect(() => {
    if (
      encounters.length > 0 &&
      reduxPatients.length > 0 &&
      reduxItems.length > 0 &&
      searchBarList.length === 0
    ) {
      const searchData = [...searchBarList];
      reduxPatients.forEach((element: PatientBody) => {
        searchData.push({
          id: element.id + '-' + element.firstName,
          name: element.firstName + ' ' + element.lastName,
          type: 'Patient',
          link: `/patient/${element.id}/encounterHistory`
        });
      });
      encounters.forEach((element: EncounterBody) => {
        searchData.push({
          id: element.id + '-' + element.name,
          name: element.name,
          type: 'Encounter',
          link: `/patient/${element.patient.id}/encounter/${element.id}/details`
        });
      });
      reduxItems.forEach((element: InventoryBody) => {
        searchData.push({
          id: element.id + '-' + element.item,
          name: element.item,
          type: 'Inventory',
          link: `/inventory/${element.department?.name}`
        });
      });
      dispatch(setSearchBarList(searchData));
    }
  }, [encounters, reduxPatients, reduxItems, encounters]);

  return (
    <React.Fragment>
      <Router>
        <ToastContainer />

        <Switch>
          {publicRoutes.map((route: RouteProps, idx: number) => (
            <AuthMiddleWare
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route: RouteProps, idx: number) => (
            <AuthMiddleWare
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};
