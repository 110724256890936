import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MyKnownError } from '~/models/Error.model';
import { User } from '~/models/User.model';
import Async from '../../../utils/Async/index';
import { Instance } from '../../../utils/axios';
import { NewUserProps } from './types';

export const SignInUser = createAsyncThunk<User, NewUserProps, { rejectValue: MyKnownError }>(
  'auth/signIn',
  async (data: NewUserProps, thunkApi) => {
    try {
      const body: NewUserProps = {
        username: data.username,
        password: data.password,
        remember: data.remember
      };
      const response = await Instance.post('/auth/user', body);

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.data as MyKnownError);
      }
      const token = response.data.jwtToken;

      const userData: User = {
        token: token,
        username: response.data.user.username,
        role: response.data.user.role,
        id: response.data.user.id,
        firstName: response.data.user.firstName,
        lastName: response.data.user.lastName
      };

      Async.setItem('@auth', userData);
      return userData;
    } catch (err: any) {
      const error: AxiosError<MyKnownError> = err.response.data;

      if (!error.response) {
        throw error;
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);
