import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import Empty from '~/components/Common/Empty';
import Loader from '~/components/Common/Loader';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { resetDeletedItem, resetGetItems } from '~/store/reducers/inventory';
import { DeleteItem, GetItems } from '~/store/reducers/inventory/thunks';
import { Batch, InventoryBody, ItemBody } from '~/store/reducers/inventory/types';
import { convertToServerDateFormat, CurrentDateAndPrediction } from '~/utils/dateUtility';
import { errorToast, successToast } from '~/utils/toast';
import { ReactComponent as AddIcon } from '../../assets/icons/add-circle.svg';
import AddItemModal from './AddItem';
import UpdateItemModal from './UpdateItem';
import { setSecondaryBarTrue, toggle } from '~/store/reducers/sideBar';
import {
  GetPanelInvestigation,
  Investigation,
  MuiltiSelectedInvestigations
} from '~/models/modalsInterfaces.model';
import { GetInvestigations } from '~/store/reducers/panel/thunks';
import { resetGetInvestigationState, resetGetPanel } from '~/store/reducers/panel';
import { useParams } from 'react-router';
import { containsDecimal, displayTwoDecimals } from '~/utils/utilMethods';

const Inventory: FC = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [selectedItem, setSelectedItem] = useState<ItemBody>();
  const [bk1000, setBk1000] = useState<MuiltiSelectedInvestigations[]>([]);
  const [bk200, setBk200] = useState<MuiltiSelectedInvestigations[]>([]);
  const [bk6190, setBk6190] = useState<MuiltiSelectedInvestigations[]>([]);
  const [cbs300, setCBS300] = useState<MuiltiSelectedInvestigations[]>([]);
  const [other, setOther] = useState<MuiltiSelectedInvestigations[]>([]);
  const [panelInvestigationsList, setPanelInvestigationsList] = useState<
    MuiltiSelectedInvestigations[]
  >([]);
  const [
    selectedPanel
    // setSelectedPanel
  ] = useState<GetPanelInvestigation>();
  const { success, error, investigations, panelLoading, panelSuccess, panelError, panels } =
    useAppSelector((state) => state.PanelManagement);
  const dispatch = useAppDispatch();
  const { collapsed } = useAppSelector((state) => state.sideBarReducer);

  const {
    //fetch all users
    isLoadingItems,
    isSuccessItems,
    isErrorItems,
    items: reduxItems,
    isLoadingDeleteItem,
    errorDeleteItem,
    successDeleteItem
  } = useAppSelector((state) => state.InventoryReducer);
  const { routeFilter } = useParams<{ routeFilter: string }>();
  const [currentFilter, selectedFilter] = useState<string>(routeFilter);
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (!isLoadingItems && isErrorItems) {
      errorToast(isErrorItems);
      dispatch(resetGetItems());
    }
    if (!isLoadingItems && isSuccessItems) {
      dispatch(resetGetItems());
    }
  }, [isLoadingItems, isErrorItems]);

  useEffect(() => {
    if (!reduxItems?.length) return;

    if (routeFilter == 'All') {
      selectedFilter('All');
      setItems(reduxItems);
    } else {
      selectedFilter(routeFilter);
      const lists = reduxItems?.filter((data: any) => data?.department?.name === routeFilter);
      setItems(lists || []);
    }
  }, [reduxItems, routeFilter]);

  // dispatch(resetGetItems());
  //DELETE USER
  useEffect(() => {
    dispatch(GetInvestigations());
    dispatch(GetItems(routeFilter));

    window.addEventListener('resize', HandleWidth);
    return () => {
      window.removeEventListener('resize', HandleWidth);
    };
  }, []);

  //DELETE USER
  useEffect(() => {
    if (!isLoadingDeleteItem && successDeleteItem) {
      successToast('Item deleted successfully !');
      dispatch(resetDeletedItem());
      dispatch(GetItems(routeFilter));
    }
    if (!isLoadingDeleteItem && errorDeleteItem) {
      errorToast('Error removing item');
      dispatch(resetDeletedItem());
    }
  }, [isLoadingDeleteItem, successDeleteItem, errorDeleteItem]);

  useEffect(() => {
    if (success && investigations?.length > 0) {
      dispatch(resetGetInvestigationState());
    } else if (error) {
      errorToast(error);
      dispatch(resetGetInvestigationState());
    }
  }, [error, success, investigations]);
  useEffect(() => {
    if (!panelLoading && panelSuccess && panels?.length > 0) {
      dispatch(resetGetPanel());
    } else if (!panelLoading && panelError) {
      errorToast(panelError);
      dispatch(resetGetPanel());
    }
  }, [panelError, panelSuccess, panels]);

  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };

  // const abbrNum = (number: any, decPlaces: number) => {
  //   // 2 decimal places => 100, 3 => 1000, etc
  //   decPlaces = Math.pow(10, decPlaces);
  //   // Enumerate number abbreviations
  //   const abbrev = ['k', 'm', 'b', 't'];
  //   // Go through the array backwards, so we do the largest first
  //   for (let i = abbrev.length - 1; i >= 0; i--) {
  //     // Convert array index to "1000", "1000000", etc
  //     const size = Math.pow(10, (i + 1) * 3);
  //     // If the number is bigger or equal do the abbreviation
  //     if (size <= number) {
  //       // Here, we multiply by decPlaces, round, and then divide by decPlaces.
  //       // This gives us nice rounding to a particular decimal place.
  //       number = Math.round((number * decPlaces) / size) / decPlaces;
  //       // Handle special case where we round up to the next abbreviation
  //       if (number == 1000 && i < abbrev.length - 1) {
  //         number = 1;
  //         i++;
  //       }
  //       // Add the letter for the abbreviation
  //       number += abbrev[i];
  //       break;
  //     }
  //   }

  //   return number;
  // };

  const toggleItemModal = () => {
    setIsOpen(!isOpen);
  };

  const handleFilter = (filter: string) => {
    window.location.href = `/inventory/${filter}`;
    selectedFilter(filter);
  };
  useEffect(() => {
    if (modalType === 'add') {
      if (investigations?.length > 0) {
        const bk1000: MuiltiSelectedInvestigations[] = [];
        const bk200: MuiltiSelectedInvestigations[] = [];
        const cbs300: MuiltiSelectedInvestigations[] = [];
        const other: MuiltiSelectedInvestigations[] = [];
        const bk6190: MuiltiSelectedInvestigations[] = [];

        investigations?.forEach((element: Investigation) => {
          if (element?.medicalDevice?.name === 'BK1000') {
            const body = {
              check: false,
              data: element
            };
            bk1000.push(body);
          } else if (element?.medicalDevice?.name === 'BK200') {
            const body = {
              check: false,
              data: element
            };
            bk200.push(body);
          } else if (element?.medicalDevice?.name === 'CBS300') {
            const body = {
              check: false,
              data: element
            };
            cbs300.push(body);
          } else if (element?.medicalDevice?.name === 'Other') {
            const body = {
              check: false,
              data: element
            };
            other.push(body);
          } else if (element?.medicalDevice?.name === 'BK6190') {
            const body = {
              check: false,
              data: element
            };
            bk6190.push(body);
          }
        });
        panels?.forEach((element: Investigation) => {
          if (element.name !== selectedPanel?.name) {
            let body;
            if (
              selectedPanel?.investigations?.panelInvestigation.find(
                (panel) => element.id === panel.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            panelInvestigationsList.push(body);
          }
        });
        setPanelInvestigationsList(panelInvestigationsList);
        setBk1000(bk1000);
        setBk200(bk200);
        setBk6190(bk6190);
        setCBS300(cbs300);
        setOther(other);
      }
    }
  }, [investigations, modalType]);

  const getRemainingDays = (batch: Batch[]) => {
    return Math.max(...batch.map((b) => b.prediction));
  };

  useEffect(() => {
    if (modalType === 'edit') {
      if (investigations?.length > 0) {
        const bk1000: MuiltiSelectedInvestigations[] = [];
        const bk200: MuiltiSelectedInvestigations[] = [];
        const cbs300: MuiltiSelectedInvestigations[] = [];
        const other: MuiltiSelectedInvestigations[] = [];
        const bk6190: MuiltiSelectedInvestigations[] = [];
        const panelInvestigationsList: MuiltiSelectedInvestigations[] = [];

        investigations?.forEach((element: Investigation) => {
          if (element?.medicalDevice?.name === 'BK1000') {
            let body;
            if (
              selectedPanel?.investigations?.bk1000.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            bk1000.push(body);
          } else if (element?.medicalDevice?.name === 'BK200') {
            let body;
            if (
              selectedPanel?.investigations?.bk200.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            bk200.push(body);
          } else if (element?.medicalDevice?.name === 'CBS300') {
            let body;
            if (
              selectedPanel?.investigations?.cbs300.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }

            cbs300.push(body);
          } else if (element?.medicalDevice?.name === 'Other') {
            let body;
            if (
              selectedPanel?.investigations?.other.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }

            other.push(body);
          } else if (element?.medicalDevice?.name === 'BK6190') {
            let body;
            if (
              selectedPanel?.investigations?.bk6190.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            bk6190.push(body);
          }
        });
        panels?.forEach((element: Investigation) => {
          if (element.name !== selectedPanel?.name) {
            let body;
            if (
              selectedPanel?.investigations?.panelInvestigation.find(
                (panel) => element.id === panel.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            panelInvestigationsList.push(body);
          }
        });

        setPanelInvestigationsList(panelInvestigationsList);
        setBk1000(bk1000);
        setBk200(bk200);
        setBk6190(bk6190);
        setCBS300(cbs300);
        setOther(other);
      }
    }
  }, [modalType]);
  useEffect(() => {
    if (modalType === 'add') {
      if (panels?.length > 0) {
        const panelInvestigationsList: MuiltiSelectedInvestigations[] = [];

        panels?.forEach((element: Investigation) => {
          const body = {
            check: false,
            data: element
          };
          panelInvestigationsList.push(body);
        });
        setPanelInvestigationsList(panelInvestigationsList);
      }
    }
  }, [panels, modalType]);
  const isInvestigationPresent = (
    panelInvestigation: number,
    bk1000: number,
    bk200: number,
    bk6190: number,
    cbs300: number,
    other: number
  ) => {
    const length = panelInvestigation + bk1000 + bk200 + bk6190 + cbs300 + other;
    if (length > 0) return true;
    return false;
  };

  return (
    <>
      {modalType == 'add' ? (
        <AddItemModal
          isOpen={isOpen}
          toggle={toggleItemModal}
          modalType="add"
          bk1000={bk1000}
          bk200={bk200}
          bk6190={bk6190}
          cbs300={cbs300}
          other={other}
          isInvestigationPresent={isInvestigationPresent}
          itemData={selectedItem}
        />
      ) : (
        <UpdateItemModal
          isOpen={isOpen}
          toggle={toggleItemModal}
          modalType="edit"
          bk1000={bk1000}
          bk200={bk200}
          bk6190={bk6190}
          cbs300={cbs300}
          other={other}
          isInvestigationPresent={isInvestigationPresent}
          itemData={selectedItem}
        />
      )}
      <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
        <Container fluid>
          <Row>
            <Col lg="3">
              <h2 className="m-0 align-self-center text-nowrap">
                <b className="text-nowrap sbl24">Inventory management</b>
              </h2>
            </Col>
            <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
            <Col lg="4" className="d-flex justify-content-end text-nowrap">
              <BlueButton
                Icon={
                  <AddIcon
                    color={'#ffffff'}
                    style={{ height: '16px', marginBottom: '4px', marginRight: '6px' }}
                  />
                }
                HandleClick={() => {
                  toggleItemModal();
                  setModalType('add');
                }}
                width="100px"
                text={'Add item'}
              />
            </Col>
          </Row>
          <div className="horizontal-line mt-3"></div>
          <div
            className="mt-4"
            // style={{
            //   minHeight: '200px',
            //   maxHeight: `${height - 170}px`,
            //   overflowX: 'hidden',
            //   scrollbarWidth: 'thin'
            // }}
          >
            <>
              <div className="col-md-12 d-flex p-1 " style={{ columnGap: '35px' }}>
                <div
                  className={`col-md-auto px-4  d-flex    flex-column ${
                    currentFilter === 'All' ? 'border-bottom  border-primary text-primary  ' : ''
                  }  `}
                  style={
                    currentFilter == 'All'
                      ? { color: '#2a45cd', cursor: 'pointer', width: '65px' }
                      : { cursor: 'pointer', width: '65px' }
                  }
                  onClick={() => handleFilter('All')}
                >
                  <h2 className={` text-center patient-details `}>All</h2>
                </div>

                <div
                  className={`col-md-auto px-4  d-flex   flex-column  ${
                    currentFilter === 'Lab' ? 'border-bottom border-primary  ' : ''
                  }`}
                  style={
                    currentFilter == 'Lab'
                      ? { color: '#2a45cd', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => handleFilter('Lab')}
                >
                  <h2 className=" text-center patient-details">Lab</h2>
                </div>
                <div
                  className={`col-md-auto px-2  d-flex    flex-column ${
                    currentFilter === 'Haematology' ? 'border-bottom border-primary  ' : ''
                  }`}
                  style={
                    currentFilter == 'Haematology'
                      ? { color: '#2a45cd', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => handleFilter('Haematology')}
                >
                  <h2 className=" text-center  patient-details">Haematology</h2>
                </div>
                <div
                  className={`col-md-auto px-2 d-flex   flex-column ${
                    currentFilter === 'Biochem' ? 'border-bottom border-primary  ' : ''
                  }`}
                  style={
                    currentFilter == 'Biochem'
                      ? { color: '#2a45cd', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => handleFilter('Biochem')}
                >
                  <h2 className=" text-center patient-details">Biochem</h2>
                </div>
                <div
                  className={`col-md-auto px-2 d-flex   flex-column ${
                    currentFilter === 'Immunochem' ? 'border-bottom border-primary ' : ''
                  }`}
                  style={
                    currentFilter == 'Immunochem'
                      ? { color: '#2a45cd', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => handleFilter('Immunochem')}
                >
                  <h2 className=" text-center patient-details">Immunochem</h2>
                </div>
                <div
                  className={`col-md-auto px-2 d-flex   flex-column ${
                    currentFilter === 'Phlebotomist' ? 'border-bottom border-primary ' : ''
                  }`}
                  style={
                    currentFilter == 'Phlebotomist'
                      ? { color: '#2a45cd', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => handleFilter('Phlebotomist')}
                >
                  <h2 className=" text-center patient-details">Phlebotomist</h2>
                </div>
                <div
                  className={`col-md-auto  d-flex px-3    flex-column ${
                    currentFilter === 'Other' ? 'border-bottom border-primary ' : ''
                  }`}
                  style={
                    currentFilter == 'Other'
                      ? { color: '#2a45cd', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => handleFilter('Other')}
                >
                  <h2 className=" text-center patient-details ">Other</h2>
                </div>
                <div className="col-5"></div>
              </div>
              <hr style={{ opacity: '0.1', margin: '0' }}></hr>
              {!isLoadingItems && !isLoadingDeleteItem && items !== null && items.length !== 0 && (
                <span
                  className="col-md-12 d-flex "
                  style={{ marginLeft: '5rem', paddingTop: '1rem' }}
                >
                  <div className="col-md-2">
                    <h2 className=" patient-details">Item</h2>
                  </div>
                  <div className="col-md-2">
                    <h2 className=" patient-details">Department</h2>
                  </div>
                  <div className="col-md-2">
                    <h2 className=" patient-details">Original</h2>
                  </div>
                  <div className="col-md-2">
                    <h2 className="  patient-details">Current</h2>
                  </div>
                  <div className="col-md-2">
                    <h2 className="  patient-details">Remaining (days)</h2>
                  </div>
                </span>
              )}
            </>

            <div
              style={{
                minHeight: '200px',
                maxHeight: `${height - 170}px`,
                overflowX: 'hidden',
                scrollbarWidth: 'thin'
              }}
            >
              {!isLoadingItems && !isLoadingDeleteItem ? (
                items !== null && items.length !== 0 ? (
                  items.map((items: InventoryBody, id: number) => (
                    <Accordion key={id}>
                      <AccordionItem
                        dropDownstyle={{
                          right: '175px'
                        }}
                        dropdownData={[
                          { id: 1, title: 'Update', color: 'default' },
                          { id: 2, title: 'Remove', color: 'danger' }
                        ]}
                        onClickDropdownItem={[
                          {
                            id: 1,
                            title: 'Edit details',
                            event: () => {
                              setSelectedItem(items);
                              setModalType('edit');
                              toggleItemModal();
                            }
                          },
                          {
                            id: 2,
                            title: 'Remove',
                            event: () => {
                              dispatch(DeleteItem(items.id));
                            }
                          }
                        ]}
                        accordionHeaderClasses={'justify-content-between'}
                        AccordionHeader={
                          <>
                            <span
                              className="col-md-12 d-flex"
                              style={{
                                marginLeft: '2rem'
                              }}
                            >
                              <div className="col-md-2">
                                <span className="patient-name">{items.item}</span>
                              </div>
                              <div className="col-md-2">
                                <span
                                  style={{
                                    marginLeft: '1.6rem'
                                  }}
                                  className="patient-name"
                                >
                                  {items.department.name}
                                </span>
                              </div>
                              <div className="col-md-2">
                                <span
                                  style={{
                                    marginLeft: '3rem'
                                  }}
                                  className="patient-name"
                                >
                                  {/* {JSON.stringify(items.originalStock).length >= 3
                                  ? abbrNum(
                                      items.originalStock,
                                      JSON.stringify(items.originalStock).length
                                    )
                                  : items.originalStock} */}
                                  {containsDecimal(items.originalStock)
                                    ? displayTwoDecimals(items.originalStock)
                                    : items.originalStock}
                                </span>
                              </div>
                              <div className="col-md-3">
                                <span
                                  style={{
                                    marginLeft: '4.2rem'
                                  }}
                                  className="patient-name"
                                >
                                  {' '}
                                  {containsDecimal(items.currentStock)
                                    ? displayTwoDecimals(items.currentStock)
                                    : items.currentStock}
                                </span>
                              </div>
                              <div className="col-md-2">
                                <span
                                  style={{
                                    marginLeft: '1.4rem'
                                  }}
                                  className="patient-name"
                                >
                                  {getRemainingDays(items.batch) > 0
                                    ? getRemainingDays(items.batch)
                                    : '-'}
                                </span>
                              </div>
                            </span>
                          </>
                        }
                        AccordionBody={
                          <div className="mb-3">
                            {items.batch && items.batch.length > 0 ? (
                              <Row
                                xs={12}
                                className={'py-1 mb-1 my-1 mx-auto'}
                                style={{ width: '90%' }}
                              >
                                <Col className="col-2 key-text ">Delivery Batch</Col>
                                <Col className="col-2 key-text ">Date Updated</Col>
                                <Col className="col-2 key-text ">User</Col>
                                <Col className="col-2 key-text ">Expiry Date</Col>
                                <Col className="col-2 key-text ">Remaining Stock</Col>
                                <Col className="col-2 key-text ">Predict use by Date</Col>
                              </Row>
                            ) : null}
                            {items.batch && items.batch.length > 0 ? (
                              items.batch.map((data, id: number) => {
                                return (
                                  <div key={id}>
                                    <Row
                                      key={id}
                                      xs={12}
                                      className={'py-1 mb-1 my-1 mx-auto'}
                                      style={{ width: '90%' }}
                                    >
                                      <Col className="col-2 value-text ">{data.name}</Col>
                                      <Col className="col-2 value-text ">
                                        {convertToServerDateFormat(data.updatedAt)}
                                      </Col>
                                      <Col className="col-2 value-text ">{data.user.username}</Col>
                                      <Col className="col-2 value-text ">{data.expiryDate}</Col>
                                      <Col className="col-2 value-text ">{data.stock}</Col>
                                      <Col
                                        className={`col-2 value-text ${
                                          data.prediction <= 5 && data.prediction >= 0
                                            ? 'text-danger'
                                            : 'value-text'
                                        }`}
                                      >
                                        {data.prediction < 0
                                          ? '-'
                                          : data.prediction <= 5
                                          ? CurrentDateAndPrediction(new Date(), data.prediction) +
                                            '(Warning)'
                                          : CurrentDateAndPrediction(new Date(), data.prediction)}
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="key-text d-flex justify-content-center mt-3">
                                No batches exist
                              </div>
                            )}
                          </div>
                        }
                      />
                    </Accordion>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center w-100"
                    style={{
                      width: '100%',
                      height: '60vh',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Empty />
                  </div>
                )
              ) : (
                <div className="d-flex justify-content-center w-100" style={{ minHeight: '500px' }}>
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Inventory;
