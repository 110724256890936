import { createSlice } from '@reduxjs/toolkit';
import { inventoryManagement } from './types';
import { GetItems, AddItems, UpdateItems, DeleteItem, DeleteBatch } from './thunks';

const initialState: inventoryManagement = {
  //get all items
  // isLoadingDepartment: false,
  // isSuccessDepartment: false,
  // isErrorDepartment: '',

  // department:[],
  isLoadingItems: false,
  isSuccessItems: false,
  isErrorItems: '',
  items: [],
  //add items
  addedItem: null,
  isLoadingAddItem: false,
  errorAddItem: null,
  successAddItem: false,
  //UPDATE items
  updatedItem: null,
  isLoadingUpdateItem: false,
  errorUpdateItem: null,
  successUpdateItem: false,
  //delete Item
  //delete
  deletedItem: null,
  isLoadingDeleteItem: false,
  errorDeleteItem: null,
  successDeleteItem: false,
  // delete Batch
  deletedBatch: null,
  isLoadingDeleteBatch: false,
  errorDeleteBatch: null,
  successDeleteBatch: false
};
const inventorySlice = createSlice({
  name: 'inventoryManagement',
  initialState,
  extraReducers: (builder) => {
    // builder.addCase(GetDepartment.pending, (state) => {
    //   state.isLoadingDepartment = true;
    //   state.isErrorDepartment = '';
    //   state.isSuccessDepartment = false;
    // });
    // builder.addCase(GetDepartment.fulfilled, (state, { payload }) => {
    //   state.isLoadingDepartment = false;
    //   state.isErrorDepartment = '';
    //   state.department = payload;
    //   state.isSuccessDepartment = true;
    // });
    // builder.addCase(GetDepartment.rejected, (state, action) => {
    //   state.isLoadingDepartment = false;
    //   state.isSuccessDepartment = false;
    //   if (action.error) {
    //     state.isErrorDepartment = action.error.message as string;
    //   } else {
    //     state.isErrorDepartment = 'Error fetching department';
    //   }
    // });
    //
    builder.addCase(GetItems.pending, (state) => {
      state.isLoadingItems = true;
      state.isErrorItems = '';
      state.isSuccessItems = false;
    });
    builder.addCase(GetItems.fulfilled, (state, { payload }) => {
      state.isLoadingItems = false;
      state.isErrorItems = '';
      state.items = payload;
      state.isSuccessItems = true;
    });
    builder.addCase(GetItems.rejected, (state, action) => {
      state.isLoadingItems = false;
      state.isSuccessItems = false;
      if (action.error) {
        state.isErrorItems = action.error.message as string;
      } else {
        state.isErrorItems = 'Error fetching items';
      }
    });
    //add
    builder.addCase(AddItems.pending, (state) => {
      state.isLoadingAddItem = true;
      state.errorAddItem = '';
      state.successAddItem = false;
    });
    builder.addCase(AddItems.fulfilled, (state, { payload }) => {
      state.isLoadingAddItem = false;
      state.errorAddItem = '';
      state.addedItem = payload;
      state.successAddItem = true;
    });
    builder.addCase(AddItems.rejected, (state, action) => {
      state.isLoadingAddItem = false;
      state.successAddItem = false;
      if (action.error) {
        state.errorAddItem = action.error.message as string;
      } else {
        state.errorAddItem = 'Error adding item';
      }
    });
    //update
    builder.addCase(UpdateItems.pending, (state) => {
      state.isLoadingUpdateItem = true;
      state.errorUpdateItem = '';
      state.successUpdateItem = false;
    });
    builder.addCase(UpdateItems.fulfilled, (state, { payload }) => {
      state.isLoadingUpdateItem = false;
      state.errorUpdateItem = '';
      state.updatedItem = payload;
      state.successUpdateItem = true;
    });
    builder.addCase(UpdateItems.rejected, (state, action) => {
      state.isLoadingUpdateItem = false;
      state.successUpdateItem = false;
      if (action.error) {
        state.errorUpdateItem = action.error.message as string;
      } else {
        state.errorUpdateItem = 'Error updating item';
      }
    });
    //delete
    builder.addCase(DeleteItem.pending, (state) => {
      state.isLoadingDeleteItem = true;
      state.errorDeleteItem = '';
      state.successDeleteItem = false;
    });
    builder.addCase(DeleteItem.fulfilled, (state, { payload }) => {
      state.isLoadingDeleteItem = false;
      state.errorDeleteItem = '';
      state.deletedItem = payload;
      state.successDeleteItem = true;
    });
    builder.addCase(DeleteItem.rejected, (state, action) => {
      state.isLoadingDeleteItem = false;
      state.successDeleteItem = false;
      if (action.error) {
        state.errorDeleteItem = action.error.message as string;
      } else {
        state.errorDeleteItem = 'Error deleting Item';
      }
    });
    //delete batch
    builder.addCase(DeleteBatch.pending, (state) => {
      state.isLoadingDeleteBatch = true;
      state.errorDeleteBatch = '';
      state.successDeleteBatch = false;
    });
    builder.addCase(DeleteBatch.fulfilled, (state, { payload }) => {
      state.isLoadingDeleteBatch = false;
      state.errorDeleteBatch = '';
      state.deletedBatch = payload;
      state.successDeleteBatch = true;
    });
    builder.addCase(DeleteBatch.rejected, (state, action) => {
      state.isLoadingDeleteBatch = false;
      state.successDeleteBatch = false;
      if (action.error) {
        state.errorDeleteBatch = action.error.message as string;
      } else {
        state.errorDeleteItem = 'Error deleting Batch';
      }
    });
  },
  reducers: {
    resetGetItems(state) {
      state.isLoadingItems = false;
      state.isErrorItems = '';
      state.isSuccessItems = false;
    },
    resetAddItems: (state) => {
      state.isLoadingAddItem = false;
      state.errorAddItem = null;
      state.successAddItem = false;
    },
    resetUpdateItems: (state) => {
      state.isLoadingUpdateItem = false;
      state.errorUpdateItem = null;
      state.successUpdateItem = false;
    },
    resetDeletedItem: (state) => {
      state.isLoadingDeleteItem = false;
      state.errorDeleteItem = null;
      state.successDeleteItem = false;
    },
    resetDeletedBatch: (state) => {
      state.isLoadingDeleteBatch = false;
      state.errorDeleteBatch = null;
      state.successDeleteBatch = false;
    }
  }
});
export const {
  resetGetItems,
  resetAddItems,
  resetUpdateItems,
  resetDeletedItem,
  resetDeletedBatch
} = inventorySlice.actions;
export default inventorySlice.reducer;
