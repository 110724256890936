import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Investigation } from '~/models/modalsInterfaces.model';
import { EncounterBody } from '~/store/reducers/encounter/types';
import { INVESTIGATION_FLAG_STATUS } from '~/utils/constants';

type RowProps = {
  general: Investigation;
  encounterDetails: EncounterBody;
};
const RenderRow: React.FC<RowProps> = ({ general, encounterDetails }) => {
  const CustomPill = ({ flag }: { flag: string | null }) => {
    return (
      <span
        className={clsx(flag !== '-' && 'rounded-circle px-2 py-1 text-danger')}
        style={flag !== '-' ? { backgroundColor: '#FFEBEE', fontWeight: '600' } : {}}
      >
        {flag}
      </span>
    );
  };
  const getApolipoFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === 0 ? (
        'Below Standard/Optimal'
      ) : flag === 1 ? (
        'Optimal'
      ) : flag === 2 ? (
        'Above Optimal'
      ) : flag === 3 ? (
        'Above Standard'
      ) : (
        <CustomPill flag={'H'} />
      );
    }
    return '-';
  };

  const getVitaminDFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === 0 ? (
        <CustomPill flag={'L'} />
      ) : flag === 1 ? (
        'Deficiency'
      ) : flag === 2 ? (
        'Partial Deficiency'
      ) : flag === 3 ? (
        'Optimal level'
      ) : (
        'Toxicity'
      );
    }
    return '-';
  };
  const getFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === INVESTIGATION_FLAG_STATUS.LOW
        ? 'L'
        : flag === INVESTIGATION_FLAG_STATUS.MEDIUM
        ? '-'
        : flag === INVESTIGATION_FLAG_STATUS.HIGH
        ? 'H'
        : null;
    }
    return '-';
  };
  return (
    <Row xs={12} className={'bg-white py-3'}>
      <Col className="col-4 text-start value-text">
        {general?.name === 'CBC' ? general?.parameter : general?.name}
      </Col>
      <Col className="col text-center value-text">{general?.result ?? '-'}</Col>
      <Col className="col text-center value-text">{general?.unit}</Col>
      <Col className="col text-center value-text">
        {encounterDetails?.patient?.gender === 'F' ? general?.femaleRange : general?.maleRange}
      </Col>
      <Col className="col text-center value-text">
        {general?.id === 84 ? (
          getApolipoFlag(general?.flag)
        ) : general?.id === 82 ? (
          getVitaminDFlag(general?.flag)
        ) : (
          <CustomPill flag={getFlag(general?.flag)} />
        )}
      </Col>
    </Row>
  );
};

export default RenderRow;
