import clsx from 'clsx';
import React, { useState } from 'react';
import { Collapse, Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/three-dots.svg';
type AccordionItemProps = {
  AccordionHeader?: JSX.Element;
  accordionHeaderClasses?: string;
  AccordionBody?: JSX.Element;
  accordionBodyClasses?: string;
  height?: number | string;
  showAccordionEndElement?: boolean;
  dropdownData?: any[];
  onClickDropdownItem?: any[]; // (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  dropDownstyle?: any;
  disableDropdown?: boolean;
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  height = '61px',
  showAccordionEndElement = true,
  AccordionHeader,
  AccordionBody,
  accordionHeaderClasses,
  accordionBodyClasses,
  dropdownData = [],
  onClickDropdownItem = [],
  dropDownstyle,
  disableDropdown
}) => {
  const [active, setActive] = useState(false);

  const toggle = () => setActive((prevState) => !prevState);
  // const toggle2 = (val: boolean) => setActive(val);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen((prevState) => !prevState);
    setDropdownOpen(!dropdownOpen);
  };

  const renderDropdown = () => {
    return (
      <>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => toggleDropdown(e)}
          style={{
            position: 'absolute',
            ...dropDownstyle
          }}
        >
          {dropdownData?.length > 0 && (
            <DropdownMenu
              style={{
                background: '#FFFFFF ',
                boxShadow: '0px 3px 12px #00000029',
                borderRadius: '8px'
              }}
            >
              {dropdownData?.map((item, index) => (
                <DropdownItem
                  className={'p-0 py-1'}
                  key={index}
                  disabled={item.disable}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickDropdownItem[index]?.event?.(e);
                  }}
                >
                  <span
                    className={clsx(
                      'dropdown-item-text p-0 px-3 font-size-14',
                      item?.color === 'danger'
                        ? 'text-danger'
                        : item?.color
                        ? item.color
                        : 'text-black'
                    )}
                  >
                    {item?.title}
                  </span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </Dropdown>
      </>
    );
  };
  return (
    <li className={`accordion_item ${active ? 'active' : ''}`}>
      <div className="accordion-header pe-3 ps-1" style={{ height: height }} onClick={toggle}>
        <ChevronDown
          color={active ? '#2A45CD' : '2c3242'}
          className={clsx('mx-2', active && 'control')}
        />
        <div
          className={clsx(
            'd-flex align-items-center flex-grow-1 ms-0 me-2',
            accordionHeaderClasses
          )}
        >
          {AccordionHeader}
        </div>
        {showAccordionEndElement && <ThreeDotsIcon color="#696E7C" onClick={toggleDropdown} />}
        {!disableDropdown && renderDropdown()}
      </div>
      <Collapse isOpen={active}>
        <div className={clsx('inner-container-wrapper', accordionBodyClasses)}>{AccordionBody}</div>
      </Collapse>
    </li>
  );
};

export default AccordionItem;
