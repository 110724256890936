import React from 'react';
import CustomInput from '~/components/CustomInput';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const DoctorFormInputs: React.FC<any> = (props) => {
  const { edit, values, errors, touched, handleChange, isLetterInput, handleBlur, setFieldValue } =
    props;

  return (
    <>
      <h3 className={edit ? 'col-md-10' : 'col-md-5' + ' m-0  modal-title text-nowrap'}>
        <b className="text-nowrap sbl18">
          {edit ? 'Update doctor details' : 'Referral doctor details'}
        </b>
      </h3>
      <div className={edit ? 'col-md-10' : 'col-md-5' + ' me-1 mt-2'}>
        <CustomInput
          label={'Title *'}
          type="select"
          name={'title'}
          value={values?.title}
          options={[
            { value: 'Dr.', name: 'Dr.' },
            { value: 'Mr.', name: 'Mr.' },
            { value: 'Mrs.', name: 'Mrs.' }
          ]}
          selected={true}
          onChange={handleChange}
          invalid={touched.title && errors.title ? true : false}
          errors={errors.title}
        />
      </div>
      <div className={edit ? 'col-md-10' : 'col-md-5'}>
        <label className=" col-form-label im gray">Legal names of doctor *</label>
        <div className="mb-3">
          <CustomInput
            label={''}
            type="text"
            placeholder="First Name"
            name={'firstName'}
            value={values.firstName}
            onChange={(e: any) => {
              if (isLetterInput(e) && e.target.value.length < 25) {
                handleChange(e);
              }
            }}
            invalid={touched.firstName && errors.firstName ? true : false}
            errors={errors.firstName}
          />
        </div>
        <div className="mt-3">
          <CustomInput
            label={''}
            type="text"
            placeholder="Last Name"
            name={'lastName'}
            value={values.lastName}
            onChange={(e: any) => {
              if (isLetterInput(e) && e.target.value.length < 25) {
                handleChange(e);
              }
            }}
            invalid={touched.lastName && errors.lastName ? true : false}
            errors={errors.lastName}
          />
        </div>
      </div>
      <div className={edit ? 'col-md-10' : 'col-md-5' + ' me-1 mt-2'}>
        <CustomInput
          label={'Clinic name *'}
          type="text"
          placeholder="Point Clinics"
          name={'clinicName'}
          value={values.clinicName}
          onChange={handleChange}
          invalid={touched.clinicName && errors.clinicName ? true : false}
          errors={errors.clinicName}
        />
      </div>
      <div className={edit ? 'col-md-10' : 'col-md-5' + ' me-1 mt-2'}>
        <CustomInput
          label={'Clinic Address *'}
          type="text"
          placeholder="Cape Town, SA"
          name={'clinicAddress'}
          value={values.clinicAddress}
          onChange={handleChange}
          invalid={touched.clinicAddress && errors.clinicAddress ? true : false}
          errors={errors.clinicAddress}
        />
      </div>
      <div className={edit ? 'col-md-10' : 'col-md-5' + ' me-1 mt-2'}>
        <label className="col-md-8 col-form-label im gray">Contact number</label>
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry="KE"
          inputProps={{
            name: 'phoneNumber',
            onBlur: handleBlur
          }}
          style={{
            height: '37px',
            border:
              (touched.phoneNumber && errors.phoneNumber) ||
              (touched.phoneNumber &&
                values.phoneNumber !== '' &&
                !isValidPhoneNumber(`${values.phoneNumber}`))
                ? '1px solid red'
                : '1px solid #e3e3e3',
            padding: '10px',
            borderRadius: '0.375rem'
          }}
          value={values.phoneNumber}
          onChange={(e: string) => setFieldValue('phoneNumber', e)}
        />
        {(touched.phoneNumber && errors.phoneNumber) ||
        (touched.phoneNumber &&
          values.phoneNumber !== '' &&
          !isValidPhoneNumber(`${values.phoneNumber}`)) ? (
          <span className="text-danger font-size-14">
            {!isValidPhoneNumber(`${values.phoneNumber}`)
              ? 'Invalid phone number'
              : errors.phoneNumber}
          </span>
        ) : null}
      </div>
      <div className={edit ? 'col-md-10' : 'col-md-5' + ' me-1 mt-2'}>
        <CustomInput
          label={'Email address *'}
          type="email"
          placeholder="example@domain.com"
          name={'email'}
          value={values.email}
          onChange={handleChange}
          invalid={touched.email && errors.email ? true : false}
          errors={
            errors.email === 'email must be a valid email' ? 'Invalid Email Address' : errors.email
          }
        />
      </div>
    </>
  );
};

export default DoctorFormInputs;
