import React, { FC, useEffect, useState, useRef } from 'react';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import Empty from '~/components/Common/Empty';
import Loader from '~/components/Common/Loader';
import WhiteButton from '~/components/WhiteButton';
import { Encounter } from '~/models/encounter.model';
import { Investigation, PanelInvestigationBody } from '~/models/modalsInterfaces.model';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import {
  resetArchiveEncounter,
  resetFetchEncounterStats,
  resetFetchPatientEncounters,
  resetUnrchiveEncounter
} from '~/store/reducers/encounter';
import {
  archiveEncounter,
  fetchAllEncounters,
  fetchEncounterStatistics,
  unarchiveEncounter
} from '~/store/reducers/encounter/thunks';
import { ENCOUNTER_INVESTIGATION_STATUS, ENCOUNTER_STATUS } from '~/utils/constants';
import { convertIsoDateTimeFormatToDateTimeFormatWithAmPm } from '~/utils/dateUtility';
import { errorToast, successToast } from '~/utils/toast';
import { ReactComponent as FilterIcon } from '../../assets/icons/filterIcon.svg';

const Dashboard: FC = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const ref = useRef<null | HTMLDivElement>(null);

  const {
    //fetch all encounters
    patientEncounters,
    isLoadingFetchPatientEncounters,
    successFetchPatientEncounters,
    errorFetchPatientEncounters,

    //archive encounters
    isLoadingArchiveEncounter,
    errorArchiveEncounter,
    successArchiveEncounter,

    //unarchive encounters
    isLoadingUnrchiveEncounter,
    errorUnarchiveEncounter,
    successUnarchiveEncounter,

    //fetch encounter stats
    encounterStats,
    isLoadingFetchEncounterStats,
    errorFetchEncounterStats,
    successFetchEncounterStats
  } = useAppSelector((state) => state.EncounterReducer);
  const options = [
    { value: 5, name: 'All time' },
    { value: 0, name: 'Today' },
    { value: 1, name: 'This week' },
    { value: 2, name: 'Last week' },
    { value: 3, name: 'This month' },
    { value: 4, name: 'Last month' }
  ];
  const [selectedValue, setSelectedValue] = useState(5);
  const [filtersList, setfiltersList] = useState([
    { id: 1, title: 'In Progress', check: false },
    { id: 2, title: 'Completed', check: false },
    { id: 3, title: 'Archived', check: false }
  ]);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [tempfiltersList, setTempfiltersList] = useState<any>([]);
  const [encounters, setEncounters] = useState<Encounter[]>([]);

  useEffect(() => {
    dispatch(fetchEncounterStatistics(selectedValue));
    dispatch(fetchAllEncounters({ patientId: undefined, timeframe: selectedValue }));
  }, [selectedValue]);
  //FETCH ENCOUNTER STATS
  useEffect(() => {
    if (!isLoadingFetchEncounterStats && errorFetchEncounterStats) {
      errorToast(errorFetchEncounterStats);
      dispatch(resetFetchEncounterStats());
    }

    if (!isLoadingFetchEncounterStats && successFetchEncounterStats) {
      dispatch(resetFetchEncounterStats());
    }
  }, [isLoadingFetchEncounterStats, successFetchEncounterStats, errorFetchEncounterStats]);

  //fetch patient encounters
  useEffect(() => {
    if (!isLoadingFetchPatientEncounters && errorFetchPatientEncounters) {
      errorToast(errorFetchPatientEncounters);
      dispatch(resetFetchPatientEncounters());
    }

    if (!isLoadingFetchPatientEncounters && successFetchPatientEncounters) {
      const data = patientEncounters?.filter((val: Encounter) => val.deactivated === false);
      setEncounters(data);
      dispatch(resetFetchPatientEncounters());
    }
  }, [
    isLoadingFetchPatientEncounters,
    successFetchPatientEncounters,
    errorFetchPatientEncounters,
    patientEncounters
  ]);

  //archive encounters
  useEffect(() => {
    if (!isLoadingArchiveEncounter && errorArchiveEncounter) {
      errorToast(errorArchiveEncounter);
      dispatch(resetArchiveEncounter());
    }

    if (!isLoadingArchiveEncounter && successArchiveEncounter) {
      successToast('Encounter archived successfully');
      resetFilters();
      dispatch(resetArchiveEncounter());
      dispatch(fetchAllEncounters({ patientId: undefined, timeframe: selectedValue }));
    }
  }, [isLoadingArchiveEncounter, successArchiveEncounter, errorArchiveEncounter]);

  //unarchive encounters
  useEffect(() => {
    if (!isLoadingUnrchiveEncounter && errorUnarchiveEncounter) {
      errorToast(errorUnarchiveEncounter);
      dispatch(resetUnrchiveEncounter());
    }

    if (!isLoadingUnrchiveEncounter && successUnarchiveEncounter) {
      successToast('Encounter unarchived successfully');
      resetFilters();
      dispatch(resetUnrchiveEncounter());
      dispatch(fetchAllEncounters({ patientId: undefined, timeframe: selectedValue }));
    }
  }, [isLoadingUnrchiveEncounter, successUnarchiveEncounter, errorUnarchiveEncounter]);

  useEffect(() => {
    window.addEventListener('resize', HandleWidth);
    return () => {
      window.removeEventListener('resize', HandleWidth);
    };
  }, []);

  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };

  const ApplyFilter = () => {
    let arr: any = [];
    if (filtersList[2].check) {
      const arc = patientEncounters.filter((data: Encounter) => data.deactivated === true);
      if (filtersList[0].check) {
        const inProgress = arc.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.INPROGRESS
        );
        arr = inProgress;
      }
      if (filtersList[1].check) {
        const completed = arc.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.COMPLETED
        );
        arr = arr.concat(completed);
      }
      if (!filtersList[0].check && !filtersList[1].check) {
        arr = arc;
      }
    } else {
      if (filtersList[0].check) {
        const inProgress = patientEncounters.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.INPROGRESS && !data.deactivated
        );
        arr = arr.concat(inProgress);
      }
      if (filtersList[1].check) {
        const completed = patientEncounters.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.COMPLETED && !data.deactivated
        );
        arr = arr.concat(completed);
      }
      if (!filtersList[0].check && !filtersList[1].check) {
        const f2 = patientEncounters.filter((data: Encounter) => !data.deactivated);
        arr = f2;
      }
    }

    setEncounters(arr);
    setFilterDropDown(!filterDropDown);
  };

  const resetFilters = () => {
    setfiltersList([
      { id: 1, title: 'In progress', check: false },
      { id: 2, title: 'Completed', check: false },
      { id: 3, title: 'Archived', check: false }
    ]);
  };

  const displayEncounterStatus = (status: number) => {
    return status === ENCOUNTER_STATUS.INPROGRESS ? 'In Progress' : 'Completed';
  };
  const displayEncounterInvestigationStatus = (status: number) => {
    return status === ENCOUNTER_INVESTIGATION_STATUS.PENDING
      ? 'Pending'
      : status === ENCOUNTER_INVESTIGATION_STATUS.COMPLETE
      ? 'Completed'
      : 'Arrived';
  };
  const StatusPill: React.FC<{ status: number }> = ({ status }) => (
    <span className="border rounded-pill px-2 py-1">
      <span
        style={{
          height: '9px',
          width: '9px',
          backgroundColor:
            status === ENCOUNTER_INVESTIGATION_STATUS.PENDING
              ? '#2945CD'
              : status === ENCOUNTER_INVESTIGATION_STATUS.COMPLETE
              ? '#19B707'
              : '#F5D10D',
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: '5px',
          paddingTop: '5px'
        }}
      ></span>
      <span className="value-text">{displayEncounterInvestigationStatus(status)}</span>
    </span>
  );

  return (
    <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
      <Container fluid>
        <Row className="justify-content-between">
          <Col>
            <h2 className=" align-self-center text-nowrap">
              <b className="text-nowrap sbl24">Laboratory dashboard</b>
            </h2>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <div
              style={{
                border: '1px solid #e3e3e3',
                borderRadius: '4px',
                paddingRight: '5px',
                backgroundColor: 'white'
                // height: '30px'
              }}
            >
              <select
                className="sb12"
                value={selectedValue}
                onChange={(e) => {
                  setSelectedValue(parseInt(e.target.value));
                }}
                style={{
                  height: '25px',
                  paddingLeft: '7px',
                  paddingRight: '5px',

                  border: 'none'
                }}
              >
                {options?.length > 0 &&
                  options.map((val: any, index: number) =>
                    index === 0 ? (
                      <option
                        className="im14n"
                        style={{ position: 'relative', left: '20px' }}
                        value={val?.value}
                        key={index}
                        selected
                      >
                        {val.name}
                      </option>
                    ) : (
                      <option
                        className="im14n"
                        style={{ position: 'relative', left: '20px' }}
                        value={val?.value}
                        key={index}
                      >
                        {val.name}
                      </option>
                    )
                  )}
              </select>
            </div>
          </Col>
          <Col className="d-flex justify-content-end align-items-center"></Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="border bg-white rounded ps-4 ps-4 pe-3 py-2">
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  <b className="text-nowrap im14b ">Completed encounters</b>
                </p>
              </div>
              {!isLoadingFetchEncounterStats ? (
                encounterStats !== null ? (
                  <p className="mb-0">
                    <span className="text-primary ib18">{encounterStats?.completedEncounters}</span>
                    <span
                      className={
                        parseFloat(encounterStats?.completedEncountersPercent) >= 50
                          ? 'text-success im10b ms-1'
                          : 'text-danger im10b ms-1'
                      }
                    >
                      {encounterStats?.completedEncountersPercent === '0.0'
                        ? 0
                        : encounterStats?.completedEncountersPercent === '100.0'
                        ? '100'
                        : encounterStats?.completedEncountersPercent}
                      %
                    </span>
                  </p>
                ) : (
                  <p className="mb-0">
                    <span className="text-warning im14n">No statistics present</span>
                  </p>
                )
              ) : (
                <Loader height="20px" width="20px" />
              )}
            </div>
          </Col>
          <Col md="4">
            <div className="border bg-white rounded ps-4 pe-3 py-2">
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  <b className="text-nowrap im14b ">Total encounter requests</b>
                </p>
              </div>
              {!isLoadingFetchEncounterStats ? (
                encounterStats !== null ? (
                  <p className="mb-0">
                    <span className="text-primary ib18">{encounterStats?.allEncounters}</span>
                  </p>
                ) : (
                  <p className="mb-0">
                    <span className="text-warning im14n">No statistics present</span>
                  </p>
                )
              ) : (
                <Loader height="20px" width="20px" />
              )}
            </div>
          </Col>
          <Col md="4"></Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="border bg-white rounded ps-4 pe-3 py-2">
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  <b className="text-nowrap im14b">In progress encounters</b>
                </p>
              </div>
              <div className="d-flex justify-content-between">
                {!isLoadingFetchEncounterStats ? (
                  encounterStats !== null ? (
                    <p className="mb-0">
                      <span className="text-primary ib18">
                        {encounterStats?.inProgressEncounters}
                      </span>
                      <span
                        className={
                          parseFloat(encounterStats?.inProgressEncountersPercent) >= 50
                            ? 'text-success im10b ms-1'
                            : 'text-danger im10b ms-1'
                        }
                      >
                        {encounterStats?.inProgressEncountersPercent === '0.0'
                          ? 0
                          : encounterStats?.inProgressEncountersPercent === '100.0'
                          ? '100'
                          : encounterStats?.inProgressEncountersPercent}
                        %
                      </span>
                    </p>
                  ) : (
                    <p className="mb-0">
                      <span className="text-warning im14n">No statistics present</span>
                    </p>
                  )
                ) : (
                  <Loader height="20px" width="20px" />
                )}
                <p
                  className="mb-0 cursor-pointer"
                  onClick={() => {
                    setFilterDropDown(false);
                    const temp = [
                      { id: 1, title: 'In progress', check: true },
                      { id: 2, title: 'Completed', check: false },
                      { id: 3, title: 'Archived', check: false }
                    ];
                    setfiltersList(temp);
                    setTempfiltersList([...temp]);
                    const arc = patientEncounters.filter(
                      (data: Encounter) => data.deactivated === false
                    );
                    const inProgress = arc.filter(
                      (data: Encounter) => data.status === ENCOUNTER_STATUS.INPROGRESS
                    );
                    setEncounters(inProgress);
                    ref.current?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <i className="fas fa-eye me-1 text-primary" />
                  <span className="text-primary im14">View all</span>
                </p>
              </div>
            </div>
          </Col>
          <Col md="8"></Col>
        </Row>
        <div className="horizontal-line mt-3 mb-3"></div>
        <Row className="justify-content-between">
          <Col>
            <h2 className=" align-self-center text-nowrap">
              <b className="text-nowrap sbl18">Encounter History</b>
            </h2>
          </Col>
          <Col className="d-flex justify-content-end align-items-center" style={{ height: '40px' }}>
            {filterDropDown && (
              <div
                style={{
                  background: '#FFFFFF ',
                  boxShadow: '0px 3px 12px #00000029',
                  borderRadius: '8px',
                  position: 'relative',
                  top: '100px',
                  left: '75px',
                  // marginRight: '15px',
                  // marginTop: '200px',
                  padding: '10px',
                  zIndex: 4
                }}
              >
                {filtersList?.map((item, index) => (
                  <div key={index}>
                    <Input
                      type="checkbox"
                      checked={item.check}
                      onChange={(e) => {
                        const { checked } = e.target;
                        const data = JSON.parse(JSON.stringify(filtersList));
                        data[index].check = checked;
                        setfiltersList(data);
                      }}
                    />
                    <Label className="font-size-14px font-family-inter ms-2">{item?.title}</Label>
                  </div>
                ))}
                <div className="mt-2" style={{ marginLeft: '-16px' }}>
                  <WhiteButton
                    text="Dismiss"
                    HandleClick={() => {
                      setFilterDropDown(false);
                      setfiltersList(tempfiltersList);
                    }}
                  />
                  <BlueButton text="Apply filters" HandleClick={ApplyFilter} />
                </div>
              </div>
            )}
            <WhiteButton
              className="zero-margin"
              styles={{ position: 'relative' }}
              Icon={
                <FilterIcon
                  color={'#2C3242'}
                  style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                />
              }
              HandleClick={() => {
                setFilterDropDown(!filterDropDown);
                setTempfiltersList([...filtersList]);
              }}
              text={'Filters'}
            />
          </Col>
        </Row>
        <div className="horizontal-line mt-3 mb-3"></div>

        <div
          className="mt-4"
          style={{
            maxHeight: `${height - 180}px`,
            overflowX: 'hidden',
            scrollbarWidth: 'thin'
          }}
          ref={ref}
        >
          {!isLoadingFetchPatientEncounters ? (
            encounters !== null && encounters.length !== 0 ? (
              encounters.map((encounter: Encounter) => (
                <Accordion key={encounter.id}>
                  <AccordionItem
                    dropDownstyle={{
                      right: '175px'
                    }}
                    dropdownData={[
                      { id: 1, title: 'Open encounter', color: 'default' },
                      encounter.deactivated
                        ? { id: 2, title: 'Unarchive', color: 'danger' }
                        : { id: 2, title: 'Archive', color: 'danger' }
                    ]}
                    onClickDropdownItem={[
                      {
                        id: 1,
                        title: 'Open encounter',
                        event: () => {
                          history.push({
                            pathname: `/patient/${encounter?.patient.id}/encounter/${encounter.id}/details`
                          });
                        }
                      },
                      encounter.deactivated
                        ? {
                            id: 2,
                            title: 'Unarchive',
                            event: () => {
                              dispatch(unarchiveEncounter(encounter?.id));
                            }
                          }
                        : {
                            id: 2,
                            title: 'Archive',
                            event: () => {
                              dispatch(archiveEncounter(encounter?.id));
                            }
                          }
                    ]}
                    accordionHeaderClasses={'justify-content-between'}
                    AccordionHeader={
                      <>
                        <span className="col-md-12 d-flex">
                          <div className="col-md-3 d-flex align-items-center">
                            <div className="d-flex flex-row">
                              <Avatar
                                name={
                                  encounter?.patient?.firstName + ' ' + encounter?.patient?.lastName
                                }
                                maxInitials={2}
                                textSizeRatio={2.2}
                                size="40"
                                color="#2A45CD30"
                                fgColor="#2A45CD"
                                round
                              />
                              <div className="d-flex flex-column ms-3">
                                <span className="patient-name">
                                  {encounter?.patient?.firstName +
                                    ' ' +
                                    encounter?.patient?.lastName}
                                </span>
                                <span className="patient-name patient-details">
                                  {encounter.name}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">Date requested</h2>
                            <h2 className="patient-name mb-0 ">
                              {convertIsoDateTimeFormatToDateTimeFormatWithAmPm(
                                encounter.createdAt
                              )}
                            </h2>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">Requested by</h2>
                            <h2 className="patient-name mb-0">
                              {encounter?.doctor?.title +
                                ' ' +
                                `${encounter?.doctor?.firstName[0].toUpperCase()}.` +
                                ' ' +
                                encounter?.doctor?.lastName}
                            </h2>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">No. of requests</h2>
                            <h2 className="patient-name mb-0">{encounter?.numberOfRequests}</h2>
                          </div>
                          <div className="col-md-3 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">Encounter status</h2>
                            <h2 className="patient-name mb-0">
                              {displayEncounterStatus(encounter?.status)}
                            </h2>
                          </div>
                        </span>
                      </>
                    }
                    AccordionBody={
                      <div className="mb-3">
                        <Row xs={12} className={'py-1 mb-1 my-1 mx-auto'} style={{ width: '90%' }}>
                          <Col className="col-1 text-start key-text"></Col>
                          <Col className="col-4 text-start key-text">Test name</Col>
                          <Col className="col-4 text-start key-text">Result status</Col>
                        </Row>
                        {encounter?.investigations?.panelInvestigation?.map(
                          (panelInvestigation: PanelInvestigationBody) => (
                            <Row
                              key={panelInvestigation.id}
                              className={'border bg-white mx-auto py-3'}
                              style={{ width: '90%' }}
                            >
                              <Col className="col-1 text-start value-text"></Col>
                              <Col className="col-4 text-start value-text">
                                {panelInvestigation?.name}
                              </Col>
                              <Col className="col-4 text-start value-text">
                                {panelInvestigation.encounterMeta?.status !== undefined && (
                                  <StatusPill status={panelInvestigation.encounterMeta?.status} />
                                )}
                              </Col>
                            </Row>
                          )
                        )}
                        {encounter?.investigations?.bk1000?.map((bk1000: Investigation) => (
                          <Row
                            key={bk1000.id}
                            className={'border bg-white mx-auto py-3'}
                            style={{ width: '90%' }}
                          >
                            <Col className="col-1 text-start value-text"></Col>
                            <Col className="col-4 text-start value-text">{bk1000?.name}</Col>
                            <Col className="col-4 text-start value-text">
                              {bk1000?.encounterMeta?.status !== undefined && (
                                <StatusPill status={bk1000?.encounterMeta?.status} />
                              )}
                            </Col>
                          </Row>
                        ))}
                        {encounter?.investigations?.bk200?.map((bk200: Investigation) => (
                          <Row
                            key={bk200.id}
                            className={'border bg-white mx-auto py-3'}
                            style={{ width: '90%' }}
                          >
                            <Col className="col-1 text-start value-text"></Col>
                            <Col className="col-4 text-start value-text">{bk200?.name}</Col>
                            <Col className="col-4 text-start value-text">
                              {bk200?.encounterMeta?.status !== undefined && (
                                <StatusPill status={bk200?.encounterMeta?.status} />
                              )}
                            </Col>
                          </Row>
                        ))}
                        {encounter?.investigations?.bk6190?.map((bk6190: Investigation) => (
                          <Row
                            key={bk6190.id}
                            className={'border bg-white mx-auto py-3'}
                            style={{ width: '90%' }}
                          >
                            <Col className="col-1 text-start value-text"></Col>
                            <Col className="col-4 text-start value-text">{bk6190?.name}</Col>
                            <Col className="col-4 text-start value-text">
                              {bk6190?.encounterMeta?.status !== undefined && (
                                <StatusPill status={bk6190?.encounterMeta?.status} />
                              )}
                            </Col>
                          </Row>
                        ))}
                        {encounter?.investigations?.cbs300?.map((cbs300: Investigation) => (
                          <Row
                            key={cbs300.id}
                            className={'border bg-white mx-auto py-3'}
                            style={{ width: '90%' }}
                          >
                            <Col className="col-1 text-start value-text"></Col>
                            <Col className="col-4 text-start value-text">{cbs300?.name}</Col>
                            <Col className="col-4 text-start value-text">
                              {cbs300?.encounterMeta?.status !== undefined && (
                                <StatusPill status={cbs300?.encounterMeta?.status} />
                              )}
                            </Col>
                          </Row>
                        ))}
                        {encounter?.investigations?.other?.map((other: Investigation) => (
                          <Row
                            key={other.id}
                            className={'border bg-white mx-auto py-3'}
                            style={{ width: '90%' }}
                          >
                            <Col className="col-1 text-start value-text"></Col>
                            <Col className="col-4 text-start value-text">{other?.name}</Col>
                            <Col className="col-4 text-start value-text">
                              {other?.encounterMeta?.status !== undefined && (
                                <StatusPill status={other?.encounterMeta?.status} />
                              )}
                            </Col>
                          </Row>
                        ))}
                      </div>
                    }
                  />
                </Accordion>
              ))
            ) : (
              <div
                className="d-flex justify-content-center w-100"
                style={{
                  width: '100%',
                  height: '60vh',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Empty />
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center w-100" style={{ minHeight: '500px' }}>
              <Loader />
            </div>
          )}{' '}
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
