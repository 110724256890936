import React, { FC, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import {
  resetFetchAllNotifications,
  resetFetchUnreadNotificationCount
} from '~/store/reducers/notification';
import {
  fetchAllNotifications,
  fetchUnreadNotificationCount
} from '~/store/reducers/notification/thunks';
import { NotificationBody } from '~/store/reducers/notification/types';
import { errorToast } from '~/utils/toast';
import Loader from '../Loader';

const NotificationDropdown: FC = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const {
    //fetch all notifications
    notifications,
    isLoadingFetchAllNotifications,
    errorFetchAllNotifications,
    successFetchAllNotifications,
    //unread count
    unreadNotificationCount,
    isLoadingUnreadNotificationCount,
    successUnreadNotificationCount,
    errorUnreadNotificationCount
  } = useAppSelector((state) => state.NotificationReducer);
  const dispatch = useAppDispatch();
  const [unread, setUnread] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState(0);
  const [previousSelected, setPreviousSelected] = useState(0);
  const options = [
    { value: 0, name: 'Today' },
    { value: 1, name: 'This week' },
    { value: 2, name: 'Last week' },
    { value: 3, name: 'This month' },
    { value: 4, name: 'Last month' },
    { value: 5, name: 'All time' }
  ];

  //FETCH UNREAD NOTIFICATION COUNT
  useEffect(() => {
    if (!isLoadingUnreadNotificationCount && errorUnreadNotificationCount) {
      errorToast(errorUnreadNotificationCount);
      dispatch(resetFetchUnreadNotificationCount());
    }
    if (!isLoadingUnreadNotificationCount && successUnreadNotificationCount) {
      setUnread(unreadNotificationCount);
      dispatch(resetFetchUnreadNotificationCount());
    }
  }, [
    isLoadingUnreadNotificationCount,
    successUnreadNotificationCount,
    errorUnreadNotificationCount
  ]);

  //FETCH UNREAD COUNT
  useEffect(() => {
    dispatch(fetchUnreadNotificationCount());
  }, [selectedValue, notifications]);

  const getNotifications = (clicked: boolean) => {
    dispatch(fetchAllNotifications({ timeframe: selectedValue, read: clicked }));
  };

  useEffect(() => {
    if (selectedValue === previousSelected) {
      getNotifications(false);
    } else {
      getNotifications(true);
    }
    setPreviousSelected(selectedValue);
  }, [selectedValue]);

  //FETCH NOTIFICATIONS
  useEffect(() => {
    if (!isLoadingFetchAllNotifications && errorFetchAllNotifications) {
      errorToast(errorFetchAllNotifications);
      dispatch(resetFetchAllNotifications());
    }
    if (!isLoadingFetchAllNotifications && successFetchAllNotifications) {
      dispatch(resetFetchAllNotifications());
    }
  }, [isLoadingFetchAllNotifications, successFetchAllNotifications, errorFetchAllNotifications]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block mt-2"
        tag="li"
      >
        <DropdownToggle
          onClick={() => {
            getNotifications(true);
          }}
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={unread === 0 ? 'bx bx-bell' : 'bx bx-bell bx-tada'} />
          <span className="badge bg-danger rounded-pill">{unread === 0 ? '' : unread}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <Row className="align-items-center px-3 py-2">
            <Col>
              <h6 className="m-0 im14l"> Notifications </h6>
            </Col>
            <div className="col-auto">
              <div
                style={{
                  backgroundColor: 'white'
                }}
              >
                <select
                  className="im14n"
                  value={selectedValue}
                  onChange={(e) => {
                    setSelectedValue(parseInt(e.target.value));
                  }}
                  style={{
                    height: '25px',
                    paddingLeft: '7px',
                    paddingRight: '5px',
                    border: 'none',
                    textAlign: 'left'
                  }}
                >
                  {options?.length > 0 &&
                    options.map((val: any, index: number) =>
                      index === 0 ? (
                        <option
                          className="im14n"
                          style={{ position: 'relative', left: '20px' }}
                          value={val?.value}
                          key={index}
                          selected
                        >
                          {val.name}
                        </option>
                      ) : (
                        <option
                          className="im14n"
                          style={{ position: 'relative', left: '20px' }}
                          value={val?.value}
                          key={index}
                        >
                          {val.name}
                        </option>
                      )
                    )}
                </select>
              </div>
            </div>
          </Row>
          <hr className="solid mt-0" />
          <div style={{ minHeight: 'max-content', maxHeight: '200px', overflowY: 'auto' }}>
            {isLoadingFetchAllNotifications ? (
              <div className="d-flex justify-content-center w-100 mb-3" style={{ height: '200px' }}>
                <Loader height="20px" width="20px" />
              </div>
            ) : notifications.length !== 0 ? (
              notifications.map((notification: NotificationBody) => (
                <div
                  key={notification.id}
                  style={!notification.read ? { fontWeight: 'bolder' } : {}}
                  // style={
                  // notification.type === NOTIFICATION_TYPE.EXPIRED
                  //   ? {
                  //       color: 'red',
                  //       fontWeight: 'bolder'
                  //     }
                  //   : notification.type === NOTIFICATION_TYPE.WILL_EXPIRE
                  //   ? { color: 'red' }
                  //   : notification.type === NOTIFICATION_TYPE.SECOND_WARNING
                  //   ? { color: 'orange', fontWeight: 'bolder' }
                  //   : {}
                  // }
                >
                  <div className="px-3 pb-2 im14l">{notification.description}</div>
                  <hr className="solid mt-0" />
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center w-100 mb-3 key-text">
                No notifications present
              </div>
            )}
          </div>
          {/* <SimpleBar style={{ height: '230px', paddingLeft: '10px' }}>
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="bx bx-cart" />
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Your order is placed</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">If several languages coalesce the grammar</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" /> 3 min ago{' '}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <img src={avatar1} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">It will seem like simplified English</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />1 hours ago{' '}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check" />
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Your item is shipped</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">If several languages coalesce the grammar</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" /> 3 min ago
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </SimpleBar> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
