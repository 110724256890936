import clsx from 'clsx';
import React, { createRef, FC, useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { useParams } from 'react-router';
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import { Investigation, PanelInvestigationBody } from '~/models/modalsInterfaces.model';
import LabTestResults from '~/components/LabTestResults';
import { PatientDetails } from '~/models/User.model';
import { WithRouterProps } from '~/models/WithRouterProps.model';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import {
  resetArchiveEncounter,
  resetEncounterFetchById,
  resetUnrchiveEncounter,
  resetUpdatedEncounter
} from '~/store/reducers/encounter';
import {
  archiveEncounter,
  fetchEncounterById,
  unarchiveEncounter,
  updateEncounter
} from '~/store/reducers/encounter/thunks';
import { EncounterBody } from '~/store/reducers/encounter/types';
import { ENCOUNTER_INVESTIGATION_STATUS } from '~/utils/constants';
import {
  convertIsoDateTimeFormatToDateTimeFormatWithAmPmAndDash,
  convertServerDateFormatToSlashDateFormat,
  convertUTCServerDateTimeFormatToLocalDateTimeFormatWithAmPmAndDash,
  getAgeFromDate,
  getCurrentDateTimeInBarcodeFormat
} from '~/utils/dateUtility';
import useWindowSize from '~/utils/hooks/useWindowSize';
import { errorToast, successToast } from '~/utils/toast';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';
import './CreateEncounter.styles.scss';
import RenderInvestigation from './RenderInvestigation';
import RenderPanel from './RenderPanel';
import { useReactToPrint } from 'react-to-print';
import Loader from '~/components/Common/Loader';
import Empty from '~/components/Common/Empty';
import PanelExport from '~/components/LabTestResults/PanelExport';
import RenderCBC from './CompleteBloodCount';
import Barcode from 'react-barcode';
import { resetFetchPatientById } from '~/store/reducers/patient';
import { fetchPatientById } from '~/store/reducers/patient/thunks';

interface OtherValues {
  general: string;
  nestedFirst: string;
  nestedSecond: string;
}
const CreateEncounter: FC<WithRouterProps> = () => {
  const dispatch = useAppDispatch();
  const {
    isLoadingUpdateEncounter,
    errorUpdateEncounter,
    successUpdateEncounter,
    //fetch by id
    encounter,
    isLoadingEncounterFetchById,
    errorEncounterFetchById,
    successEncounterFetchById,

    //archive encounters
    isLoadingArchiveEncounter,
    errorArchiveEncounter,
    successArchiveEncounter,

    //unarchive encounters
    isLoadingUnrchiveEncounter,
    errorUnarchiveEncounter,
    successUnarchiveEncounter
  } = useAppSelector((state) => state.EncounterReducer);
  const {
    //fetch patient
    patient: reduxPatient,
    isLoadingFetchPatientById,
    successFetchPatientById,
    errorFetchPatientById
  } = useAppSelector((state) => state.PatientReducer);

  // const { encounterId } = location?.state as PageType;

  const { pId, id } = useParams<{ pId: string; id: string }>();
  const patientId: number = parseInt(pId);
  const encounterId: number = parseInt(id);

  const printRef = useRef(null);
  let panelRef = useRef(null);
  let bk1000Ref = useRef(null);
  let bk200Ref = useRef(null);
  const bk6190Ref = useRef(null);
  let cbs300Ref = useRef(null);
  let otherRef = useRef(null);
  const barcodeRef = useRef(null);
  //ref arrays
  const panelRefArray: any = [];
  const bk1000RefArray: any = [];
  const bk200RefArray: any = [];
  const cbs300RefArray: any = [];
  const otherRefArray: any = [];

  const handleBarcodePrint = useReactToPrint({
    content: () => barcodeRef.current
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const handlePrintPanel = useReactToPrint({
    content: () => panelRef.current
  });
  const setPanel = async (ref: any) => {
    panelRef = ref;
    return;
  };

  const handlePrintBk1000 = useReactToPrint({
    content: () => bk1000Ref.current
  });
  const setBk1000 = async (ref: any) => {
    bk1000Ref = ref;
  };

  const handlePrintBk200 = useReactToPrint({
    content: () => bk200Ref.current
  });
  const setBk200 = async (ref: any) => {
    bk200Ref = ref;
  };

  const handlePrintBk6190 = useReactToPrint({
    content: () => bk6190Ref.current
  });

  const handlePrintCbs300 = useReactToPrint({
    content: () => cbs300Ref.current
  });
  const setCbs300 = async (ref: any) => {
    cbs300Ref = ref;
  };

  const handlePrintOther = useReactToPrint({
    content: () => otherRef.current
  });
  const setOther = async (ref: any) => {
    otherRef = ref;
  };

  const { height } = useWindowSize();
  const [patientData, setPatientData] = useState<PatientDetails>();

  const [encounterDetails, setEncounterDetails] = useState<EncounterBody>();
  const [anionGapVal, setAnionGapVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [sodiumValforAnionGap, setSodiumValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [potassiumValforAnionGap, setPotassiumValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [chlorideValforAnionGap, setChlorideValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [bicarbonateValforAnionGap, setBicarbonateValforAnionGap] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [firstPanelGFRFemaleWeight, setFirstPanelGFRFemaleWeight] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [firstPanelGFRMaleWeight, setFirstPanelGFRMaleWeight] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [firstPanelGFRFemaleVal, setFirstPanelGFRFemaleVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [firstPanelGFRMaleVal, setFirstPanelGFRMaleVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [serumCretValforGFRF, setSerumCretValforGFRF] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [apolipoRatioVal, setApolipoRatioVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [apolipoAVal, setApolipoAVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [apolipoBVal, setApolipoBVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [correctedCalciumVal, setCorrectedCalciumVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [albuminValforcorrCal, setAlbuminValforcorrCal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [calciumValforcorrCal, setCalciumValforcorrCal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  const [panelEdits, setPanelEdits] = useState<boolean[]>([]);
  const [bk1000Edits, setBk1000Edits] = useState<boolean[]>([]);
  const [bk200Edits, setBk200Edits] = useState<boolean[]>([]);
  // const [bk6190Edits, setBk6190Edits] = useState<boolean[]>([]);
  const [bk6190Edit, setBk6190Edit] = useState<boolean>(false);
  const [cbs300Edits, setCbs300Edits] = useState<boolean[]>([]);
  const [otherEdits, setOtherEdits] = useState<boolean[]>([]);
  const [indexSave, setIndexSave] = useState<number>(-1);
  const [saveBtn, setSaveBtn] = useState<boolean>(false);

  const resetEdits = () => {
    const temp = [...panelEdits];
    temp[indexSave] = false;

    for (let i = 0; i < temp?.length; i++) {
      temp[i] = false;
    }
    setPanelEdits(temp);
    // bk1000
    const temp2 = [...bk1000Edits];
    temp2[indexSave] = false;

    for (let i = 0; i < temp2?.length; i++) {
      temp2[i] = false;
    }
    setBk1000Edits(temp2);
    //bk200
    const temp3 = [...bk200Edits];
    temp3[indexSave] = false;

    for (let i = 0; i < temp3?.length; i++) {
      temp3[i] = false;
    }
    setBk200Edits(temp3);
    //bk6190
    // const temp4 = [...bk6190Edits];
    // temp4[indexSave] = false;

    // for (let i = 0; i < temp4?.length; i++) {
    //   temp4[i] = false;
    // }
    // setBk6190Edits(temp4);
    setBk6190Edit(false);
    //cbs300
    const temp5 = [...cbs300Edits];
    temp5[indexSave] = false;

    for (let i = 0; i < temp5?.length; i++) {
      temp5[i] = false;
    }
    setCbs300Edits(temp5);
    //others
    const temp6 = [...otherEdits];
    temp6[indexSave] = false;

    for (let i = 0; i < temp6?.length; i++) {
      temp6[i] = false;
    }
    setOtherEdits(temp6);
  };
  //UPDATE ENCOUNTER
  useEffect(() => {
    if (!isLoadingUpdateEncounter && successUpdateEncounter) {
      successToast('Encounter updated successfully');
      dispatch(resetUpdatedEncounter());
      dispatch(fetchEncounterById(encounterId));
      setSaveBtn(false);
      resetEdits();
    } else if (!isLoadingUpdateEncounter && errorUpdateEncounter) {
      errorToast(errorUpdateEncounter);
      dispatch(resetUpdatedEncounter());
      // setEncounterDetails(encounter);
      dispatch(fetchEncounterById(encounterId));
      setSaveBtn(false);
      resetEdits();
    }
  }, [isLoadingUpdateEncounter, successUpdateEncounter, errorUpdateEncounter]);

  useEffect(() => {
    if (reduxPatient) {
      setPatientData(reduxPatient);
    }
  }, [reduxPatient]);

  //fetch patient by id
  useEffect(() => {
    if (!isLoadingFetchPatientById && errorFetchPatientById) {
      errorToast(errorFetchPatientById);
      dispatch(resetFetchPatientById());
    }

    if (!isLoadingFetchPatientById && successFetchPatientById) {
      dispatch(resetFetchPatientById());
    }
  }, [isLoadingFetchPatientById, successFetchPatientById, errorFetchPatientById]);

  //archive encounters
  useEffect(() => {
    if (!isLoadingArchiveEncounter && errorArchiveEncounter) {
      errorToast(errorArchiveEncounter);
      dispatch(resetArchiveEncounter());
    }

    if (!isLoadingArchiveEncounter && successArchiveEncounter) {
      successToast('Encounter archived successfully');
      dispatch(resetArchiveEncounter());
    }
  }, [isLoadingArchiveEncounter, successArchiveEncounter, errorArchiveEncounter]);

  //unarchive encounters
  useEffect(() => {
    if (!isLoadingUnrchiveEncounter && errorUnarchiveEncounter) {
      errorToast(errorUnarchiveEncounter);
      dispatch(resetUnrchiveEncounter());
    }

    if (!isLoadingUnrchiveEncounter && successUnarchiveEncounter) {
      successToast('Encounter unarchived successfully');
      dispatch(resetUnrchiveEncounter());
    }
  }, [isLoadingUnrchiveEncounter, successUnarchiveEncounter, errorUnarchiveEncounter]);

  useEffect(() => {
    if (!isLoadingEncounterFetchById && successEncounterFetchById) {
      setEncounterDetails(encounter);

      dispatch(resetEncounterFetchById());
    }
    if (!isLoadingEncounterFetchById && errorEncounterFetchById) {
      errorToast(errorEncounterFetchById);

      dispatch(resetEncounterFetchById());
    }
  }, [isLoadingEncounterFetchById, successEncounterFetchById, errorEncounterFetchById]);

  useEffect(() => {
    dispatch(fetchPatientById(patientId));
    dispatch(fetchEncounterById(encounterId));
  }, [encounterId]);

  //Encounter Level values for other investigations
  useEffect(() => {
    if (encounterDetails) {
      //values for apolipo ratio
      if (apolipoAVal?.general === '') {
        const apolipoA = encounterDetails?.investigations.bk1000.findIndex(
          (bk1000: any) => bk1000.id === 63
        );
        if (apolipoA !== -1) {
          setApolipoAVal({
            ...apolipoAVal,
            general: String(encounterDetails?.investigations?.bk1000[apolipoA]?.result ?? '')
          });
        }
      }
      if (apolipoBVal?.general === '') {
        const apolipoB =
          encounterDetails?.investigations.bk1000.findIndex((bk1000: any) => bk1000.id === 64) ??
          -1;
        if (apolipoB !== -1) {
          setApolipoBVal({
            ...apolipoBVal,
            general: String(encounterDetails?.investigations?.bk1000[apolipoB]?.result ?? '')
          });
        }
      }

      //values for anion gap
      if (bicarbonateValforAnionGap?.general === '') {
        const bicarbonate =
          encounterDetails?.investigations?.bk200.findIndex((bk200: any) => bk200.id === 33) ?? -1;
        if (bicarbonate !== -1)
          setBicarbonateValforAnionGap({
            ...bicarbonateValforAnionGap,
            general: String(encounterDetails?.investigations?.bk200[bicarbonate]?.result ?? '')
          });
      }
      if (chlorideValforAnionGap?.general === '') {
        const chloride =
          encounterDetails?.investigations?.cbs300.findIndex((cbs300: any) => cbs300.id === 34) ??
          -1;
        if (chloride !== -1)
          setChlorideValforAnionGap({
            ...chlorideValforAnionGap,
            general: String(encounterDetails?.investigations?.cbs300[chloride]?.result ?? '')
          });
      }
      if (potassiumValforAnionGap?.general === '') {
        const potassium =
          encounterDetails?.investigations.cbs300.findIndex(
            (cbs300: Investigation) => cbs300.id === 35
          ) ?? -1;
        if (potassium !== -1)
          setPotassiumValforAnionGap({
            ...potassiumValforAnionGap,
            general: String(encounterDetails?.investigations.cbs300[potassium].result ?? '')
          });
      }
      if (sodiumValforAnionGap?.general === '') {
        const sodium =
          encounterDetails?.investigations?.cbs300.findIndex((cbs300: any) => cbs300.id === 36) ??
          -1;
        if (sodium !== -1)
          setSodiumValforAnionGap({
            ...sodiumValforAnionGap,
            general: String(encounterDetails?.investigations?.cbs300[sodium]?.result ?? '')
          });
      }

      //values for corrected calcium
      if (calciumValforcorrCal?.general === '') {
        const calcium =
          encounterDetails?.investigations.bk200.findIndex(
            (bk200: Investigation) => bk200.id === 46
          ) ?? -1;
        if (calcium !== -1)
          setCalciumValforcorrCal({
            ...calciumValforcorrCal,
            general: String(encounterDetails?.investigations.bk200[calcium].result ?? '')
          });
      }
      if (albuminValforcorrCal?.general === '') {
        const albumin =
          encounterDetails?.investigations?.bk200.findIndex((bk200: any) => bk200.id === 42) ?? -1;
        if (albumin !== -1)
          setAlbuminValforcorrCal({
            ...albuminValforcorrCal,
            general: String(encounterDetails?.investigations.bk200[albumin].result ?? '')
          });
      }
      //values for GFR
      if (serumCretValforGFRF?.general === '') {
        const serum =
          encounterDetails?.investigations.bk200.findIndex(
            (bk200: Investigation) => bk200.id === 47
          ) ?? -1;
        if (serum !== -1)
          setSerumCretValforGFRF({
            ...serumCretValforGFRF,
            general: String(encounterDetails?.investigations.bk200[serum].result ?? '')
          });
      }
      if (firstPanelGFRFemaleWeight?.general === '') {
        const gfrf =
          encounterDetails?.investigations.other.findIndex(
            (other: Investigation) => other.id === 39
          ) ?? -1;
        if (gfrf !== -1)
          setFirstPanelGFRFemaleWeight({
            ...firstPanelGFRFemaleWeight,
            general: String(encounterDetails?.investigations.other[gfrf].result ?? '')
          });
      }
      if (firstPanelGFRMaleWeight?.general === '') {
        const gfrm =
          encounterDetails?.investigations.other.findIndex(
            (other: Investigation) => other.id === 40
          ) ?? -1;
        if (gfrm !== -1)
          setFirstPanelGFRMaleWeight({
            ...firstPanelGFRMaleWeight,
            general: String(encounterDetails?.investigations.other[gfrm].result ?? '')
          });
      }
    }
  }, [encounterDetails]);

  const EncounterInvestigationStatus = ({ status }: { status: number | undefined }) => {
    return isLoadingUpdateEncounter ? (
      <Loader width="10px" height="10px" />
    ) : (
      <span
        className={
          status === ENCOUNTER_INVESTIGATION_STATUS.PENDING
            ? 'text-danger'
            : status === ENCOUNTER_INVESTIGATION_STATUS.COMPLETE
            ? 'text-success'
            : 'text-success'
        }
      >
        {status === ENCOUNTER_INVESTIGATION_STATUS.PENDING
          ? 'Pending'
          : status === ENCOUNTER_INVESTIGATION_STATUS.COMPLETE
          ? 'Complete'
          : 'Arrived'}
      </span>
    );
  };
  const handleChangeAdditionalNotes = (text: string, investigation: string, index: number) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    if (investigation === 'panel') {
      data.investigations.panelInvestigation[index].encounterMeta.additionalNotes = text;
    } else if (investigation === 'bk1000') {
      data.investigations.bk1000[index].encounterMeta.additionalNotes = text;
    } else if (investigation === 'bk200') {
      data.investigations.bk200[index].encounterMeta.additionalNotes = text;
    } else if (investigation === 'bk6190') {
      data.investigations.bk6190[index].encounterMeta.additionalNotes = text;
    } else if (investigation === 'cbs300') {
      data.investigations.cbs300[index].encounterMeta.additionalNotes = text;
    } else if (investigation === 'other') {
      data.investigations.other[index].encounterMeta.additionalNotes = text;
    }
    setEncounterDetails(data);
  };

  const markInvestigationAsComplete = (status: number, investigation: string, index: number) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));

    if (investigation === 'panel') {
      data.investigations.panelInvestigation[index].encounterMeta.status = status;
    } else if (investigation === 'bk1000') {
      data.investigations.bk1000[index].encounterMeta.status = status;
    } else if (investigation === 'bk200') {
      data.investigations.bk200[index].encounterMeta.status = status;
    } else if (investigation === 'bk6190') {
      // data.investigations.bk6190[index].encounterMeta.status = status;
      data.investigations.bk6190.forEach((bk6190: Investigation) => {
        if (bk6190.encounterMeta) {
          bk6190.encounterMeta.status = status;
        }
      });
    } else if (investigation === 'cbs300') {
      data.investigations.cbs300[index].encounterMeta.status = status;
    } else if (investigation === 'other') {
      data.investigations.other[index].encounterMeta.status = status;
    }
    setEncounterDetails(data);
    dispatch(updateEncounter(data));
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(true);
    }
  };

  const encounterRerun = (data: EncounterBody | PanelInvestigationBody) => {
    data?.investigations?.bk1000?.length !== 0 &&
      data?.investigations?.bk1000?.forEach((val: Investigation) => {
        val.tracker = val.tracker + 1;
      });
    data?.investigations?.bk200?.length !== 0 &&
      data?.investigations?.bk200?.forEach((val: Investigation) => {
        val.tracker = val.tracker + 1;
      });
    data?.investigations?.bk6190?.length !== 0 &&
      data?.investigations?.bk6190?.forEach((val: Investigation) => {
        val.tracker = val.tracker + 1;
      });
    data?.investigations?.cbs300?.length !== 0 &&
      data?.investigations?.cbs300?.forEach((val: Investigation) => {
        val.tracker = val.tracker + 1;
      });
    data?.investigations?.other?.length !== 0 &&
      data?.investigations?.other?.forEach((val: Investigation) => {
        val.tracker = val.tracker + 1;
      });
  };

  const panelRerun = (data: EncounterBody | PanelInvestigationBody) => {
    data?.investigations?.panelInvestigation?.length !== 0 &&
      data?.investigations?.panelInvestigation?.forEach((panel: PanelInvestigationBody) => {
        encounterRerun(panel);
        panel?.investigations?.panelInvestigation?.length !== 0 &&
          panel?.investigations?.panelInvestigation?.forEach(
            (nestedFirst: PanelInvestigationBody) => {
              encounterRerun(nestedFirst);
              nestedFirst?.investigations?.panelInvestigation?.length !== 0 &&
                nestedFirst?.investigations?.panelInvestigation?.forEach(
                  (nestedSecond: PanelInvestigationBody) => {
                    //nested first panel investigations
                    encounterRerun(nestedSecond);
                  }
                );
            }
          );
      });
  };

  const handleFullEncounterRerun = () => {
    //TODO
    if (encounterDetails) {
      const data: EncounterBody = JSON.parse(JSON.stringify(encounterDetails));
      encounterRerun(data);
      //panel
      panelRerun(data);
      setEncounterDetails(data);
      dispatch(updateEncounter(data));
    }
  };

  const handleSingleAccordionRerun = (index: number, type: string) => {
    if (encounterDetails) {
      const data: EncounterBody = JSON.parse(JSON.stringify(encounterDetails));
      if (type === 'panel') {
        if (data?.investigations?.panelInvestigation?.length !== 0) {
          encounterRerun(data?.investigations?.panelInvestigation[index]);
          if (
            data?.investigations?.panelInvestigation[index]?.investigations?.panelInvestigation
              ?.length !== 0
          ) {
            data?.investigations?.panelInvestigation[
              index
            ]?.investigations?.panelInvestigation?.forEach(
              (nestedFirst: PanelInvestigationBody) => {
                encounterRerun(nestedFirst);
                nestedFirst?.investigations?.panelInvestigation?.length !== 0 &&
                  nestedFirst?.investigations?.panelInvestigation?.forEach(
                    (nestedSecond: PanelInvestigationBody) => {
                      encounterRerun(nestedSecond);
                    }
                  );
              }
            );
          }
        }
      } else if (type === 'bk1000') {
        if (data?.investigations?.bk1000?.length !== 0) {
          data.investigations.bk1000[index].tracker =
            data?.investigations?.bk1000[index]?.tracker + 1;
        }
      } else if (type === 'bk200') {
        if (data?.investigations?.bk200?.length !== 0) {
          data.investigations.bk200[index].tracker =
            data?.investigations?.bk200[index]?.tracker + 1;
        }
      } else if (type === 'bk6190') {
        if (data?.investigations?.bk6190?.length !== 0) {
          data.investigations.bk6190.forEach((bk6190: Investigation) => {
            if (bk6190?.tracker) {
              bk6190.tracker = bk6190.tracker + 1;
            }
          });
        }
      } else if (type === 'cbs300') {
        if (data?.investigations?.cbs300?.length !== 0) {
          data.investigations.cbs300[index].tracker =
            data?.investigations?.cbs300[index]?.tracker + 1;
        }
      } else if (type === 'other') {
        if (data?.investigations?.other?.length !== 0) {
          data.investigations.other[index].tracker =
            data?.investigations?.other[index]?.tracker + 1;
        }
      }
      setEncounterDetails(data);
      dispatch(updateEncounter(data));
    }
  };

  const editInvestigation = (index: number, arrayEdit: boolean[], type: string) => {
    if (type === 'bk6190') {
      setBk6190Edit(true);
    } else {
      const temp = [...arrayEdit];
      temp[index] = true;
      if (type === 'panel') {
        setPanelEdits(temp);
      } else if (type === 'bk1000') {
        setBk1000Edits(temp);
      } else if (type === 'bk200') {
        setBk200Edits(temp);
      } else if (type === 'bk6190') {
        // setBk6190Edits(temp);
      } else if (type === 'cbs300') {
        setCbs300Edits(temp);
      } else if (type === 'other') {
        setOtherEdits(temp);
      }
    }
  };

  const handleUpdateEncounter = () => {
    if (encounterDetails) {
      dispatch(updateEncounter(encounterDetails));
    } else {
      errorToast('Error: Some issue with data - please try again later!');
    }
  };

  return (
    <>
      {isLoadingEncounterFetchById ? (
        <div className="d-flex justify-content-center w-100" style={{ minHeight: '700px' }}>
          <Loader />
        </div>
      ) : encounterDetails ? (
        <>
          <div className="page-content bg-white" style={{ maxHeight: `${height - 10}px` }}>
            <Container fluid>
              <Row className="bg-white">
                <Col lg="3">
                  {encounterDetails && (
                    <h2 className="m-0 align-self-center text-nowrap">
                      <b className="text-nowrap sbl24">Encounter: {encounterDetails?.name}</b>
                    </h2>
                  )}
                </Col>
                <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
                <Col
                  lg="4"
                  className="text-end text-nowrap d-flex justify-content-end align-items-center"
                >
                  <UncontrolledDropdown direction={'down'}>
                    <DropdownToggle className="p-0 border-0 m-0 bg-transparent">
                      <ThreeDotsIcon color="#696E7C" onClick={() => toggleDropdown()} />
                    </DropdownToggle>

                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                      style={{
                        position: 'relative',
                        right: '150px',
                        marginTop: '-10px'
                      }}
                    >
                      <DropdownMenu
                        style={{
                          background: '#FFFFFF ',
                          boxShadow: '0px 3px 12px #00000029',
                          borderRadius: '8px'
                        }}
                      >
                        {[
                          { id: 1, title: 'Email' },
                          {
                            id: 2,
                            title: 'Export PDF',
                            onClick: handlePrint
                          },
                          { id: 3, title: 'Re-run', onClick: handleFullEncounterRerun },
                          {
                            id: 4,
                            title: 'Print barcode',
                            onClick: handleBarcodePrint
                          },
                          encounterDetails?.deactivated
                            ? {
                                id: 5,
                                title: 'Unarchive',
                                onCick: () => {
                                  if (encounterDetails?.id) {
                                    dispatch(unarchiveEncounter(encounterDetails.id));
                                  }
                                }
                              }
                            : {
                                id: 5,
                                title: 'Archive',
                                color: 'danger',
                                onClick: () => {
                                  if (encounterDetails?.id) {
                                    dispatch(archiveEncounter(encounterDetails.id));
                                  }
                                }
                              }
                        ]?.map((item, index) => (
                          <DropdownItem
                            className={'p-0 py-1'}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              item?.onClick && item?.onClick();
                              // onClickDropdownItem[index]?.event?.(e);
                            }}
                          >
                            <span
                              className={clsx(
                                'dropdown-item-text p-0 px-3 font-size-14',
                                item?.color === 'danger' ? 'text-danger' : 'text-black'
                              )}
                            >
                              {item?.title}
                            </span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              <div className="horizontal-line mt-3 mb-2"></div>
              {patientData && (
                <Row className="mb-3">
                  <Col className="col-lg-4 col-md-2">
                    <div className="d-flex align-items-center ">
                      <Avatar
                        name={`${patientData?.firstName} ${patientData?.lastName}`}
                        maxInitials={2}
                        textSizeRatio={2.2}
                        size="40"
                        color="#2A45CD30"
                        fgColor="#2A45CD"
                        round
                      />
                      <div className="d-flex  flex-column ms-2">
                        <span
                          className="font-size-14px"
                          style={{
                            fontFamily: 'Satoshi-Bold',
                            letterSpacing: '-0.17px',
                            color: '#2C3242'
                          }}
                        >
                          {`${patientData?.firstName} ${patientData?.lastName}`}
                        </span>
                        <span
                          className="font-size-12"
                          style={{
                            whiteSpace: 'nowrap',
                            fontFamily: 'Inter',
                            fontWeight: 'medium',
                            letterSpacing: '-0.17px',
                            color: '#6b6f7a'
                          }}
                        >
                          {patientData?.gender === 'M'
                            ? 'Male'
                            : patientData?.gender === 'F'
                            ? 'Female'
                            : patientData?.gender === 'O'
                            ? 'Others'
                            : '-'}
                          · {getAgeFromDate(patientData.dateOfBirth)} ·{' '}
                          {convertServerDateFormatToSlashDateFormat(patientData?.dateOfBirth)}
                        </span>
                      </div>
                    </div>
                  </Col>

                  {encounterDetails && (
                    <Col className="col-lg-8 col-md-10">
                      <div className="d-flex flex-row">
                        <Col className="d-flex flex-column col-4">
                          <span className="small-label">Sample Collection Time</span>
                          <span className="font-size-14" style={{ fontWeight: '500' }}>
                            {convertUTCServerDateTimeFormatToLocalDateTimeFormatWithAmPmAndDash(
                              encounterDetails?.collectionTime
                            )}
                          </span>
                        </Col>
                        <Col className="d-flex flex-column col-6">
                          <span className="small-label">Encounter requested by</span>
                          <span className="font-size-14" style={{ fontWeight: '500' }}>
                            {`${
                              encounterDetails?.doctor?.title
                            } ${encounterDetails?.doctor?.firstName[0].toLocaleUpperCase()}.`}{' '}
                            {encounterDetails?.doctor?.lastName} on{' '}
                            {convertIsoDateTimeFormatToDateTimeFormatWithAmPmAndDash(
                              encounterDetails?.createdAt
                            )}
                          </span>
                        </Col>
                        <Col
                          className="d-flex flex-column"
                          style={{
                            justifyContent: 'center'
                          }}
                        >
                          {saveBtn && (
                            <BlueButton
                              text={'Save'}
                              style={{
                                alignSelf: 'flex-end'
                              }}
                              loading={isLoadingUpdateEncounter}
                              disabled={isLoadingUpdateEncounter}
                              width="102px"
                              HandleClick={() => {
                                handleUpdateEncounter();
                              }}
                            />
                          )}
                        </Col>
                      </div>
                    </Col>
                  )}
                </Row>
              )}

              <div className="horizontal-line mt-2 mb-3"></div>
            </Container>
          </div>
          <div
            style={{
              minHeight: '400px',
              maxHeight: `${height - 170}px`,
              scrollbarWidth: 'thin',
              marginTop: '-60px',
              overflowX: 'hidden'
            }}
          >
            <div className="ms-4 me-2">
              <Accordion>
                {encounterDetails?.investigations &&
                  encounterDetails.investigations?.panelInvestigation?.length > 0 &&
                  encounterDetails?.investigations?.panelInvestigation?.map(
                    (panel: PanelInvestigationBody, index: number) => {
                      const newRef = createRef();
                      panelRefArray.push(newRef);

                      panelEdits.push(false);

                      return (
                        <AccordionItem
                          key={index}
                          dropDownstyle={{
                            right: '175px'
                          }}
                          height={'40px'}
                          disableDropdown={isLoadingUpdateEncounter}
                          accordionHeaderClasses={'justify-content-between'}
                          dropdownData={[
                            !panelEdits[index]
                              ? { id: 1, title: 'Edit' }
                              : { id: 1, title: 'Edit', color: 'text-secondary', disable: true },
                            { id: 2, title: 'Email' },
                            { id: 3, title: 'Export PDF' },
                            panel?.encounterMeta?.status === 0
                              ? { id: 4, title: 'Mark as complete' }
                              : { id: 4, title: 'Mark as pending' },
                            { id: 5, title: 'Re-run', color: 'danger' }
                          ]}
                          onClickDropdownItem={[
                            !panelEdits[index] && {
                              id: 1,
                              title: 'Edit',
                              event: () => {
                                setIndexSave(index);
                                setSaveBtn(true);
                                editInvestigation(index, panelEdits, 'panel');
                              }
                            },
                            {
                              id: 2,
                              title: 'Email',
                              event: () => {
                                //TODO
                              }
                            },
                            {
                              id: 3,
                              title: 'Export PDF',
                              event: () => {
                                setPanel(panelRefArray[index]).then(() => {
                                  handlePrintPanel();
                                });
                              }
                            },
                            panel?.encounterMeta?.status === 0
                              ? {
                                  id: 4,
                                  title: 'Mark as complete',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.COMPLETE,
                                      'panel',
                                      index
                                    );
                                  }
                                }
                              : {
                                  id: 4,
                                  title: 'Mark as pending',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.PENDING,
                                      'panel',
                                      index
                                    );
                                  }
                                },
                            {
                              id: 5,
                              title: 'Re-run',
                              color: 'danger',
                              event: () => {
                                //TODO
                                handleSingleAccordionRerun(index, 'panel');
                              }
                            }
                          ]}
                          AccordionHeader={
                            <>
                              <span className="patient-name ms-2">{panel?.name}</span>
                              <span className="patient-name text-primary me-3">
                                <EncounterInvestigationStatus
                                  status={panel?.encounterMeta?.status}
                                />
                              </span>
                            </>
                          }
                          AccordionBody={
                            <div className={'px-4'}>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="requestNotes" className="small-label">
                                      Additional notes
                                    </Label>
                                    <Input
                                      placeholder="Notes added here will appear on PDF results"
                                      id="referralDoctor"
                                      name="select"
                                      type="textarea"
                                      className={!panelEdits[index] ? '' : 'bg-white'}
                                      value={panel.encounterMeta?.additionalNotes ?? ''}
                                      disabled={!panelEdits[index]}
                                      onChange={(e) => {
                                        handleChangeAdditionalNotes(e.target.value, 'panel', index);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={4} className={'small-label'}>
                                  Investigations
                                </Col>
                                <Col xs={2} className={'small-label'}>
                                  Enter result value
                                </Col>
                                <Col className={'small-label text-center'}>Tracker</Col>
                                <Col className={'small-label text-center'}>Unit</Col>
                                <Col className={'small-label text-center'}>Range</Col>
                                <Col className={'small-label text-center'}>Flag</Col>
                                <Col />
                              </Row>
                              <RenderPanel
                                panel={panel}
                                key={index}
                                indexMain={index}
                                encounterDetails={encounterDetails}
                                setEncounterDetails={setEncounterDetails}
                                patientData={patientData}
                                edit={panelEdits[index]}
                              />
                              <div className="d-flex justify-content-between mb-2">
                                <p
                                  className={'small-label '}
                                  style={{ textAlign: 'justify', width: '80%' }}
                                >
                                  Request notes: {panel?.encounterMeta?.notes}
                                </p>
                                {!panelEdits[index] && (
                                  <BlueButton
                                    text={'Edit'}
                                    width="102px"
                                    HandleClick={() => {
                                      setIndexSave(index);
                                      setSaveBtn(true);
                                      editInvestigation(index, panelEdits, 'panel');
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ display: 'none' }}>
                                <PanelExport
                                  innerRef={newRef}
                                  encounterDetails={encounterDetails}
                                  data={panel}
                                />
                              </div>
                            </div>
                          }
                        />
                      );
                    }
                  )}
                {encounterDetails?.investigations &&
                  encounterDetails.investigations?.bk1000?.length > 0 &&
                  encounterDetails?.investigations?.bk1000?.map(
                    (bk1000: Investigation, index: number) => {
                      const newRef2 = createRef();
                      bk1000RefArray.push(newRef2);

                      bk1000Edits.push(false);

                      return (
                        <AccordionItem
                          key={index}
                          dropDownstyle={{
                            right: '175px'
                          }}
                          height={'40px'}
                          disableDropdown={isLoadingUpdateEncounter}
                          accordionHeaderClasses={'justify-content-between'}
                          dropdownData={[
                            !bk1000Edits[index]
                              ? { id: 1, title: 'Edit' }
                              : { id: 1, title: 'Edit', color: 'text-secondary', disable: true },
                            { id: 2, title: 'Email' },
                            { id: 3, title: 'Export PDF' },
                            bk1000?.encounterMeta?.status === 0
                              ? {
                                  id: 4,
                                  title: 'Mark as complete'
                                }
                              : {
                                  id: 4,
                                  title: 'Mark as pending'
                                },
                            { id: 5, title: 'Re-run', color: 'danger' }
                          ]}
                          onClickDropdownItem={[
                            !bk1000Edits[index] && {
                              id: 1,
                              title: 'Edit',
                              event: () => {
                                setIndexSave(index);
                                setSaveBtn(true);
                                editInvestigation(index, bk1000Edits, 'bk1000');
                              }
                            },

                            {
                              id: 2,
                              title: 'Email',
                              event: () => {
                                //TODO
                              }
                            },
                            {
                              id: 3,
                              title: 'Export PDF',
                              event: () => {
                                setBk1000(bk1000RefArray[index]).then(() => {
                                  handlePrintBk1000();
                                });
                              }
                            },
                            bk1000?.encounterMeta?.status === 0
                              ? {
                                  id: 4,
                                  title: 'Mark as complete',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.COMPLETE,
                                      'bk1000',
                                      index
                                    );
                                  }
                                }
                              : {
                                  id: 4,
                                  title: 'Mark as pending',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.PENDING,
                                      'bk1000',
                                      index
                                    );
                                  }
                                },
                            {
                              id: 5,
                              title: 'Re-run',
                              color: 'danger',
                              event: () => {
                                handleSingleAccordionRerun(index, 'bk1000');
                              }
                            }
                          ]}
                          AccordionHeader={
                            <>
                              <span className="patient-name ms-2">{bk1000?.name}</span>
                              <span className="patient-name text-primary me-3">
                                <EncounterInvestigationStatus
                                  status={bk1000?.encounterMeta?.status}
                                />
                              </span>
                            </>
                          }
                          AccordionBody={
                            <div className={'px-4'}>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="requestNotes" className="small-label">
                                      Additional notes
                                    </Label>
                                    <Input
                                      placeholder="Notes added here will appear on PDF results"
                                      id="referralDoctor"
                                      name="select"
                                      type="textarea"
                                      className={!bk1000Edits[index] ? '' : 'bg-white'}
                                      value={bk1000?.encounterMeta?.additionalNotes ?? ''}
                                      disabled={!bk1000Edits[index]}
                                      onChange={(e) => {
                                        handleChangeAdditionalNotes(
                                          e.target.value,
                                          'bk1000',
                                          index
                                        );
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={4} className={'small-label'}>
                                  Investigations
                                </Col>
                                <Col xs={2} className={'small-label'}>
                                  Enter result value
                                </Col>
                                <Col className={'small-label text-center'}>Tracker</Col>
                                <Col className={'small-label text-center'}>Unit</Col>
                                <Col className={'small-label text-center'}>Range</Col>
                                <Col className={'small-label text-center'}>Flag</Col>
                                <Col />
                              </Row>
                              <RenderInvestigation
                                val={bk1000}
                                medicalDevice="bk1000"
                                encounterDetails={encounterDetails}
                                indexMain={index}
                                setEncounterDetails={setEncounterDetails}
                                patientData={patientData}
                                //apolipo ratio
                                apolipoAVal={apolipoAVal}
                                apolipoBVal={apolipoBVal}
                                setApolipoAVal={setApolipoAVal}
                                setApolipoBVal={setApolipoBVal}
                                edit={bk1000Edits[index]}
                              />
                              <div className="d-flex justify-content-between mb-2">
                                <p
                                  className={'small-label '}
                                  style={{ textAlign: 'justify', width: '80%' }}
                                >
                                  Request notes: {bk1000?.encounterMeta?.notes}
                                </p>
                                {!bk1000Edits[index] && (
                                  <BlueButton
                                    text={'Edit'}
                                    width="102px"
                                    HandleClick={() => {
                                      setIndexSave(index);
                                      setSaveBtn(true);
                                      editInvestigation(index, bk1000Edits, 'bk1000');
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ display: 'none' }}>
                                <LabTestResults
                                  innerRef={newRef2}
                                  encounterDetails={encounterDetails}
                                  data={bk1000}
                                  dataType={'investigation'}
                                />
                              </div>
                            </div>
                          }
                        />
                      );
                    }
                  )}
                {encounterDetails?.investigations &&
                  encounterDetails.investigations?.bk200?.length > 0 &&
                  encounterDetails?.investigations?.bk200?.map(
                    (bk200: Investigation, index: number) => {
                      const newRef3 = createRef();
                      bk200RefArray.push(newRef3);
                      bk200Edits.push(false);
                      return (
                        <AccordionItem
                          key={index}
                          dropDownstyle={{
                            right: '175px'
                          }}
                          height={'40px'}
                          accordionHeaderClasses={'justify-content-between'}
                          disableDropdown={isLoadingUpdateEncounter}
                          dropdownData={[
                            !bk200Edits[index]
                              ? { id: 1, title: 'Edit' }
                              : { id: 1, title: 'Edit', color: 'text-secondary', disable: true },
                            { id: 2, title: 'Email' },
                            { id: 3, title: 'Export PDF' },
                            bk200?.encounterMeta?.status === 0
                              ? {
                                  id: 4,
                                  title: 'Mark as complete'
                                }
                              : {
                                  id: 4,
                                  title: 'Mark as pending'
                                },
                            { id: 5, title: 'Re-run', color: 'danger' }
                          ]}
                          onClickDropdownItem={[
                            !bk200Edits[index] && {
                              id: 1,
                              title: 'Edit',
                              event: () => {
                                setIndexSave(index);
                                setSaveBtn(true);
                                editInvestigation(index, bk200Edits, 'bk200');
                              }
                            },

                            {
                              id: 2,
                              title: 'Email',
                              event: () => {
                                //TODO
                              }
                            },
                            {
                              id: 3,
                              title: 'Export PDF',
                              event: () => {
                                setBk200(bk200RefArray[index]).then(() => {
                                  handlePrintBk200();
                                });
                              }
                            },
                            bk200?.encounterMeta?.status === 0
                              ? {
                                  id: 4,
                                  title: 'Mark as complete',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.COMPLETE,
                                      'bk200',
                                      index
                                    );
                                  }
                                }
                              : {
                                  id: 4,
                                  title: 'Mark as pending',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.PENDING,
                                      'bk200',
                                      index
                                    );
                                  }
                                },
                            {
                              id: 5,
                              title: 'Re-run',
                              color: 'danger',
                              event: () => {
                                handleSingleAccordionRerun(index, 'bk200');
                              }
                            }
                          ]}
                          AccordionHeader={
                            <>
                              <span className="patient-name ms-2"> {bk200?.name}</span>
                              <span className="patient-name text-primary me-3">
                                <EncounterInvestigationStatus
                                  status={bk200?.encounterMeta?.status}
                                />
                              </span>
                            </>
                          }
                          AccordionBody={
                            <div className={'px-4'}>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="requestNotes" className="small-label">
                                      Additional notes
                                    </Label>
                                    <Input
                                      placeholder="Notes added here will appear on PDF results"
                                      id="referralDoctor"
                                      name="select"
                                      type="textarea"
                                      className={!bk200Edits[index] ? '' : 'bg-white'}
                                      value={bk200?.encounterMeta?.additionalNotes ?? ''}
                                      disabled={!bk200Edits[index]}
                                      onChange={(e) => {
                                        handleChangeAdditionalNotes(e.target.value, 'bk200', index);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={4} className={'small-label'}>
                                  Investigations
                                </Col>
                                <Col xs={2} className={'small-label'}>
                                  Enter result value
                                </Col>
                                <Col className={'small-label text-center'}>Tracker</Col>
                                <Col className={'small-label text-center'}>Unit</Col>
                                <Col className={'small-label text-center'}>Range</Col>
                                <Col className={'small-label text-center'}>Flag</Col>
                                <Col />
                              </Row>
                              <RenderInvestigation
                                val={bk200}
                                medicalDevice="bk200"
                                encounterDetails={encounterDetails}
                                indexMain={index}
                                setEncounterDetails={setEncounterDetails}
                                patientData={patientData}
                                //anion gap
                                bicarbonateValforAnionGap={bicarbonateValforAnionGap}
                                setBicarbonateValforAnionGap={setBicarbonateValforAnionGap}
                                serumCretValforGFRF={serumCretValforGFRF}
                                setSerumCretValforGFRF={setSerumCretValforGFRF}
                                albuminValforcorrCal={albuminValforcorrCal}
                                setAlbuminValforcorrCal={setAlbuminValforcorrCal}
                                calciumValforcorrCal={calciumValforcorrCal}
                                setCalciumValforcorrCal={setCalciumValforcorrCal}
                                edit={bk200Edits[index]}
                              />
                              <div className="d-flex justify-content-between mb-2">
                                <p
                                  className={'small-label '}
                                  style={{ textAlign: 'justify', width: '80%' }}
                                >
                                  Request notes: {bk200?.encounterMeta?.notes}
                                </p>

                                {!bk200Edits[index] && (
                                  <BlueButton
                                    text={'Edit'}
                                    width="102px"
                                    HandleClick={() => {
                                      setIndexSave(index);
                                      setSaveBtn(true);
                                      editInvestigation(index, bk200Edits, 'bk200');
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ display: 'none' }}>
                                <LabTestResults
                                  innerRef={newRef3}
                                  encounterDetails={encounterDetails}
                                  data={bk200}
                                  dataType={'investigation'}
                                />
                              </div>
                            </div>
                          }
                        />
                      );
                    }
                  )}
                {encounterDetails?.investigations.bk6190 &&
                  encounterDetails?.investigations?.bk6190?.length !== 0 && (
                    <AccordionItem
                      dropDownstyle={{
                        right: '175px'
                      }}
                      height={'40px'}
                      disableDropdown={isLoadingUpdateEncounter}
                      accordionHeaderClasses={'justify-content-between'}
                      dropdownData={[
                        !bk6190Edit
                          ? { id: 1, title: 'Edit' }
                          : { id: 1, title: 'Edit', color: 'text-secondary', disable: true },
                        { id: 2, title: 'Email' },
                        { id: 3, title: 'Export PDF' },
                        encounterDetails?.investigations.bk6190[0]?.encounterMeta?.status === 0
                          ? { id: 4, title: 'Mark as complete' }
                          : { id: 4, title: 'Mark as pending' },
                        { id: 5, title: 'Re-run', color: 'danger' }
                      ]}
                      onClickDropdownItem={[
                        !bk6190Edit && {
                          id: 1,
                          title: 'Edit',
                          event: () => {
                            setSaveBtn(true);
                            editInvestigation(0, [bk6190Edit], 'bk6190');
                          }
                        },
                        {
                          id: 2,
                          title: 'Email',
                          event: () => {
                            //TODO
                          }
                        },
                        {
                          id: 3,
                          title: 'Export PDF',
                          event: handlePrintBk6190
                          // () => {
                          // setBk6190(bk6190RefArray[index]).then(() => {
                          //   handlePrintBk6190();
                          // });
                          // }
                        },
                        encounterDetails?.investigations.bk6190[0]?.encounterMeta?.status === 0
                          ? {
                              id: 4,
                              title: 'Mark as complete',
                              event: () => {
                                markInvestigationAsComplete(
                                  ENCOUNTER_INVESTIGATION_STATUS.COMPLETE,
                                  'bk6190',
                                  0
                                );
                              }
                            }
                          : {
                              id: 4,
                              title: 'Mark as pending',
                              event: () => {
                                markInvestigationAsComplete(
                                  ENCOUNTER_INVESTIGATION_STATUS.PENDING,
                                  'bk6190',
                                  0
                                );
                              }
                            },
                        {
                          id: 5,
                          title: 'Re-run',
                          color: 'danger',
                          event: () => {
                            handleSingleAccordionRerun(0, 'bk6190');
                          }
                        }
                      ]}
                      AccordionHeader={
                        <>
                          <span className="patient-name ms-2">
                            {' '}
                            {encounterDetails?.investigations.bk6190[0]?.name}
                          </span>
                          <span className="patient-name text-primary me-3">
                            <EncounterInvestigationStatus
                              status={
                                encounterDetails?.investigations.bk6190[0]?.encounterMeta?.status
                              }
                            />
                          </span>
                        </>
                      }
                      AccordionBody={
                        <div className={'px-4'}>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="requestNotes" className="small-label">
                                  Additional notes
                                </Label>
                                <Input
                                  placeholder="Notes added here will appear on PDF results"
                                  id="referralDoctor"
                                  name="select"
                                  type="textarea"
                                  className={!bk6190Edit ? '' : 'bg-white'}
                                  value={
                                    encounterDetails?.investigations.bk6190[0]?.encounterMeta
                                      ?.additionalNotes ?? ''
                                  }
                                  disabled={!bk6190Edit}
                                  onChange={(e) => {
                                    handleChangeAdditionalNotes(e.target.value, 'bk6190', 0);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={4} className={'small-label'}>
                              Investigations
                            </Col>
                            <Col xs={2} className={'small-label'}>
                              Enter result value
                            </Col>
                            <Col className={'small-label text-center'}>Tracker</Col>
                            <Col className={'small-label text-center'}>Unit</Col>
                            <Col className={'small-label text-center'}>Range</Col>
                            <Col className={'small-label text-center'}>Flag</Col>
                            <Col />
                          </Row>
                          <RenderCBC
                            allCbc={encounterDetails?.investigations.bk6190}
                            encounterDetails={encounterDetails}
                            setEncounterDetails={setEncounterDetails}
                            patientData={patientData}
                            edit={bk6190Edit}
                          />
                          <div className="d-flex justify-content-between mb-2">
                            <p
                              className={'small-label '}
                              style={{ textAlign: 'justify', width: '80%' }}
                            >
                              Request notes:{' '}
                              {encounterDetails?.investigations.bk6190[0]?.encounterMeta?.notes}
                            </p>

                            {!bk6190Edit && (
                              <BlueButton
                                text={'Edit'}
                                width="102px"
                                HandleClick={() => {
                                  setSaveBtn(true);
                                  editInvestigation(0, [bk6190Edit], 'bk6190');
                                }}
                              />
                            )}
                          </div>
                          <div style={{ display: 'none' }}>
                            <LabTestResults
                              innerRef={bk6190Ref}
                              encounterDetails={encounterDetails}
                              data={encounterDetails}
                              dataType={'encounter/bk6190'}
                            />
                          </div>
                        </div>
                      }
                    />
                  )}
                {encounterDetails?.investigations &&
                  encounterDetails.investigations?.cbs300?.length > 0 &&
                  encounterDetails?.investigations?.cbs300?.map(
                    (cbs300: Investigation, index: number) => {
                      const newRef6 = createRef();
                      cbs300RefArray.push(newRef6);
                      cbs300Edits.push(false);
                      return (
                        <AccordionItem
                          key={index}
                          dropDownstyle={{
                            right: '175px'
                          }}
                          height={'40px'}
                          disableDropdown={isLoadingUpdateEncounter}
                          accordionHeaderClasses={'justify-content-between'}
                          dropdownData={[
                            !cbs300Edits[index]
                              ? { id: 1, title: 'Edit' }
                              : { id: 1, title: 'Edit', color: 'text-secondary', disable: true },
                            { id: 2, title: 'Email' },
                            { id: 3, title: 'Export PDF' },
                            cbs300?.encounterMeta?.status === 0
                              ? { id: 4, title: 'Mark as complete' }
                              : { id: 4, title: 'Mark as pending' },
                            { id: 5, title: 'Re-run', color: 'danger' }
                          ]}
                          onClickDropdownItem={[
                            !cbs300Edits[index] && {
                              id: 1,
                              title: 'Edit',
                              event: () => {
                                setIndexSave(index);
                                setSaveBtn(true);
                                editInvestigation(index, cbs300Edits, 'cbs300');
                              }
                            },
                            {
                              id: 2,
                              title: 'Email',
                              event: () => {
                                //TODO
                              }
                            },
                            {
                              id: 3,
                              title: 'Export PDF',
                              event: () => {
                                setCbs300(cbs300RefArray[index]).then(() => {
                                  handlePrintCbs300();
                                });
                              }
                            },
                            cbs300?.encounterMeta?.status === 0
                              ? {
                                  id: 4,
                                  title: 'Mark as complete',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.COMPLETE,
                                      'cbs300',
                                      index
                                    );
                                  }
                                }
                              : {
                                  id: 4,
                                  title: 'Mark as pending',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.PENDING,
                                      'cbs300',
                                      index
                                    );
                                  }
                                },
                            {
                              id: 5,
                              title: 'Re-run',
                              color: 'danger',
                              event: () => {
                                handleSingleAccordionRerun(index, 'cbs300');
                              }
                            }
                          ]}
                          AccordionHeader={
                            <>
                              <span className="patient-name ms-2"> {cbs300?.name}</span>
                              <span className="patient-name text-primary me-3">
                                <EncounterInvestigationStatus
                                  status={cbs300?.encounterMeta?.status}
                                />
                              </span>
                            </>
                          }
                          AccordionBody={
                            <div className={'px-4'}>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="requestNotes" className="small-label">
                                      Additional notes
                                    </Label>
                                    <Input
                                      placeholder="Notes added here will appear on PDF results"
                                      id="referralDoctor"
                                      name="select"
                                      type="textarea"
                                      className={!cbs300Edits[index] ? '' : 'bg-white'}
                                      value={cbs300?.encounterMeta?.additionalNotes ?? ''}
                                      disabled={!cbs300Edits[index]}
                                      onChange={(e) => {
                                        handleChangeAdditionalNotes(
                                          e.target.value,
                                          'cbs300',
                                          index
                                        );
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={4} className={'small-label'}>
                                  Investigations
                                </Col>
                                <Col xs={2} className={'small-label'}>
                                  Enter result value
                                </Col>
                                <Col className={'small-label text-center'}>Tracker</Col>
                                <Col className={'small-label text-center'}>Unit</Col>
                                <Col className={'small-label text-center'}>Range</Col>
                                <Col className={'small-label text-center'}>Flag</Col>
                                <Col />
                              </Row>
                              <RenderInvestigation
                                val={cbs300}
                                medicalDevice="cbs300"
                                encounterDetails={encounterDetails}
                                indexMain={index}
                                setEncounterDetails={setEncounterDetails}
                                patientData={patientData}
                                //anion gap
                                sodiumValforAnionGap={sodiumValforAnionGap}
                                setSodiumValforAnionGap={setSodiumValforAnionGap}
                                potassiumValforAnionGap={potassiumValforAnionGap}
                                setPotassiumValforAnionGap={setPotassiumValforAnionGap}
                                chlorideValforAnionGap={chlorideValforAnionGap}
                                setChlorideValforAnionGap={setChlorideValforAnionGap}
                                edit={cbs300Edits[index]}
                              />
                              <div className="d-flex justify-content-between mb-2">
                                <p
                                  className={'small-label '}
                                  style={{ textAlign: 'justify', width: '80%' }}
                                >
                                  Request notes: {cbs300?.encounterMeta?.notes}
                                </p>

                                {!cbs300Edits[index] && (
                                  <BlueButton
                                    text={'Edit'}
                                    width="102px"
                                    HandleClick={() => {
                                      setIndexSave(index);
                                      setSaveBtn(true);
                                      editInvestigation(index, cbs300Edits, 'cbs300');
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ display: 'none' }}>
                                <LabTestResults
                                  innerRef={newRef6}
                                  encounterDetails={encounterDetails}
                                  data={cbs300}
                                  dataType={'investigation'}
                                />
                              </div>
                            </div>
                          }
                        />
                      );
                    }
                  )}
                {encounterDetails?.investigations &&
                  encounterDetails.investigations?.other?.length > 0 &&
                  encounterDetails?.investigations?.other?.map(
                    (other: Investigation, index: number) => {
                      const newRef7 = createRef();
                      otherRefArray.push(newRef7);
                      otherEdits.push(false);
                      return (
                        <AccordionItem
                          key={index}
                          dropDownstyle={{
                            right: '175px'
                          }}
                          height={'40px'}
                          disableDropdown={isLoadingUpdateEncounter}
                          accordionHeaderClasses={'justify-content-between'}
                          dropdownData={[
                            !otherEdits[index]
                              ? { id: 1, title: 'Edit' }
                              : { id: 1, title: 'Edit', color: 'text-secondary', disable: true },
                            { id: 2, title: 'Email' },
                            { id: 3, title: 'Export PDF' },
                            other?.encounterMeta?.status === 0
                              ? { id: 4, title: 'Mark as complete' }
                              : { id: 4, title: 'Mark as pending' },
                            { id: 5, title: 'Re-run', color: 'danger' }
                          ]}
                          onClickDropdownItem={[
                            !otherEdits[index] && {
                              id: 1,
                              title: 'Edit',
                              event: () => {
                                setIndexSave(index);
                                setSaveBtn(true);
                                editInvestigation(index, otherEdits, 'other');
                              }
                            },
                            {
                              id: 2,
                              title: 'Email',
                              event: () => {
                                //TODO
                              }
                            },
                            {
                              id: 3,
                              title: 'Export PDF',
                              event: () => {
                                setOther(otherRefArray[index]).then(() => {
                                  handlePrintOther();
                                });
                              }
                            },
                            other?.encounterMeta?.status === 0
                              ? {
                                  id: 4,
                                  title: 'Mark as complete',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.COMPLETE,
                                      'other',
                                      index
                                    );
                                  }
                                }
                              : {
                                  id: 4,
                                  title: 'Mark as pending',
                                  event: () => {
                                    markInvestigationAsComplete(
                                      ENCOUNTER_INVESTIGATION_STATUS.PENDING,
                                      'other',
                                      index
                                    );
                                  }
                                },
                            {
                              id: 5,
                              title: 'Re-run',
                              color: 'danger',
                              event: () => {
                                handleSingleAccordionRerun(index, 'other');
                              }
                            }
                          ]}
                          AccordionHeader={
                            <>
                              <span className="patient-name ms-2"> {other?.name}</span>
                              <span className="patient-name text-primary me-3">
                                <EncounterInvestigationStatus
                                  status={other?.encounterMeta?.status}
                                />
                              </span>
                            </>
                          }
                          AccordionBody={
                            <div className={'px-4'}>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="requestNotes" className="small-label">
                                      Additional notes
                                    </Label>
                                    <Input
                                      placeholder="Notes added here will appear on PDF results"
                                      id="referralDoctor"
                                      name="select"
                                      type="textarea"
                                      className={!otherEdits[index] ? '' : 'bg-white'}
                                      value={other?.encounterMeta?.additionalNotes ?? ''}
                                      disabled={!otherEdits[index]}
                                      onChange={(e) => {
                                        handleChangeAdditionalNotes(e.target.value, 'other', index);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={4} className={'small-label'}>
                                  Investigations
                                </Col>
                                <Col xs={2} className={'small-label'}>
                                  Enter result value
                                </Col>
                                <Col className={'small-label text-center'}>Tracker</Col>
                                <Col className={'small-label text-center'}>Unit</Col>
                                <Col className={'small-label text-center'}>Range</Col>
                                <Col className={'small-label text-center'}>Flag</Col>
                                <Col />
                              </Row>
                              <RenderInvestigation
                                val={other}
                                medicalDevice="other"
                                encounterDetails={encounterDetails}
                                indexMain={index}
                                setEncounterDetails={setEncounterDetails}
                                patientData={patientData}
                                //anion gap
                                anionGapVal={anionGapVal}
                                setAnionGapVal={setAnionGapVal}
                                sodiumValforAnionGap={sodiumValforAnionGap}
                                potassiumValforAnionGap={potassiumValforAnionGap}
                                chlorideValforAnionGap={chlorideValforAnionGap}
                                bicarbonateValforAnionGap={bicarbonateValforAnionGap}
                                //gfr
                                firstPanelGFRFemaleWeight={firstPanelGFRFemaleWeight}
                                setFirstPanelGFRFemaleWeight={setFirstPanelGFRFemaleWeight}
                                firstPanelGFRMaleWeight={firstPanelGFRMaleWeight}
                                setFirstPanelGFRMaleWeight={setFirstPanelGFRMaleWeight}
                                setFirstPanelGFRFemaleVal={setFirstPanelGFRFemaleVal}
                                firstPanelGFRMaleVal={firstPanelGFRMaleVal}
                                setFirstPanelGFRMaleVal={setFirstPanelGFRMaleVal}
                                firstPanelGFRFemaleVal={firstPanelGFRFemaleVal}
                                serumCretValforGFRF={serumCretValforGFRF}
                                //apolipo ratio
                                apolipoRatioVal={apolipoRatioVal}
                                setApolipoRatioVal={setApolipoRatioVal}
                                apolipoAVal={apolipoAVal}
                                apolipoBVal={apolipoBVal}
                                //corrected calcium
                                correctedCalciumVal={correctedCalciumVal}
                                setCorrectedCalciumVal={setCorrectedCalciumVal}
                                albuminValforcorrCal={albuminValforcorrCal}
                                calciumValforcorrCal={calciumValforcorrCal}
                                edit={otherEdits[index]}
                              />
                              <div className="d-flex justify-content-between mb-2">
                                <p
                                  className={'small-label '}
                                  style={{ textAlign: 'justify', width: '80%' }}
                                >
                                  Request notes: {other?.encounterMeta?.notes}
                                </p>

                                {!otherEdits[index] && (
                                  <BlueButton
                                    text={'Edit'}
                                    width="102px"
                                    HandleClick={() => {
                                      setIndexSave(index);
                                      setSaveBtn(true);
                                      editInvestigation(index, otherEdits, 'other');
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ display: 'none' }}>
                                <LabTestResults
                                  innerRef={newRef7}
                                  encounterDetails={encounterDetails}
                                  data={other}
                                  dataType={'investigation'}
                                />
                              </div>
                            </div>
                          }
                        />
                      );
                    }
                  )}
              </Accordion>
            </div>
          </div>
          <div style={{ display: 'none' }}>
            <div
              ref={barcodeRef}
              className="d-flex flex-column align-items-center justify-content-center mt-5"
            >
              <span style={{ fontSize: '30px' }}>{encounter?.name}</span>
              <Barcode value={encounter?.name} displayValue={false} height={200} width={4} />
              <span style={{ fontSize: '30px' }}>{getCurrentDateTimeInBarcodeFormat()}</span>
            </div>
            <LabTestResults
              innerRef={printRef}
              encounterDetails={encounterDetails}
              data={encounterDetails}
              dataType={'encounter'}
            />
          </div>
        </>
      ) : (
        <div
          className="d-flex justify-content-center w-100"
          style={{
            width: '100%',
            height: '60vh',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Empty />
        </div>
      )}
    </>
  );
};

export default CreateEncounter;
