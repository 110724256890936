import React from 'react';

interface LoaderProps {
  message?: string;
  style?: object;
}

const Empty: React.FC<LoaderProps> = (props) => {
  const { message, style } = props;
  return <span style={style}>{message}</span>;
};
Empty.defaultProps = {
  message: 'No Data Found',
  style: {}
};
export default Empty;
