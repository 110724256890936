import { createSlice } from '@reduxjs/toolkit';
import { LoaderType } from './types';

const initialState: LoaderType = {
  isPreloader: false
};
const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setPreloader: (state, action) => {
      state.isPreloader = action.payload;
    }
  }
});

export const { setPreloader } = loaderSlice.actions;

export default loaderSlice.reducer;
