import { createSlice } from '@reduxjs/toolkit';
import { GetUsers, deleteUser, updateUser, resetUserPassword, registerUser } from './thunks';
import { userManagement } from './types';

const initialState: userManagement = {
  //get all users
  userLoading: false,
  userSuccess: false,
  userError: '',
  users: [],
  //add
  addedUser: null,
  isLoadingAddUser: false,
  errorAddUser: null,
  successAddUser: false,
  //update
  updatedUser: null,
  isLoadingUpdateUser: false,
  errorUpdateUser: null,
  successUpdateUser: false,
  //delete
  deletedUser: null,
  isLoadingDeleteUser: false,
  errorDeleteUser: null,
  successDeleteUser: false,
  //reset password
  resetUserPassword: null,
  isLoadingResetUserPassword: false,
  errorResetUserPassword: null,
  successResetUserPassword: false
};
const userSlice = createSlice({
  name: 'userManagement',
  initialState,
  extraReducers: (builder) => {
    //get all
    builder.addCase(GetUsers.pending, (state) => {
      state.userLoading = true;
      state.userError = '';
      state.userSuccess = false;
    });
    builder.addCase(GetUsers.fulfilled, (state, { payload }) => {
      state.userLoading = false;
      state.userError = '';
      state.users = payload;
      state.userSuccess = true;
    });
    builder.addCase(GetUsers.rejected, (state, action) => {
      state.userLoading = false;
      state.userSuccess = false;
      if (action.error) {
        state.userError = action.error.message as string;
      } else {
        state.userError = 'Error fetching users';
      }
    });
    //add
    builder.addCase(registerUser.pending, (state) => {
      state.isLoadingAddUser = true;
      state.errorAddUser = '';
      state.successAddUser = false;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.isLoadingAddUser = false;
      state.errorAddUser = '';
      state.addedUser = payload;
      state.successAddUser = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.isLoadingAddUser = false;
      state.successAddUser = false;
      if (action.error) {
        state.errorAddUser = action.error.message as string;
      } else {
        state.errorAddUser = 'Error adding user';
      }
    });
    //update
    builder.addCase(updateUser.pending, (state) => {
      state.isLoadingUpdateUser = true;
      state.errorUpdateUser = '';
      state.successUpdateUser = false;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.isLoadingUpdateUser = false;
      state.errorUpdateUser = '';
      state.updatedUser = payload;
      state.successUpdateUser = true;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoadingUpdateUser = false;
      state.successUpdateUser = false;
      if (action.error) {
        state.errorUpdateUser = action.error.message as string;
      } else {
        state.errorUpdateUser = 'Error updating user';
      }
    });
    //delete
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoadingDeleteUser = true;
      state.errorDeleteUser = '';
      state.successDeleteUser = false;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.isLoadingDeleteUser = false;
      state.errorDeleteUser = '';
      state.deletedUser = payload;
      state.successDeleteUser = true;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoadingDeleteUser = false;
      state.successDeleteUser = false;
      if (action.error) {
        state.errorDeleteUser = action.error.message as string;
      } else {
        state.errorDeleteUser = 'Error deleting user';
      }
    });
    //reset password
    builder.addCase(resetUserPassword.pending, (state) => {
      state.isLoadingResetUserPassword = true;
      state.errorResetUserPassword = '';
      state.successResetUserPassword = false;
    });
    builder.addCase(resetUserPassword.fulfilled, (state, { payload }) => {
      state.isLoadingResetUserPassword = false;
      state.errorResetUserPassword = '';
      state.resetUserPassword = payload;
      state.successResetUserPassword = true;
    });
    builder.addCase(resetUserPassword.rejected, (state, action) => {
      state.isLoadingResetUserPassword = false;
      state.successResetUserPassword = false;
      if (action.error) {
        state.errorResetUserPassword = action.error.message as string;
      } else {
        state.errorResetUserPassword = 'Error resetting user password';
      }
    });
  },
  reducers: {
    resetGetUser(state) {
      state.userLoading = false;
      state.userError = '';
      state.userSuccess = false;
    },
    resetAddUser: (state) => {
      state.isLoadingAddUser = false;
      state.errorAddUser = null;
      state.successAddUser = false;
    },
    resetUpdatedUser: (state) => {
      state.isLoadingUpdateUser = false;
      state.errorUpdateUser = null;
      state.successUpdateUser = false;
    },
    resetDeletedUser: (state) => {
      state.isLoadingDeleteUser = false;
      state.errorDeleteUser = null;
      state.successDeleteUser = false;
    },
    resetUserPasswordReset: (state) => {
      state.isLoadingResetUserPassword = false;
      state.errorResetUserPassword = null;
      state.successResetUserPassword = false;
    }
  }
});

// // Action creators are generated for each case reducer function
export const {
  resetDeletedUser,
  resetGetUser,
  resetAddUser,
  resetUpdatedUser,
  resetUserPasswordReset
} = userSlice.actions;
export default userSlice.reducer;
