import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import BlueButton from '~/components/BlueButton';
import WhiteButton from '~/components/WhiteButton';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { resetUpdatedDoctor } from '~/store/reducers/Doctor';
import { errorToast, successToast } from '~/utils/toast';
import { GetDoctors, updateDoctor } from '~/store/reducers/Doctor/thunks';
import DoctorFormInputs from './DoctorFormInputs';

const EditDoctorModal: React.FC<any> = (props) => {
  const { doctorData, isOpen, toggle } = props;
  const dispatch = useAppDispatch();
  const {
    //update doctor
    isLoadingUpdateDoctor,
    successUpdateDoctor,
    errorUpdateDoctor
  } = useAppSelector((state) => state.DoctorReducer);

  const { handleBlur, handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        title: doctorData?.title,
        firstName: doctorData?.firstName,
        lastName: doctorData?.lastName,
        clinicName: doctorData?.clinicName,
        clinicAddress: doctorData?.clinicAddress,
        phoneNumber: doctorData?.phoneNumber ?? '',
        email: doctorData?.email
      },
      validationSchema: Yup.object({
        title: Yup.string().required('required*'),
        firstName: Yup.string().required('required*'),
        lastName: Yup.string().required('required*'),
        clinicName: Yup.string().required('required*'),
        clinicAddress: Yup.string().required('required*'),
        phoneNumber: Yup.string().notRequired(),
        email: Yup.string().email().required('required*')
      }),
      onSubmit: (values) => {
        if (
          values.phoneNumber === '' ||
          values.phoneNumber === undefined ||
          isValidPhoneNumber(`${values.phoneNumber}`)
        ) {
          dispatch(
            updateDoctor({
              ...values,
              phoneNumber: values.phoneNumber ?? '',
              id: doctorData.id
            })
          );
        }
      }
    });
  useEffect(() => {
    if (!isLoadingUpdateDoctor && successUpdateDoctor) {
      successToast('Doctor updated successfully !');
      dispatch(resetUpdatedDoctor());
      dispatch(GetDoctors());
      toggle();
    }
    if (!isLoadingUpdateDoctor && errorUpdateDoctor) {
      errorToast(errorUpdateDoctor);
      dispatch(resetUpdatedDoctor());
      dispatch(GetDoctors());
    }
  }, [isLoadingUpdateDoctor, successUpdateDoctor, errorUpdateDoctor, resetUpdatedDoctor]);

  const isLetterInput = (e: any) => {
    const letterRegex = /^[A-Za-z',\s]*$/;
    if (e.target.value === '' || letterRegex.test(e.target.value)) {
      return true;
    }
    return false;
  };

  const resetModalStates = () => {
    values.firstName = doctorData?.firstName;
    values.lastName = doctorData?.lastName;
    values.title = doctorData?.title;
    values.clinicName = doctorData?.clinicName;
    values.clinicAddress = doctorData?.clinicAddress;
    values.phoneNumber = doctorData?.phoneNumber;
    values.email = doctorData?.lastName;
    toggle();
  };
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop="static"
    >
      <div className="modal-body ps-1 d-flex flex-column align-items-center">
        <DoctorFormInputs
          edit={true}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          isLetterInput={isLetterInput}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </div>
      <div
        className="d-flex align-self-center justify-content-between py-3"
        style={{ width: '84%', marginLeft: '-25px' }}
      >
        <WhiteButton text="Dismiss" HandleClick={resetModalStates} />
        <BlueButton
          text={'Save Changes'}
          loading={isLoadingUpdateDoctor}
          disabled={isLoadingUpdateDoctor}
          width={'105px'}
          HandleClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};

export default EditDoctorModal;
