import React, { FC } from 'react';
interface Btn {
  Icon?: any;
  text?: string;
  HandleClick?: any;
  width?: string;
  className?: string;
  styles?: any;
}
const WhiteButton: FC<Btn> = (props) => {
  const { Icon, text, HandleClick, width, className, styles } = props;

  return (
    <button
      type="button"
      className={`btn btn-light mx-3 sbold ${className}`}
      onClick={HandleClick}
      style={{
        backgroundColor: 'white',
        color: '#2C3242',
        border: '1px solid #E3E3E3',
        width: width,
        height: '33px',
        fontSize: '12px',
        fontFamily: 'Satoshi-Bold',
        ...styles
      }}
    >
      {Icon}
      {text}
    </button>
  );
};
WhiteButton.defaultProps = {
  width: 'auto'
};
export default WhiteButton;
