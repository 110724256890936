// import { useFormik } from 'formik';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import BlueButton from '~/components/BlueButton';
import CustomInput from '~/components/CustomInput';
import WhiteButton from '~/components/WhiteButton';
import { isLetterInput } from '~/utils/utilMethods';
import DatePicker from 'reactstrap-date-picker';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { GetUsers, registerUser, updateUser } from '~/store/reducers/user/thunks';
import {
  convertServerDateFormatToIsoDateTimeFormat,
  convertToServerDateFormat
} from '~/utils/dateUtility';
import { errorToast, successToast } from '~/utils/toast';
import { resetAddUser, resetUpdatedUser } from '~/store/reducers/user';

interface FilterProps {
  isOpen: boolean;
  toggle: () => void;
  modalType: string;
  userData: any;
}

const AddUserModal: FC<FilterProps> = ({ isOpen, toggle, modalType, userData }) => {
  const dispatch = useAppDispatch();
  const {
    //add user
    isLoadingAddUser,
    errorAddUser,
    successAddUser,
    //update user
    isLoadingUpdateUser,
    errorUpdateUser,
    successUpdateUser
  } = useAppSelector((state) => state.UserReducer);
  const [isSubmit, setIsSubmit] = useState(false);
  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    initialValues,
    touched,
    errors,
    setErrors,
    setTouched,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: modalType === 'edit' ? userData?.username : '',
      password: '',
      role: modalType === 'edit' ? userData?.role : 'ROLE_LAB_TECH',
      firstName: modalType === 'edit' ? userData?.firstName : '',
      lastName: modalType === 'edit' ? userData?.lastName : '',
      phoneNumber: modalType === 'edit' ? userData?.phoneNumber : '',
      email: modalType === 'edit' ? userData?.email : '',
      gender: modalType === 'edit' ? userData?.gender : '',
      address: modalType === 'edit' ? userData?.address : '',
      dateOfBirth:
        modalType === 'edit'
          ? userData?.dateOfBirth
            ? convertServerDateFormatToIsoDateTimeFormat(userData.dateOfBirth)
            : ''
          : ''
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(5, 'Username should atleast have five characters!')
        .max(25, 'Username should not exceed 25 characters!')
        .required('required*'),
      password:
        modalType === 'add'
          ? Yup.string()
              .min(6, 'Password should atleast have six characters!')
              .required('required*')
          : Yup.string().notRequired(),
      role: Yup.string().required('required*'),
      firstName: Yup.string().required('required*'),
      lastName: Yup.string().required('required*'),
      gender: Yup.string().required('required*'),
      address: Yup.string().notRequired().nullable(),
      phoneNumber: Yup.string().notRequired(),
      dateOfBirth: Yup.string().required('required*'),
      email: Yup.string().email().required('required*')
    }),
    onSubmit: (values) => {
      if (values) {
        if (
          values.phoneNumber === '' ||
          values.phoneNumber === undefined ||
          isValidPhoneNumber(`${values.phoneNumber}`)
        ) {
          dispatch(
            modalType === 'add'
              ? registerUser({
                  ...values,
                  phoneNumber: values.phoneNumber ?? '',
                  dateOfBirth: convertToServerDateFormat(values.dateOfBirth)
                })
              : updateUser({
                  ...values,
                  id: userData.id,
                  phoneNumber: values.phoneNumber ?? '',
                  dateOfBirth: convertToServerDateFormat(values.dateOfBirth)
                })
          );
        }
      }
    }
  });

  //ADD USER
  useEffect(() => {
    if (!isLoadingAddUser && successAddUser) {
      successToast('User added successfully!');
      dispatch(resetAddUser());
      dispatch(GetUsers());
      resetModalStates();
    }
    if (!isLoadingAddUser && errorAddUser) {
      errorToast(errorAddUser);
      dispatch(resetAddUser());
    }
  }, [isLoadingAddUser, successAddUser, errorAddUser]);

  //UPDATE USER
  useEffect(() => {
    if (!isLoadingUpdateUser && successUpdateUser) {
      successToast('User updated successfully!');
      dispatch(resetUpdatedUser());
      dispatch(GetUsers());
      resetModalStates();
    }
    if (!isLoadingUpdateUser && errorUpdateUser) {
      errorToast('Error updating user');
      dispatch(resetUpdatedUser());
    }
  }, [isLoadingUpdateUser, successUpdateUser, errorUpdateUser]);

  const resetModalStates = () => {
    toggle();
    values.firstName = initialValues.firstName;
    values.lastName = initialValues.lastName;
    values.role = initialValues.role;
    values.address = initialValues.address;
    values.username = initialValues.username;
    values.dateOfBirth = initialValues.dateOfBirth;
    values.gender = initialValues.gender;
    values.phoneNumber = initialValues.phoneNumber;
    values.email = initialValues.email;
    values.password = initialValues.password;
    setErrors({});
    setTouched({});
    setIsSubmit(false);
  };
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop="static"
    >
      <div className="modal-body ms-2">
        <h4 className="modal-title mt-0 sb font-size-18" id="myLargeModalLabel">
          {modalType === 'add' ? 'Add new user' : 'Edit user'}
        </h4>
        <button
          onClick={resetModalStates}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="filtersBox mt-2 justify-content-around">
          <label
            className="col-md-11 col-form-label offset-md-1 im gray"
            style={{ marginLeft: '5px' }}
          >
            Full Name*
          </label>
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={''}
              type="text"
              placeholder="First Name"
              name={'firstName'}
              value={values.firstName}
              onChange={(e: any) => {
                if (isLetterInput(e) && e.target.value.length < 25) {
                  handleChange(e);
                }
              }}
              invalid={touched.firstName && errors.firstName ? true : false}
              errors={errors.firstName}
            />
          </div>
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={''}
              type="text"
              placeholder="Last Name"
              name={'lastName'}
              value={values.lastName}
              onChange={(e: any) => {
                if (isLetterInput(e) && e.target.value.length < 25) {
                  handleChange(e);
                }
              }}
              invalid={touched.lastName && errors.lastName ? true : false}
              errors={errors.lastName}
            />
          </div>
        </div>
        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <label className="col-md-5 col-form-label im gray">Date of birth*</label>
            <DatePicker
              id="birthDate"
              dateFormat="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              showClearButton={false}
              name={'dateOfBirth'}
              value={values.dateOfBirth || ''}
              onBlur={handleBlur}
              invalid={touched.dateOfBirth && errors.dateOfBirth ? true : false}
              style={
                isSubmit && touched.dateOfBirth && errors.dateOfBirth
                  ? { border: '1px #f46a6a solid', borderRadius: '0.375rem' }
                  : { borderRadius: '0.375rem' }
              }
              onChange={(e: string) => setFieldValue('dateOfBirth', e)}
            />
            {isSubmit && touched.dateOfBirth && errors.dateOfBirth ? (
              <span className="text-danger font-size-14">
                {errors.dateOfBirth === 'required*' ? 'required*' : 'Invalid Date'}
              </span>
            ) : null}
          </div>
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Sex*'}
              type="select"
              placeholder="Sex"
              name={'gender'}
              value={values.gender}
              options={[
                { value: 'F', name: 'Female' },
                { value: 'M', name: 'Male' }
              ]}
              onChange={handleChange}
              invalid={touched.gender && errors.gender ? true : false}
              errors={errors.gender}
            />
          </div>
        </div>
        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <label className="col-md-5 col-form-label im gray">Contact number</label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="KE"
              inputProps={{
                name: 'phoneNumber',
                onBlur: handleBlur
              }}
              style={{
                height: '37px',
                border:
                  (touched.phoneNumber && errors.phoneNumber) ||
                  (touched.phoneNumber &&
                    values.phoneNumber !== '' &&
                    !isValidPhoneNumber(`${values.phoneNumber}`))
                    ? '1px solid red'
                    : '1px solid #e3e3e3',
                padding: '10px',
                borderRadius: '0.375rem'
              }}
              value={values.phoneNumber}
              onChange={(e: string) => setFieldValue('phoneNumber', e)}
            />
            {(touched.phoneNumber && errors.phoneNumber) ||
            (touched.phoneNumber &&
              values.phoneNumber !== '' &&
              !isValidPhoneNumber(`${values.phoneNumber}`)) ? (
              <span className="text-danger font-size-14">
                {!isValidPhoneNumber(`${values.phoneNumber}`)
                  ? 'Invalid phone number'
                  : errors.phoneNumber}
              </span>
            ) : null}
          </div>
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Email address*'}
              type="email"
              placeholder="example@domain.com"
              name={'email'}
              value={values.email}
              onChange={handleChange}
              invalid={touched.email && errors.email ? true : false}
              errors={errors.email === 'required*' ? 'required*' : 'Invalid Email Address'}
            />
          </div>
        </div>
        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'City/Area of residence'}
              type="text"
              placeholder="e.g Cape Town"
              name={'address'}
              value={values.address}
              onChange={handleChange}
              invalid={touched.address && errors.address ? true : false}
              errors={errors.address}
            />
          </div>
          <div className="col-md-5 ms-3" style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Role*'}
              type="select"
              name={'role'}
              value={values?.role}
              options={[
                { value: 'ROLE_LAB_TECH', name: 'Lab Tech' },
                { value: 'ROLE_LAB_MANAGER', name: 'Lab Manager' },
                { value: 'ROLE_ADMIN', name: 'Admin' },
                { value: 'ROLE_DOCTOR', name: 'Doctor' },
                { value: 'ROLE_PHLEBOTOMIST', name: 'Phlebotomist' }
              ]}
              selected={true}
              onChange={handleChange}
              invalid={touched.role && errors.role ? true : false}
              errors={errors.role}
            />
          </div>
        </div>
      </div>{' '}
      <div className="filtersBox mt-2 justify-content-around modal-footer">
        <div className="col-md-5 ms-3" style={{ whiteSpace: 'nowrap' }}>
          <CustomInput
            label={'Username*'}
            type="text"
            disabled={modalType === 'edit'}
            placeholder="username"
            name={'username'}
            value={values.username}
            onChange={handleChange}
            invalid={touched.username && errors.username ? true : false}
            errors={errors.username}
          />
        </div>
        {modalType === 'add' ? (
          <div className="col-md-5 ms-3" style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Password*'}
              type="text"
              placeholder="password"
              name={'password'}
              value={values.password}
              onChange={handleChange}
              invalid={touched.password && errors.password ? true : false}
              errors={errors.password}
            />
          </div>
        ) : (
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}></div>
        )}
      </div>
      <div className="modal-footer px-3 mt-2 pt-5">
        <WhiteButton text="Dismiss" HandleClick={resetModalStates} />

        <div className="ms-auto">
          <BlueButton
            text={modalType === 'add' ? '+ Add user' : '+ Update user'}
            loading={isLoadingAddUser || isLoadingUpdateUser}
            disabled={isLoadingAddUser || isLoadingUpdateUser}
            width={modalType === 'add' ? '109px' : '120px'}
            HandleClick={() => {
              setIsSubmit(true);
              handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
export default AddUserModal;
