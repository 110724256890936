import React, { FC, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '~/assets/images/logo.svg';
import { WithRouterProps } from '~/models/WithRouterProps.model';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { setSecondaryBarFalse, toggle } from '~/store/reducers/sideBar';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as AdminIcon } from '../../assets/icons/borad.svg';
import { ReactComponent as PollIcon } from '../../assets/icons/poll.svg';

import Avatar from 'react-avatar';
import Async from '~/utils/Async';
interface SideBarProps extends WithRouterProps {
  type: string;
}

const Sidebar: FC<SideBarProps> = ({ location }) => {
  const { collapsed } = useAppSelector((state) => state.sideBarReducer);
  const dispatch = useAppDispatch();

  function tToggle() {
    const body: HTMLElement = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      if (collapsed) {
        dispatch(toggle(false));
      } else {
        dispatch(toggle(true));
      }
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  useEffect(() => {
    handleResize();
  }, []);
  const handleResize = () => {
    const { innerWidth: width } = window;
    const body: HTMLElement = document.body;
    const check = body.classList.contains('vertical-collpsed');

    if (
      width <= 998 &&
      (location.pathname === '/dashboard' ||
        location.pathname === '/patients' ||
        location.pathname === '/admin' ||
        location.pathname.includes('encounterHistory'))
    ) {
      if (!check) {
        dispatch(toggle(true));
        body.classList.toggle('vertical-collpsed');
        body.classList.toggle('sidebar-enable');
      }
    } else if (
      width > 998 &&
      (location.pathname === '/dashboard' ||
        location.pathname === '/patients' ||
        location.pathname === '/admin' ||
        location.pathname.includes('encounterHistory'))
    ) {
      if (check) {
        dispatch(toggle(false));
        body.classList.toggle('vertical-collpsed');
        body.classList.toggle('sidebar-enable');
      }
    }
  };
  return (
    <React.Fragment>
      <div className="vertical-menu tansition">
        <div className="navbar-brand-box ">
          <div style={{ cursor: 'pointer' }} className="logo logo-dark">
            <span className="logo-sm ms-2">
              <button
                type="button"
                onClick={() => {
                  tToggle();
                }}
                className="btn btn-lg p-0 m-0 font-size-16 header-item "
                id="vertical-menu-btn"
                style={{ color: '#ffffff' }}
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </span>

            <span className="logo-lg ">
              <div style={{ whiteSpace: 'nowrap' }}>
                <Link
                  to="/dashboard"
                  className="sb18"
                  style={{ textDecoration: 'none', color: '#ffffff' }}
                >
                  {' '}
                  <img src={logo} alt="" width="186px" height="22" className="mb-1" />
                </Link>

                <button
                  type="button"
                  style={{
                    marginTop: '-5px',
                    color: '#ffffff',
                    border: 'none'
                  }}
                  onClick={() => {
                    tToggle();
                  }}
                  className="btn btn-lg"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
              </div>
            </span>
            {!collapsed ? (
              <div className="mt-4">
                <h5 className="text-white font-size-14 ms-2">Institute of Healthy Ageing</h5>
                <h6 className="text-white font-size-14 opacity-75 ms-2">Cape Town</h6>
              </div>
            ) : (
              <Avatar
                name={`${Async.getItem('@auth')?.firstName} ${Async.getItem('@auth')?.lastName}`}
                maxInitials={2}
                textSizeRatio={2.2}
                size="40"
                color="#556AD7"
                fgColor="#fff"
                round
              />
            )}
          </div>
        </div>
        <div className={collapsed ? 'width-close' : 'width-open'}>
          <div id="sidebar-menu-blue">
            <ul className="metismenu list-unstyled mb-1" id="side-menu">
              <li>
                <Link
                  to="/dashboard"
                  className={location.pathname === '/dashboard' ? 'mm-active-blue-bar' : ''}
                  style={{
                    textDecoration: 'none'
                  }}
                  onClick={() => {
                    dispatch(setSecondaryBarFalse());
                  }}
                >
                  {collapsed ? (
                    <Home
                      className="mb-1 me-1"
                      color="#ffffff"
                      style={{ height: '18px', width: '18px' }}
                    />
                  ) : (
                    <>
                      <Home
                        className="mb-1 ms-1 me-1"
                        color="#ffffff"
                        style={{ height: '18px', width: '18px' }}
                      />
                      <span
                        className="sb14 ms-2"
                        style={{
                          color: '#ffffff'
                        }}
                      >
                        Dashboard
                      </span>
                    </>
                  )}
                </Link>
              </li>
            </ul>
            <ul className="metismenu list-unstyled mb-1" id="side-menu">
              <li>
                <Link
                  to="/patients"
                  className={
                    location.pathname === '/patients' ||
                    location.pathname === '/AddPatient' ||
                    location.pathname.includes('/encounterHistory')
                      ? 'mm-active-blue-bar'
                      : ''
                  }
                  style={{
                    textDecoration: 'none'
                  }}
                  onClick={() => {
                    dispatch(setSecondaryBarFalse());
                  }}
                >
                  {collapsed ? (
                    <User height="20" className="mb-1" style={{ height: '18px', width: '18px' }} />
                  ) : (
                    <div>
                      <User
                        height="20"
                        className="mb-1 ms-1 me-1"
                        style={{ height: '18px', width: '18px' }}
                      />
                      <span
                        className="sb14 ms-2"
                        style={{
                          color: '#ffffff'
                        }}
                      >
                        Patients
                      </span>
                    </div>
                  )}
                </Link>
              </li>
            </ul>
            <ul className="metismenu list-unstyled mb-1" id="side-menu">
              <li>
                <Link
                  to="/doctors"
                  className={
                    location.pathname === '/doctors' || location.pathname === '/AddDoctor'
                      ? 'mm-active-blue-bar'
                      : ''
                  }
                  style={{
                    textDecoration: 'none'
                  }}
                  onClick={() => {
                    dispatch(setSecondaryBarFalse());
                  }}
                >
                  {collapsed ? (
                    <PollIcon
                      height="20"
                      className="mb-1"
                      style={{ height: '18px', width: '18px' }}
                    />
                  ) : (
                    <div>
                      <PollIcon
                        height="20"
                        className="mb-1 ms-1 me-1"
                        style={{ height: '18px', width: '18px' }}
                      />
                      <span
                        className="sb14 ms-2"
                        style={{
                          color: '#ffffff'
                        }}
                      >
                        Doctors
                      </span>
                    </div>
                  )}
                </Link>
              </li>
            </ul>
            <ul className="metismenu list-unstyled mb-1" id="side-menu">
              <li>
                <Link
                  to="/inventory/All"
                  className={location.pathname.includes('/inventory') ? 'mm-active-blue-bar' : ''}
                  style={{
                    textDecoration: 'none'
                  }}
                  onClick={() => {
                    dispatch(setSecondaryBarFalse());
                  }}
                >
                  {collapsed ? (
                    <PollIcon
                      height="20"
                      className="mb-1"
                      style={{ height: '18px', width: '18px' }}
                    />
                  ) : (
                    <div>
                      <PollIcon
                        height="20"
                        className="mb-1 ms-1 me-1"
                        style={{ height: '18px', width: '18px' }}
                      />
                      <span
                        className="sb14 ms-2"
                        style={{
                          color: '#ffffff'
                        }}
                      >
                        Inventory
                      </span>
                    </div>
                  )}
                </Link>
              </li>
            </ul>
            <ul className="metismenu list-unstyled mb-1" id="side-menu">
              <li>
                <Link
                  to="/userManagement"
                  className={location.pathname === '/userManagement' ? 'mm-active-blue-bar' : ''}
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  {collapsed ? (
                    <AdminIcon
                      height="20"
                      className="mb-1"
                      color="#ffffff"
                      style={{ height: '18px', width: '18px' }}
                    />
                  ) : (
                    <div>
                      <AdminIcon
                        height="20"
                        className="mb-1 ms-1 me-1"
                        color="#ffffff"
                        style={{ height: '18px', width: '18px' }}
                      />
                      <span
                        className="sb14 ms-2"
                        style={{
                          color: '#ffffff'
                        }}
                      >
                        Admin
                      </span>
                    </div>
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sidebar) as any;
