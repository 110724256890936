import React, { FC, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useHistory, useParams } from 'react-router';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import Empty from '~/components/Common/Empty';
import Loader from '~/components/Common/Loader';
import WhiteButton from '~/components/WhiteButton';
import { Encounter } from '~/models/encounter.model';
import { Investigation, PanelInvestigationBody } from '~/models/modalsInterfaces.model';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import {
  resetArchiveEncounter,
  resetFetchPatientEncounters,
  resetUnrchiveEncounter
} from '~/store/reducers/encounter';
import {
  archiveEncounter,
  fetchAllEncounters,
  unarchiveEncounter
} from '~/store/reducers/encounter/thunks';
import { resetFetchPatientById } from '~/store/reducers/patient';
import { fetchPatientById } from '~/store/reducers/patient/thunks';
import { ENCOUNTER_STATUS } from '~/utils/constants';
import {
  convertUTCServerDateTimeFormatToLocalDateTimeFormatWithAmPm,
  convertServerDateFormatToSlashDateFormat,
  getAgeFromDate,
  convertIsoDateTimeFormatToDateTimeFormatWithAmPm
} from '~/utils/dateUtility';
import { errorToast, successToast } from '~/utils/toast';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filterIcon.svg';

const EncounterHistory: FC = () => {
  const { id } = useParams<{ id: string }>();
  const patientId: number = parseInt(id);
  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    //fetch all encounters
    patientEncounters,
    isLoadingFetchPatientEncounters,
    successFetchPatientEncounters,
    errorFetchPatientEncounters,

    //archive encounters
    isLoadingArchiveEncounter,
    errorArchiveEncounter,
    successArchiveEncounter,

    //unarchive encounters
    isLoadingUnrchiveEncounter,
    errorUnarchiveEncounter,
    successUnarchiveEncounter
  } = useAppSelector((state) => state.EncounterReducer);

  const {
    //fetch patient
    patient,
    isLoadingFetchPatientById,
    successFetchPatientById,
    errorFetchPatientById
  } = useAppSelector((state) => state.PatientReducer);

  const [filtersList, setfiltersList] = useState([
    { id: 1, title: 'In Progress', check: false },
    { id: 2, title: 'Completed', check: false },
    { id: 3, title: 'Archived', check: false }
  ]);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [tempfiltersList, setTempfiltersList] = useState<any>([]);
  const [encounters, setEncounters] = useState<Encounter[]>([]);

  //fetch patient by id
  useEffect(() => {
    if (!isLoadingFetchPatientById && errorFetchPatientById) {
      errorToast(errorFetchPatientById);
      dispatch(resetFetchPatientById());
    }

    if (!isLoadingFetchPatientById && successFetchPatientById) {
      dispatch(resetFetchPatientById());
    }
  }, [isLoadingFetchPatientById, successFetchPatientById, errorFetchPatientById]);

  //fetch patient encounters
  useEffect(() => {
    if (!isLoadingFetchPatientEncounters && errorFetchPatientEncounters) {
      errorToast(errorFetchPatientEncounters);
      dispatch(resetFetchPatientEncounters());
    }

    if (
      !isLoadingFetchPatientEncounters &&
      successFetchPatientEncounters &&
      patientEncounters?.length > 0
    ) {
      const data = patientEncounters?.filter((val: Encounter) => val.deactivated === false);
      setEncounters(data);
      dispatch(resetFetchPatientEncounters());
    }
  }, [
    isLoadingFetchPatientEncounters,
    successFetchPatientEncounters,
    errorFetchPatientEncounters,
    patientEncounters
  ]);

  //archive encounters
  useEffect(() => {
    if (!isLoadingArchiveEncounter && errorArchiveEncounter) {
      errorToast(errorArchiveEncounter);
      dispatch(resetArchiveEncounter());
    }

    if (!isLoadingArchiveEncounter && successArchiveEncounter) {
      successToast('Encounter archived successfully');
      resetFilters();
      dispatch(resetArchiveEncounter());
      dispatch(fetchAllEncounters({ patientId, timeframe: undefined }));
    }
  }, [isLoadingArchiveEncounter, successArchiveEncounter, errorArchiveEncounter]);

  //unarchive encounters
  useEffect(() => {
    if (!isLoadingUnrchiveEncounter && errorUnarchiveEncounter) {
      errorToast(errorUnarchiveEncounter);
      dispatch(resetUnrchiveEncounter());
    }

    if (!isLoadingUnrchiveEncounter && successUnarchiveEncounter) {
      successToast('Encounter unarchived successfully');
      resetFilters();
      dispatch(resetUnrchiveEncounter());
      dispatch(fetchAllEncounters({ patientId, timeframe: undefined }));
    }
  }, [isLoadingUnrchiveEncounter, successUnarchiveEncounter, errorUnarchiveEncounter]);

  useEffect(() => {
    dispatch(fetchPatientById(patientId));
    dispatch(fetchAllEncounters({ patientId, timeframe: undefined }));
    window.addEventListener('resize', HandleWidth);
    return () => {
      window.removeEventListener('resize', HandleWidth);
    };
  }, [patientId]);

  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };

  const ApplyFilter = () => {
    let arr: any = [];
    if (filtersList[2].check) {
      const arc = patientEncounters.filter((data: Encounter) => data.deactivated === true);
      if (filtersList[0].check) {
        const inProgress = arc.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.INPROGRESS
        );
        arr = inProgress;
      }
      if (filtersList[1].check) {
        const completed = arc.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.COMPLETED
        );
        arr = arr.concat(completed);
      }
      if (!filtersList[0].check && !filtersList[1].check) {
        arr = arc;
      }
    } else {
      if (filtersList[0].check) {
        const inProgress = patientEncounters.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.INPROGRESS && !data.deactivated
        );
        arr = arr.concat(inProgress);
      }
      if (filtersList[1].check) {
        const completed = patientEncounters.filter(
          (data: Encounter) => data.status === ENCOUNTER_STATUS.COMPLETED && !data.deactivated
        );
        arr = arr.concat(completed);
      }
      if (!filtersList[0].check && !filtersList[1].check) {
        const f2 = patientEncounters.filter((data: Encounter) => !data.deactivated);
        arr = f2;
      }
    }

    setEncounters(arr);
    setFilterDropDown(!filterDropDown);
  };

  const resetFilters = () => {
    setfiltersList([
      { id: 1, title: 'In progress', check: false },
      { id: 2, title: 'Completed', check: false },
      { id: 3, title: 'Archived', check: false }
    ]);
  };
  const getRequests = (encounter: Encounter) => {
    const requests: any[] = [];
    encounter.investigations.panelInvestigation?.forEach((panels: PanelInvestigationBody) =>
      requests.push(panels.name)
    );
    encounter.investigations.bk1000?.forEach((bk1000: Investigation) => requests.push(bk1000.name));

    if (encounter?.investigations?.bk6190?.length > 0) {
      requests.push(encounter.investigations.bk6190[0]?.name);
    }
    encounter.investigations.bk200?.forEach((bk200: Investigation) => requests.push(bk200.name));
    encounter.investigations.cbs300?.forEach((cbs300: Investigation) => requests.push(cbs300.name));
    encounter.investigations.other?.forEach((other: Investigation) => requests.push(other.name));

    return requests.map((request, index) =>
      index === requests.length - 1 ? request : request + ', '
    );
  };
  const displayEncounterStatus = (status: number) => {
    return status === ENCOUNTER_STATUS.INPROGRESS ? 'In Progress' : 'Completed';
  };
  return (
    <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
      <Container fluid>
        <Row>
          <Col lg="3">
            <h2 className="m-0 align-self-center text-nowrap">
              <b className="text-nowrap sbl24">Patient History</b>
            </h2>
          </Col>
          <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
          <Col lg="4" className="d-flex justify-content-end text-nowrap">
            {filterDropDown && (
              <div
                style={{
                  background: '#FFFFFF ',
                  boxShadow: '0px 3px 12px #00000029',
                  borderRadius: '8px',
                  position: 'absolute',
                  marginRight: '15px',
                  marginTop: '108px',
                  padding: '10px',
                  zIndex: 4
                }}
              >
                {filtersList?.map((item, index) => (
                  <div key={index}>
                    <Input
                      type="checkbox"
                      checked={item.check}
                      onChange={(e) => {
                        const { checked } = e.target;
                        const data = JSON.parse(JSON.stringify(filtersList));
                        data[index].check = checked;
                        setfiltersList(data);
                      }}
                    />
                    <Label className="font-size-14px font-family-inter ms-2">{item?.title}</Label>
                  </div>
                ))}
                <div className="mt-3" style={{ marginLeft: '-16px' }}>
                  <WhiteButton
                    text="Dismiss"
                    HandleClick={() => {
                      setFilterDropDown(false);
                      setfiltersList(tempfiltersList);
                    }}
                  />
                  <BlueButton text="Apply filters" HandleClick={ApplyFilter} />
                </div>
              </div>
            )}

            <BlueButton
              HandleClick={() => {
                history.push({
                  pathname: '/newEncounter',
                  state: {
                    patientData: patient
                  }
                });
              }}
              disabled={isLoadingArchiveEncounter || isLoadingUnrchiveEncounter}
              text={'New Encounter'}
            />
          </Col>
        </Row>
        <div className="horizontal-line mt-3 mb-3"></div>
        {!isLoadingFetchPatientById ? (
          patient ? (
            <>
              <span className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <Avatar
                    name={patient?.firstName + ' ' + patient?.lastName}
                    maxInitials={2}
                    textSizeRatio={2.2}
                    size="40"
                    color="#2A45CD30"
                    fgColor="#2A45CD"
                    round
                  />
                  <div className="d-flex flex-column ms-3">
                    <span className="patient-name">
                      {patient?.firstName + ' ' + patient?.lastName}
                    </span>
                    <span className="patient-name patient-details">
                      {patient?.gender === 'M'
                        ? 'Male'
                        : patient?.gender === 'F'
                        ? 'Female'
                        : patient?.gender === 'O'
                        ? 'Others'
                        : '-'}
                      {' · '}
                      {getAgeFromDate(patient?.dateOfBirth)}
                      {' · '}
                      {convertServerDateFormatToSlashDateFormat(patient?.dateOfBirth)}
                    </span>
                  </div>
                </div>
                <div>
                  <WhiteButton
                    className="zero-margin"
                    Icon={
                      <FilterIcon
                        color={'#2C3242'}
                        style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                      />
                    }
                    HandleClick={() => {
                      setFilterDropDown(!filterDropDown);
                      setTempfiltersList([...filtersList]);
                    }}
                    text={'Filters'}
                  />
                </div>
              </span>
              <div className="horizontal-line mt-3"></div>
            </>
          ) : (
            <>
              <span className="d-flex justify-content-between">
                <span className="d-flex align-items-center">Cannot Fetch Patient Info</span>
                <WhiteButton
                  className="zero-margin"
                  Icon={
                    <FilterIcon
                      color={'#2C3242'}
                      style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                    />
                  }
                  HandleClick={() => {
                    setFilterDropDown(!filterDropDown);
                    setTempfiltersList([...filtersList]);
                  }}
                  text={'Filters'}
                />
              </span>
              <div className="horizontal-line mt-3"></div>
            </>
          )
        ) : null}
        <div
          className="mt-4"
          style={{
            maxHeight: `${height - 270}px`,
            scrollbarWidth: 'thin'
          }}
        >
          {!isLoadingFetchPatientEncounters ? (
            encounters !== null && encounters.length !== 0 ? (
              encounters.map((encounter: Encounter) => (
                <Accordion key={encounter.id}>
                  <AccordionItem
                    dropDownstyle={{
                      right: '175px'
                    }}
                    dropdownData={[
                      { id: 1, title: 'Open encounter', color: 'default' },
                      encounter.deactivated
                        ? { id: 2, title: 'Unarchive', color: 'danger' }
                        : { id: 2, title: 'Archive', color: 'danger' }
                    ]}
                    onClickDropdownItem={[
                      {
                        id: 1,
                        title: 'Open encounter',
                        event: () => {
                          history.push({
                            pathname: `/patient/${patient.id}/encounter/${encounter.id}/details`
                          });
                        }
                      },
                      encounter.deactivated
                        ? {
                            id: 2,
                            title: 'Unarchive',
                            event: () => {
                              dispatch(unarchiveEncounter(encounter?.id));
                            }
                          }
                        : {
                            id: 2,
                            title: 'Archive',
                            event: () => {
                              dispatch(archiveEncounter(encounter?.id));
                            }
                          }
                    ]}
                    accordionHeaderClasses={'justify-content-between'}
                    AccordionHeader={
                      <>
                        <span className="col-md-12 d-flex">
                          <div className="col-md-2 d-flex justify-content-center flex-column">
                            <span className="patient-name">{encounter.name}</span>
                          </div>
                          <div className="col-md-3 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">Date requested</h2>
                            <h2 className="patient-name ">
                              {convertIsoDateTimeFormatToDateTimeFormatWithAmPm(
                                encounter.createdAt
                              )}
                            </h2>
                          </div>
                          <div className="col-md-3 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">Requested by</h2>
                            <h2 className="patient-name ">
                              {encounter?.doctor?.title +
                                ' ' +
                                `${encounter?.doctor?.firstName[0].toUpperCase()}.` +
                                ' ' +
                                encounter?.doctor?.lastName}
                            </h2>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">No. of requests</h2>
                            <h2 className="patient-name">{encounter?.numberOfRequests}</h2>
                          </div>
                          <div className="col-md-3 d-flex justify-content-center flex-column">
                            <h2 className="patient-name patient-details">Encounter status</h2>
                            <h2 className="patient-name ">
                              {displayEncounterStatus(encounter?.status)}
                            </h2>
                          </div>
                        </span>
                      </>
                    }
                    AccordionBody={
                      <div className="col-md-12 p-3 d-flex">
                        <div className="col-md-6">
                          <span className="d-flex py-1">
                            <Col className="key-text" sm={3}>
                              Requests
                            </Col>
                            <Col className="value-text">{getRequests(encounter)}</Col>
                          </span>
                          {encounter?.completionTime && (
                            <span className="d-flex py-1">
                              <Col className="key-text" sm={3}>
                                Completion date
                              </Col>
                              <Col className="value-text">
                                {convertUTCServerDateTimeFormatToLocalDateTimeFormatWithAmPm(
                                  encounter.completionTime
                                )}
                              </Col>
                            </span>
                          )}
                        </div>
                      </div>
                    }
                  />
                </Accordion>
              ))
            ) : (
              <div
                className="d-flex justify-content-center w-100"
                style={{
                  width: '100%',
                  height: '60vh',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Empty />
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center w-100" style={{ minHeight: '500px' }}>
              <Loader />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default EncounterHistory;
