import { createSlice } from '@reduxjs/toolkit';
import {
  addNewPatient,
  deletePatient,
  fetchAllPatients,
  fetchPatientById,
  updatePatient,
  archivePatient,
  unarchivePatient
} from './thunks';
import { InitialState } from './types';
const initialState: InitialState = {
  //get by id
  patient: null,
  isLoadingFetchPatientById: false,
  successFetchPatientById: false,
  errorFetchPatientById: null,
  //get all
  patients: [],
  isLoadingFetchAllPatients: false,
  successFetchAllPatients: false,
  errorFetchAllPatients: null,
  //add
  addedPatient: null,
  isLoadingAddPatient: false,
  errorAddPatient: null,
  successAddPatient: false,
  //update
  updatedPatient: null,
  isLoadingUpdatePatient: false,
  errorUpdatePatient: null,
  successUpdatePatient: false,
  //delete
  deletedPatient: null,
  isLoadingDeletePatient: false,
  errorDeletePatient: null,
  successDeletePatient: false,
  //archive
  archivedPatient: null,
  isLoadingArchivePatient: false,
  errorArchivePatient: null,
  successArchivePatient: false,

  //unarchive
  unarchivedPatient: null,
  isLoadingUnarchivePatient: false,
  errorUnarchivePatient: '',
  successUnarchivePatient: false
};
const PatientSlice = createSlice({
  name: 'patient',
  initialState,
  extraReducers(builder) {
    //get by id
    builder.addCase(fetchPatientById.pending, (state) => {
      state.patient = null;
      state.isLoadingFetchPatientById = true;
      state.errorFetchPatientById = null;
      state.successFetchPatientById = false;
    });
    builder.addCase(fetchPatientById.rejected, (state, action) => {
      state.patient = null;
      state.isLoadingFetchPatientById = true;
      state.successFetchPatientById = false;

      if (action.error) {
        state.errorFetchPatientById = action.error.message;
      } else {
        state.errorFetchPatientById = 'Could not fetch patients';
      }
    });
    builder.addCase(fetchPatientById.fulfilled, (state, action) => {
      state.patient = action.payload;
      state.isLoadingFetchPatientById = false;
      state.errorFetchPatientById = null;
      state.successFetchPatientById = true;
    });
    //get
    builder.addCase(fetchAllPatients.pending, (state) => {
      state.patients = [];
      state.isLoadingFetchAllPatients = true;
      state.errorFetchAllPatients = null;
      state.successFetchAllPatients = false;
    });
    builder.addCase(fetchAllPatients.rejected, (state, action) => {
      state.patients = [];
      state.isLoadingFetchAllPatients = true;
      state.successFetchAllPatients = false;

      if (action.error) {
        state.errorFetchAllPatients = action.error.message;
      } else {
        state.errorFetchAllPatients = 'Could not fetch patients';
      }
    });
    builder.addCase(fetchAllPatients.fulfilled, (state, action) => {
      state.patients = action.payload;
      state.isLoadingFetchAllPatients = false;
      state.errorFetchAllPatients = null;
      state.successFetchAllPatients = true;
    });
    //add
    builder.addCase(addNewPatient.pending, (state) => {
      state.addedPatient = null;
      state.isLoadingAddPatient = true;
      state.errorAddPatient = null;
      state.successAddPatient = false;
    });
    builder.addCase(addNewPatient.rejected, (state, action) => {
      state.addedPatient = null;
      state.isLoadingAddPatient = false;
      state.successAddPatient = false;
      if (action.error) {
        state.errorAddPatient = action.error.message;
      } else {
        state.errorAddPatient = 'Could not add new patient';
      }
    });
    builder.addCase(addNewPatient.fulfilled, (state, action) => {
      state.addedPatient = action.payload;
      state.isLoadingAddPatient = false;
      state.errorAddPatient = null;
      state.successAddPatient = true;
    });
    //update
    builder.addCase(updatePatient.pending, (state) => {
      state.updatedPatient = null;
      state.isLoadingUpdatePatient = true;
      state.errorUpdatePatient = null;
      state.successUpdatePatient = false;
    });
    builder.addCase(updatePatient.rejected, (state, action) => {
      state.updatedPatient = null;
      state.isLoadingUpdatePatient = true;
      state.successUpdatePatient = false;

      if (action.error) {
        state.errorUpdatePatient = action.error.message;
      } else {
        state.errorUpdatePatient = 'Could not update patient';
      }
    });
    builder.addCase(updatePatient.fulfilled, (state, action) => {
      state.updatedPatient = action.payload;
      state.isLoadingUpdatePatient = false;
      state.errorUpdatePatient = null;
      state.successUpdatePatient = true;
    });
    //delete
    builder.addCase(deletePatient.pending, (state) => {
      state.deletedPatient = null;
      state.isLoadingDeletePatient = true;
      state.errorDeletePatient = null;
      state.successDeletePatient = false;
    });
    builder.addCase(deletePatient.rejected, (state, action) => {
      state.deletedPatient = null;
      state.isLoadingDeletePatient = false;
      state.successDeletePatient = false;

      if (action.error) {
        state.errorDeletePatient = action.error.message;
      } else {
        state.errorDeletePatient = 'Could not delete patient';
      }
    });
    builder.addCase(deletePatient.fulfilled, (state, action) => {
      state.deletedPatient = action.payload;
      state.isLoadingDeletePatient = false;
      state.errorDeletePatient = null;
      state.successDeletePatient = true;
    });
    //archive
    builder.addCase(archivePatient.pending, (state) => {
      state.archivedPatient = null;
      state.isLoadingArchivePatient = true;
      state.errorArchivePatient = null;
      state.successArchivePatient = false;
    });
    builder.addCase(archivePatient.rejected, (state, action) => {
      state.archivedPatient = null;
      state.isLoadingArchivePatient = false;
      state.successArchivePatient = false;

      if (action.error) {
        state.errorArchivePatient = action.error.message;
      } else {
        state.errorArchivePatient = 'Could not archive patient';
      }
    });
    builder.addCase(archivePatient.fulfilled, (state, action) => {
      state.archivedPatient = action.payload;
      state.isLoadingArchivePatient = false;
      state.errorArchivePatient = null;
      state.successArchivePatient = true;
    });
    //unarchive
    builder.addCase(unarchivePatient.pending, (state) => {
      state.unarchivedPatient = null;
      state.isLoadingUnarchivePatient = true;
      state.errorUnarchivePatient = '';
      state.successUnarchivePatient = false;
    });
    builder.addCase(unarchivePatient.rejected, (state, action) => {
      state.unarchivedPatient = null;
      state.isLoadingUnarchivePatient = false;
      state.successUnarchivePatient = false;

      if (action.error) {
        state.errorUnarchivePatient = action.error.message as string;
      } else {
        state.errorUnarchivePatient = 'Could not archive patient';
      }
    });
    builder.addCase(unarchivePatient.fulfilled, (state, action) => {
      state.unarchivedPatient = action.payload;
      state.isLoadingUnarchivePatient = false;
      state.errorUnarchivePatient = '';
      state.successUnarchivePatient = true;
    });
  },
  reducers: {
    resetAddPatient: (state) => {
      state.addedPatient = null;
      state.isLoadingAddPatient = false;
      state.errorAddPatient = null;
      state.successAddPatient = false;
    },
    resetUpdatedPatient: (state) => {
      state.isLoadingUpdatePatient = false;
      state.errorUpdatePatient = null;
      state.successUpdatePatient = false;
    },
    resetFetchAllPatients: (state) => {
      state.isLoadingFetchAllPatients = false;
      state.errorFetchAllPatients = null;
      state.successFetchAllPatients = false;
    },
    resetFetchPatientById: (state) => {
      state.isLoadingFetchPatientById = false;
      state.errorFetchPatientById = null;
      state.successFetchPatientById = false;
    },
    resetArchivePatientsState: (state) => {
      state.isLoadingArchivePatient = false;
      state.errorArchivePatient = null;
      state.successArchivePatient = false;
    },
    resetUnrchivePatientsState: (state) => {
      state.isLoadingUnarchivePatient = false;
      state.errorUnarchivePatient = '';
      state.successUnarchivePatient = false;
    }
  }
});
export const {
  resetAddPatient,
  resetFetchAllPatients,
  resetFetchPatientById,
  resetArchivePatientsState,
  resetUpdatedPatient,
  resetUnrchivePatientsState
} = PatientSlice.actions;
export default PatientSlice.reducer;
