import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { INVESTIGATION_FLAG_STATUS } from '~/utils/constants';
import { getAgeFromDate } from '~/utils/dateUtility';
import { errorToast } from '~/utils/toast';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';
import './CreateEncounter.styles.scss';
interface OtherValues {
  general: string;
  nestedFirst: string;
  nestedSecond: string;
}
interface RowProps {
  val?: any;
  encounterInvestigationIndex?: any;
  firstPanelIndex?: any;
  firstPanelInvestigationIndex?: any;
  secondPanelIndex?: any;
  secondPanelInvestigationIndex?: any;
  medicalDevice?: any;
  encounterDetails?: any;
  setEncounterDetails?: any;
  indexMain?: any;
  patientData?: any;
  nestedLevel?: any;

  anionGapVal?: OtherValues;
  setAnionGapVal?: any;
  sodiumValforAnionGap?: OtherValues;
  setSodiumValforAnionGap?: any;
  potassiumValforAnionGap?: OtherValues;
  setPotassiumValforAnionGap?: any;
  chlorideValforAnionGap?: OtherValues;
  setChlorideValforAnionGap?: any;
  bicarbonateValforAnionGap?: OtherValues;
  setBicarbonateValforAnionGap?: any;

  firstPanelGFRFemaleWeight?: OtherValues;
  setFirstPanelGFRFemaleWeight?: any;
  firstPanelGFRMaleWeight?: OtherValues;
  setFirstPanelGFRMaleWeight?: any;
  firstPanelGFRFemaleVal?: OtherValues;
  setFirstPanelGFRFemaleVal?: any;
  firstPanelGFRMaleVal?: OtherValues;
  setFirstPanelGFRMaleVal?: any;
  serumCretValforGFRF?: OtherValues;
  setSerumCretValforGFRF?: any;

  apolipoRatioVal?: OtherValues;
  setApolipoRatioVal?: any;
  apolipoAVal?: OtherValues;
  setApolipoAVal?: any;
  apolipoBVal?: OtherValues;
  setApolipoBVal?: any;

  correctedCalciumVal?: OtherValues;
  setCorrectedCalciumVal?: any;
  albuminValforcorrCal?: OtherValues;
  setAlbuminValforcorrCal?: any;
  calciumValforcorrCal?: OtherValues;
  setCalciumValforcorrCal?: any;

  edit?: boolean;
}
const RenderRow: FC<RowProps> = ({
  val,
  encounterInvestigationIndex,
  firstPanelIndex,
  firstPanelInvestigationIndex,
  secondPanelIndex,
  secondPanelInvestigationIndex,
  medicalDevice,
  encounterDetails,
  setEncounterDetails,
  indexMain,
  patientData,
  nestedLevel,

  sodiumValforAnionGap,
  setSodiumValforAnionGap,
  potassiumValforAnionGap,
  setPotassiumValforAnionGap,
  chlorideValforAnionGap,
  setChlorideValforAnionGap,
  bicarbonateValforAnionGap,
  setBicarbonateValforAnionGap,

  firstPanelGFRFemaleWeight,
  setFirstPanelGFRFemaleWeight,
  firstPanelGFRMaleWeight,
  setFirstPanelGFRMaleWeight,
  serumCretValforGFRF,
  setSerumCretValforGFRF,

  apolipoAVal,
  setApolipoAVal,
  apolipoBVal,
  setApolipoBVal,

  albuminValforcorrCal,
  setAlbuminValforcorrCal,
  calciumValforcorrCal,
  setCalciumValforcorrCal,

  edit
}) => {
  const CustomPill = ({ flag }: { flag: string | null }) => {
    return (
      <span
        className={clsx(flag !== '-' && 'rounded-pill px-2 text-danger')}
        style={flag !== '-' ? { backgroundColor: '#FFEBEE', fontWeight: '600' } : {}}
      >
        {flag}
      </span>
    );
  };

  const getApolipoFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === 0 ? (
        'Below Standard/Optimal'
      ) : flag === 1 ? (
        'Optimal'
      ) : flag === 2 ? (
        'Above Optimal'
      ) : flag === 3 ? (
        'Above Standard'
      ) : (
        <CustomPill flag={'H'} />
      );
    }
    return '-';
  };

  const getVitaminDFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === 0 ? (
        <CustomPill flag={'L'} />
      ) : flag === 1 ? (
        'Deficiency'
      ) : flag === 2 ? (
        'Partial Deficiency'
      ) : flag === 3 ? (
        'Optimal level'
      ) : (
        'Toxicity'
      );
    }
    return '-';
  };

  const validateFlag = (range: string, value: number) => {
    if (range.includes('Before') || range.includes('Foll')) {
      return 1;
    } else if (range.includes('Ages')) {
      //age btw 16 - 24
      if (getAgeInYear() >= 16 && getAgeInYear() <= 24) {
        if (value <= 182) {
          return 0;
        } else if (value > 780) {
          return 2;
        } else {
          return 1;
        }
      } //age btw 24 - 39
      else if (getAgeInYear() >= 25 && getAgeInYear() <= 39) {
        if (value <= 114) {
          return 0;
        } else if (value > 492) {
          return 2;
        } else {
          return 1;
        }
      } //age btw 40 - 54
      else if (getAgeInYear() >= 40 && getAgeInYear() <= 54) {
        if (value <= 90) {
          return 0;
        } else if (value > 360) {
          return 2;
        } else {
          return 1;
        }
      } //age ? 55
      else if (getAgeInYear() >= 55) {
        if (value <= 71) {
          return 0;
        } else if (value > 290) {
          return 2;
        } else {
          return 1;
        }
      }
    } else if (range.includes('=')) {
      const rangeArray = range.split(' ');
      if (rangeArray[0] === 'Deficiency') {
        if (value < parseFloat(rangeArray[2])) {
          return 0;
        } else if (value >= parseFloat(rangeArray[2]) && value <= parseFloat(rangeArray[4])) {
          return 1;
        } else if (value > parseFloat(rangeArray[10]) && value <= parseFloat(rangeArray[12])) {
          return 2;
        } else if (value > parseFloat(rangeArray[19]) && value <= parseFloat(rangeArray[21])) {
          return 3;
        } else {
          return 4;
        }
      } else {
        if (value <= parseFloat(rangeArray[3])) {
          return 0;
        } else if (value > parseFloat(rangeArray[10]) && value <= parseFloat(rangeArray[12])) {
          return 1;
        } else if (value > parseFloat(rangeArray[17]) && value <= parseFloat(rangeArray[19])) {
          return 2;
        } else if (value > parseFloat(rangeArray[24]) && value <= parseFloat(rangeArray[26])) {
          return 3;
        } else {
          return 4;
        }
      }
    } else if (range.includes('greater or equal')) {
      const rangeArray = range.split('to');
      if (value >= parseFloat(rangeArray[1])) {
        return 1;
      } else {
        return 0;
      }
    } else if (range.includes('>')) {
      const rangeArray = range.split(' ');
      if (value < parseFloat(rangeArray[1])) {
        return 0;
      } else {
        return 1;
      }
    } else if (range.includes('<')) {
      const rangeArray = range.split(' ');
      if (value > parseFloat(rangeArray[1])) {
        return 2;
      } else {
        return 1;
      }
    } else if (range.includes('-')) {
      const rangeArray = range.split('-');
      if (value < parseFloat(rangeArray[0])) {
        return 0;
      }
      if (value > parseFloat(rangeArray[1])) {
        return 2;
      } else {
        return 1;
      }
    } else if (range.includes('to')) {
      const rangeArray = range.split('to');
      const low = rangeArray[0];
      const high = rangeArray[1].split(' ');
      if (value < parseFloat(low)) {
        return 0;
      }
      if (value > parseFloat(high[1])) {
        return 2;
      } else {
        return 1;
      }
    }
  };

  const getFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === INVESTIGATION_FLAG_STATUS.LOW
        ? 'L'
        : flag === INVESTIGATION_FLAG_STATUS.MEDIUM
        ? '-'
        : flag === INVESTIGATION_FLAG_STATUS.HIGH
        ? 'H'
        : null;
    }
    return '-';
  };

  const getIndexForId = () => {
    return nestedLevel === 2
      ? secondPanelInvestigationIndex
      : nestedLevel === 1
      ? firstPanelInvestigationIndex
      : encounterInvestigationIndex;
  };

  const rerunInvestigation = () => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    //bk1000
    if (medicalDevice === 'bk1000' && nestedLevel === 0) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.bk1000[
          encounterInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.bk1000[
        encounterInvestigationIndex
      ].tracker = num + 1;
    } else if (medicalDevice === 'bk1000' && nestedLevel === 1) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.bk1000[firstPanelInvestigationIndex].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk1000[firstPanelInvestigationIndex].tracker = num + 1;
    } else if (medicalDevice === 'bk1000' && nestedLevel === 2) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.bk1000[
          secondPanelInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk1000[
        secondPanelInvestigationIndex
      ].tracker = num + 1;
    }
    //bk200
    else if (medicalDevice === 'bk200' && nestedLevel === 0) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.bk200[
          encounterInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.bk200[
        encounterInvestigationIndex
      ].tracker = num + 1;
    } else if (medicalDevice === 'bk200' && nestedLevel === 1) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.bk200[firstPanelInvestigationIndex].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk200[firstPanelInvestigationIndex].tracker = num + 1;
    } else if (medicalDevice === 'bk200' && nestedLevel === 2) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.bk200[
          secondPanelInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk200[
        secondPanelInvestigationIndex
      ].tracker = num + 1;
    }
    //bk6190
    else if (medicalDevice === 'bk6190' && nestedLevel === 0) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.bk6190[
          encounterInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.bk6190[
        encounterInvestigationIndex
      ].tracker = num + 1;
    } else if (medicalDevice === 'bk6190' && nestedLevel === 1) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.bk6190[firstPanelInvestigationIndex].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk6190[firstPanelInvestigationIndex].tracker = num + 1;
    } else if (medicalDevice === 'bk6190' && nestedLevel === 2) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.bk6190[
          secondPanelInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk6190[
        secondPanelInvestigationIndex
      ].tracker = num + 1;
    }
    //cbs300
    else if (medicalDevice === 'cbs300' && nestedLevel === 0) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.cbs300[
          encounterInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.cbs300[
        encounterInvestigationIndex
      ].tracker = num + 1;
    } else if (medicalDevice === 'cbs300' && nestedLevel === 1) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.cbs300[firstPanelInvestigationIndex].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.cbs300[firstPanelInvestigationIndex].tracker = num + 1;
    } else if (medicalDevice === 'cbs300' && nestedLevel === 2) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.cbs300[
          secondPanelInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.cbs300[
        secondPanelInvestigationIndex
      ].tracker = num + 1;
    }
    //other
    else if (medicalDevice === 'other' && nestedLevel === 0) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.other[
          encounterInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.other[
        encounterInvestigationIndex
      ].tracker = num + 1;
    } else if (medicalDevice === 'other' && nestedLevel === 1) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.other[firstPanelInvestigationIndex].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.other[firstPanelInvestigationIndex].tracker = num + 1;
    } else if (medicalDevice === 'other' && nestedLevel === 2) {
      const num =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
          secondPanelInvestigationIndex
        ].tracker;

      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
        secondPanelInvestigationIndex
      ].tracker = num + 1;
    }
    setEncounterDetails(data);
    toggleRerun();
  };

  const onChangeInvestigationInput = (e: any) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    //bk1000
    if (medicalDevice === 'bk1000' && nestedLevel === 0) {
      data.investigations.panelInvestigation[indexMain].investigations.bk1000[
        encounterInvestigationIndex
      ].result = Number(e.target.value);

      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.bk1000[
        encounterInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.bk1000[
              encounterInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.bk1000[
              encounterInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );

      if (val.id === 63) {
        const a = { ...apolipoAVal };
        const temp = { ...a, general: e.target.value };
        setApolipoAVal(temp);

        calApolopoprotein(nestedLevel, data);
      } else if (val.id === 64) {
        const tem2 = { ...apolipoBVal, general: e.target.value };
        setApolipoBVal(tem2);

        calApolopoprotein(nestedLevel, data);
      } else {
        setEncounterDetails(data);
      }
    } else if (medicalDevice === 'bk1000' && nestedLevel === 1) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk1000[firstPanelInvestigationIndex].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk1000[firstPanelInvestigationIndex].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.bk1000[firstPanelInvestigationIndex].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.bk1000[firstPanelInvestigationIndex].maleRange
          : '',
        Number(e.target.value)
      );
      if (val.id === 63) {
        const temp = { ...apolipoAVal, nestedFirst: e.target.value };
        setApolipoAVal(temp);

        calApolopoprotein(nestedLevel, data);
      } else if (val.id === 64) {
        const tem2 = { ...apolipoBVal, nestedFirst: e.target.value };
        setApolipoBVal(tem2);

        calApolopoprotein(nestedLevel, data);
      } else {
        setEncounterDetails(data);
      }
    } else if (medicalDevice === 'bk1000' && nestedLevel === 2) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk1000[
        secondPanelInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk1000[
        secondPanelInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.bk1000[
              secondPanelInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.bk1000[
              secondPanelInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );

      if (val.id === 63) {
        const temp = { ...apolipoAVal, nestedSecond: e.target.value };
        setApolipoAVal(temp);

        calApolopoprotein(nestedLevel, data);
      } else if (val.id === 64) {
        const tem2 = { ...apolipoBVal, nestedSecond: e.target.value };
        setApolipoBVal(tem2);

        calApolopoprotein(nestedLevel, data);
      } else {
        setEncounterDetails(data);
      }
    }
    //bk200
    else if (medicalDevice === 'bk200' && nestedLevel === 0) {
      data.investigations.panelInvestigation[indexMain].investigations.bk200[
        encounterInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.bk200[
        encounterInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.bk200[
              encounterInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.bk200[
              encounterInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );

      if (val.id === 33) {
        setBicarbonateValforAnionGap({
          ...bicarbonateValforAnionGap,
          general: e.target.value
        });
        calAnionGap(nestedLevel, data);
      } else if (val.id === 42) {
        setAlbuminValforcorrCal({ ...albuminValforcorrCal, general: e.target.value });
        calCorrectedCal(nestedLevel, data);
      } else if (val.id === 46) {
        setCalciumValforcorrCal({ ...calciumValforcorrCal, general: e.target.value });
        calCorrectedCal(nestedLevel, data);
      } else if (val.id === 47) {
        setSerumCretValforGFRF({ ...serumCretValforGFRF, general: e.target.value });
        setEncounterDetails(data);
      } else {
        setEncounterDetails(data);
      }
    } else if (medicalDevice === 'bk200' && nestedLevel === 1) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk200[firstPanelInvestigationIndex].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk200[firstPanelInvestigationIndex].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.bk200[firstPanelInvestigationIndex].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.bk200[firstPanelInvestigationIndex].maleRange
          : '',
        Number(e.target.value)
      );
      if (val.id === 33) {
        setBicarbonateValforAnionGap({
          ...bicarbonateValforAnionGap,
          nestedFirst: e.target.value
        });
        calAnionGap(nestedLevel, data);
      } else if (val.id === 42) {
        setAlbuminValforcorrCal({ ...albuminValforcorrCal, nestedFirst: e.target.value });
        calCorrectedCal(nestedLevel, data);
      } else if (val.id === 46) {
        setCalciumValforcorrCal({ ...calciumValforcorrCal, nestedFirst: e.target.value });
        calCorrectedCal(nestedLevel, data);
      } else if (val.id === 47) {
        setSerumCretValforGFRF({ ...serumCretValforGFRF, nestedFirst: e.target.value });
        setEncounterDetails(data);
      } else {
        setEncounterDetails(data);
      }
    } else if (medicalDevice === 'bk200' && nestedLevel === 2) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk200[
        secondPanelInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk200[
        secondPanelInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.bk200[
              secondPanelInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.bk200[
              secondPanelInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );
      if (val.id === 33) {
        setBicarbonateValforAnionGap({
          ...bicarbonateValforAnionGap,
          nestedSecond: e.target.value
        });
        calAnionGap(nestedLevel, data);
      } else if (val.id === 42) {
        setAlbuminValforcorrCal({ ...albuminValforcorrCal, nestedSecond: e.target.value });
        calCorrectedCal(nestedLevel, data);
      } else if (val.id === 46) {
        setCalciumValforcorrCal({ ...calciumValforcorrCal, nestedSecond: e.target.value });
        calCorrectedCal(nestedLevel, data);
      } else if (val.id === 47) {
        setSerumCretValforGFRF({ ...serumCretValforGFRF, nestedSecond: e.target.value });
        setEncounterDetails(data);
      } else {
        setEncounterDetails(data);
      }
    }
    //bk6190
    else if (medicalDevice === 'bk6190' && nestedLevel === 0) {
      data.investigations.panelInvestigation[indexMain].investigations.bk6190[
        encounterInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.bk6190[
        encounterInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.bk6190[
              encounterInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.bk6190[
              encounterInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );
      setEncounterDetails(data);
    } else if (medicalDevice === 'bk6190' && nestedLevel === 1) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk6190[firstPanelInvestigationIndex].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.bk6190[firstPanelInvestigationIndex].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.bk6190[firstPanelInvestigationIndex].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.bk6190[firstPanelInvestigationIndex].maleRange
          : '',
        Number(e.target.value)
      );
      setEncounterDetails(data);
    } else if (medicalDevice === 'bk6190' && nestedLevel === 2) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk6190[
        secondPanelInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.bk6190[
        secondPanelInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.bk6190[
              secondPanelInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.bk6190[
              secondPanelInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );
      setEncounterDetails(data);
    }
    //cbs300
    else if (medicalDevice === 'cbs300' && nestedLevel === 0) {
      data.investigations.panelInvestigation[indexMain].investigations.cbs300[
        encounterInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.cbs300[
        encounterInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.cbs300[
              encounterInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.cbs300[
              encounterInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );
      if (val?.id === 34) {
        setChlorideValforAnionGap({ ...chlorideValforAnionGap, general: e.target.value });
        calAnionGap(nestedLevel, data);
      } else if (val?.id === 35) {
        setPotassiumValforAnionGap({ ...potassiumValforAnionGap, general: e.target.value });
        calAnionGap(nestedLevel, data);
      } else if (val?.id === 36) {
        setSodiumValforAnionGap({ ...sodiumValforAnionGap, general: e.target.value });
        calAnionGap(nestedLevel, data);
      } else {
        setEncounterDetails(data);
      }
    } else if (medicalDevice === 'cbs300' && nestedLevel === 1) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.cbs300[firstPanelInvestigationIndex].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.cbs300[firstPanelInvestigationIndex].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.cbs300[firstPanelInvestigationIndex].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.cbs300[firstPanelInvestigationIndex].maleRange
          : '',
        Number(e.target.value)
      );
      if (val?.id === 34) {
        setChlorideValforAnionGap({ ...chlorideValforAnionGap, nestedFirst: e.target.value });
        calAnionGap(nestedLevel, data);
      } else if (val?.id === 35) {
        setPotassiumValforAnionGap({ ...potassiumValforAnionGap, nestedFirst: e.target.value });
        calAnionGap(nestedLevel, data);
      } else if (val?.id === 36) {
        setSodiumValforAnionGap({ ...sodiumValforAnionGap, nestedFirst: e.target.value });
        calAnionGap(nestedLevel, data);
      } else {
        setEncounterDetails(data);
      }
    } else if (medicalDevice === 'cbs300' && nestedLevel === 2) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.cbs300[
        secondPanelInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.cbs300[
        secondPanelInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.cbs300[
              secondPanelInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.cbs300[
              secondPanelInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );

      if (val?.id === 34) {
        setChlorideValforAnionGap({ ...chlorideValforAnionGap, nestedSecond: e.target.value });
        calAnionGap(nestedLevel, data);
      } else if (val?.id === 35) {
        setPotassiumValforAnionGap({ ...potassiumValforAnionGap, nestedSecond: e.target.value });
        calAnionGap(nestedLevel, data);
      } else if (val?.id === 36) {
        setSodiumValforAnionGap({ ...sodiumValforAnionGap, nestedSecond: e.target.value });
        calAnionGap(nestedLevel, data);
      } else {
        setEncounterDetails(data);
      }
    }
    //other
    else if (medicalDevice === 'other' && nestedLevel === 0) {
      data.investigations.panelInvestigation[indexMain].investigations.other[
        encounterInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.other[
        encounterInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.other[
              encounterInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.other[
              encounterInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );
      setEncounterDetails(data);
    } else if (medicalDevice === 'other' && nestedLevel === 1) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.other[firstPanelInvestigationIndex].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.other[firstPanelInvestigationIndex].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.other[firstPanelInvestigationIndex].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.other[firstPanelInvestigationIndex].maleRange
          : '',
        Number(e.target.value)
      );
      setEncounterDetails(data);
    } else if (medicalDevice === 'other' && nestedLevel === 2) {
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
        secondPanelInvestigationIndex
      ].result = Number(e.target.value);
      //set flag
      data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
        firstPanelIndex
      ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
        secondPanelInvestigationIndex
      ].flag = validateFlag(
        patientData?.gender === 'F'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
              secondPanelInvestigationIndex
            ].femaleRange
          : patientData?.gender === 'M'
          ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
              firstPanelIndex
            ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
              secondPanelInvestigationIndex
            ].maleRange
          : '',
        Number(e.target.value)
      );
      setEncounterDetails(data);
    }
  };

  const calAnionGap = (nestedLevel: number, encounterDetails: any) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    if (nestedLevel === 0) {
      const generalBk200 = data.investigations.panelInvestigation[indexMain].investigations.bk200;

      const bicarbonate = generalBk200.findIndex((bk200: any) => bk200.id === 33) ?? -1;
      //cbs300
      const generalCbs300 = data.investigations.panelInvestigation[indexMain].investigations.cbs300;

      const cloride = generalCbs300.findIndex((bk200: any) => bk200.id === 34) ?? -1;
      const potassium = generalCbs300.findIndex((bk200: any) => bk200.id === 35) ?? -1;
      const sodium = generalCbs300.findIndex((bk200: any) => bk200.id === 36) ?? -1;

      if (bicarbonate !== -1 && cloride !== -1 && potassium !== -1 && sodium !== -1) {
        const calculation =
          Number(generalCbs300[sodium]?.result) +
          Number(generalCbs300[potassium]?.result) -
          (Number(generalCbs300[cloride]?.result) + Number(generalBk200[bicarbonate]?.result));

        const anion = data.investigations.panelInvestigation[
          indexMain
        ].investigations?.other.findIndex((bk1000: any) => bk1000.id === 37);

        if (anion !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.other[anion].result =
            Number(Number(calculation).toFixed(3));
          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.other[anion].flag =
            validateFlag(
              patientData?.gender === 'F'
                ? data.investigations.panelInvestigation[indexMain].investigations.other[anion]
                    .femaleRange
                : patientData?.gender === 'M'
                ? data.investigations.panelInvestigation[indexMain].investigations.other[anion]
                    .maleRange
                : '',
              Number(Number(calculation).toFixed(3))
            );
        }
      }
    } else if (nestedLevel === 1) {
      const generalBk200 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ]?.investigations?.bk200;

      const bicarbonate = generalBk200.findIndex((bk200: any) => bk200.id === 33) ?? -1;
      //cbs300
      const generalCbs300 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ]?.investigations?.cbs300;

      const cloride = generalCbs300.findIndex((bk200: any) => bk200.id === 34) ?? -1;
      const potassium = generalCbs300.findIndex((bk200: any) => bk200.id === 35) ?? -1;
      const sodium = generalCbs300.findIndex((bk200: any) => bk200.id === 36) ?? -1;

      if (bicarbonate !== -1 && cloride !== -1 && potassium !== -1 && sodium !== -1) {
        const calculation =
          Number(generalCbs300[sodium]?.result) +
          Number(generalCbs300[potassium]?.result) -
          (Number(generalCbs300[cloride]?.result) + Number(generalBk200[bicarbonate]?.result));

        const anion = data.investigations.panelInvestigation[
          indexMain
        ].investigations?.other.findIndex((bk1000: any) => bk1000.id === 37);

        if (anion !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.other[anion].result = Number(Number(calculation).toFixed(3));
          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.other[anion].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.other[anion].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.other[anion].maleRange
              : '',
            Number(Number(calculation).toFixed(3))
          );
        }
      }
    } else if (nestedLevel === 2) {
      const generalBk200 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex]?.investigations?.bk200;

      const bicarbonate = generalBk200.findIndex((bk200: any) => bk200.id === 33) ?? -1;
      //cbs300
      const generalCbs300 =
        data?.investigations?.panelInvestigation[indexMain]?.investigations?.panelInvestigation[
          firstPanelIndex
        ]?.investigations?.panelInvestigation[secondPanelIndex]?.investigations?.cbs300;

      const cloride = generalCbs300.findIndex((bk200: any) => bk200.id === 34) ?? -1;
      const potassium = generalCbs300.findIndex((bk200: any) => bk200.id === 35) ?? -1;
      const sodium = generalCbs300.findIndex((bk200: any) => bk200.id === 36) ?? -1;

      if (bicarbonate !== -1 && cloride !== -1 && potassium !== -1 && sodium !== -1) {
        const calculation =
          Number(generalCbs300[sodium]?.result) +
          Number(generalCbs300[potassium]?.result) -
          (Number(generalCbs300[cloride]?.result) + Number(generalBk200[bicarbonate]?.result));

        const anion = data.investigations.panelInvestigation[
          indexMain
        ].investigations?.other.findIndex((bk1000: any) => bk1000.id === 37);

        if (anion !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.panelInvestigation[secondPanelIndex].investigations.other[anion].result =
            Number(Number(calculation).toFixed(3));
          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.panelInvestigation[secondPanelIndex].investigations.other[anion].flag =
            validateFlag(
              patientData?.gender === 'F'
                ? data.investigations.panelInvestigation[indexMain].investigations
                    .panelInvestigation[firstPanelIndex].investigations.panelInvestigation[
                    secondPanelIndex
                  ].investigations.other[anion].femaleRange
                : patientData?.gender === 'M'
                ? data.investigations.panelInvestigation[indexMain].investigations
                    .panelInvestigation[firstPanelIndex].investigations.panelInvestigation[
                    secondPanelIndex
                  ].investigations.other[anion].maleRange
                : '',
              Number(Number(calculation).toFixed(3))
            );
        }
      }
    }
    setEncounterDetails(data);
  };

  const calCorrectedCal = (nestedLevel: number, encounterDetails: any) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));

    if (nestedLevel === 0) {
      const generalBk200 =
        data?.investigations?.panelInvestigation[indexMain]?.investigations?.bk200;
      const albumin = generalBk200.findIndex((bk200: any) => bk200.id === 42) ?? -1;
      const calcium = generalBk200.findIndex((bk200: any) => bk200.id === 46) ?? -1;

      if (albumin !== -1 && calcium !== -1) {
        const calculation =
          0.02 * (40 - Number(generalBk200[albumin].result)) + Number(generalBk200[calcium].result);

        const corrected = data?.investigations?.panelInvestigation[
          indexMain
        ].investigations.other.findIndex((bk1000: any) => bk1000.id === 38);

        if (corrected !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.other[corrected].result =
            Number(Number(calculation).toFixed(3));

          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.other[corrected].flag =
            validateFlag(
              patientData?.gender === 'F'
                ? data.investigations.panelInvestigation[indexMain].investigations.other[corrected]
                    .femaleRange
                : patientData?.gender === 'M'
                ? data.investigations.panelInvestigation[indexMain].investigations.other[corrected]
                    .maleRange
                : '',
              Number(Number(calculation).toFixed(3))
            );
        }
      }
    } else if (nestedLevel === 1) {
      const generalBk200 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.bk200;

      const albumin = generalBk200.findIndex((bk200: any) => bk200.id === 42) ?? -1;
      const calcium = generalBk200.findIndex((bk200: any) => bk200.id === 46) ?? -1;

      if (albumin !== -1 && calcium !== -1) {
        const calculation =
          0.02 * (40 - Number(generalBk200[albumin].result)) + Number(generalBk200[calcium].result);

        const corrected = data.investigations.panelInvestigation[
          indexMain
        ].investigations.panelInvestigation[firstPanelIndex].investigations?.other.findIndex(
          (bk1000: any) => bk1000.id === 38
        );

        if (corrected !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.other[corrected].result = Number(Number(calculation).toFixed(3));
          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.other[corrected].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.other[corrected].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.other[corrected].maleRange
              : '',
            Number(Number(calculation).toFixed(3))
          );
        }
      }
    } else if (nestedLevel === 2) {
      const generalBk200 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations?.bk200;

      const albumin = generalBk200.findIndex((bk200: any) => bk200.id === 42) ?? -1;
      const calcium = generalBk200.findIndex((bk200: any) => bk200.id === 46) ?? -1;

      if (albumin !== -1 && calcium !== -1) {
        const calculation =
          0.02 * (40 - Number(generalBk200[albumin].result)) + Number(generalBk200[calcium].result);

        const corrected = data.investigations.panelInvestigation[
          indexMain
        ].investigations.panelInvestigation[firstPanelIndex].investigations?.other.findIndex(
          (bk1000: any) => bk1000.id === 38
        );

        if (corrected !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
            corrected
          ].result = Number(Number(calculation).toFixed(3));
          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
            corrected
          ].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
                  corrected
                ].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
                  corrected
                ].maleRange
              : '',
            Number(Number(calculation).toFixed(3))
          );
        }
      }
    }
    setEncounterDetails(data);
  };
  //apolipo
  const calApolopoprotein = (nestedLevel: number, encounterDetails: any) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));

    if (nestedLevel === 0) {
      const generalBk1000 =
        data?.investigations?.panelInvestigation[indexMain]?.investigations?.bk1000;

      const apolipoA = generalBk1000.findIndex((bk1000: any) => bk1000.id === 63) ?? -1;

      const apolipoB = generalBk1000.findIndex((bk1000: any) => bk1000.id === 64) ?? -1;

      if (apolipoA !== -1 && apolipoB !== -1) {
        const calculation =
          Number(generalBk1000[apolipoB].result) / Number(generalBk1000[apolipoA].result);

        const ratioA = data?.investigations?.panelInvestigation[
          indexMain
        ].investigations.other.findIndex((bk1000: any) => bk1000.id === 84);

        if (ratioA !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.other[ratioA].result =
            Number(Number(calculation).toFixed(3));

          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.other[ratioA].flag =
            validateFlag(
              patientData?.gender === 'F'
                ? data.investigations.panelInvestigation[indexMain].investigations.other[ratioA]
                    .femaleRange
                : patientData?.gender === 'M'
                ? data.investigations.panelInvestigation[indexMain].investigations.other[ratioA]
                    .maleRange
                : '',
              Number(Number(calculation).toFixed(3))
            );
        }
      }
    } else if (nestedLevel === 1) {
      const generalBk1000 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.bk1000;

      const apolipoA = generalBk1000.findIndex((bk1000: any) => bk1000.id === 63) ?? -1;

      const apolipoB = generalBk1000.findIndex((bk1000: any) => bk1000.id === 64) ?? -1;

      if (apolipoA !== -1 && apolipoB !== -1) {
        const calculation =
          Number(generalBk1000[apolipoB].result) / Number(generalBk1000[apolipoA].result);

        const firstPanelRatioIndex = data.investigations.panelInvestigation[
          indexMain
        ].investigations.panelInvestigation[firstPanelIndex].investigations.other.findIndex(
          (bk1000: any) => bk1000.id === 84
        );

        if (firstPanelRatioIndex !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.other[firstPanelRatioIndex].result = Number(
            Number(calculation).toFixed(3)
          );

          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.other[firstPanelRatioIndex].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.other[firstPanelRatioIndex].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.other[firstPanelRatioIndex].maleRange
              : '',
            Number(Number(calculation).toFixed(3))
          );
        }
      }
    } else if (nestedLevel === 2) {
      const generalBk1000 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.bk1000;

      const apolipoA = generalBk1000.findIndex((bk1000: any) => bk1000.id === 63) ?? -1;

      const apolipoB = generalBk1000.findIndex((bk1000: any) => bk1000.id === 64) ?? -1;

      if (apolipoA !== -1 && apolipoB !== -1) {
        const calculation =
          Number(generalBk1000[apolipoB].result) / Number(generalBk1000[apolipoA].result);

        const secondPanelRatioIndex = data.investigations.panelInvestigation[
          indexMain
        ].investigations.panelInvestigation[firstPanelIndex].investigations.panelInvestigation[
          secondPanelIndex
        ].investigations.other.findIndex((bk1000: any) => bk1000.id === 84);

        if (secondPanelRatioIndex !== -1) {
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
            secondPanelRatioIndex
          ].result = Number(Number(calculation).toFixed(3));
          //set flag
          data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
            firstPanelIndex
          ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
            secondPanelRatioIndex
          ].flag = validateFlag(
            patientData?.gender === 'F'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
                  secondPanelRatioIndex
                ].femaleRange
              : patientData?.gender === 'M'
              ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                  firstPanelIndex
                ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
                  secondPanelRatioIndex
                ].maleRange
              : '',
            Number(Number(calculation).toFixed(3))
          );
        }
      }
    }
    setEncounterDetails(data);
  };

  const getAgeInYear = () => {
    const age = getAgeFromDate(patientData.dateOfBirth);
    const patientAge = age.split(' ');
    return Number(patientAge[0]);
  };

  const [firstPanelGFRFemaleVal, setFirstPanelGFRFemaleVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });
  const [firstPanelGFRMaleVal, setFirstPanelGFRMaleVal] = useState<OtherValues>({
    general: '',
    nestedFirst: '',
    nestedSecond: ''
  });

  useEffect(() => {
    if (
      nestedLevel === 0 &&
      serumCretValforGFRF?.general !== '' &&
      firstPanelGFRFemaleWeight?.general !== '' &&
      firstPanelGFRMaleWeight?.general !== '' &&
      serumCretValforGFRF &&
      firstPanelGFRFemaleWeight &&
      firstPanelGFRMaleWeight
    ) {
      calculateVal();
    } else if (
      nestedLevel === 1 &&
      serumCretValforGFRF?.nestedFirst !== '' &&
      serumCretValforGFRF &&
      firstPanelGFRFemaleWeight &&
      firstPanelGFRMaleWeight &&
      firstPanelGFRFemaleWeight?.nestedFirst !== '' &&
      firstPanelGFRMaleWeight?.nestedFirst !== ''
    ) {
      calculateVal();
    } else if (
      nestedLevel === 2 &&
      serumCretValforGFRF?.nestedSecond !== '' &&
      serumCretValforGFRF &&
      firstPanelGFRFemaleWeight &&
      firstPanelGFRMaleWeight &&
      firstPanelGFRFemaleWeight?.nestedSecond !== '' &&
      firstPanelGFRMaleWeight?.nestedSecond !== ''
    ) {
      calculateVal();
    }
  }, [serumCretValforGFRF, firstPanelGFRFemaleWeight, firstPanelGFRMaleWeight]);

  const calculateVal = () => {
    if (medicalDevice === 'other') {
      if (val.id === 39 || val.id === 40) {
        let femaleValTemp: OtherValues = JSON.parse(JSON.stringify(firstPanelGFRFemaleVal));
        let maleValTemp: OtherValues = JSON.parse(JSON.stringify(firstPanelGFRMaleVal));
        if (nestedLevel === 0) {
          if (
            getAgeInYear() &&
            serumCretValforGFRF?.general.length !== 0 &&
            femaleValTemp &&
            (firstPanelGFRFemaleWeight?.general.length !== 0 ||
              firstPanelGFRMaleWeight?.general.length !== 0)
          ) {
            const weight =
              val.id === 39
                ? Number(firstPanelGFRFemaleWeight?.general)
                : Number(firstPanelGFRMaleWeight?.general);
            const a = Number(
              (
                0.85 *
                ((140 - getAgeInYear()) / (Number(serumCretValforGFRF?.general) * 100)) *
                (weight / 72)
              ).toFixed(3)
            );
            if (val.id === 39) {
              femaleValTemp = { ...femaleValTemp, general: String(a) };
              setFirstPanelGFRFemaleVal(femaleValTemp);
            } else {
              maleValTemp = { ...maleValTemp, general: String(a) };

              setFirstPanelGFRMaleVal(maleValTemp);
            }
          }
        } else if (nestedLevel === 1) {
          if (
            getAgeInYear() &&
            serumCretValforGFRF?.nestedFirst.length !== 0 &&
            (firstPanelGFRFemaleWeight?.nestedFirst.length !== 0 ||
              firstPanelGFRMaleWeight?.nestedFirst.length !== 0)
          ) {
            const weight =
              val.id === 39
                ? Number(firstPanelGFRFemaleWeight?.nestedFirst)
                : Number(firstPanelGFRMaleWeight?.nestedFirst);
            const a = Number(
              (
                0.85 *
                ((140 - getAgeInYear()) / (Number(serumCretValforGFRF?.nestedFirst) * 100)) *
                (weight / 72)
              ).toFixed(3)
            );

            if (val.id === 39) {
              const temp = { ...femaleValTemp, nestedFirst: String(a) };

              setFirstPanelGFRFemaleVal(temp);
            } else {
              setFirstPanelGFRMaleVal({ ...maleValTemp, nestedFirst: String(a) });
            }
          }
        } else if (nestedLevel === 2) {
          if (
            getAgeInYear() &&
            serumCretValforGFRF?.nestedSecond.length !== 0 &&
            (firstPanelGFRFemaleWeight?.nestedSecond.length !== 0 ||
              firstPanelGFRMaleWeight?.nestedSecond.length !== 0)
          ) {
            const weight =
              val.id === 39
                ? Number(firstPanelGFRFemaleWeight?.nestedSecond)
                : Number(firstPanelGFRMaleWeight?.nestedSecond);
            const a = Number(
              (
                0.85 *
                ((140 - getAgeInYear()) / (Number(serumCretValforGFRF?.nestedSecond) * 100)) *
                (weight / 72)
              ).toFixed(3)
            );
            val.id === 39
              ? setFirstPanelGFRFemaleVal({ ...femaleValTemp, nestedSecond: String(a) })
              : setFirstPanelGFRMaleVal({ ...maleValTemp, nestedSecond: String(a) });
          }
        }
      }
    }
  };

  const onChangeGRF = (e: any, id: number) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));

    if (nestedLevel === 0) {
      const generalBk200 =
        data?.investigations?.panelInvestigation[indexMain]?.investigations?.bk200;

      const serum = generalBk200.findIndex((bk1000: any) => bk1000.id === 47) ?? -1;

      if (serum !== -1) {
        if (id === 39) {
          setFirstPanelGFRFemaleWeight({
            ...firstPanelGFRFemaleWeight,
            general: e.target.value
          });
          //calculation
          const weight = Number(e.target.value);
          const a = Number(
            (
              0.85 *
              ((140 - getAgeInYear()) / (Number(generalBk200[serum]?.result) * 100)) *
              (weight / 72)
            ).toFixed(3)
          );

          setFirstPanelGFRFemaleVal({ ...firstPanelGFRFemaleVal, general: String(a) });
        } else {
          setFirstPanelGFRMaleWeight({ ...firstPanelGFRMaleWeight, general: e.target.value });
          //calculation
          const weight = Number(e.target.value);
          const a = Number(
            (
              0.85 *
              ((140 - getAgeInYear()) / (Number(generalBk200[serum]?.result) * 100)) *
              (weight / 72)
            ).toFixed(3)
          );
          setFirstPanelGFRMaleVal({ ...firstPanelGFRMaleVal, general: String(a) });
        }
        data.investigations.panelInvestigation[indexMain].investigations.other[
          encounterInvestigationIndex
        ].result = Number(e.target.value);
        //set flag
        data.investigations.panelInvestigation[indexMain].investigations.other[
          encounterInvestigationIndex
        ].flag = validateFlag(
          patientData?.gender === 'F'
            ? data.investigations.panelInvestigation[indexMain].investigations.other[
                encounterInvestigationIndex
              ].femaleRange
            : patientData?.gender === 'M'
            ? data.investigations.panelInvestigation[indexMain].investigations.other[
                encounterInvestigationIndex
              ].maleRange
            : '',
          Number(e.target.value)
        );
        setEncounterDetails(data);
      } else {
        errorToast('Enter a value for Serum Creatinine first');
      }
    } else if (nestedLevel === 1) {
      const generalBk200 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations?.bk200;

      const serum = generalBk200.findIndex((bk1000: any) => bk1000.id === 47) ?? -1;

      if (serum !== -1) {
        if (id === 39) {
          setFirstPanelGFRFemaleWeight({
            ...firstPanelGFRFemaleWeight,
            nestedFirst: e.target.value
          });
          //calculation
          const weight = Number(firstPanelGFRFemaleWeight?.nestedFirst);
          const a = Number(
            (
              0.85 *
              ((140 - getAgeInYear()) / (Number(generalBk200[serum]?.result) * 100)) *
              (weight / 72)
            ).toFixed(3)
          );

          setFirstPanelGFRFemaleVal({ ...firstPanelGFRFemaleVal, nestedFirst: String(a) });
        } else {
          setFirstPanelGFRMaleWeight({ ...firstPanelGFRMaleWeight, nestedFirst: e.target.value });
          //calculation
          const weight = Number(firstPanelGFRMaleWeight?.nestedFirst);
          const a = Number(
            (
              0.85 *
              ((140 - getAgeInYear()) / (Number(generalBk200[serum]?.result) * 100)) *
              (weight / 72)
            ).toFixed(3)
          );

          setFirstPanelGFRMaleVal({ ...firstPanelGFRMaleVal, nestedFirst: String(a) });
        }
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.other[firstPanelInvestigationIndex].result = Number(e.target.value);
        //set flag
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.other[firstPanelInvestigationIndex].flag = validateFlag(
          patientData?.gender === 'F'
            ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                firstPanelIndex
              ].investigations.other[firstPanelInvestigationIndex].femaleRange
            : patientData?.gender === 'M'
            ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                firstPanelIndex
              ].investigations.other[firstPanelInvestigationIndex].maleRange
            : '',
          Number(e.target.value)
        );

        setEncounterDetails(data);
      } else {
        errorToast('Enter a value for Serum Creatinine first');
      }
    } else if (nestedLevel === 2) {
      const generalBk200 =
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.bk200;

      const serum = generalBk200.findIndex((bk1000: any) => bk1000.id === 47) ?? -1;

      if (serum !== -1) {
        if (id === 39) {
          setFirstPanelGFRFemaleWeight({
            ...firstPanelGFRFemaleWeight,
            nestedSecond: e.target.value
          });
          //calculation
          const weight = Number(firstPanelGFRFemaleWeight?.nestedSecond);
          const a = Number(
            (
              0.85 *
              ((140 - getAgeInYear()) / (Number(generalBk200[serum]?.result) * 100)) *
              (weight / 72)
            ).toFixed(3)
          );
          setFirstPanelGFRFemaleVal({ ...firstPanelGFRFemaleVal, nestedSecond: String(a) });
        } else {
          setFirstPanelGFRMaleWeight({ ...firstPanelGFRMaleWeight, nestedSecond: e.target.value });
          //calculation
          const weight = Number(firstPanelGFRMaleWeight?.nestedSecond);
          const a = Number(
            (
              0.85 *
              ((140 - getAgeInYear()) / (Number(generalBk200[serum]?.result) * 100)) *
              (weight / 72)
            ).toFixed(3)
          );
          setFirstPanelGFRMaleVal({ ...firstPanelGFRMaleVal, nestedSecond: String(a) });
        }
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
          secondPanelInvestigationIndex
        ].result = Number(e.target.value);
        //set flag
        data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
          firstPanelIndex
        ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
          secondPanelInvestigationIndex
        ].flag = validateFlag(
          patientData?.gender === 'F'
            ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                firstPanelIndex
              ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
                secondPanelInvestigationIndex
              ].femaleRange
            : patientData?.gender === 'M'
            ? data.investigations.panelInvestigation[indexMain].investigations.panelInvestigation[
                firstPanelIndex
              ].investigations.panelInvestigation[secondPanelIndex].investigations.other[
                secondPanelInvestigationIndex
              ].maleRange
            : '',
          Number(e.target.value)
        );
        setEncounterDetails(data);
      } else {
        errorToast('Enter a value for Serum Creatinine first');
      }
    }
  };

  const toggleRerun = () => {
    const id = `drop-${medicalDevice}-${nestedLevel}-${val?.name}-${val?.id}-${getIndexForId()}`;
    const body: HTMLElement | null = document.getElementById(id);
    if (body) {
      const check = body.classList.contains('d-none');
      if (check) {
        body.classList.remove('d-none');
      } else {
        body.classList.add('d-none');
      }
    }
  };

  return (
    <Row className="align-items-center my-2">
      <Col xs={4} className="font-size-14">
        {val?.name === 'CBC' ? val?.parameter : val?.name}
      </Col>
      <Col xs={2} className={'text-center'}>
        {
          //Anion Gap
          val?.id === 37 ? (
            <Input
              type="number"
              className={!edit ? '' : 'bg-white'}
              disabled
              style={{ color: '#2c3242' }}
              value={
                val?.result
                // nestedLevel === 0
                //   ? anionGapVal?.general
                //   : nestedLevel === 1
                //   ? anionGapVal?.nestedFirst
                //   : nestedLevel === 2
                //   ? anionGapVal?.nestedSecond
                //   : ''
              }
            />
          ) : //corrected calcium
          val?.id === 38 ? (
            <Input type="number" className={!edit ? '' : 'bg-white'} disabled value={val?.result} />
          ) : //GFR
          val?.id === 39 || val?.id === 40 ? (
            <div
              className="d-flex flex-row"
              style={!edit ? { border: 'none' } : { border: '1px solid #e3e3e3' }}
            >
              <Input
                type="number"
                name="gfr"
                className={!edit ? 'other-input ' : 'other-input  bg-white'}
                style={
                  !edit
                    ? {
                        border: 'none',
                        color: '#2c3242',
                        backgroundColor: 'inherit',
                        width: '120px'
                      }
                    : { backgroundColor: 'white', width: '120px' }
                }
                min={0}
                disabled={!edit}
                onChange={(e) => {
                  val.id === 39 ? onChangeGRF(e, 39) : onChangeGRF(e, 40);
                }}
                value={val?.result}
              />
              <span
                className="align-items-center justify-content-center"
                style={
                  !edit
                    ? { display: 'none' }
                    : {
                        width: '40px',
                        borderRight: '1px solid #e3e3e3',
                        display: 'flex'
                      }
                }
              >
                Kg
              </span>
              <Input
                type="number"
                style={
                  !edit
                    ? {
                        width: '80px',
                        border: 'none',
                        color: '#818287',
                        backgroundColor: 'inherit'
                      }
                    : { width: '80px', color: '#818287' }
                }
                disabled
                value={
                  nestedLevel === 0
                    ? val?.id === 39
                      ? firstPanelGFRFemaleVal?.general
                      : firstPanelGFRMaleVal?.general
                    : nestedLevel === 1
                    ? val?.id === 39
                      ? firstPanelGFRFemaleVal?.nestedFirst
                      : firstPanelGFRMaleVal?.nestedFirst
                    : nestedLevel === 2
                    ? val?.id === 39
                      ? firstPanelGFRFemaleVal?.nestedSecond
                      : firstPanelGFRMaleVal?.nestedSecond
                    : ''
                }
              />
            </div>
          ) : //Apolipo
          val?.id === 84 ? (
            <Input
              type="number"
              style={
                !edit
                  ? { border: 'none', color: '#2c3242', backgroundColor: 'inherit' }
                  : { backgroundColor: 'white' }
              }
              disabled
              value={val?.result}
            />
          ) : (
            <Input
              type="number"
              style={
                !edit
                  ? { border: 'none', color: '#2c3242', backgroundColor: 'inherit' }
                  : { backgroundColor: 'white' }
              }
              disabled={!edit}
              onChange={onChangeInvestigationInput}
              value={val?.result}
            />
          )
        }
      </Col>
      <Col className="text-center font-size-14"> {val?.tracker}</Col>
      <Col className="text-center font-size-14">{val?.unit}</Col>
      <Col className="text-center font-size-14">
        {patientData?.gender === 'F'
          ? val?.femaleRange
          : patientData?.gender === 'M'
          ? val?.maleRange
          : ''}
      </Col>
      <Col className="text-center font-size-14">
        {val?.id === 84 ? (
          getApolipoFlag(val?.flag)
        ) : val?.id === 82 ? (
          getVitaminDFlag(val?.flag)
        ) : (
          <CustomPill flag={getFlag(val?.flag)} />
        )}
      </Col>
      <Col className="d-flex justify-content-end">
        <ThreeDotsIcon
          color="#696E7C"
          className={!edit ? 'cursor-pointer disable-pointer' : 'cursor-pointer '}
          onClick={toggleRerun}
        />
        <div
          id={`drop-${medicalDevice}-${nestedLevel}-${val?.name}-${val?.id}-${getIndexForId()}`}
          className="d-none"
          style={{ position: 'relative' }}
        >
          <div className="drop-down-container cursor-pointer" onClick={rerunInvestigation}>
            <h4 className="re-run mt-2">Re-run</h4>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default RenderRow;
