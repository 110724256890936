import React, { FC, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import Empty from '~/components/Common/Empty';
import Loader from '~/components/Common/Loader';
import WhiteButton from '~/components/WhiteButton';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import {
  resetArchivePatientsState,
  resetFetchAllPatients,
  resetUnrchivePatientsState
} from '~/store/reducers/patient';
import {
  archivePatient,
  fetchAllPatients,
  unarchivePatient
} from '~/store/reducers/patient/thunks';
import {
  convertServerDateFormatToDateFormatWithoutHyphen,
  getAgeFromDate
} from '~/utils/dateUtility';
import { errorToast, successToast } from '~/utils/toast';
import { ReactComponent as UserAdd } from '../../assets/icons/account-plus-outline.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filterIcon.svg';

const Patients: FC = () => {
  const history = useHistory();
  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useAppDispatch();
  const {
    //fetch all patients
    patients: reduxPatients,
    isLoadingFetchAllPatients,
    errorFetchAllPatients,
    successFetchAllPatients,
    //archive patient
    isLoadingArchivePatient,
    errorArchivePatient,
    successArchivePatient,

    //unarchive patient
    isLoadingUnarchivePatient,
    errorUnarchivePatient,
    successUnarchivePatient
  } = useAppSelector((state) => state.PatientReducer);

  const [filtersList, setfiltersList] = useState([
    { id: 1, title: 'Male', check: false },
    { id: 2, title: 'Female', check: false },
    { id: 3, title: 'Archived', check: false }
  ]);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [tempfiltersList, setTempfiltersList] = useState<any>([]);
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    if (!isLoadingFetchAllPatients && errorFetchAllPatients) {
      errorToast(errorFetchAllPatients);
      dispatch(resetFetchAllPatients());
    }

    if (!isLoadingFetchAllPatients && successFetchAllPatients && reduxPatients?.length > 0) {
      const data = reduxPatients?.filter((val: any) => val.deactivated === false);
      setPatients(data);
      dispatch(resetFetchAllPatients());
    }
  }, [isLoadingFetchAllPatients, errorFetchAllPatients, successFetchAllPatients, reduxPatients]);

  //unarchive
  useEffect(() => {
    if (!isLoadingUnarchivePatient && errorUnarchivePatient) {
      errorToast(errorUnarchivePatient);
      dispatch(resetUnrchivePatientsState());
    }
    if (!isLoadingUnarchivePatient && successUnarchivePatient) {
      successToast('Patient unarchived successfully');
      dispatch(resetUnrchivePatientsState());
      resetFilters();
      dispatch(fetchAllPatients());
    }
  }, [
    isLoadingUnarchivePatient,
    errorUnarchivePatient,
    successUnarchivePatient,
    resetUnrchivePatientsState
  ]);

  //archive
  useEffect(() => {
    if (!isLoadingArchivePatient && errorArchivePatient) {
      errorToast(errorArchivePatient);
      dispatch(resetArchivePatientsState());
    }
    if (!isLoadingArchivePatient && successArchivePatient) {
      successToast('Patient archived successfully');
      dispatch(resetArchivePatientsState());
      resetFilters();
      dispatch(fetchAllPatients());
    }
  }, [
    isLoadingArchivePatient,
    errorArchivePatient,
    successArchivePatient,
    resetArchivePatientsState
  ]);

  useEffect(() => {
    dispatch(fetchAllPatients());
    window.addEventListener('resize', HandleWidth);
    return () => {
      window.removeEventListener('resize', HandleWidth);
    };
  }, []);

  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };

  const ApplyFilter = () => {
    let arr: any = [];
    if (filtersList[2].check) {
      const arc = reduxPatients.filter((data: any) => data.deactivated === true);
      if (filtersList[0].check) {
        const males = arc.filter((data: any) => data.gender === 'M');
        arr = males;
      }
      if (filtersList[1].check) {
        const f = arc.filter((data: any) => data.gender === 'F');
        arr = arr.concat(f);
      }
      if (!filtersList[0].check && !filtersList[1].check) {
        arr = arc;
      }
    } else {
      if (filtersList[0].check) {
        const males = reduxPatients.filter((data: any) => data.gender === 'M' && !data.deactivated);
        arr = arr.concat(males);
      }
      if (filtersList[1].check) {
        const f = reduxPatients.filter((data: any) => data.gender === 'F' && !data.deactivated);
        arr = arr.concat(f);
      }
      if (!filtersList[0].check && !filtersList[1].check) {
        const f2 = reduxPatients.filter((data: any) => !data.deactivated);
        arr = f2;
      }
    }

    setPatients(arr);
    setFilterDropDown(!filterDropDown);
  };
  const resetFilters = () => {
    setfiltersList([
      { id: 1, title: 'Male', check: false },
      { id: 2, title: 'Female', check: false },
      { id: 3, title: 'Archived', check: false }
    ]);
  };

  return (
    <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
      <Container fluid>
        <Row>
          <Col lg="3">
            <h2 className="m-0 align-self-center text-nowrap">
              <b className="text-nowrap sbl24">Patient management</b>
            </h2>
          </Col>
          <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
          <Col lg="4" className="d-flex justify-content-end text-nowrap">
            <WhiteButton
              Icon={
                <FilterIcon
                  color={'#2C3242'}
                  style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                />
              }
              HandleClick={() => {
                setFilterDropDown(!filterDropDown);
                setTempfiltersList([...filtersList]);
              }}
              text={'Filters'}
            />
            {filterDropDown && (
              <div
                style={{
                  background: '#FFFFFF ',
                  boxShadow: '0px 3px 12px #00000029',
                  borderRadius: '8px',
                  position: 'absolute',
                  marginRight: '155px',
                  marginTop: '35px',
                  padding: '10px',
                  zIndex: 4
                }}
              >
                {filtersList?.map((item, index) => (
                  <div key={index}>
                    <Input
                      type="checkbox"
                      checked={item.check}
                      onChange={(e) => {
                        const { checked } = e.target;
                        const data = JSON.parse(JSON.stringify(filtersList));
                        data[index].check = checked;
                        setfiltersList(data);
                      }}
                    />
                    <Label className="font-size-14px font-family-inter ms-2">{item?.title}</Label>
                  </div>
                ))}
                <div className="mt-3" style={{ marginLeft: '-16px' }}>
                  <WhiteButton
                    text="Dismiss"
                    HandleClick={() => {
                      setFilterDropDown(false);
                      setfiltersList(tempfiltersList);
                    }}
                  />
                  <BlueButton text="Apply filters" HandleClick={ApplyFilter} />
                </div>
              </div>
            )}

            <BlueButton
              Icon={
                <UserAdd
                  color={'#ffffff'}
                  style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                />
              }
              HandleClick={() => {
                history.push({
                  pathname: '/AddPatient'
                });
              }}
              width="151px"
              text={'New registration'}
            />
          </Col>
        </Row>
        <div className="horizontal-line mt-3"></div>
        <div
          className="mt-4"
          style={{
            // minHeight: `${height - 70}px`,
            height: `${height - 170}px`,
            overflowX: 'hidden',
            overflowY: 'auto',
            scrollbarWidth: 'thin'
          }}
        >
          {!isLoadingFetchAllPatients ? (
            patients !== null && patients.length !== 0 ? (
              patients.map((patient: any) => (
                <Accordion key={patient.id}>
                  <AccordionItem
                    dropDownstyle={{
                      right: '175px'
                    }}
                    dropdownData={[
                      { id: 1, title: 'Encounter history', color: 'default' },
                      { id: 2, title: 'Edit information', color: 'default' },
                      { id: 3, title: 'Email', color: 'default' },
                      patient.deactivated
                        ? { id: 4, title: 'Unarchive', color: 'danger' }
                        : { id: 4, title: 'Archive', color: 'danger' }
                    ]}
                    onClickDropdownItem={[
                      {
                        id: 1,
                        title: 'Encounter history',
                        event: () => {
                          history.push({
                            pathname: `/patient/${patient?.id}/encounterHistory`
                          });
                        }
                      },
                      {
                        id: 2,
                        title: 'Edit information',
                        event: () => {
                          history.push({
                            pathname: '/AddPatient',
                            state: patient
                          });
                        }
                      },
                      {
                        id: 3,
                        title: 'Email',
                        event: () => {
                          //TODO
                        }
                      },
                      patient.deactivated
                        ? {
                            id: 4,
                            title: 'Unarchive',
                            event: () => {
                              dispatch(unarchivePatient(patient.id));
                            }
                          }
                        : {
                            id: 4,
                            title: 'Archive',
                            event: () => {
                              dispatch(archivePatient(patient.id));
                            }
                          }
                    ]}
                    accordionHeaderClasses={'justify-content-between'}
                    AccordionHeader={
                      <>
                        <span className="d-flex align-items-center">
                          <Avatar
                            name={patient.firstName + ' ' + patient.lastName}
                            maxInitials={2}
                            textSizeRatio={2.2}
                            size="40"
                            color="#2A45CD30"
                            fgColor="#2A45CD"
                            round
                          />
                          <div className="d-flex flex-column ms-3">
                            <span className="patient-name">
                              {patient.firstName + ' ' + patient.lastName}
                            </span>
                            <span className="patient-name patient-details">
                              {patient?.gender === 'M'
                                ? 'Male'
                                : patient?.gender === 'F'
                                ? 'Female'
                                : patient?.gender === 'O'
                                ? 'Others'
                                : '-'}
                              · {getAgeFromDate(patient.dateOfBirth)}
                            </span>
                          </div>
                        </span>
                        <span
                          className="btn me-4"
                          style={{
                            color: '#2A45CD',
                            fontFamily: 'Inter',
                            letterSpacing: '-0.17px',
                            fontWeight: 500,
                            fontSize: '14px'
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push({
                              pathname: '/newEncounter',
                              state: {
                                patientData: patient
                              }
                            });
                          }}
                        >
                          New encounter
                        </span>
                      </>
                    }
                    AccordionBody={
                      <div className="col-md-12 p-3 d-flex">
                        <div className="col-md-3">
                          <span className="d-flex  py-2">
                            <Col className="key-text" sm={4}>
                              Full name
                            </Col>
                            <Col className="value-text">
                              {patient.firstName?.length !== 0
                                ? patient.firstName + ' ' + patient.lastName
                                : '-'}
                            </Col>
                          </span>

                          <span className="d-flex  py-2">
                            <Col className="key-text" sm={4}>
                              Age
                            </Col>
                            <Col className="value-text">
                              {patient.dateOfBirth
                                ? `${getAgeFromDate(
                                    patient.dateOfBirth
                                  )} (${convertServerDateFormatToDateFormatWithoutHyphen(
                                    patient.dateOfBirth
                                  )})`
                                : '-'}
                            </Col>
                          </span>

                          <span className="d-flex  py-2">
                            <Col className="key-text" sm={4}>
                              Gender
                            </Col>
                            <Col className="value-text">
                              {patient?.gender === 'M'
                                ? 'Male'
                                : patient?.gender === 'F'
                                ? 'Female'
                                : patient?.gender === 'O'
                                ? 'Others'
                                : '-'}
                            </Col>
                          </span>

                          <span className="d-flex  py-2">
                            <Col className="key-text" sm={4}>
                              Phone
                            </Col>
                            <Col className="value-text">
                              {patient.phoneNumber?.length !== 0 ? patient.phoneNumber : '-'}
                            </Col>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="d-flex  py-2">
                            <Col className="key-text" md={5}>
                              Email
                            </Col>
                            <Col className="value-text">
                              {patient.email?.length !== 0 ? patient.email : '-'}
                            </Col>
                          </span>

                          <span className="d-flex py-2">
                            <Col className="key-text" md={5}>
                              Patient ID
                            </Col>
                            <Col className="value-text">{patient.id ?? '-'}</Col>
                          </span>

                          <span className="d-flex py-2">
                            <Col className="key-text" md={5}>
                              City of Residence{' '}
                            </Col>
                            <Col className="value-text">
                              {patient.address?.length !== 0 ? patient.address : '-'}
                            </Col>
                          </span>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    }
                  />
                </Accordion>
              ))
            ) : (
              <div
                className="d-flex justify-content-center w-100"
                style={{
                  width: '100%',
                  height: '60vh',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Empty />
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center w-100" style={{ minHeight: '500px' }}>
              <Loader />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Patients;
