import React, { FC, ReactNode } from 'react';
import './accordion.scss';

type AccordionProps = {
  children: ReactNode | ReactNode[];
};
const Accordion: FC<AccordionProps> = ({ children }) => {
  return <ul className="accordion">{children}</ul>;
};

export default Accordion;
