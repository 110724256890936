import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Col, Form, Row } from 'reactstrap';
import DatePicker from 'reactstrap-date-picker';
import * as Yup from 'yup';
import BlueButton from '~/components/BlueButton';
import CustomInput from '~/components/CustomInput';
import WhiteButton from '~/components/WhiteButton';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { resetAddPatient, resetUpdatedPatient } from '~/store/reducers/patient';
import { addNewPatient, updatePatient } from '~/store/reducers/patient/thunks';
import {
  convertServerDateFormatToIsoDateTimeFormat,
  convertToServerDateFormat,
  isDateTodayOrPast
} from '~/utils/dateUtility';
import useWindowSize from '~/utils/hooks/useWindowSize';
import { errorToast, successToast } from '~/utils/toast';

const AddPatientForm: React.FC<any> = (props) => {
  const { patientData } = props;
  const { height } = useWindowSize();
  const dispatch = useAppDispatch();
  const {
    //add patient
    isLoadingAddPatient,
    successAddPatient,
    errorAddPatient,
    //update patient
    isLoadingUpdatePatient,
    successUpdatePatient,
    errorUpdatePatient
  } = useAppSelector((state) => state.PatientReducer);
  const { handleBlur, handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        firstName: patientData?.firstName ?? '',
        lastName: patientData?.lastName ?? '',
        gender: patientData?.gender ?? '',
        dateOfBirth: patientData?.dateOfBirth
          ? convertServerDateFormatToIsoDateTimeFormat(patientData.dateOfBirth)
          : '',
        phoneNumber: patientData?.phoneNumber ?? '',
        email: patientData?.email ?? '',
        uniqueId: patientData?.uniqueId ?? '',
        address: patientData?.address ?? ''
      },
      validationSchema: Yup.object({
        firstName: Yup.string().required('required*'),
        lastName: Yup.string().required('required*'),
        gender: Yup.string().required('required*'),
        dateOfBirth: Yup.string().required('required*'),
        phoneNumber: Yup.string().required('required*'),
        uniqueId: Yup.string().required('required*'),
        email: Yup.string().email().notRequired(),
        address: Yup.string().notRequired()
      }),
      onSubmit: (values) => {
        if (values.phoneNumber !== undefined || values.phoneNumber !== '') {
          if (values.dateOfBirth && isValidPhoneNumber(`${values.phoneNumber}`)) {
            if (isDateTodayOrPast(convertToServerDateFormat(values.dateOfBirth))) {
              patientData
                ? dispatch(
                    updatePatient({
                      ...values,
                      phoneNumber: values.phoneNumber ?? '',
                      dateOfBirth: convertToServerDateFormat(values.dateOfBirth),
                      id: patientData.id
                    })
                  )
                : dispatch(
                    addNewPatient({
                      ...values,
                      phoneNumber: values.phoneNumber ?? '',
                      dateOfBirth: convertToServerDateFormat(values.dateOfBirth)
                    })
                  );
            } else {
              errorToast('Please enter a valid Date of birth');
            }
          }
        } else {
          errorToast('Please enter a valid contact number');
        }
      }
    });

  useEffect(() => {
    if (!isLoadingUpdatePatient && successUpdatePatient) {
      successToast('Patient updated successfully !');
      dispatch(resetUpdatedPatient());
      history.back();
    }
    if (!isLoadingUpdatePatient && errorUpdatePatient) {
      errorToast(errorUpdatePatient);
      dispatch(resetUpdatedPatient());
    }
  }, [isLoadingUpdatePatient, successUpdatePatient, errorUpdatePatient, resetUpdatedPatient]);

  useEffect(() => {
    if (!isLoadingAddPatient && successAddPatient) {
      successToast('Patient added successfully !');
      dispatch(resetAddPatient());
      history.back();
    }
    if (!isLoadingAddPatient && errorAddPatient) {
      errorToast(errorAddPatient);
      dispatch(resetAddPatient());
    }
  }, [isLoadingAddPatient, successAddPatient, errorAddPatient]);

  const isLetterInput = (e: any) => {
    const letterRegex = /^[A-Za-z',\s]*$/;
    if (e.target.value === '' || letterRegex.test(e.target.value)) {
      return true;
    }
    return false;
  };

  return (
    <Form id="addPatient" onSubmit={handleSubmit}>
      <Row>
        <Col lg="3">
          <h2 className="m-0 align-self-center text-nowrap">
            <b className="text-nowrap sbl24">
              {patientData?.id ? 'Edit patient information' : 'New patient registration'}
            </b>
          </h2>
        </Col>
        <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
        <Col lg="4" className="text-end text-nowrap">
          <WhiteButton width="71px" text={'Dismiss'} HandleClick={() => history.back()} />
          <BlueButton
            HandleClick={handleSubmit}
            width="105px"
            loading={isLoadingAddPatient || isLoadingUpdatePatient}
            disabled={isLoadingAddPatient || isLoadingUpdatePatient}
            text={'Save changes'}
          />
        </Col>
      </Row>
      <div className="horizontal-line mt-3 mb-3"></div>
      <div
        className="ps-1"
        style={{
          maxHeight: `${height - 215}px`,
          overflowX: 'hidden',
          scrollbarWidth: 'thin'
        }}
      >
        <div className="col-md-5">
          <label className="col-md-5 col-form-label im gray">Legal names of patient *</label>
          <div className="mb-3">
            <CustomInput
              label={''}
              type="text"
              placeholder="First Name"
              name={'firstName'}
              value={values.firstName}
              onChange={(e: any) => {
                if (isLetterInput(e) && e.target.value.length < 25) {
                  handleChange(e);
                }
              }}
              invalid={touched.firstName && errors.firstName ? true : false}
              errors={errors.firstName}
            />
          </div>
          <div className="mt-3">
            <CustomInput
              label={''}
              type="text"
              placeholder="Last Name"
              name={'lastName'}
              value={values.lastName}
              onChange={(e: any) => {
                if (isLetterInput(e) && e.target.value.length < 25) {
                  handleChange(e);
                }
              }}
              invalid={touched.lastName && errors.lastName ? true : false}
              errors={errors.lastName}
            />
          </div>
        </div>
        <div className="col-md-5 me-1 mt-2">
          <CustomInput
            label={'Sex *'}
            type="select"
            placeholder="Sex"
            name={'gender'}
            value={values.gender}
            options={[
              { value: 'F', name: 'Female' },
              { value: 'M', name: 'Male' }
            ]}
            onChange={handleChange}
            invalid={touched.gender && errors.gender ? true : false}
            errors={errors.gender}
          />
        </div>
        <div className="col-md-5 me-1 mt-2">
          <label className="col-md-5 col-form-label im gray">Date of birth *</label>
          <DatePicker
            id="birthDate"
            dateFormat="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
            showClearButton={false}
            name={'dateOfBirth'}
            value={values.dateOfBirth || ''}
            onBlur={handleBlur}
            invalid={touched.dateOfBirth && errors.dateOfBirth ? true : false}
            style={
              touched.dateOfBirth && errors.dateOfBirth
                ? { border: '1px #f46a6a solid', borderRadius: '0.375rem' }
                : { borderRadius: '0.375rem' }
            }
            onChange={(e: string) => setFieldValue('dateOfBirth', e)}
          />
          {touched.dateOfBirth && errors.dateOfBirth ? (
            <span className="text-danger font-size-14">Invalid Date</span>
          ) : null}
        </div>
        <div className="col-md-5 me-1 mt-2">
          <CustomInput
            label={'ID *'}
            type="text"
            placeholder="e.g. passport/driving license ID"
            name={'uniqueId'}
            value={values.uniqueId}
            onChange={handleChange}
            invalid={touched.uniqueId && errors.uniqueId ? true : false}
            errors={errors.uniqueId}
          />
        </div>
        <div className="col-md-5 me-1 mt-2">
          <label className="col-md-6 col-form-label im gray">Contact number *</label>
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="KE"
            inputProps={{
              name: 'phoneNumber',
              onBlur: handleBlur
            }}
            style={{
              height: '37px',
              border:
                (touched.phoneNumber && errors.phoneNumber) ||
                (touched.phoneNumber &&
                  values.phoneNumber !== '' &&
                  !isValidPhoneNumber(`${values.phoneNumber}`))
                  ? '1px solid red'
                  : '1px solid #e3e3e3',
              padding: '10px',
              borderRadius: '0.375rem'
            }}
            value={values.phoneNumber}
            onChange={(e: string) => setFieldValue('phoneNumber', e)}
          />
          {(touched.phoneNumber && errors.phoneNumber) ||
          (touched.phoneNumber &&
            values.phoneNumber !== '' &&
            !isValidPhoneNumber(`${values.phoneNumber}`)) ? (
            <span className="text-danger font-size-14">
              {values?.phoneNumber === undefined || values?.phoneNumber === ''
                ? 'required*'
                : !isValidPhoneNumber(`${values.phoneNumber}`)
                ? 'Invalid phone number'
                : errors.phoneNumber}
            </span>
          ) : null}
        </div>
        <div className="col-md-5 me-1 mt-2">
          <CustomInput
            label={'Email address'}
            type="email"
            placeholder="example@domain.com"
            name={'email'}
            value={values.email}
            onChange={handleChange}
            invalid={touched.email && errors.email ? true : false}
            errors={errors.email === 'required*' ? 'required*' : 'Invalid Email Address'}
          />
        </div>
        <div className="col-md-5 me-1 mt-2 mb-1">
          <CustomInput
            label={'City of residence'}
            type="text"
            placeholder="e.g Cape Town"
            name={'address'}
            value={values.address}
            onChange={handleChange}
            invalid={touched.address && errors.address ? true : false}
            errors={errors.address}
          />
        </div>
      </div>
    </Form>
  );
};

export default AddPatientForm;
