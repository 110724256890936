import clsx from 'clsx';
import React, { FC } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { Investigation } from '~/models/modalsInterfaces.model';
import { INVESTIGATION_FLAG_STATUS } from '~/utils/constants';
import { getAgeFromDate } from '~/utils/dateUtility';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';
import './CreateEncounter.styles.scss';

const RenderCBC: FC<any> = ({
  allCbc,
  encounterDetails,
  setEncounterDetails,
  patientData,
  edit
}) => {
  const onChangeInvestigationInput = (e: any, index: number) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    data.investigations.bk6190[index].result = Number(e.target.value);
    //set flag
    data.investigations.bk6190[index].flag = validateFlag(
      patientData?.gender === 'F'
        ? data.investigations.bk6190[index].femaleRange
        : patientData?.gender === 'M'
        ? data.investigations.bk6190[index].maleRange
        : '',
      Number(e.target.value)
    );
    setEncounterDetails(data);
  };
  const getAgeInYear = () => {
    const age = getAgeFromDate(patientData.dateOfBirth);
    const patientAge = age.split(' ');
    return Number(patientAge[0]);
  };

  const validateFlag = (range: string, value: number) => {
    if (range.includes('Before') || range.includes('Foll')) {
      return 1;
    } else if (range.includes('Ages')) {
      //age btw 16 - 24
      if (getAgeInYear() >= 16 && getAgeInYear() <= 24) {
        if (value <= 182) {
          return 0;
        } else if (value > 780) {
          return 2;
        } else {
          return 1;
        }
      } //age btw 24 - 39
      else if (getAgeInYear() >= 25 && getAgeInYear() <= 39) {
        if (value <= 114) {
          return 0;
        } else if (value > 492) {
          return 2;
        } else {
          return 1;
        }
      } //age btw 40 - 54
      else if (getAgeInYear() >= 40 && getAgeInYear() <= 54) {
        if (value <= 90) {
          return 0;
        } else if (value > 360) {
          return 2;
        } else {
          return 1;
        }
      } //age ? 55
      else if (getAgeInYear() >= 55) {
        if (value <= 71) {
          return 0;
        } else if (value > 290) {
          return 2;
        } else {
          return 1;
        }
      }
    } else if (range.includes('=')) {
      const rangeArray = range.split(' ');
      if (rangeArray[0] === 'Deficiency') {
        if (value < parseFloat(rangeArray[2])) {
          return 0;
        } else if (value >= parseFloat(rangeArray[2]) && value <= parseFloat(rangeArray[4])) {
          return 1;
        } else if (value > parseFloat(rangeArray[10]) && value <= parseFloat(rangeArray[12])) {
          return 2;
        } else if (value > parseFloat(rangeArray[19]) && value <= parseFloat(rangeArray[21])) {
          return 3;
        } else {
          return 4;
        }
      } else {
        if (value <= parseFloat(rangeArray[3])) {
          return 0;
        } else if (value > parseFloat(rangeArray[10]) && value <= parseFloat(rangeArray[12])) {
          return 1;
        } else if (value > parseFloat(rangeArray[17]) && value <= parseFloat(rangeArray[19])) {
          return 2;
        } else if (value > parseFloat(rangeArray[24]) && value <= parseFloat(rangeArray[26])) {
          return 3;
        } else {
          return 4;
        }
      }
    } else if (range.includes('greater or equal')) {
      const rangeArray = range.split('to');
      if (value >= parseFloat(rangeArray[1])) {
        return 1;
      } else {
        return 0;
      }
    } else if (range.includes('>')) {
      const rangeArray = range.split(' ');
      if (value < parseFloat(rangeArray[1])) {
        return 0;
      } else {
        return 1;
      }
    } else if (range.includes('<')) {
      const rangeArray = range.split(' ');
      if (value > parseFloat(rangeArray[1])) {
        return 2;
      } else {
        return 1;
      }
    } else if (range.includes('-')) {
      const rangeArray = range.split('-');
      if (value < parseFloat(rangeArray[0])) {
        return 0;
      }
      if (value > parseFloat(rangeArray[1])) {
        return 2;
      } else {
        return 1;
      }
    } else if (range.includes('to')) {
      const rangeArray = range.split('to');
      const low = rangeArray[0];
      const high = rangeArray[1].split(' ');
      if (value < parseFloat(low)) {
        return 0;
      }
      if (value > parseFloat(high[1])) {
        return 2;
      } else {
        return 1;
      }
    }
  };

  const getFlag = (flag: number | undefined) => {
    if (flag !== null) {
      return flag === INVESTIGATION_FLAG_STATUS.LOW
        ? 'L'
        : flag === INVESTIGATION_FLAG_STATUS.MEDIUM
        ? '-'
        : flag === INVESTIGATION_FLAG_STATUS.HIGH
        ? 'H'
        : null;
    }
    return '-';
  };
  const CustomPill = ({ flag }: { flag: string | null }) => {
    return (
      <span
        className={clsx(flag !== '-' && 'rounded-pill px-2 text-danger')}
        style={flag !== '-' ? { backgroundColor: '#FFEBEE', fontWeight: '600' } : {}}
      >
        {flag}
      </span>
    );
  };
  const toggleRerun = (val: Investigation) => {
    const id = `drop-${val?.name}-${val?.parameter}`;
    const body: HTMLElement | null = document.getElementById(id);
    if (body) {
      const check = body.classList.contains('d-none');
      if (check) {
        body.classList.remove('d-none');
      } else {
        body.classList.add('d-none');
      }
    }
  };
  const rerunInvestigation = (val: Investigation, index: number) => {
    const data = JSON.parse(JSON.stringify(encounterDetails));
    //bk6190
    const num = data.investigations.bk6190[index].tracker;
    data.investigations.bk6190[index].tracker = num + 1;
    setEncounterDetails(data);
    toggleRerun(val);
  };

  return (
    <div>
      {allCbc.map((val: Investigation, index: number) => {
        return (
          <Row key={index} className="align-items-center my-2">
            <Col xs={4} className="font-size-14">
              {val.parameter}
            </Col>
            <Col xs={2} className={'text-center'}>
              <Input
                type="number"
                style={
                  !edit
                    ? { border: 'none', color: '#2c3242', backgroundColor: 'inherit' }
                    : { backgroundColor: 'white' }
                }
                disabled={!edit}
                onChange={(e) => onChangeInvestigationInput(e, index)}
                value={val?.result}
              />
            </Col>
            <Col className="text-center font-size-14"> {val?.tracker}</Col>
            <Col className="text-center font-size-14">{val?.unit}</Col>
            <Col className="text-center font-size-14">
              {patientData?.gender === 'F'
                ? val?.femaleRange
                : patientData?.gender === 'M'
                ? val?.maleRange
                : ''}
            </Col>
            <Col className="text-center font-size-14">
              <CustomPill flag={getFlag(val?.flag)} />
            </Col>
            <Col className="d-flex justify-content-end">
              <ThreeDotsIcon
                color="#696E7C"
                className={!edit ? 'cursor-pointer disable-pointer' : 'cursor-pointer '}
                onClick={() => toggleRerun(val)}
              />
              <div
                id={`drop-${val?.name}-${val?.parameter}`}
                className="d-none"
                style={{ position: 'relative' }}
              >
                <div
                  className="drop-down-container cursor-pointer"
                  onClick={() => rerunInvestigation(val, index)}
                >
                  <h4 className="re-run mt-2">Re-run</h4>
                </div>
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default RenderCBC;
