import React, { FC } from 'react';
import { RouteProps, withRouter } from 'react-router-dom';
import { WithRouterProps } from '~/models/WithRouterProps.model';

interface NonAuthLayoutProps extends WithRouterProps {
  children: RouteProps['children'];
}

const NonAuthLayout: FC<NonAuthLayoutProps> = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

export default withRouter(NonAuthLayout) as any;
