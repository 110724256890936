import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  Investigation,
  InvestigationRowProp,
  PanelInvestigationBody,
  PanelInvestigationProps
} from '~/models/modalsInterfaces.model';
import { checkPanelInvestigationsPresent, TableFooter, TableHeader } from '.';
import RenderRow from './Row';
type PanelExportProps = {
  innerRef: any;
  encounterDetails: any;
  data: any;
};
const PanelExport: React.FC<PanelExportProps> = ({ innerRef, encounterDetails, data }) => {
  const RenderInvestigationRow: React.FC<InvestigationRowProp> = ({ data }) => (
    <div>
      {data.investigations &&
        data.investigations?.bk1000?.length > 0 &&
        data.investigations?.bk1000?.map((investigation: Investigation, index: number) => (
          <RenderRow key={index} general={investigation} encounterDetails={encounterDetails} />
        ))}
      {data?.investigations &&
        data?.investigations?.bk200?.length > 0 &&
        data?.investigations?.bk200.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {data?.investigations &&
        data?.investigations?.bk6190?.length > 0 &&
        data?.investigations?.bk6190.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {data?.investigations &&
        data?.investigations?.cbs300?.length > 0 &&
        data?.investigations?.cbs300.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {data?.investigations &&
        data?.investigations?.other?.length > 0 &&
        data?.investigations?.other.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
    </div>
  );
  const RenderPanelRow: React.FC<PanelInvestigationProps> = ({ panel }) => (
    <div>
      {panel?.investigations &&
        panel?.investigations?.bk1000?.length > 0 &&
        panel?.investigations?.bk1000.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.bk200?.length > 0 &&
        panel?.investigations?.bk200.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.bk6190?.length > 0 &&
        panel?.investigations?.bk6190.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.cbs300?.length > 0 &&
        panel?.investigations?.cbs300.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.other?.length > 0 &&
        panel?.investigations?.other.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
    </div>
  );

  return (
    <div ref={innerRef} className={'bg-white p-4'}>
      <table className="pdf-table">
        <TableHeader encounterDetails={encounterDetails} />
        <tbody>
          {/* GENERAL PANEL INVESTIGATION */}
          <>
            <>
              <div
                className={'d-flex rounded border-0 px-4 py-1 m-2'}
                style={{ backgroundColor: '#E0E0E0' }}
              >
                <h5>{data.name}</h5>
              </div>
              {checkPanelInvestigationsPresent(data) && (
                <div className="px-4 py-1 m-2 pdf-data">
                  <p className="text-center">Individual Investigations</p>{' '}
                  <Row xs={12} className={'py-1'}>
                    <Col className="col-4 text-start font-size-12 text-black">Investigation</Col>
                    <Col className="col text-center font-size-12 text-black">Result value</Col>
                    <Col className="col text-center font-size-12 text-black">Unit</Col>
                    <Col className="col text-center font-size-12 text-black">Range</Col>
                    <Col className="col text-center font-size-12 text-black">Flag</Col>
                  </Row>
                  <RenderInvestigationRow data={data} />
                  {data?.encounterMeta?.additionalNotes !== '' && (
                    <>
                      {' '}
                      <span className="font-size-12 text-black">Comments:</span>
                      <hr className="solid" />
                      <span className={'pdf-comment-text'}>
                        {data?.encounterMeta?.additionalNotes}
                      </span>
                    </>
                  )}{' '}
                </div>
              )}
            </>
            {data?.investigations &&
              data?.investigations?.panelInvestigation?.length > 0 &&
              data?.investigations?.panelInvestigation?.map(
                (nestedFirst: PanelInvestigationBody, index: number) => (
                  <div key={index}>
                    {/* NESTED FIRST INVESTIGATIONS */}
                    {checkPanelInvestigationsPresent(nestedFirst) && (
                      <div className="px-4 py-1 m-2 pdf-data">
                        <p className="text-center">{nestedFirst.name}</p>
                        <Row xs={12} className={'py-1'}>
                          <Col className="col-4 text-start font-size-12 text-black">
                            Investigation
                          </Col>
                          <Col className="col text-center font-size-12 text-black">
                            Result value
                          </Col>
                          <Col className="col text-center font-size-12 text-black">Unit</Col>
                          <Col className="col text-center font-size-12 text-black">Range</Col>
                          <Col className="col text-center font-size-12 text-black">Flag</Col>
                        </Row>
                        <RenderPanelRow panel={nestedFirst} />
                        {nestedFirst?.encounterMeta?.additionalNotes !== '' && (
                          <>
                            {' '}
                            <span className="font-size-12 text-black">Comments:</span>
                            <hr className="solid" />
                            <span className={'pdf-comment-text'}>
                              {nestedFirst?.encounterMeta?.additionalNotes}
                            </span>
                          </>
                        )}
                      </div>
                    )}

                    {/* NESTED FIRST INVESTIGATIONS */}
                    {nestedFirst?.investigations &&
                      nestedFirst?.investigations?.panelInvestigation?.length > 0 &&
                      nestedFirst?.investigations?.panelInvestigation.map(
                        (nestedSecond: PanelInvestigationBody, nestedSecondIndex: number) => (
                          <div key={nestedSecondIndex}>
                            {checkPanelInvestigationsPresent(nestedSecond) && (
                              <div className="px-4 py-1 m-2 pdf-data">
                                <p className="text-center">{nestedSecond?.name}</p>{' '}
                                <Row xs={12} className={'py-1'}>
                                  <Col className="col-4 text-start font-size-12 text-black">
                                    Investigation
                                  </Col>
                                  <Col className="col text-center font-size-12 text-black">
                                    Result value
                                  </Col>
                                  <Col className="col text-center font-size-12 text-black">
                                    Unit
                                  </Col>
                                  <Col className="col text-center font-size-12 text-black">
                                    Range
                                  </Col>
                                  <Col className="col text-center font-size-12 text-black">
                                    Flag
                                  </Col>
                                </Row>
                                <RenderPanelRow panel={nestedSecond} />
                                {data?.encounterMeta?.additionalNotes !== '' && (
                                  <>
                                    {' '}
                                    <span className="font-size-12 text-black">Comments:</span>
                                    <hr className="solid" />
                                    <span className={'pdf-comment-text'}>
                                      {data?.encounterMeta?.additionalNotes}
                                    </span>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </div>
                )
              )}
          </>
        </tbody>
        <TableFooter />
      </table>
    </div>
  );
};

export default PanelExport;
