import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import Loader from '~/components/Common/Loader';
import CustomInput from '~/components/CustomInput';
import WhiteButton from '~/components/WhiteButton';
import {
  Investigation,
  MuiltiSelectedInvestigations,
  PanelInvestigationBody
} from '~/models/modalsInterfaces.model';
import { PatientDetails } from '~/models/User.model';
import { WithRouterProps } from '~/models/WithRouterProps.model';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { resetGetDoctor } from '~/store/reducers/Doctor';
import { GetDoctors } from '~/store/reducers/Doctor/thunks';
import useWindowSize from '~/utils/hooks/useWindowSize';
import * as Yup from 'yup';
import './NewEncounter.styles.scss';
import DateTimeCalendarInput from '~/components/Common/DateTimeCalendarInput';
import { GetInvestigations, GetPanel } from '~/store/reducers/panel/thunks';
import { resetGetInvestigationState, resetGetPanel } from '~/store/reducers/panel';
import { errorToast, successToast } from '~/utils/toast';
import { useHistory } from 'react-router';
import {
  convertServerDateFormatToSlashDateFormat,
  convertLocalServerDateTimeFormatWithAmPmToUTCServerDateTimeFormat,
  getAgeFromDate
} from '~/utils/dateUtility';
import { addNewEncounter } from '~/store/reducers/encounter/thunks';
import { resetAddEncounter } from '~/store/reducers/encounter';
import { getNumberOfRequests } from '~/utils/utilMethods';
interface PageType {
  patientData: any;
}
const NewEncounter: FC<WithRouterProps> = (props) => {
  const history = useHistory();
  const { location } = props;
  const { height } = useWindowSize();
  const [patientData, setPatientData] = useState<PatientDetails>();
  const dispatch = useAppDispatch();
  const [doctorsList, setDoctorsList] = useState([]);
  const [panelList, setPanelList] = useState<MuiltiSelectedInvestigations[]>([]);
  const [bk1000, setBk1000] = useState<MuiltiSelectedInvestigations[]>([]);
  const [bk200, setBk200] = useState<MuiltiSelectedInvestigations[]>([]);
  const [bk6190, setBk6190] = useState<MuiltiSelectedInvestigations[]>([]);
  const [cbs300, setCBS300] = useState<MuiltiSelectedInvestigations[]>([]);
  const [other, setOther] = useState<MuiltiSelectedInvestigations[]>([]);

  const [checkedBk1000, setCheckedBk1000] = useState<Investigation[]>([]);
  const [checkedBk200, setCheckedBk200] = useState<Investigation[]>([]);
  const [checkedBk6190, setCheckedBk6190] = useState<Investigation[]>([]);
  const [checkedCbs300, setCheckedCbs300] = useState<Investigation[]>([]);
  const [checkedOther, setCheckedOther] = useState<Investigation[]>([]);
  const [checkedPanelInvestigationsList, setCheckedPanelInvestigationsList] = useState<
    PanelInvestigationBody[]
  >([]);

  const {
    investigations,
    loading,
    success,
    error,
    //
    panelLoading,
    panels,
    panelSuccess,
    panelError
  } = useAppSelector((state) => state.PanelManagement);

  const { doctorLoading, doctorSuccess, doctorError, doctors } = useAppSelector(
    (state) => state.DoctorReducer
  );
  const { addedEncounter, isLoadingAddEncounter, errorAddEncounter, successAddEncounter } =
    useAppSelector((state) => state.EncounterReducer);

  useEffect(() => {
    dispatch(GetInvestigations());
    dispatch(GetPanel());
    dispatch(GetDoctors());
  }, []);

  // encounter
  useEffect(() => {
    if (!isLoadingAddEncounter && successAddEncounter && addedEncounter) {
      successToast('Encounter added successfully');
      if (patientData) {
        history.push({
          pathname: `patient/${patientData.id}/encounter/${addedEncounter.id}/details`
        });
      } else {
        errorToast('Error fetching patient data');
      }

      dispatch(resetAddEncounter());
    } else if (!isLoadingAddEncounter && errorAddEncounter) {
      errorToast(errorAddEncounter);

      dispatch(resetAddEncounter());
    }
  }, [isLoadingAddEncounter, successAddEncounter, errorAddEncounter, addedEncounter]);

  useEffect(() => {
    if (!panelLoading && panelSuccess && panels?.length > 0) {
      const pa: MuiltiSelectedInvestigations[] = [];
      panels?.forEach((element: Investigation) => {
        const body: MuiltiSelectedInvestigations = {
          check: false,
          data: element
        };
        pa.push(body);
      });

      setPanelList(pa);
      dispatch(resetGetPanel());
    } else if (!panelLoading && panelError) {
      errorToast(panelError);
      dispatch(resetGetPanel());
    }
  }, [panelError, panelSuccess, panels]);

  useEffect(() => {
    if (!doctorLoading && doctorSuccess && doctors?.length > 0) {
      const array: any = [];
      doctors?.forEach((element: any) => {
        const body = {
          value: `${element.title} ${element.firstName[0].toUpperCase()}. ${element.lastName}`,
          name: `${element.title} ${element.firstName[0].toUpperCase()}. ${element.lastName}`,
          id: element.id
        };
        array.push(body);
      });
      setDoctorsList(array);
      dispatch(resetGetDoctor());
    } else if (!doctorLoading && doctorError) {
      dispatch(resetGetDoctor());
    }
  }, [doctorLoading, doctorSuccess, doctorError, doctors]);

  useEffect(() => {
    if (location?.state) {
      const { patientData } = location?.state as PageType;
      setPatientData(patientData);
    }
  }, [location?.state]);

  useEffect(() => {
    if (success && investigations?.length > 0) {
      const bk1000: MuiltiSelectedInvestigations[] = [];
      const bk200: MuiltiSelectedInvestigations[] = [];
      const cbs300: MuiltiSelectedInvestigations[] = [];
      const other: MuiltiSelectedInvestigations[] = [];
      const bk6190: MuiltiSelectedInvestigations[] = [];

      investigations?.forEach((element: Investigation) => {
        if (element?.medicalDevice?.name === 'BK1000') {
          const body = {
            check: false,
            data: element
          };
          bk1000.push(body);
        } else if (element?.medicalDevice?.name === 'BK200') {
          const body = {
            check: false,
            data: element
          };
          bk200.push(body);
        } else if (element?.medicalDevice?.name === 'CBS300') {
          const body = {
            check: false,
            data: element
          };
          cbs300.push(body);
        } else if (element?.medicalDevice?.name === 'Other') {
          const body = {
            check: false,
            data: element
          };
          other.push(body);
        } else if (element?.medicalDevice?.name === 'BK6190') {
          const body = {
            check: false,
            data: element
          };
          bk6190.push(body);
        }
      });

      // const aa = investigations?.find(
      //   (investigation: any) => investigation?.medicalDevice?.name === 'BK6190'
      // );
      // const body = {
      //   check: false,
      //   data: aa
      // };

      // bk6190.push(body);
      setBk1000(bk1000);
      setBk200(bk200);
      setBk6190(bk6190);
      setCBS300(cbs300);
      setOther(other);
      dispatch(resetGetInvestigationState());
    } else if (error) {
      errorToast(error);
      dispatch(resetGetInvestigationState());
    }
  }, [error, success, investigations]);

  function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      doctorId: '',
      sampleCollectionTime: '',
      panelNotes: '',
      bk1000Notes: '',
      bk200Notes: '',
      bk6190Notes: '',
      cbs3000Notes: '',
      otherNotes: ''
    },
    validationSchema: Yup.object({
      doctorId: Yup.number().required('Please enter doctor name'),
      sampleCollectionTime: Yup.string().required('Please enter collection time'),
      panelNotes: Yup.string(),
      bk1000Notes: Yup.string(),
      bk200Notes: Yup.string(),
      bk6190Notes: Yup.string(),
      cbs3000Notes: Yup.string(),
      otherNotes: Yup.string()
    }),
    onSubmit: (values) => {
      const name = `${patientData?.firstName[0].toUpperCase()}${patientData?.lastName[0].toUpperCase()}`;
      const val = Math.floor(1000 + Math.random() * 9000);
      const enName = `${name}-${val}-${makeid(2)}`;

      //number of requests
      const req = getNumberOfRequests(
        checkedPanelInvestigationsList.length,
        checkedBk1000.length,
        checkedBk200.length,
        //send only request for cbc
        checkedBk6190.length > 0 ? 1 : 0,
        checkedCbs300.length,
        checkedOther.length
      );

      //set status and notes
      checkedPanelInvestigationsList.forEach((panel) => {
        panel.encounterMeta = {
          notes: values.panelNotes,
          status: 0
        };
      });

      checkedBk1000.forEach((investigastion) => {
        investigastion.encounterMeta = {
          notes: values.bk1000Notes,
          status: 0
        };
      });

      checkedBk200.forEach((investigastion) => {
        investigastion.encounterMeta = {
          notes: values.bk200Notes,
          status: 0
        };
      });

      checkedBk6190.forEach((investigastion) => {
        investigastion.encounterMeta = {
          notes: values.bk6190Notes,
          status: 0
        };
      });

      checkedCbs300.forEach((investigastion) => {
        investigastion.encounterMeta = {
          notes: values.cbs3000Notes,
          status: 0
        };
      });

      checkedOther.forEach((investigastion) => {
        investigastion.encounterMeta = {
          notes: values.otherNotes,
          status: 0
        };
      });

      if (checkedOther.length !== 0) {
        //Anion Gap
        if (checkedOther.find((other) => other.id === 37)) {
          //All cbs-300 Investigation
          if (checkedCbs300.length !== 3) {
            errorToast('For Anion Gap, all Electrolytes investigations are mandatory');
            return;
          }
          //Bicarbonate Investigation
          else if (!(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 33))) {
            errorToast('For Anion Gap, Bicarbonate (Biochemistry) Investigation is mandatory');
            return;
          }
        }
        //Corrected Calcium
        if (checkedOther.find((other) => other.id === 38)) {
          //Albumin Investigation
          if (!(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 42))) {
            errorToast('For Corrected Calcium, Albumin (Biochemistry) Investigation is mandatory');
            return;
          }
          //Calcium Investigation
          else if (!(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 46))) {
            errorToast('For Corrected Calcium, Calcium (Biochemistry) Investigation is mandatory');
            return;
          }
        }
        //GFR
        if (
          checkedOther.find((other) => other.id === 39) ||
          checkedOther.find((other) => other.id === 40)
        ) {
          //Serum Creatinine Investigation
          if (!(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 47))) {
            errorToast(
              'For GFR Investigations, Serum Creatinine (Biochemistry) Investigation is mandatory'
            );
            return;
          }
        }
        //Apolipo Ratio
        if (checkedOther.find((other) => other.id === 84)) {
          //Serum Creatinine Investigation
          if (!(checkedBk1000.length !== 0 && checkedBk1000.find((bk1000) => bk1000.id === 63))) {
            errorToast(
              'For Apolipoprotein B : Apolipoprotein A1 ratio, Apolipoprotein A1 (Immunochemistry) Investigation is mandatory'
            );
            return;
          } //Calcium Investigation
          if (!(checkedBk1000.length !== 0 && checkedBk1000.find((bk1000) => bk1000.id === 64))) {
            errorToast(
              'For Apolipoprotein B : Apolipoprotein A1 ratio, Apolipoprotein B (Immunochemistry) Investigation is mandatory'
            );
            return;
          }
        }
      }
      // if (
      //   !isDateTodayOrPast(
      //     convertServerDateTimeFormatWithAmPmToServerDateTimeFormat(values.sampleCollectionTime)
      //   )
      // ) {
      //   errorToast('Sample collection time should not be a future date');
      //   return;
      // }
      if (patientData?.id && req > 0) {
        //set body
        const body = {
          name: enName,
          collectionTime: convertLocalServerDateTimeFormatWithAmPmToUTCServerDateTimeFormat(
            values.sampleCollectionTime
          ),
          doctor: {
            id: parseInt(values.doctorId)
          },
          patient: {
            id: patientData?.id
          },
          status: 0,
          numberOfRequests: req,
          investigations: {
            panelInvestigation: checkedPanelInvestigationsList,
            bk1000: checkedBk1000,
            bk200: checkedBk200,
            bk6190: checkedBk6190,
            cbs300: checkedCbs300,
            other: checkedOther
          }
        };
        dispatch(addNewEncounter(body));
      } else if (req <= 0) {
        errorToast('No investigation selected');
      }
    }
  });

  const setChecked = (
    checked: boolean,
    data: any,
    index: number,
    setCheckedValue: any,
    setCheckedFunction: any
  ) => {
    const tempChecked: any[] = setCheckedValue;
    if (checked) {
      tempChecked.push(data[index].data);
    } else {
      const indexOfObject = tempChecked.findIndex((object) => {
        return object.id === data[index].data?.id;
      });
      tempChecked.splice(indexOfObject, 1);
    }
    setCheckedFunction(tempChecked);
  };

  return (
    <>
      <Form
        className="form-horizontal"
        id="loginForm"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="page-content bg-white" style={{ maxHeight: `${height - 10}px` }}>
          <Container fluid>
            <Row>
              <Col lg="3">
                <h2 className="m-0 align-self-center text-nowrap">
                  <b className="text-nowrap sbl24">New encounter</b>
                </h2>
              </Col>
              <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
              <Col
                lg="4"
                className="text-end text-nowrap d-flex justify-content-end align-items-center"
              >
                <WhiteButton
                  text={'Dismiss'}
                  className="font-weight-bold"
                  HandleClick={() =>
                    history.push({
                      pathname: '/patients'
                    })
                  }
                />
                <BlueButton
                  text={'Create encounter'}
                  loading={isLoadingAddEncounter}
                  disabled={isLoadingAddEncounter}
                  type="submit"
                  width="122px"
                />
              </Col>
            </Row>
            <div className="horizontal-line mt-3 mb-2"></div>
            {patientData && (
              <Row className="mb-3">
                <Col className="d-flex align-items-center pt-2">
                  <Avatar
                    name={`${patientData?.firstName} ${patientData?.lastName}`}
                    maxInitials={2}
                    textSizeRatio={2.2}
                    size="40"
                    color="#2A45CD30"
                    fgColor="#2A45CD"
                    round
                  />
                  <div className="d-flex  flex-column ms-2">
                    <span
                      className="patient-info"
                      style={{
                        fontFamily: 'Helvetica',
                        letterSpacing: '-0.17px',
                        fontSize: '14px',
                        color: '#6B6F7A'
                      }}
                    >
                      {`${patientData?.firstName} ${patientData?.lastName}`}
                    </span>
                    <span
                      className="font-size-12"
                      style={{
                        whiteSpace: 'nowrap',
                        fontFamily: 'Inter',
                        fontWeight: 'medium',
                        letterSpacing: '-0.17px',
                        color: '#6b6f7a'
                      }}
                    >
                      {patientData?.gender === 'M'
                        ? 'Male'
                        : patientData?.gender === 'F'
                        ? 'Female'
                        : patientData?.gender === 'O'
                        ? 'Others'
                        : '-'}
                      · {getAgeFromDate(patientData.dateOfBirth)} ·{' '}
                      {convertServerDateFormatToSlashDateFormat(patientData?.dateOfBirth)}
                    </span>
                  </div>
                </Col>
              </Row>
            )}

            <div className="horizontal-line mt-2 mb-2"></div>
            <Row className="mb-2">
              <Col xs={4}>
                <CustomInput
                  label={'Referral doctor'}
                  labelClass="small-label"
                  type="select"
                  placeholder="Select referral doctor..."
                  name={'doctorId'}
                  value={validation.values.doctorId}
                  options={doctorsList}
                  getIdInValues
                  onChange={validation.handleChange}
                  invalid={validation.touched.doctorId && validation.errors.doctorId ? true : false}
                  errors={validation.errors.doctorId}
                />
              </Col>

              <Col xs={4}>
                <DateTimeCalendarInput
                  label={'Sample collection time'}
                  labelClass="small-label"
                  name={'sampleCollectionTime'}
                  value={validation.values.sampleCollectionTime}
                  onChange={validation.handleChange}
                  invalid={
                    validation.touched.sampleCollectionTime &&
                    validation.errors.sampleCollectionTime
                      ? true
                      : false
                  }
                  errors={validation.errors.sampleCollectionTime}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div
          style={{
            maxHeight: `${height - 170}px`,
            overflowX: 'hidden',
            scrollbarWidth: 'thin',
            marginTop: '-40px'
          }}
        >
          {!panelLoading || !loading ? (
            <>
              <Accordion>
                <AccordionItem
                  height={'46px'}
                  showAccordionEndElement={false}
                  accordionHeaderClasses={'justify-content-between'}
                  AccordionHeader={
                    <>
                      <span className="patient-name ms-2">Panels</span>
                    </>
                  }
                  AccordionBody={
                    panelLoading ? (
                      <div
                        className="d-flex justify-content-center w-100"
                        style={{ minHeight: '50px' }}
                      >
                        <Loader width="20px" height="20px" />
                      </div>
                    ) : (
                      <div className={'px-4'}>
                        <Row>
                          <Label className="small-label investigation-head-label pt-2">
                            Select all that apply (multi-select), add comments to provide more
                            details.
                          </Label>
                        </Row>
                        <Row xs={'1'} sm={'2'} lg={'4'} md={'4'}>
                          {panelList?.length > 0 &&
                            panelList?.map((val: any, index: number) => (
                              <Col key={index}>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    checked={val.check}
                                    onChange={(e) => {
                                      const { checked } = e.target;
                                      const data = JSON.parse(JSON.stringify(panelList));
                                      data[index].check = checked;
                                      setPanelList(data);

                                      setChecked(
                                        checked,
                                        data,
                                        index,
                                        checkedPanelInvestigationsList,
                                        setCheckedPanelInvestigationsList
                                      );
                                    }}
                                  />
                                  <Label check className="investigation-checkbox-label">
                                    {val?.data?.name}
                                  </Label>
                                </FormGroup>
                              </Col>
                            ))}
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label
                                for="requestNotes"
                                className="small-label investigation-head-label mt-3"
                              >
                                Request notes
                              </Label>
                              <Input
                                name="panelNotes"
                                type="text"
                                className="bg-white"
                                value={validation.values.panelNotes}
                                onChange={validation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                />
                <AccordionItem
                  height={'46px'}
                  showAccordionEndElement={false}
                  accordionHeaderClasses={'justify-content-between'}
                  AccordionHeader={
                    <>
                      <span className="patient-name ms-2">Immunochemistry</span>
                    </>
                  }
                  AccordionBody={
                    <div className={'px-4'}>
                      <Row>
                        <Label className="small-label investigation-head-label pt-2">
                          Select all that apply (multi-select), add comments to provide more
                          details.
                        </Label>
                      </Row>
                      <Row xs={'1'} sm={'2'} lg={'4'} md={'4'}>
                        {bk1000?.length > 0 &&
                          bk1000?.map((val: any, index: number) => (
                            <Col key={index}>
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  checked={val.check}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    const data = JSON.parse(JSON.stringify(bk1000));
                                    data[index].check = checked;
                                    setBk1000(data);
                                    setChecked(
                                      checked,
                                      data,
                                      index,
                                      checkedBk1000,
                                      setCheckedBk1000
                                    );
                                  }}
                                />
                                <Label check className="investigation-checkbox-label">
                                  {val?.data?.name}
                                </Label>
                              </FormGroup>
                            </Col>
                          ))}
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="small-label investigation-head-label mt-3">
                              Request Notes
                            </Label>
                            <Input
                              name="bk1000Notes"
                              type="text"
                              className="bg-white"
                              value={validation.values.bk1000Notes}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  }
                />

                <AccordionItem
                  height={'46px'}
                  showAccordionEndElement={false}
                  accordionHeaderClasses={'justify-content-between'}
                  AccordionHeader={
                    <>
                      <span className="patient-name ms-2">Biochemistry</span>
                    </>
                  }
                  AccordionBody={
                    <div className={'px-4'}>
                      <Row>
                        <Label className="small-label investigation-head-label pt-2">
                          Select all that apply (multi-select), add comments to provide more
                          details.
                        </Label>
                      </Row>
                      <Row xs={'1'} sm={'2'} lg={'4'} md={'4'}>
                        {bk200?.length > 0 &&
                          bk200?.map((val: any, index: number) => (
                            <Col key={index}>
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  checked={val.check}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    const data = JSON.parse(JSON.stringify(bk200));
                                    data[index].check = checked;
                                    setBk200(data);
                                    setChecked(checked, data, index, checkedBk200, setCheckedBk200);
                                  }}
                                />
                                <Label check className="investigation-checkbox-label">
                                  {val?.data?.name}
                                </Label>
                              </FormGroup>
                            </Col>
                          ))}
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label
                              for="requestNotes"
                              className="small-label investigation-head-label mt-3"
                            >
                              Request Notes
                            </Label>
                            <Input
                              name="bk200Notes"
                              type="text"
                              className="bg-white"
                              value={validation.values.bk200Notes}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  }
                />

                <AccordionItem
                  height={'46px'}
                  showAccordionEndElement={false}
                  accordionHeaderClasses={'justify-content-between'}
                  AccordionHeader={
                    <>
                      <span className="patient-name ms-2">Haematology</span>
                    </>
                  }
                  AccordionBody={
                    <div className={'px-4'}>
                      <Row>
                        <Label className="small-label investigation-head-label pt-2">
                          Select all that apply (multi-select), add comments to provide more
                          details.
                        </Label>
                      </Row>
                      <Row xs={'1'} sm={'2'} lg={'4'} md={'4'}>
                        {bk6190?.length > 0 && (
                          <Col>
                            <FormGroup check>
                              <Input
                                type="checkbox"
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const data = JSON.parse(JSON.stringify(bk6190));
                                  data.forEach((val: any, index: number) => {
                                    data[index].check = checked;
                                    setBk6190(data);
                                    setChecked(
                                      checked,
                                      data,
                                      index,
                                      checkedBk6190,
                                      setCheckedBk6190
                                    );
                                  });
                                }}
                              />
                              <Label check className="investigation-checkbox-label">
                                {bk6190[0].data?.name}
                              </Label>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label
                              for="requestNotes"
                              className="small-label investigation-head-label mt-3"
                            >
                              Request Notes
                            </Label>
                            <Input
                              name="bk6190Notes"
                              type="text"
                              className="bg-white"
                              value={validation.values.bk6190Notes}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  }
                />
                <AccordionItem
                  height={'46px'}
                  showAccordionEndElement={false}
                  accordionHeaderClasses={'justify-content-between'}
                  AccordionHeader={
                    <>
                      <span className="patient-name ms-2">Electrolytes</span>
                    </>
                  }
                  AccordionBody={
                    <div className={'px-4'}>
                      <Row>
                        <Label className="small-label investigation-head-label pt-2">
                          Select all that apply (multi-select), add comments to provide more
                          details.
                        </Label>
                      </Row>
                      <Row xs={'1'} sm={'2'} lg={'4'} md={'4'}>
                        {cbs300?.length > 0 &&
                          cbs300.map((data: any, index: number) => (
                            <Col key={index}>
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    const data = JSON.parse(JSON.stringify(cbs300));
                                    data[index].check = checked;
                                    setCBS300(data);
                                    setChecked(
                                      checked,
                                      data,
                                      index,
                                      checkedCbs300,
                                      setCheckedCbs300
                                    );
                                  }}
                                />
                                <Label check className="investigation-checkbox-label">
                                  {data?.data?.name}
                                </Label>
                              </FormGroup>
                            </Col>
                          ))}
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label
                              for="requestNotes"
                              className="small-label investigation-head-label mt-3"
                            >
                              Request Notes
                            </Label>
                            <Input
                              name="cbs3000Notes"
                              type="text"
                              className="bg-white"
                              value={validation.values.cbs3000Notes}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  }
                />

                <AccordionItem
                  height={'46px'}
                  showAccordionEndElement={false}
                  accordionHeaderClasses={'justify-content-between'}
                  AccordionHeader={
                    <>
                      <span className="patient-name ms-2">Other</span>
                    </>
                  }
                  AccordionBody={
                    <div className={'px-4'}>
                      <Row>
                        <Label className="small-label investigation-head-label pt-2">
                          Select all that apply (multi-select), add comments to provide more
                          details.
                        </Label>
                      </Row>
                      <Row xs={'1'} sm={'2'} lg={'4'} md={'4'}>
                        {other?.length > 0 &&
                          other?.map((data: any, index: number) => (
                            <Col key={index}>
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    const data = JSON.parse(JSON.stringify(other));
                                    data[index].check = checked;
                                    setOther(data);
                                    setChecked(checked, data, index, checkedOther, setCheckedOther);
                                  }}
                                />
                                <Label check className="investigation-checkbox-label">
                                  {data?.data?.name}
                                </Label>
                              </FormGroup>
                            </Col>
                          ))}
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label
                              for="requestNotes"
                              className="small-label investigation-head-label mt-3"
                            >
                              Request Notes
                            </Label>
                            <Input
                              name="otherNotes"
                              type="text"
                              className="bg-white"
                              value={validation.values.otherNotes}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  }
                />
              </Accordion>
            </>
          ) : (
            <div className="d-flex justify-content-center w-100" style={{ minHeight: '200px' }}>
              <Loader />
            </div>
          )}
        </div>
      </Form>
    </>
  );
};

export default NewEncounter;
