import React, { FC, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import { Accordion, AccordionItem } from '~/components/Accordion';
import BlueButton from '~/components/BlueButton';
import Empty from '~/components/Common/Empty';
import Loader from '~/components/Common/Loader';
import WhiteButton from '~/components/WhiteButton';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import {
  resetArchiveDoctorsState,
  resetGetDoctor,
  resetUnrchiveDoctorsState
} from '~/store/reducers/Doctor';
import { archiveDoctor, GetDoctors, unarchiveDoctor } from '~/store/reducers/Doctor/thunks';
import { DocterBody } from '~/store/reducers/Doctor/types';

import { errorToast, successToast } from '~/utils/toast';
import { ReactComponent as UserAdd } from '../../assets/icons/account-plus-outline.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filterIcon.svg';
import EditDoctorModal from './AddDoctor/EditDoctorModal';

const Doctors: FC = () => {
  const history = useHistory();
  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useAppDispatch();
  const {
    //fetch all doctors
    doctorLoading,
    doctorSuccess,
    doctorError,
    doctors: reduxDoctors,
    //archive Doctor
    isLoadingArchiveDoctor,
    errorArchiveDoctor,
    successArchiveDoctor,

    //unarchive Doctor
    isLoadingUnarchiveDoctor,
    errorUnarchiveDoctor,
    successUnarchiveDoctor
  } = useAppSelector((state) => state.DoctorReducer);

  const [filtersList, setfiltersList] = useState([{ id: 1, title: 'Archived', check: false }]);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tempfiltersList, setTempfiltersList] = useState<any>([]);
  const [doctors, setDoctors] = useState([]);
  const [currentDocter, setCurrentDoctor] = useState({});
  useEffect(() => {
    if (!doctorLoading && doctorError) {
      errorToast(doctorError);
      dispatch(resetGetDoctor());
    }

    if (!doctorLoading && doctorSuccess && reduxDoctors?.length > 0) {
      const data = reduxDoctors?.filter((val: any) => val.deactivated === false);
      setDoctors(data);
      dispatch(resetGetDoctor());
    }
  }, [doctorLoading, doctorSuccess, doctorError, reduxDoctors]);

  useEffect(() => {
    dispatch(GetDoctors());
    window.addEventListener('resize', HandleWidth);
    return () => {
      window.removeEventListener('resize', HandleWidth);
    };
  }, []);
  //unarchive
  useEffect(() => {
    if (!isLoadingUnarchiveDoctor && errorUnarchiveDoctor) {
      errorToast(errorUnarchiveDoctor);
      dispatch(resetUnrchiveDoctorsState());
    }
    if (!isLoadingUnarchiveDoctor && successUnarchiveDoctor) {
      successToast('Doctor unarchived successfully');
      dispatch(resetUnrchiveDoctorsState());
      resetFilters();
      dispatch(GetDoctors());
    }
  }, [
    isLoadingUnarchiveDoctor,
    errorUnarchiveDoctor,
    successUnarchiveDoctor,
    resetUnrchiveDoctorsState
  ]);

  //archive
  useEffect(() => {
    if (!isLoadingArchiveDoctor && errorArchiveDoctor) {
      errorToast(errorArchiveDoctor);
      dispatch(resetArchiveDoctorsState());
    }
    if (!isLoadingArchiveDoctor && successArchiveDoctor) {
      successToast('Doctor archived successfully');
      dispatch(resetArchiveDoctorsState());
      resetFilters();
      dispatch(GetDoctors());
    }
  }, [isLoadingArchiveDoctor, errorArchiveDoctor, successArchiveDoctor, resetArchiveDoctorsState]);

  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };

  const ApplyFilter = () => {
    let arr: any = [];
    const arc = reduxDoctors.filter((data: any) => data.deactivated === true);
    if (filtersList[0].check) {
      arr = arc;
    } else if (!filtersList[0].check) {
      const f = reduxDoctors.filter((data: any) => !data.deactivated);
      arr = arr.concat(f);
    }
    setDoctors(arr);
    setFilterDropDown(!filterDropDown);
  };
  const resetFilters = () => {
    setfiltersList([{ id: 1, title: 'Archived', check: false }]);
  };

  return (
    <>
      <EditDoctorModal
        doctorData={currentDocter}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      />
      <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
        <Container fluid>
          <Row>
            <Col lg="3">
              <h2 className="m-0 align-self-center text-nowrap">
                <b className="text-nowrap sbl24">Doctor management</b>
              </h2>
            </Col>
            <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
            <Col lg="4" className="d-flex justify-content-end text-nowrap">
              <WhiteButton
                Icon={
                  <FilterIcon
                    color={'#2C3242'}
                    style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                  />
                }
                HandleClick={() => {
                  setFilterDropDown(!filterDropDown);
                  setTempfiltersList([...filtersList]);
                }}
                text={'Filters'}
              />
              {filterDropDown && (
                <div
                  style={{
                    background: '#FFFFFF ',
                    boxShadow: '0px 3px 12px #00000029',
                    borderRadius: '8px',
                    position: 'absolute',
                    marginRight: '155px',
                    marginTop: '35px',
                    padding: '10px',
                    zIndex: 4
                  }}
                >
                  {filtersList?.map((item, index) => (
                    <div key={index}>
                      <Input
                        type="checkbox"
                        checked={item.check}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const data = JSON.parse(JSON.stringify(filtersList));
                          data[index].check = checked;
                          setfiltersList(data);
                        }}
                      />
                      <Label className="font-size-14px font-family-inter ms-2">{item?.title}</Label>
                    </div>
                  ))}
                  <div style={{ marginLeft: '-16px' }}>
                    <WhiteButton
                      text="Dismiss"
                      HandleClick={() => {
                        setFilterDropDown(false);
                        setfiltersList(tempfiltersList);
                      }}
                    />
                    <BlueButton text="Apply filters" HandleClick={ApplyFilter} />
                  </div>
                </div>
              )}

              <BlueButton
                Icon={
                  <UserAdd
                    color={'#ffffff'}
                    style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                  />
                }
                HandleClick={() => {
                  history.push({
                    pathname: '/AddDoctor'
                  });
                }}
                width="151px"
                text={'New registration'}
              />
            </Col>
          </Row>
          <div className="horizontal-line mt-3"></div>
          <div
            className="mt-4"
            style={{
              maxHeight: `${height - 170}px`,
              overflowX: 'hidden',
              scrollbarWidth: 'thin'
            }}
          >
            {!doctorLoading ? (
              doctors !== null && doctors.length !== 0 ? (
                doctors.map((doctor: DocterBody) => (
                  <Accordion key={doctor.id}>
                    <AccordionItem
                      dropDownstyle={{
                        right: '175px'
                      }}
                      dropdownData={[
                        { id: 1, title: 'Edit information', color: 'default' },
                        { id: 2, title: 'Email', color: 'default' },
                        doctor.deactivated
                          ? { id: 3, title: 'Unarchive', color: 'danger' }
                          : { id: 3, title: 'Archive', color: 'danger' }
                      ]}
                      onClickDropdownItem={[
                        {
                          id: 1,
                          title: 'Edit information',
                          event: () => {
                            setCurrentDoctor(doctor);
                            setIsOpen(true);
                          }
                        },
                        {
                          id: 2,
                          title: 'Email',
                          event: () => {
                            //TODO
                          }
                        },
                        doctor.deactivated
                          ? {
                              id: 3,
                              title: 'Unarchive',
                              event: () => {
                                dispatch(unarchiveDoctor(doctor.id));
                              }
                            }
                          : {
                              id: 3,
                              title: 'Archive',
                              event: () => {
                                dispatch(archiveDoctor(doctor.id));
                              }
                            }
                      ]}
                      accordionHeaderClasses={'justify-content-between'}
                      AccordionHeader={
                        <>
                          <span className="d-flex align-items-center">
                            <Avatar
                              name={doctor.firstName + ' ' + doctor.lastName}
                              maxInitials={2}
                              textSizeRatio={2.2}
                              size="40"
                              color="#2A45CD30"
                              fgColor="#2A45CD"
                              round
                            />
                            <div className="d-flex flex-column ms-3">
                              <span className="patient-name">
                                {doctor.title + ' ' + doctor.firstName + ' ' + doctor.lastName}
                              </span>
                              <span className="patient-name patient-details">
                                {doctor.clinicName}
                              </span>
                            </div>
                          </span>
                          <div className="d-flex align-items-center me-5">
                            <span className="patient-name patient-details me-2">Encounters</span>
                            <span className="patient-name">{doctor.numberOfEncounter}</span>
                          </div>
                        </>
                      }
                      AccordionBody={
                        <div className="col-md-12 p-3 d-flex">
                          <div className="col-md-4">
                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Full name
                              </Col>
                              <Col className="value-text">
                                {doctor.firstName?.length !== 0
                                  ? doctor.firstName + ' ' + doctor.lastName
                                  : '-'}
                              </Col>
                            </span>

                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Clinic name
                              </Col>
                              <Col className="value-text">{doctor.clinicName ?? '-'}</Col>
                            </span>

                            <span className="d-flex  py-2">
                              <Col className="key-text" sm={3}>
                                Phone
                              </Col>
                              <Col className="value-text">
                                {doctor.phoneNumber?.length !== 0 && doctor.phoneNumber !== null
                                  ? doctor.phoneNumber
                                  : '-'}
                              </Col>
                            </span>
                          </div>
                          <div className="col-md-4">
                            <span className="d-flex  py-2">
                              <Col className="key-text" md={4}>
                                Email
                              </Col>
                              <Col className="value-text">
                                {doctor.email?.length !== 0 ? doctor.email : '-'}
                              </Col>
                            </span>

                            <span className="d-flex py-2">
                              <Col className="key-text" md={4}>
                                Clinic address
                              </Col>
                              <Col className="value-text">{doctor.clinicAddress ?? '-'}</Col>
                            </span>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      }
                    />
                  </Accordion>
                ))
              ) : (
                <div
                  className="d-flex justify-content-center w-100"
                  style={{
                    width: '100%',
                    height: '60vh',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Empty />
                </div>
              )
            ) : (
              <div className="d-flex justify-content-center w-100" style={{ minHeight: '500px' }}>
                <Loader />
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Doctors;
