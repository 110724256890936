// import { useFormik } from 'formik';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import moment from 'moment';

import BlueButton from '~/components/BlueButton';
import CustomInput from '~/components/CustomInput';
import WhiteButton from '~/components/WhiteButton';
import {
  checkDatesFirstWarning,
  checkDatesSecondWarning,
  compareDates,
  isPositiveDecimalInput,
  isPositiveInput
} from '~/utils/utilMethods';
import DatePicker from 'reactstrap-date-picker';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { convertToServerDateFormat, daysToDate } from '~/utils/dateUtility';
import { errorToast, successToast } from '~/utils/toast';
import { AddItems, GetItems } from '~/store/reducers/inventory/thunks';
import { resetAddItems } from '~/store/reducers/inventory';
import MultiSelectInput from '~/components/Common/MultiSelectInput';
import { Investigation, MuiltiSelectedInvestigations } from '~/models/modalsInterfaces.model';
import { resetGetPanel } from '~/store/reducers/panel';
import { INVENTORY_USED_BY } from '~/utils/constants';

interface FilterProps {
  isOpen: boolean;
  toggle: () => void;
  modalType: string;
  itemData: any;
  bk1000: MuiltiSelectedInvestigations[];
  bk200: MuiltiSelectedInvestigations[];
  bk6190: MuiltiSelectedInvestigations[];
  cbs300: MuiltiSelectedInvestigations[];
  other: MuiltiSelectedInvestigations[];
  isInvestigationPresent: any;
}

const AddItemModal: FC<FilterProps> = ({
  isOpen,
  toggle,
  modalType,
  itemData,
  bk1000,
  bk200,
  bk6190,
  cbs300,
  other,
  isInvestigationPresent
}) => {
  const dispatch = useAppDispatch();
  const {
    //add user
    isLoadingAddItem,
    errorAddItem,
    successAddItem
    //update user
  } = useAppSelector((state) => state.InventoryReducer);

  const [tempbk1000, setTempBk1000] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempbk200, setTempBk200] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempbk6190, setTempBk6190] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempcbs300, setTempcbs300] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempOther, setTempOther] = useState<MuiltiSelectedInvestigations[]>([]);

  const [checkedBk1000, setCheckedBk1000] = useState<Investigation[]>([]);
  const [checkedBk200, setCheckedBk200] = useState<Investigation[]>([]);
  const [checkedBk6190, setCheckedBk6190] = useState<Investigation[]>([]);
  const [checkedCbs300, setCheckedCbs300] = useState<Investigation[]>([]);
  const [checkedOther, setCheckedOther] = useState<Investigation[]>([]);

  const [isSubmit, setIsSubmit] = useState(false);
  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    initialValues,
    touched,
    errors,
    setErrors,
    setTouched,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,

    initialValues: {
      // batch:modalType === 'edit' &&itemData?.batch.id,
      department: itemData?.department.id || 1,
      item: '',
      unit: '',
      usagePerTest: '',
      originalStock: '',
      currentStock: '',
      investigation: '',
      expiryDate: '',
      firstWarningDate: '',
      secondWarningDate: '',
      usedBy: ''
    },
    validationSchema: Yup.object({
      item: Yup.string().required('required*'),
      department: Yup.string().required('required*'),
      unit: Yup.string().required('required*'),
      originalStock: Yup.string().required('required*'),
      usagePerTest: Yup.string().required('required*'),
      firstWarningDate: Yup.string().required('required*'),
      expiryDate: Yup.string().required('required*'),
      secondWarningDate: Yup.string().required('required*'),
      usedBy: Yup.string().required('required*')
    }),
    onSubmit: async (values) => {
      const abc: any = await HandleSave();
      const investigation: any = [];
      Object.values(abc).map((data: any) => {
        data.map((a: any) => {
          investigation.push(a.id);
        });
      });

      if (values) {
        //  HandleSave()
        if (
          checkDatesFirstWarning(values.expiryDate, values.firstWarningDate) &&
          checkDatesSecondWarning(values.expiryDate, values.secondWarningDate) &&
          compareDates(values.expiryDate, values.firstWarningDate, values.secondWarningDate)
        ) {
          dispatch(
            AddItems({
              ...values,
              currentStock: values.originalStock,
              department: {
                id: values.department
              },
              investigation,
              firstWarningDate: daysToDate(values.expiryDate, values.firstWarningDate),
              secondWarningDate: daysToDate(values.expiryDate, values.secondWarningDate), //  currentStock?currentStock:values.currentStock,
              // firstWarningDate:res,

              expiryDate: convertToServerDateFormat(values.expiryDate)
            })
          );
        }
      }
    }
  });

  useEffect(() => {
    setTempBk1000(bk1000);
  }, [bk1000]);

  useEffect(() => {
    setTempBk200(bk200);
  }, [bk200]);

  useEffect(() => {
    setTempBk6190(bk6190);
  }, [bk6190]);

  useEffect(() => {
    setTempcbs300(cbs300);
  }, [cbs300]);

  useEffect(() => {
    setTempOther(other);
  }, [other]);

  useEffect(() => {
    if (!isLoadingAddItem && successAddItem) {
      successToast('Item added successfully!');
      dispatch(resetAddItems());
      dispatch(resetGetPanel());
      dispatch(GetItems('All'));
      resetModalStates();
      resetModalStates2();
    }
    if (!isLoadingAddItem && errorAddItem) {
      errorToast(errorAddItem);
      dispatch(resetAddItems());
      dispatch(resetGetPanel());
    }
  }, [isLoadingAddItem, successAddItem, errorAddItem]);
  const HandleSave = async () => {
    if (
      !isInvestigationPresent(
        0,
        checkedBk1000.length,
        checkedBk200.length,
        checkedBk6190.length,
        checkedCbs300.length,
        checkedOther.length
      )
    ) {
      errorToast('No investigation selected');
      return;
    }

    return {
      bk1000: checkedBk1000,
      bk200: checkedBk200,
      bk6190: checkedBk6190,
      cbs300: checkedCbs300,
      other: checkedOther
    };
  };
  const resetModalStates2 = () => {
    setCheckedBk1000([]);
    setCheckedBk200([]);
    setCheckedBk6190([]);
    setCheckedCbs300([]);
    setCheckedOther([]);
    setTempBk6190(bk6190);
    setTempBk1000(bk1000);
    setTempBk200(bk200);
    setTempOther(other);
    setTempcbs300(cbs300);
    toggle();
  };

  const resetModalStates = () => {
    toggle();
    values.department = initialValues.department;
    // values.id = initialValues.id;
    values.item = initialValues.item;
    values.unit = initialValues.unit;
    // values.currentStock?values.currentStock = initialValues.currentStock:"";

    values.expiryDate = initialValues.expiryDate;
    values.originalStock = initialValues.originalStock;
    values.firstWarningDate = initialValues.firstWarningDate;
    values.secondWarningDate = initialValues.secondWarningDate;
    values.usagePerTest = initialValues.usagePerTest;
    values.investigation = initialValues.investigation;
    values.usedBy = initialValues.usedBy;
    // values.batch?values.batch= initialValues.batch:"";
    setErrors({});
    setTouched({});
    setIsSubmit(false);
  };
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop="static"
    >
      {/* {modalType=='add' &&       */}
      <div className="modal-body ms-2">
        <h4
          style={{ marginLeft: '4%' }}
          className="modal-title mt-0 sb font-size-18 "
          id="myLargeModalLabel"
        >
          {modalType === 'add' && 'Add new Item'}
        </h4>
        <button
          onClick={resetModalStates}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Item name*'}
              type="text"
              placeholder="Item name"
              name={'item'}
              value={values.item}
              onChange={(e: string) => {
                handleChange(e);
              }}
              invalid={touched.item && errors.item ? true : false}
              errors={errors.item}
            />
          </div>

          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Department*'}
              type="select"
              name={'department'}
              placeholder=""
              value={values.department.name}
              options={[
                { value: 1, name: 'Lab' },
                { value: 2, name: 'Haematoloy' },
                { value: 3, name: 'Biochem' },
                { value: 4, name: 'Immunochem' },
                {
                  value: 5,
                  name: 'Phlebotomist'
                },
                {
                  value: 6,
                  name: 'Other'
                }
              ]}
              selected={true}
              onChange={handleChange}
              invalid={touched.department && errors.department ? true : false}
              errors={errors.department}
            />
          </div>
        </div>
        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5  " style={{ whiteSpace: 'nowrap' }}>
            <label className="col-md-5 col-form-label im gray">Stocks*</label>

            <div className="d-flex">
              <div className="col-6">
                <CustomInput
                  label={''}
                  type="text"
                  placeholder="Amount"
                  name={'originalStock'}
                  value={values.originalStock}
                  onChange={(e: any) => {
                    if (isPositiveDecimalInput(e)) {
                      handleChange(e);
                    }
                  }}
                  invalid={touched.originalStock && errors.originalStock ? true : false}
                  errors={errors.originalStock}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  label={''}
                  type="select"
                  placeholder="Units"
                  name={'unit'}
                  value={values.unit}
                  options={[
                    { value: 'ml', name: 'ml' },
                    { value: 'item', name: 'item' }
                  ]}
                  onChange={handleChange}
                  invalid={touched.unit && errors.unit ? true : false}
                  errors={errors.unit}
                />
              </div>
            </div>
          </div>

          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            {/* <label className="col-md-5 col-form-label im gray">Stocks*</label> */}
            <div className="d-flex">
              <div className="col-6">
                <CustomInput
                  label={'Used per test*'}
                  type="text"
                  placeholder="Amount"
                  name={'usagePerTest'}
                  value={values.usagePerTest}
                  onChange={(e: any) => {
                    // handleChange(e);
                    if (isPositiveDecimalInput(e)) {
                      handleChange(e);
                    }
                  }}
                  invalid={touched.usagePerTest && errors.usagePerTest ? true : false}
                  errors={errors.usagePerTest}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  label={'Used By*'}
                  type="select"
                  placeholder="Used by"
                  name={'usedBy'}
                  value={values.usedBy}
                  options={[
                    { value: INVENTORY_USED_BY.INVESTIGATION, name: 'Investigation' },
                    { value: INVENTORY_USED_BY.ENCOUNTER, name: 'Encounter' }
                  ]}
                  onChange={handleChange}
                  invalid={touched.usedBy && errors.usedBy ? true : false}
                  errors={errors.usedBy}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="filtersBox mt-2 mb-3 justify-content-around">
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <label className="col-md-5 col-form-label im gray">Expiry Date* </label>
            <DatePicker
              id="expiryDate"
              dateFormat="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              showClearButton={false}
              minDate={moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ')}
              // minDate={new Date()}

              name={'expiryDate'}
              value={values.expiryDate || ''}
              onBlur={handleBlur}
              invalid={touched.expiryDate && errors.expiryDate ? true : false}
              style={
                isSubmit && touched.expiryDate && errors.expiryDate
                  ? { border: '1px #f46a6a solid', borderRadius: '0.375rem' }
                  : { borderRadius: '0.375rem' }
              }
              onChange={(e: string) => setFieldValue('expiryDate', e)}
            />
            {isSubmit && touched.expiryDate && errors.expiryDate ? (
              <span className="text-danger font-size-14">
                {errors.expiryDate === 'required*' ? 'required*' : 'Invalid Date'}
              </span>
            ) : null}
          </div>

          <div className="col-md-5  " style={{ whiteSpace: 'nowrap' }}>
            <label className="col-md-5 col-form-label im gray">Stock warning (days)*</label>

            <div className="d-flex">
              <div className="col-6">
                <CustomInput
                  label={''}
                  type="text"
                  placeholder="60(1st Warning)"
                  name={'firstWarningDate'}
                  value={
                    values.firstWarningDate || '' //  ''
                  }
                  onChange={(e: string) => {
                    if (isPositiveInput(e)) {
                      // checkDates
                      handleChange(e);
                    }
                  }}
                  invalid={touched.firstWarningDate && errors.firstWarningDate ? true : false}
                  errors={errors.firstWarningDate}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  label={''}
                  type="text"
                  placeholder="30(2nd warning)"
                  name={'secondWarningDate'}
                  value={values.secondWarningDate || ''}
                  onChange={(e: string) => {
                    // handleChange(e);
                    if (isPositiveInput(e)) {
                      handleChange(e);
                    }
                  }}
                  invalid={touched.secondWarningDate && errors.secondWarningDate ? true : false}
                  errors={errors.secondWarningDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="filtersBox filtersBox mt-2 justify-content-around">
          <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
            <MultiSelectInput
              label="Immunochemistry"
              errors={'Select investigations'}
              placeholder="Select investigations"
              options={tempbk1000}
              setOptions={setTempBk1000}
              setChecked={setCheckedBk1000}
              checkedOptions={checkedBk1000}
              modalType={modalType}
            />
          </div>
          <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
            <MultiSelectInput
              label="Biochemistry"
              placeholder="Select investigations"
              options={tempbk200}
              setOptions={setTempBk200}
              setChecked={setCheckedBk200}
              checkedOptions={checkedBk200}
              modalType={modalType}
            />
          </div>
        </div>
        <div className="filtersBox filtersBox mt-2 justify-content-around">
          <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
            <MultiSelectInput
              label="Haematology"
              placeholder="Select investigations"
              options={tempbk6190}
              setOptions={setTempBk6190}
              setChecked={setCheckedBk6190}
              checkedOptions={checkedBk6190}
              modalType={modalType}
              screen="inventory"
            />
          </div>
          <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
            <MultiSelectInput
              label="Electrolytes"
              placeholder="Select investigations"
              options={tempcbs300}
              setOptions={setTempcbs300}
              setChecked={setCheckedCbs300}
              checkedOptions={checkedCbs300}
              modalType={modalType}
            />
          </div>
        </div>
        <div className="filtersBox filtersBox mt-2 justify-content-around">
          <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
            <MultiSelectInput
              label="Other"
              placeholder="Select investigations"
              options={tempOther}
              setOptions={setTempOther}
              setChecked={setCheckedOther}
              checkedOptions={checkedOther}
              modalType={modalType}
            />
          </div>
          <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}></div>
        </div>
      </div>

      <div className="modal-footer px-3 mt-2 pt-4 ">
        <WhiteButton text="Dismiss" HandleClick={resetModalStates} />

        <div className="ms-auto">
          <BlueButton
            text={'+ Add Item'}
            loading={isLoadingAddItem}
            disabled={isLoadingAddItem}
            width={modalType === 'add' ? '109px' : '120px'}
            HandleClick={() => {
              setIsSubmit(true);
              handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
export default AddItemModal;
