import React, { FC, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
// Import menuDropdown
import NotificationDropdown from '../Common/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../Common/TopbarDropdown/ProfileMenu';
// import images
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import logo from '../../assets/icons/Logo.svg';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { searchBarDataType } from '~/store/reducers/PureRedux/types';
import { setSecondaryBarFalse } from '~/store/reducers/sideBar';

const Header: FC = () => {
  const location = useLocation();
  const [search, setSearch] = useState<boolean>(false);
  const { showSecondaryBar, collapsed } = useAppSelector((state) => state.sideBarReducer);
  const { searchBarList } = useAppSelector((state) => state.PureRedux);
  const [selectedItem, setSelectedItem] = useState<searchBarDataType>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleSearch = () => {
    if (selectedItem) {
      history.push(selectedItem.link);
      if (!selectedItem.link.includes('user' || 'investigation' || 'panel'))
        dispatch(setSecondaryBarFalse());
    }
  };

  const formatResult = (item: searchBarDataType) => {
    return (
      <>
        {/* <span
          style={{
            textAlign: 'left',
            borderRadius: '10px',
            backgroundColor: '#2A45CD',
            marginRight: '10px'
          }}
          className="text-white p-3"
        >
          {item.type}
        </span> */}
        <span style={{ textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };
  const handleOnSelect = (item: searchBarDataType) => {
    // the item selected
    setSelectedItem(item);
  };

  return (
    <React.Fragment>
      <header
        className={
          !collapsed && !showSecondaryBar
            ? 'page-topbar-left-margin'
            : collapsed && !showSecondaryBar
            ? 'page-topbar-collapsed tansition'
            : collapsed && showSecondaryBar
            ? 'page-topbar-right-margin'
            : !collapsed && showSecondaryBar
            ? 'page-topbar-full  '
            : ''
        }
        id="page-topbar"
      >
        <div className="navbar-header">
          <div className="d-flex flex-fill">
            {!showSecondaryBar && (
              <div className="navbar-brand-box d-lg-none d-md-block">
                <Link to="/">
                  <div className="brand-box-content">
                    <img src={logo} alt="" className="logo-size" />
                    <span
                      className="font-size-16 mt-1 ms-1 sbl"
                      style={{ fontWeight: 'bold', color: 'rgb(73, 80, 87)' }}
                    >
                      EXPAND HEALTH
                    </span>
                  </div>
                </Link>
              </div>
            )}
            {showSecondaryBar && (
              <div className="navbar-brand-box d-lg-none d-md-block ms-0">
                <Link to="/" className="">
                  <div className="brand-box-content">
                    <img src={logo} alt="" className="logo-size" />
                    {location.pathname !== '/dashboard' && collapsed ? (
                      <span></span>
                    ) : (
                      <span
                        className="font-size-16 mt-1 ms-1 sbl"
                        style={{ fontWeight: 'bold', color: 'rgb(73, 80, 87)' }}
                      >
                        EXPAND HEALTH
                      </span>
                    )}
                  </div>
                </Link>
              </div>
            )}
            <div className=" d-none d-lg-block ps-4 w-100 me-5">
              <div id="searchbar" className={searchBarList.length !== 0 ? '' : 'disable-pointer'}>
                <ReactSearchAutocomplete
                  placeholder={searchBarList.length !== 0 ? 'Search' : 'Loading data...'}
                  items={searchBarList}
                  onSearch={handleSearch}
                  onSelect={handleOnSelect}
                  autoFocus
                  formatResult={formatResult}
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center ">
            <div className="dropdown d-inline-block d-lg-none mt-2 ms-2">
              <button
                onClick={() => {
                  setSearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="bx bx-search-alt-2" />
              </button>
              <div
                className={
                  search
                    ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                    : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form
                  className={searchBarList.length !== 0 ? 'p-3' : 'disable-pointer p-3'}
                  id="searchbar"
                >
                  {/* <div className="form-group m-0">
                      <div className="input-group"> */}
                  <ReactSearchAutocomplete
                    placeholder={searchBarList.length !== 0 ? 'Search' : 'Loading data...'}
                    items={searchBarList}
                    onSearch={handleSearch}
                    onSelect={handleOnSelect}
                    autoFocus
                    formatResult={formatResult}
                  />
                  {/* </div>
                    </div> */}
                </form>
              </div>
            </div>
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
