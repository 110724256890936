import React from 'react';
import { Container } from 'reactstrap';
import useWindowSize from '~/utils/hooks/useWindowSize';
import AddDoctorForm from './AddDoctor';

const AddDoctor = () => {
  const { height } = useWindowSize();

  return (
    <div
      className="page-content ms-2"
      style={{
        minHeight: `${height - 10}px`,
        background: '#ffffff'
      }}
    >
      <Container fluid>
        <AddDoctorForm />
      </Container>
    </div>
  );
};

export default AddDoctor;
