import { createSlice } from '@reduxjs/toolkit';

import { InitialState } from './types';
const initialState: InitialState = {
  searchBarList: []
};
const PureReduxSlice = createSlice({
  name: 'pureRedux',
  initialState,
  reducers: {
    setSearchBarList: (state, action) => {
      state.searchBarList = action.payload;
    }
  }
});
export const { setSearchBarList } = PureReduxSlice.actions;
export default PureReduxSlice.reducer;
