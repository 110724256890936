import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BlueButton from '~/components/BlueButton';
import { ReactComponent as AddIcon } from '../../assets/icons/add-circle.svg';
import { setSecondaryBarTrue, toggle } from '~/store/reducers/sideBar';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import AddPanelModal from '~/components/Common/Modals/AddPanelModal';
import { Accordion, AccordionItem } from '~/components/Accordion';
import { DeletePanel, GetInvestigations, GetPanel } from '~/store/reducers/panel/thunks';
import {
  resetDeletePanel,
  resetGetInvestigationState,
  resetGetPanel
} from '~/store/reducers/panel';
import { errorToast, successToast } from '~/utils/toast';
import {
  GetPanelInvestigation,
  Investigation,
  MuiltiSelectedInvestigations
} from '~/models/modalsInterfaces.model';
import Loader from '~/components/Common/Loader';
import Empty from '~/components/Common/Empty';

const PanelManagement = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [addOrEditModal, setAddOrEditModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('add');

  const dispatch = useAppDispatch();
  const { collapsed } = useAppSelector((state) => state.sideBarReducer);

  const {
    success,
    error,
    investigations,
    panelLoading,
    panelSuccess,
    panelError,
    panels,
    isLoadingDeletePanel,
    errorDeletePanel,
    successDeletePanel
  } = useAppSelector((state) => state.PanelManagement);
  const [panelName, setPanelName] = useState<any>('');
  const [panelId, setPanelId] = useState<any>();
  const [bk1000, setBk1000] = useState<MuiltiSelectedInvestigations[]>([]);
  const [bk200, setBk200] = useState<MuiltiSelectedInvestigations[]>([]);
  const [bk6190, setBk6190] = useState<MuiltiSelectedInvestigations[]>([]);
  const [cbs300, setCBS300] = useState<MuiltiSelectedInvestigations[]>([]);
  const [other, setOther] = useState<MuiltiSelectedInvestigations[]>([]);
  const [panelInvestigationsList, setPanelInvestigationsList] = useState<
    MuiltiSelectedInvestigations[]
  >([]);
  const [selectedPanel, setSelectedPanel] = useState<GetPanelInvestigation>();
  useEffect(() => {
    dispatch(GetInvestigations());
    dispatch(GetPanel());
    window.addEventListener('resize', HandleWidth);
    return () => window.removeEventListener('resize', HandleWidth);
  }, []);

  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    const body: HTMLElement = document.body;
    dispatch(setSecondaryBarTrue());
    if (!collapsed) {
      dispatch(toggle(true));
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }, []);

  //GET ALL INVESTIGATIONS
  useEffect(() => {
    if (success && investigations?.length > 0) {
      dispatch(resetGetInvestigationState());
    } else if (error) {
      errorToast(error);
      dispatch(resetGetInvestigationState());
    }
  }, [error, success, investigations]);

  //GET ALL PANELS
  useEffect(() => {
    if (!panelLoading && panelSuccess && panels?.length > 0) {
      dispatch(resetGetPanel());
    } else if (!panelLoading && panelError) {
      errorToast(panelError);
      dispatch(resetGetPanel());
    }
  }, [panelError, panelSuccess, panels]);

  //SET INVESTIGATIONS FOR ADD PANEL
  useEffect(() => {
    if (modalType === 'add') {
      if (investigations?.length > 0) {
        const bk1000: MuiltiSelectedInvestigations[] = [];
        const bk200: MuiltiSelectedInvestigations[] = [];
        const cbs300: MuiltiSelectedInvestigations[] = [];
        const other: MuiltiSelectedInvestigations[] = [];
        const bk6190: MuiltiSelectedInvestigations[] = [];

        investigations?.forEach((element: Investigation) => {
          if (element?.medicalDevice?.name === 'BK1000') {
            const body = {
              check: false,
              data: element
            };
            bk1000.push(body);
          } else if (element?.medicalDevice?.name === 'BK200') {
            const body = {
              check: false,
              data: element
            };
            bk200.push(body);
          } else if (element?.medicalDevice?.name === 'CBS300') {
            const body = {
              check: false,
              data: element
            };
            cbs300.push(body);
          } else if (element?.medicalDevice?.name === 'Other') {
            const body = {
              check: false,
              data: element
            };
            other.push(body);
          } else if (element?.medicalDevice?.name === 'BK6190') {
            const body = {
              check: false,
              data: element
            };
            bk6190.push(body);
          }
        });
        setBk1000(bk1000);
        setBk200(bk200);
        setBk6190(bk6190);
        setCBS300(cbs300);
        setOther(other);
      }
    }
  }, [investigations, modalType]);

  //SET INVESTIGATIONS FOR EDIT PANEL
  useEffect(() => {
    if (modalType === 'edit' && addOrEditModal) {
      setPanelName(selectedPanel?.name);
      setPanelId(selectedPanel?.id);
      if (investigations?.length > 0) {
        const bk1000: MuiltiSelectedInvestigations[] = [];
        const bk200: MuiltiSelectedInvestigations[] = [];
        const cbs300: MuiltiSelectedInvestigations[] = [];
        const other: MuiltiSelectedInvestigations[] = [];
        const bk6190: MuiltiSelectedInvestigations[] = [];
        const panelInvestigationsList: MuiltiSelectedInvestigations[] = [];

        investigations?.forEach((element: Investigation) => {
          if (element?.medicalDevice?.name === 'BK1000') {
            let body;
            if (
              selectedPanel?.investigations?.bk1000.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            bk1000.push(body);
          } else if (element?.medicalDevice?.name === 'BK200') {
            let body;
            if (
              selectedPanel?.investigations?.bk200.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            bk200.push(body);
          } else if (element?.medicalDevice?.name === 'CBS300') {
            let body;
            if (
              selectedPanel?.investigations?.cbs300.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }

            cbs300.push(body);
          } else if (element?.medicalDevice?.name === 'Other') {
            let body;
            if (
              selectedPanel?.investigations?.other.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }

            other.push(body);
          } else if (element?.medicalDevice?.name === 'BK6190') {
            let body;
            if (
              selectedPanel?.investigations?.bk6190.find(
                (investigation) => element.id === investigation.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            bk6190.push(body);
          }
        });
        panels?.forEach((element: Investigation) => {
          if (element.name !== selectedPanel?.name) {
            let body;
            if (
              selectedPanel?.investigations?.panelInvestigation.find(
                (panel) => element.id === panel.id
              )
            ) {
              body = {
                check: true,
                data: element
              };
            } else {
              body = {
                check: false,
                data: element
              };
            }
            panelInvestigationsList.push(body);
          }
        });

        setPanelInvestigationsList(panelInvestigationsList);
        setBk1000(bk1000);
        setBk200(bk200);
        setBk6190(bk6190);
        setCBS300(cbs300);
        setOther(other);
      }
    }
  }, [modalType, addOrEditModal]);

  //DELETE PANEL
  useEffect(() => {
    if (!isLoadingDeletePanel && successDeletePanel) {
      successToast('Panel deleted successfully');
      dispatch(GetInvestigations());
      dispatch(GetPanel());
      dispatch(resetDeletePanel());
    } else if (!isLoadingDeletePanel && errorDeletePanel) {
      errorToast(errorDeletePanel);
      dispatch(resetDeletePanel());
    }
  }, [errorDeletePanel, successDeletePanel, isLoadingDeletePanel]);

  //SET PANEL INVESTIGATIONS
  useEffect(() => {
    if (modalType === 'add') {
      if (panels?.length > 0) {
        const panelInvestigationsList: MuiltiSelectedInvestigations[] = [];

        panels?.forEach((element: Investigation) => {
          const body = {
            check: false,
            data: element
          };
          panelInvestigationsList.push(body);
        });
        setPanelInvestigationsList(panelInvestigationsList);
      }
    }
  }, [panels, modalType]);

  const isInvestigationPresent = (
    panelInvestigation: number,
    bk1000: number,
    bk200: number,
    bk6190: number,
    cbs300: number,
    other: number
  ) => {
    const length = panelInvestigation + bk1000 + bk200 + bk6190 + cbs300 + other;
    if (length > 0) return true;
    return false;
  };

  return (
    <>
      <AddPanelModal
        isOpen={addOrEditModal}
        toggle={() => setAddOrEditModal(!addOrEditModal)}
        bk1000={bk1000}
        bk200={bk200}
        bk6190={bk6190}
        cbs300={cbs300}
        other={other}
        panelInvestigationsList={panelInvestigationsList}
        panelSuccess={panelSuccess}
        panelError={panelError}
        panelLoading={panelLoading}
        modalType={modalType}
        panelName={panelName}
        panelId={panelId}
        isInvestigationPresent={isInvestigationPresent}
      />
      <div className="page-content bg-white" style={{ maxHeight: `${height - 10}px` }}>
        <Container fluid>
          <Row>
            <Col lg="3">
              <h2 className="m-0 align-self-center text-nowrap">
                <b className="text-nowrap sbl24">Panel management</b>
              </h2>
            </Col>
            <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
            <Col lg="4" className="text-end text-nowrap">
              <BlueButton
                HandleClick={() => {
                  setModalType('add');
                  setAddOrEditModal(true);
                }}
                Icon={
                  <AddIcon
                    color={'#ffffff'}
                    style={{ height: '16px', marginBottom: '4px', marginRight: '3px' }}
                  />
                }
                width="109px"
                disabled={isLoadingDeletePanel}
                text={'Add Panel'}
              />
            </Col>
          </Row>
          <div className="horizontal-line mt-3"></div>
          <div
            style={{
              maxHeight: `${height - 170}px`,
              overflowX: 'hidden',
              scrollbarWidth: 'thin'
            }}
          >
            <div className="pt-3">
              <span className="sbold mb-2 font-size-18px">Lab panels</span>
              {panels.length !== 0 ? (
                <h6
                  className={panelLoading ? `d-none menu-title-2 mt-2` : `d-flex menu-title-2 mt-2`}
                >
                  {!panelLoading && !isLoadingDeletePanel && 'Panel name'}
                </h6>
              ) : (
                !panelLoading &&
                !isLoadingDeletePanel && (
                  <div
                    className="d-flex justify-content-center w-100"
                    style={{
                      width: '100%',
                      height: '60vh',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Empty />
                  </div>
                )
              )}
            </div>
          </div>
        </Container>
      </div>
      {!panelLoading && !isLoadingDeletePanel ? (
        panels?.length !== 0 && (
          <div
            style={{
              maxHeight: `${height - 230}px`,
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              marginTop: '-40px'
            }}
          >
            <Accordion>
              {panels.map((panel: GetPanelInvestigation) => (
                <AccordionItem
                  dropDownstyle={{
                    right: '170px'
                  }}
                  height={'40px'}
                  key={panel.id}
                  dropdownData={[
                    { id: 1, title: 'Edit panel details', color: 'default' },
                    { id: 2, title: 'Remove panel', color: 'default' }
                  ]}
                  onClickDropdownItem={[
                    {
                      id: 1,
                      title: 'Edit panel details',
                      event: () => {
                        setModalType('edit');
                        setAddOrEditModal(true);
                        setSelectedPanel(panel);
                      }
                    },
                    {
                      id: 2,
                      title: 'Remove panel',
                      event: () => {
                        dispatch(DeletePanel(panel.id));
                      }
                    }
                  ]}
                  AccordionHeader={<Col className="patient-name">{panel.name}</Col>}
                  accordionBodyClasses={'p-3'}
                  AccordionBody={
                    <>
                      {isInvestigationPresent(
                        panel?.investigations?.panelInvestigation?.length,
                        panel?.investigations?.bk1000?.length,
                        panel?.investigations?.bk200?.length,
                        panel?.investigations?.bk6190?.length,
                        panel?.investigations?.cbs300?.length,
                        panel?.investigations?.other?.length
                      ) ? (
                        <Row xs={12} className={'px-4 py-1'}>
                          <Col className="col-6 text-start key-text">Investigation</Col>
                          <Col className="col-3 text-start key-text">Unit</Col>
                          <Col className="col-3 text-start key-text">Range</Col>
                        </Row>
                      ) : (
                        <Row xs={12} className={'px-4 py-1 text-start key-text'}>
                          <Empty message="No investigations present" />
                        </Row>
                      )}
                      {panel?.investigations?.panelInvestigation?.map((panelInvestigation) => (
                        <Row
                          xs={12}
                          key={panelInvestigation.id}
                          className={'border  bg-white mx-2 py-3'}
                        >
                          <Col className="col-6 text-start value-text">
                            {panelInvestigation.name}
                          </Col>
                          <Col className="col-3 text-start value-text">N/A</Col>
                          <Col className="col-3 text-start value-text">N/A</Col>
                        </Row>
                      ))}
                      {panel?.investigations?.bk1000?.map((bk1000) => (
                        <Row xs={12} key={bk1000.id} className={'border  bg-white mx-2 py-3'}>
                          <Col className="col-6 text-start value-text">{bk1000.name}</Col>
                          <Col className="col-3 text-start value-text">{bk1000.unit}</Col>
                          <Col className="col-3 text-start value-text">{bk1000.maleRange}</Col>
                        </Row>
                      ))}
                      {panel?.investigations?.bk200?.map((bk200) => (
                        <Row xs={12} key={bk200.id} className={'border  bg-white mx-2 py-3'}>
                          <Col className="col-6 text-start value-text">{bk200.name}</Col>
                          <Col className="col-3 text-start value-text">{bk200.unit}</Col>
                          <Col className="col-3 text-start value-text">{bk200.maleRange}</Col>
                        </Row>
                      ))}
                      {panel?.investigations?.bk6190?.map((bk6190) => (
                        <Row xs={12} key={bk6190.id} className={'border  bg-white mx-2 py-3'}>
                          <Col className="col-6 text-start value-text">{bk6190.parameter}</Col>
                          <Col className="col-3 text-start value-text">{bk6190.unit}</Col>
                          <Col className="col-3 text-start value-text">{bk6190.maleRange}</Col>
                        </Row>
                      ))}
                      {panel?.investigations?.cbs300?.map((cbs300) => (
                        <Row xs={12} key={cbs300.id} className={'border  bg-white mx-2 py-3'}>
                          <Col className="col-6 text-start value-text">{cbs300.name}</Col>
                          <Col className="col-3 text-start value-text">{cbs300.unit}</Col>
                          <Col className="col-3 text-start value-text">{cbs300.maleRange}</Col>
                        </Row>
                      ))}
                      {panel?.investigations?.other?.map((other) => (
                        <Row xs={12} key={other.id} className={'border  bg-white mx-2 py-3'}>
                          <Col className="col-6 text-start value-text">{other.name}</Col>
                          <Col className="col-3 text-start value-text">{other.unit}</Col>
                          <Col className="col-3 text-start value-text">{other.maleRange}</Col>
                        </Row>
                      ))}
                    </>
                  }
                />
              ))}
            </Accordion>
          </div>
        )
      ) : (
        <div className="d-flex justify-content-center w-100" style={{ minHeight: '200px' }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default PanelManagement;
