import React, { FC, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import Avatar from 'react-avatar';
import { useAppDispatch } from '~/store/hooks';
import { clearState } from '~/store/reducers/AuthSlice';
import Async from '~/utils/Async';
import { getRole } from '~/utils/utilMethods';

const ProfileMenu: FC = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const LogOut = (): void => {
    localStorage.clear();
    dispatch(clearState());
    const body: HTMLElement = document.body;
    const check = body.classList.contains('vertical-collpsed');

    if (check) {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <div className="d-inline-block align-self-center ms-2">
            <Row>
              <Col>
                <Avatar
                  name={`${Async.getItem('@auth')?.firstName} ${Async.getItem('@auth')?.lastName}`}
                  maxInitials={2}
                  textSizeRatio={2.2}
                  size="40"
                  color="#2A45CD30"
                  fgColor="#2A45CD"
                  round
                />
              </Col>
              <Col className="ps-0 m-0 me-5" style={{ textAlign: 'left', verticalAlign: 'super' }}>
                <div className="d-flex flex-row">
                  <span
                    className="font-size-14px"
                    style={{
                      fontFamily: 'Satoshi-Bold',
                      letterSpacing: '-0.17px',
                      color: '#2C3242',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {`${Async.getItem('@auth')?.firstName} ${Async.getItem('@auth')?.lastName}`}
                  </span>
                </div>

                <p
                  className="m-0 p-0 font-size-12"
                  style={{
                    whiteSpace: 'nowrap',
                    fontFamily: 'Inter',
                    fontWeight: 'medium',
                    letterSpacing: '-0.17px',
                    fontSize: '12px',
                    color: '#2C3242'
                  }}
                >
                  {getRole(Async.getItem('@auth')?.role)}
                  <i className="bx bx-chevron-down d-inline-block ms-2 my-auto" />
                </p>
              </Col>
            </Row>
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end"
          style={{
            boxShadow: '0px 3px 12px #00000029',
            borderRadius: '8px',
            background: '#fffff',
            border: '1px transparent'
          }}
        >
          <div
            className="horizontal-line mt-1 mb-1"
            style={{ width: '140px', marginLeft: '10px' }}
          ></div>

          <Link to="/logout" onClick={LogOut} className="dropdown-item">
            <i
              className="bx bx-power-off font-size-18 align-middle me-2 "
              style={{ color: 'rgb(100 100 100)' }}
            />
            <span className="im font-size-14">{'Sign out'}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(ProfileMenu) as any;
