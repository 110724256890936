import React from 'react';
import { Spinner } from 'reactstrap';

interface LoaderProps {
  color?: string;
  width?: string;
  height?: string;
  animation?: string;
  variant?: string;
  alignSelf?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { color, width, height, animation, variant, alignSelf } = props;
  return (
    <Spinner
      style={{
        color: color,
        alignSelf: alignSelf,
        width: width,
        height: height
      }}
      animation={animation}
      variant={variant}
    />
  );
};
Loader.defaultProps = {
  color: '#2a45cd',
  width: '70px',
  height: '70px',
  animation: 'border',
  variant: 'primary',
  alignSelf: 'center'
};
export default Loader;
