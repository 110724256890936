import { createSlice } from '@reduxjs/toolkit';
import { User } from '~/models/User.model';
import { SignInUser } from './thunks';

const initialState: User = {
  loading: false,
  error: '',
  token: ''
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(SignInUser.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(SignInUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.token = payload.token;
      })
      .addCase(SignInUser.rejected, (state, action) => {
        state.loading = false;
        if (action.error) {
          state.error = action.error.message;
        } else {
          state.error = 'Wrong username, password';
        }
      });
  },
  reducers: {
    clearState(state) {
      state.loading = false;
      state.error = '';
      state.token = '';
    }
  }
});

// // Action creators are generated for each case reducer function
export const { clearState } = authSlice.actions;
export default authSlice.reducer;
