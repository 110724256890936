import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import moment from 'moment';
import { INVENTORY_USED_BY } from '~/utils/constants';
import BlueButton from '~/components/BlueButton';
import CustomInput from '~/components/CustomInput';
import WhiteButton from '~/components/WhiteButton';
import {
  checkDatesFirstWarning,
  checkDatesSecondWarning,
  compareDates,
  isPositiveDecimalInput,
  isPositiveInput
} from '~/utils/utilMethods';
import DatePicker from 'reactstrap-date-picker';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import {
  convertServerDateFormatToDateFormat,
  convertToServerDateFormat,
  daysToDate1,
  differenceInDays1,
  getCurrentDateInIsoFormat
} from '~/utils/dateUtility';
import { errorToast, successToast } from '~/utils/toast';
import { DeleteBatch, GetItems, UpdateItems } from '~/store/reducers/inventory/thunks';
import { resetDeletedBatch, resetUpdateItems } from '~/store/reducers/inventory';
import { Investigation, MuiltiSelectedInvestigations } from '~/models/modalsInterfaces.model';
import MultiSelectInput from '~/components/Common/MultiSelectInput';
import Async from '~/utils/Async';
import Loader from '~/components/Common/Loader';

interface FilterProps {
  isOpen: boolean;
  toggle: () => void;
  modalType: string;
  itemData: any;
  bk1000: MuiltiSelectedInvestigations[];
  bk200: MuiltiSelectedInvestigations[];
  bk6190: MuiltiSelectedInvestigations[];
  cbs300: MuiltiSelectedInvestigations[];
  other: MuiltiSelectedInvestigations[];
  isInvestigationPresent: any;
}

const UpdateItemModal: FC<FilterProps> = ({
  isOpen,
  toggle,
  modalType,
  itemData,
  bk1000,
  bk200,
  bk6190,
  cbs300,
  other,
  isInvestigationPresent
}) => {
  const dispatch = useAppDispatch();
  const {
    //update item
    isLoadingUpdateItem,
    errorUpdateItem,
    successUpdateItem,
    isLoadingDeleteBatch,
    errorDeleteBatch,
    successDeleteBatch
  } = useAppSelector((state) => state.InventoryReducer);
  const [tempbk1000, setTempBk1000] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempbk200, setTempBk200] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempbk6190, setTempBk6190] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempcbs300, setTempcbs300] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempOther, setTempOther] = useState<MuiltiSelectedInvestigations[]>([]);

  const [checkedBk1000, setCheckedBk1000] = useState<Investigation[]>([]);
  const [checkedBk200, setCheckedBk200] = useState<Investigation[]>([]);
  const [checkedBk6190, setCheckedBk6190] = useState<Investigation[]>([]);
  const [checkedCbs300, setCheckedCbs300] = useState<Investigation[]>([]);
  const [checkedOther, setCheckedOther] = useState<Investigation[]>([]);

  const [isSubmit, setIsSubmit] = useState(false);
  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    initialValues,
    touched,
    errors,
    setErrors,
    setTouched,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,

    initialValues: {
      batch: [
        ...itemData.batch,
        {
          ...itemData?.batch[0],
          id: '',
          name: '',
          expiryDate: '',
          stock: '',
          unit: '',
          firstWarningDate: moment().format('DD-MM-YYYY'),
          secondWarningDate: moment().format('DD-MM-YYYY')
        }
      ],
      firstWarningDate: itemData?.batch?.[0]?.firstWarningDate
        ? differenceInDays1(itemData.batch[0].expiryDate, itemData.batch[0].firstWarningDate)
        : '',
      secondWarningDate: itemData?.batch?.[0]?.secondWarningDate
        ? differenceInDays1(itemData.batch[0].expiryDate, itemData.batch[0].secondWarningDate)
        : '',
      expiryDate: itemData.expiryDate,
      item: itemData?.item,
      unit: itemData?.unit,
      usagePerTest: itemData?.usagePerTest ?? '',
      originalStock: itemData?.originalStock,
      department: itemData?.department?.id,
      investigation: itemData?.investigation,
      usedBy: itemData?.usedBy ?? ''
    },
    validationSchema: Yup.object({
      item: Yup.string().required('required*'),
      department: Yup.string().required('required*'),
      unit: Yup.string().required('required*'),
      originalStock: Yup.string().required('required*'),
      usagePerTest: Yup.string().required('required*'),
      usedBy: Yup.string().required('required*'),
      firstWarningDate: Yup.string().required('required*'),
      secondWarningDate: Yup.string().required('required*')
    }),
    onSubmit: async (values) => {
      let flag = true;
      values.batch.forEach((a: any, i) => {
        if (i === values?.batch?.length - 1) return;

        if (a.name == '') {
          errorToast("name of item can't be empty");
          flag = false;
        }
        if (a.stock == '') {
          errorToast("Stock of item can't be empty");
          flag = false;
        }
        if (a.unit == '') {
          errorToast("Unit of item can't be empty");
          flag = false;
        }
        if (a.expiryDate == '') {
          errorToast("Expiry Date of item can't be empty");
          flag = false;
        }
      });

      if (!flag) {
        return;
      }
      let bool = true;
      const abcd: any = await HandleSave();
      const investigation: any = [];
      Object.values(abcd).map((data: any) => {
        data.map((a: any) => {
          investigation.push(a.id);
        });
      });

      if (values) {
        const newItem = values.batch[values.batch.length - 1];
        let temp = values.batch;
        if (
          newItem.name == '' &&
          newItem.stock == '' &&
          newItem.unit == '' &&
          newItem.expiryDate == ''
        ) {
          temp = [];
          for (let i = 0; i < values.batch.length - 1; i++) {
            temp.push(values.batch[i]);
          }
        } else {
          if (
            newItem.name == '' ||
            newItem.stock == '' ||
            newItem.expiryDate == '' ||
            newItem.unit == ''
          ) {
            bool = false;
            errorToast('Fill the remaining batch fields');
          }
        }

        const batch = JSON.parse(JSON.stringify(temp));
        if (batch.length === 1) {
          batch.forEach((a: any) => {
            a.firstWarningDate = daysToDate1(a.expiryDate, values.firstWarningDate);
            a.secondWarningDate = daysToDate1(a.expiryDate, values.secondWarningDate);
            a.inventory = { id: itemData.id };
            a.user = { id: Async.getItem('@auth')?.id };
            a.createdAt = getCurrentDateInIsoFormat();
            a.updatedAt = getCurrentDateInIsoFormat();
          });
        } else {
          batch.forEach((a: any) => {
            a.firstWarningDate = daysToDate1(a.expiryDate, values.firstWarningDate);
            a.secondWarningDate = daysToDate1(a.expiryDate, values.secondWarningDate);
          });
        }

        try {
          if (
            checkDatesFirstWarning(values.expiryDate, values.firstWarningDate) &&
            checkDatesSecondWarning(values.expiryDate, values.secondWarningDate) &&
            compareDates(values.expiryDate, values.firstWarningDate, values.secondWarningDate) &&
            bool == true
          ) {
            dispatch(
              UpdateItems({
                ...values,
                batch,
                department: {
                  id: values.department
                },
                id: itemData.id,
                investigation,
                currentStock: itemData.currentStock,
                expiryDate: itemData.expiryDate
              })
            );
          }
        } catch (e) {
          return e;
        }
      }
    }
  });

  // handleBatchDelete()
  useEffect(() => {
    if (!isLoadingDeleteBatch && successDeleteBatch) {
      successToast('Batch deleted successfully !');
      resetModalStates();
      dispatch(resetDeletedBatch());
      dispatch(GetItems('All'));
      dispatch(resetUpdateItems());
    }
    if (!isLoadingDeleteBatch && errorDeleteBatch) {
      errorToast('Error removing Batch');
      dispatch(resetDeletedBatch());
    }
  }, [isLoadingDeleteBatch, successDeleteBatch, errorDeleteBatch]);

  useEffect(() => {
    setTempBk1000(
      bk1000?.map((itm: any) => {
        if (itemData?.investigation?.includes(itm?.data?.id)) {
          return {
            ...itm,
            check: true
          };
        } else {
          return itm;
        }
      })
    );
  }, [bk1000]);

  useEffect(() => {
    setTempBk200(
      bk200?.map((itm: any) => {
        if (itemData?.investigation?.includes(itm?.data?.id)) {
          return {
            ...itm,
            check: true
          };
        } else {
          return itm;
        }
      })
    );
  }, [bk200]);

  useEffect(() => {
    setTempBk6190(
      bk6190?.map((itm: any) => {
        if (itemData?.investigation?.includes(itm?.data?.id)) {
          return {
            ...itm,
            check: true
          };
        } else {
          return itm;
        }
      })
    );
  }, [bk6190]);

  useEffect(() => {
    setTempcbs300(
      cbs300?.map((itm: any) => {
        if (itemData?.investigation?.includes(itm?.data?.id)) {
          return {
            ...itm,
            check: true
          };
        } else {
          return itm;
        }
      })
    );
  }, [cbs300]);

  useEffect(() => {
    setTempOther(
      other?.map((itm: any) => {
        if (itemData?.investigation?.includes(itm?.data?.id)) {
          return {
            ...itm,
            check: true
          };
        } else {
          return itm;
        }
      })
    );
  }, [other]);

  const HandleSave = async () => {
    if (
      !isInvestigationPresent(
        0,
        checkedBk1000.length,
        checkedBk200.length,
        checkedBk6190.length,
        checkedCbs300.length,
        checkedOther.length
      )
    ) {
      errorToast('No investigation selected');
      return;
    }

    return {
      bk1000: checkedBk1000,
      bk200: checkedBk200,
      bk6190: checkedBk6190,
      cbs300: checkedCbs300,
      other: checkedOther
    };
  };

  useEffect(() => {
    if (!isLoadingUpdateItem && successUpdateItem) {
      successToast('Item updated successfully!');
      dispatch(resetUpdateItems());
      dispatch(GetItems('All'));
      resetModalStates();
    }
    if (!isLoadingUpdateItem && errorUpdateItem) {
      errorToast(errorUpdateItem);
    }
  }, [isLoadingUpdateItem, successUpdateItem, errorUpdateItem]);

  const resetModalStates = () => {
    toggle();
    values.department = initialValues.department;
    // // values.id = initialValues.id;
    values.item = initialValues.item;
    values.unit = initialValues.unit;
    values.secondWarningDate = initialValues.secondWarningDate;
    values.usagePerTest = initialValues.usagePerTest;
    values.batch = initialValues.batch;
    // values.expiryDate=initialValues.expiryDate;
    values.firstWarningDate = initialValues.firstWarningDate;
    values.investigation = initialValues.investigation;
    values.expiryDate = initialValues.expiryDate;
    values.usedBy = initialValues.usedBy;
    setErrors({});
    setTouched({});
    setIsSubmit(false);
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop="static"
    >
      <div className="modal-body ms-2" style={{ paddingLeft: '0', paddingRight: '0' }}>
        <h4
          style={{ marginLeft: '4%' }}
          className="modal-title mt-0 sb font-size-18 "
          id="myLargeModalLabel"
        >
          Update Item
        </h4>
        <button
          onClick={resetModalStates}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Item name*'}
              type="text"
              placeholder="Item name"
              name={'item'}
              value={values.item}
              onChange={(e: string) => {
                handleChange(e);
              }}
              invalid={touched.item && errors.item ? true : false}
              errors={errors.item}
            />
          </div>

          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Department*'}
              type="select"
              name={'department'}
              placeholder=""
              value={values.department.name}
              options={[
                { value: 1, name: 'Lab' },
                { value: 2, name: 'Haematoloy' },
                { value: 3, name: 'Biochem' },
                { value: 4, name: 'Immunochem' },
                {
                  value: 5,
                  name: 'Phlebotomist'
                },
                {
                  value: 6,
                  name: 'Other'
                }
              ]}
              selected={true}
              onChange={handleChange}
              invalid={touched.department && errors.department ? true : false}
              errors={errors.department}
            />
          </div>
        </div>
        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5  " style={{ whiteSpace: 'nowrap' }}>
            <label className="col-md-5 col-form-label im gray">Used Per test*</label>

            <div className="d-flex">
              <div className="col-8">
                <CustomInput
                  label={''}
                  type="text"
                  placeholder="Amount"
                  name={'usagePerTest'}
                  value={values.usagePerTest}
                  onChange={(e: any) => {
                    if (isPositiveDecimalInput(e)) {
                      handleChange(e);
                    }
                  }}
                  invalid={touched.usagePerTest && errors.usagePerTest ? true : false}
                  errors={errors.usagePerTest}
                />
              </div>
              <div className="col-4">
                <CustomInput
                  label={''}
                  type="select"
                  placeholder="Units"
                  name={'unit'}
                  value={values.unit}
                  options={[
                    { value: 'ml', name: 'ml' },
                    { value: 'item', name: 'item' }
                  ]}
                  onChange={handleChange}
                  invalid={touched.unit && errors.unit ? true : false}
                  errors={errors.unit}
                />
              </div>
            </div>
          </div>

          <div className="col-md-5  " style={{ whiteSpace: 'nowrap' }}>
            <label className="col-md-5 col-form-label im gray">Stock warning (days)*</label>

            <div className="d-flex">
              <div className="col-6">
                <CustomInput
                  label={''}
                  type="text"
                  placeholder="60(1st warning)"
                  name={`firstWarningDate`}
                  value={values.firstWarningDate}
                  onChange={(e: string) => {
                    if (isPositiveInput(e)) {
                      handleChange(e);
                    }
                    // handleChange(e);
                  }}
                  invalid={touched?.firstWarningDate && errors?.firstWarningDate ? true : false}
                  errors={errors.firstWarningDate}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  label={''}
                  type="text"
                  placeholder="30(2nd warning)"
                  name={`secondWarningDate`}
                  value={
                    values.secondWarningDate
                    //  values.batch[0].name
                  }
                  onChange={(e: string) => {
                    if (isPositiveInput(e)) {
                      handleChange(e);
                    }
                    // handleChange(e);
                  }}
                  invalid={touched.secondWarningDate && errors.secondWarningDate ? true : false}
                  errors={errors.secondWarningDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="filtersBox mt-2 justify-content-around">
          <div className="col-md-5 " style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Used By*'}
              type="select"
              placeholder="Used by"
              name={'usedBy'}
              value={values.usedBy}
              options={[
                { value: INVENTORY_USED_BY.INVESTIGATION, name: 'Investigation' },
                { value: INVENTORY_USED_BY.ENCOUNTER, name: 'Encounter' }
              ]}
              onChange={handleChange}
              invalid={touched.usedBy && errors.usedBy ? true : false}
              errors={errors.usedBy}
            />
          </div>
          <div className="col-md-5"></div>
        </div>
        <div
        // className=" filtersBox  mt-2 mb-3 justify-content-around "
        >
          <hr
            style={{ opacity: '0.1', marginTop: '4%', paddingLeft: '0px', paddingRight: '0px' }}
          ></hr>
          {/* <div className="modal-footer  mt-2 pt-4 "> */}
          <div className=" filtersBox mt-2 justify-content-around">
            <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
              <MultiSelectInput
                label="Immunochemistry"
                errors={'Select investigations'}
                placeholder="Select investigations"
                options={tempbk1000}
                setOptions={setTempBk1000}
                setChecked={setCheckedBk1000}
                checkedOptions={checkedBk1000}
                modalType={modalType}
              />
            </div>
            <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
              <MultiSelectInput
                label="Biochemistry"
                placeholder="Select investigations"
                options={tempbk200}
                setOptions={setTempBk200}
                setChecked={setCheckedBk200}
                checkedOptions={checkedBk200}
                modalType={modalType}
              />
            </div>
          </div>
          <div className="filtersBox mt-2 justify-content-around">
            <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
              <MultiSelectInput
                label="Haematology"
                placeholder="Select investigations"
                options={tempbk6190}
                setOptions={setTempBk6190}
                setChecked={setCheckedBk6190}
                checkedOptions={checkedBk6190}
                modalType={modalType}
                screen="inventory"
              />
            </div>
            <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
              <MultiSelectInput
                label="Electrolytes"
                placeholder="Select investigations"
                options={tempcbs300}
                setOptions={setTempcbs300}
                setChecked={setCheckedCbs300}
                checkedOptions={checkedCbs300}
                modalType={modalType}
              />
            </div>
          </div>
          <div className="filtersBox filtersBox mt-2 justify-content-around">
            <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}>
              <MultiSelectInput
                label="Other"
                placeholder="Select investigations"
                options={tempOther}
                setOptions={setTempOther}
                setChecked={setCheckedOther}
                checkedOptions={checkedOther}
                modalType={modalType}
              />
            </div>
            <div className="col-md-5" style={{ whiteSpace: 'nowrap' }}></div>
          </div>
          <hr
            style={{
              opacity: '0.1',
              marginTop: '4%',
              paddingLeft: '0px',
              paddingRight: '0px',
              marginLeft: '0px',
              marginRight: '0px'
            }}
          ></hr>
          <div className="mt-2 mb-3" style={{ marginLeft: '1.4%' }}>
            {!isLoadingDeleteBatch ? (
              values.batch.map((data: any, index: number) => {
                return (
                  <div key={index} className="d-flex flex-row justify-content-around">
                    <div
                      className={`col-3 ${
                        index === values?.batch?.length - 1 &&
                        values?.batch?.length !== 1 &&
                        values?.batch?.length !== 1
                          ? 'mt-5'
                          : ''
                      }`}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="col-form-label im gray">Item name*</label>
                      <CustomInput
                        label={''}
                        type="text"
                        placeholder="Item name"
                        name={`batch.${index}.name`}
                        value={data.name}
                        onChange={handleChange}
                        invalid={touched.batch && errors.batch ? true : false}
                        errors={errors.batch}
                      />
                    </div>
                    <div
                      className={`col-3 ${
                        index === values?.batch?.length - 1 && values?.batch?.length !== 1
                          ? 'mt-5'
                          : ''
                      }`}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="col-md-5 col-form-label im gray">Expiry Date* </label>
                      <DatePicker
                        id="expiryDate"
                        dateFormat="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        showClearButton={false}
                        name={`batch.${index}.expiryDate`}
                        minDate={moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ')}
                        value={
                          data.expiryDate
                            ? convertServerDateFormatToDateFormat(data.expiryDate)
                            : ''
                        }
                        onBlur={handleBlur}
                        invalid={touched.batch && errors.batch ? true : false}
                        style={
                          isSubmit && touched.batch && errors.batch
                            ? { border: '1px #f46a6a solid', borderRadius: '0.375rem' }
                            : { borderRadius: '0.375rem' }
                        }
                        onChange={
                          (e: string) => {
                            if (e != null) {
                              setFieldValue(
                                `batch.${index}.expiryDate`,

                                convertToServerDateFormat(e)
                              );
                            }
                          }
                          // setFieldValue(
                          //   `batch.${id}.firstWarningDate`,
                        }
                      />
                      {isSubmit && touched.batch && errors.batch ? (
                        <span className="text-danger font-size-14">
                          {errors.batch === 'required*' ? 'required*' : 'Invalid Date'}
                        </span>
                      ) : null}
                    </div>
                    {/*  */}
                    <div
                      className={`col-4 ${
                        index === values?.batch?.length - 1 && values?.batch?.length !== 1
                          ? 'mt-5'
                          : ''
                      }`}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="col-md-3 col-form-label im gray">Stock* </label>

                      <div className="d-flex">
                        <div className="col-5">
                          <CustomInput
                            label={''}
                            type="text"
                            placeholder="Stock"
                            name={`batch.${index}.stock`}
                            value={
                              data.stock //  ''
                            }
                            onChange={(e: any) => {
                              if (isPositiveDecimalInput(e)) {
                                handleChange(e);
                              }
                            }}
                            invalid={touched.batch && errors.batch ? true : false}
                            errors={errors.batch}
                            // invalid={false}
                            // errors={null}
                          />
                        </div>
                        <div className="col-5">
                          <CustomInput
                            label={''}
                            type="select"
                            placeholder="Units*"
                            name={`batch.${index}.unit`}
                            value={data.unit}
                            options={[
                              { value: 'ml', name: 'ml' },
                              { value: 'item', name: 'item' }
                            ]}
                            onChange={handleChange}
                            invalid={touched.batch && errors.batch ? true : false}
                            errors={errors.batch}
                            // invalid={false}
                            // errors={null}
                          />
                        </div>

                        {index !== values.batch.length - 1 && (
                          <div
                            className="col-2 ml-2"
                            style={{ marginTop: '10px', cursor: 'Pointer' }}
                            onClick={() => dispatch(DeleteBatch(data.id))}
                          >
                            <i
                              style={{ marginLeft: '10px', fontSize: '20px', color: '#E53C41' }}
                              className="bx bx-trash "
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="d-flex justify-content-center w-100" style={{ minHeight: '200px' }}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-footer px-3 mt-2 pt-4 ">
        <WhiteButton text="Dismiss" HandleClick={resetModalStates} />

        <div className="ms-auto">
          <BlueButton
            text={'+ Save Changes'}
            loading={isLoadingUpdateItem}
            // disabled={isLoadingAddUser || isLoadingUpdateUser}
            width={modalType === 'add' ? '109px' : '120px'}
            HandleClick={() => {
              setIsSubmit(true);
              handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
export default UpdateItemModal;
