import { createSlice } from '@reduxjs/toolkit';
import {
  addNewEncounter,
  fetchAllEncounters,
  updateEncounter,
  archiveEncounter,
  unarchiveEncounter,
  fetchEncounterById,
  fetchEncounterStatistics
} from './thunks';
import { InitialState } from './types';
const initialState: InitialState = {
  //get by patient id
  patientEncounters: [],
  isLoadingFetchPatientEncounters: false,
  successFetchPatientEncounters: false,
  errorFetchPatientEncounters: null,
  //add
  addedEncounter: null,
  isLoadingAddEncounter: false,
  errorAddEncounter: null,
  successAddEncounter: false,
  //update
  updatedEncounter: null,
  isLoadingUpdateEncounter: false,
  errorUpdateEncounter: null,
  successUpdateEncounter: false,
  //archive
  archivedEncounter: null,
  isLoadingArchiveEncounter: false,
  errorArchiveEncounter: null,
  successArchiveEncounter: false,
  //unarchive
  unarchivedEncounter: null,
  isLoadingUnarchiveEncounter: false,
  errorUnarchiveEncounter: '',
  successUnarchiveEncounter: false,
  //fetch by id
  encounter: null,
  isLoadingEncounterFetchById: false,
  errorEncounterFetchById: '',
  successEncounterFetchById: false,
  //get encounter stats
  encounterStats: null,
  isLoadingFetchEncounterStats: false,
  successFetchEncounterStats: false,
  errorFetchEncounterStats: null
};
const EncounterSlice = createSlice({
  name: 'encounter',
  initialState,
  extraReducers(builder) {
    //get all by patient id
    builder.addCase(fetchAllEncounters.pending, (state) => {
      state.patientEncounters = [];
      state.isLoadingFetchPatientEncounters = true;
      state.errorFetchPatientEncounters = null;
      state.successFetchPatientEncounters = false;
    });
    builder.addCase(fetchAllEncounters.rejected, (state, action) => {
      state.patientEncounters = [];
      state.isLoadingFetchPatientEncounters = true;
      state.successFetchPatientEncounters = false;

      if (action.error) {
        state.errorFetchPatientEncounters = action.error.message;
      } else {
        state.errorFetchPatientEncounters = 'Could not fetch patient encounters';
      }
    });
    builder.addCase(fetchAllEncounters.fulfilled, (state, action) => {
      state.patientEncounters = action.payload;
      state.isLoadingFetchPatientEncounters = false;
      state.errorFetchPatientEncounters = null;
      state.successFetchPatientEncounters = true;
    });
    //add
    builder.addCase(addNewEncounter.pending, (state) => {
      state.addedEncounter = null;
      state.isLoadingAddEncounter = true;
      state.errorAddEncounter = null;
      state.successAddEncounter = false;
    });
    builder.addCase(addNewEncounter.rejected, (state, action) => {
      state.addedEncounter = null;
      state.isLoadingAddEncounter = false;
      state.successAddEncounter = false;
      if (action.error) {
        state.errorAddEncounter = action.error.message;
      } else {
        state.errorAddEncounter = 'Could not add new encounter';
      }
    });
    builder.addCase(addNewEncounter.fulfilled, (state, action) => {
      state.addedEncounter = action.payload;
      state.isLoadingAddEncounter = false;
      state.errorAddEncounter = null;
      state.successAddEncounter = true;
    });
    //update
    builder.addCase(updateEncounter.pending, (state) => {
      state.isLoadingUpdateEncounter = true;
      state.errorUpdateEncounter = '';
      state.successUpdateEncounter = false;
    });
    builder.addCase(updateEncounter.rejected, (state, action) => {
      state.isLoadingUpdateEncounter = false;
      state.successUpdateEncounter = false;

      if (action.error) {
        state.errorUpdateEncounter = action.error.message;
      } else {
        state.errorUpdateEncounter = 'Could not update encounter';
      }
    });
    builder.addCase(updateEncounter.fulfilled, (state, action) => {
      state.updatedEncounter = action.payload;
      state.isLoadingUpdateEncounter = false;
      state.errorUpdateEncounter = '';
      state.successUpdateEncounter = true;
    });
    //archive
    builder.addCase(archiveEncounter.pending, (state) => {
      state.archivedEncounter = null;
      state.isLoadingArchiveEncounter = true;
      state.errorArchiveEncounter = null;
      state.successArchiveEncounter = false;
    });
    builder.addCase(archiveEncounter.rejected, (state, action) => {
      state.archivedEncounter = null;
      state.isLoadingArchiveEncounter = false;
      state.successArchiveEncounter = false;

      if (action.error) {
        state.errorArchiveEncounter = action.error.message;
      } else {
        state.errorArchiveEncounter = 'Could not archive encounter';
      }
    });
    builder.addCase(archiveEncounter.fulfilled, (state, action) => {
      state.archivedEncounter = action.payload;
      state.isLoadingArchiveEncounter = false;
      state.errorArchiveEncounter = null;
      state.successArchiveEncounter = true;
    });
    //unarchive
    builder.addCase(unarchiveEncounter.pending, (state) => {
      state.unarchivedEncounter = null;
      state.isLoadingUnarchiveEncounter = true;
      state.errorUnarchiveEncounter = '';
      state.successUnarchiveEncounter = false;
    });
    builder.addCase(unarchiveEncounter.rejected, (state, action) => {
      state.unarchivedEncounter = null;
      state.isLoadingUnarchiveEncounter = false;
      state.successUnarchiveEncounter = false;

      if (action.error) {
        state.errorUnarchiveEncounter = action.error.message as string;
      } else {
        state.errorUnarchiveEncounter = 'Could not archive encounter';
      }
    });
    builder.addCase(unarchiveEncounter.fulfilled, (state, action) => {
      state.unarchivedEncounter = action.payload;
      state.isLoadingUnarchiveEncounter = false;
      state.errorUnarchiveEncounter = '';
      state.successUnarchiveEncounter = true;
    });
    //fetch by id
    builder.addCase(fetchEncounterById.pending, (state) => {
      state.encounter = null;
      state.isLoadingEncounterFetchById = true;
      state.errorEncounterFetchById = '';
      state.successEncounterFetchById = false;
    });
    builder.addCase(fetchEncounterById.rejected, (state, action) => {
      state.encounter = null;
      state.isLoadingEncounterFetchById = false;
      state.successEncounterFetchById = false;

      if (action.error) {
        state.errorEncounterFetchById = action.error.message as string;
      } else {
        state.errorEncounterFetchById = 'Could not fetch encounter details';
      }
    });
    builder.addCase(fetchEncounterById.fulfilled, (state, action) => {
      state.encounter = action.payload;
      state.isLoadingEncounterFetchById = false;
      state.errorEncounterFetchById = '';
      state.successEncounterFetchById = true;
    });
    //fetch encounter stats
    builder.addCase(fetchEncounterStatistics.pending, (state) => {
      state.encounterStats = null;
      state.isLoadingFetchEncounterStats = true;
      state.errorFetchEncounterStats = '';
      state.successFetchEncounterStats = false;
    });
    builder.addCase(fetchEncounterStatistics.rejected, (state, action) => {
      state.encounterStats = null;
      state.isLoadingFetchEncounterStats = false;
      state.successFetchEncounterStats = false;

      if (action.error) {
        state.errorFetchEncounterStats = action.error.message as string;
      } else {
        state.errorFetchEncounterStats = 'Could not fetch encounter statistics';
      }
    });
    builder.addCase(fetchEncounterStatistics.fulfilled, (state, action) => {
      state.encounterStats = action.payload;
      state.isLoadingFetchEncounterStats = false;
      state.errorFetchEncounterStats = '';
      state.successFetchEncounterStats = true;
    });
  },
  reducers: {
    resetAddEncounter: (state) => {
      state.isLoadingAddEncounter = false;
      state.errorAddEncounter = null;
      state.successAddEncounter = false;
    },
    resetUpdatedEncounter: (state) => {
      state.isLoadingUpdateEncounter = false;
      state.errorUpdateEncounter = null;
      state.successUpdateEncounter = false;
    },
    resetFetchPatientEncounters: (state) => {
      state.isLoadingFetchPatientEncounters = false;
      state.errorFetchPatientEncounters = null;
      state.successFetchPatientEncounters = false;
    },
    resetArchiveEncounter: (state) => {
      state.isLoadingArchiveEncounter = false;
      state.errorArchiveEncounter = null;
      state.successArchiveEncounter = false;
    },
    resetUnrchiveEncounter: (state) => {
      state.isLoadingUnarchiveEncounter = false;
      state.errorUnarchiveEncounter = '';
      state.successUnarchiveEncounter = false;
    },
    resetEncounterFetchById: (state) => {
      state.isLoadingEncounterFetchById = false;
      state.errorEncounterFetchById = '';
      state.successEncounterFetchById = false;
    },
    resetFetchEncounterStats: (state) => {
      state.isLoadingFetchEncounterStats = false;
      state.errorFetchEncounterStats = '';
      state.successFetchEncounterStats = false;
    },
    setEncounterDetails: (state, action) => {
      state.encounter = action.payload;
    }
  }
});
export const {
  resetAddEncounter,
  resetArchiveEncounter,
  resetUpdatedEncounter,
  resetUnrchiveEncounter,
  resetFetchPatientEncounters,
  resetEncounterFetchById,
  resetFetchEncounterStats
} = EncounterSlice.actions;
export default EncounterSlice.reducer;
