import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Col, Form, Row } from 'reactstrap';
import * as Yup from 'yup';
import BlueButton from '~/components/BlueButton';
import WhiteButton from '~/components/WhiteButton';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { resetAddDoctor, resetUpdatedDoctor } from '~/store/reducers/Doctor';
import useWindowSize from '~/utils/hooks/useWindowSize';
import { errorToast, successToast } from '~/utils/toast';
import { addNewDoctor } from '~/store/reducers/Doctor/thunks';
import DoctorFormInputs from './DoctorFormInputs';

const AddDoctorForm = () => {
  const { height } = useWindowSize();
  const dispatch = useAppDispatch();
  const {
    //add doctor
    isLoadingAddDoctor,
    successAddDoctor,
    errorAddDoctor,
    //update doctor
    isLoadingUpdateDoctor,
    successUpdateDoctor,
    errorUpdateDoctor
  } = useAppSelector((state) => state.DoctorReducer);
  const { handleBlur, handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        title: 'Dr.',
        firstName: '',
        lastName: '',
        clinicName: '',
        clinicAddress: '',
        phoneNumber: '',
        email: ''
      },
      validationSchema: Yup.object({
        title: Yup.string().required('required*'),
        firstName: Yup.string().required('required*'),
        lastName: Yup.string().required('required*'),
        clinicName: Yup.string().required('required*'),
        clinicAddress: Yup.string().required('required*'),
        phoneNumber: Yup.string().notRequired(),
        email: Yup.string().email().required('required*')
      }),
      onSubmit: (values) => {
        if (
          values.phoneNumber === '' ||
          values.phoneNumber === undefined ||
          isValidPhoneNumber(`${values.phoneNumber}`)
        ) {
          dispatch(
            addNewDoctor({
              ...values,
              phoneNumber: values.phoneNumber ?? ''
            })
          );
        }
      }
    });
  useEffect(() => {
    if (!isLoadingUpdateDoctor && successUpdateDoctor) {
      successToast('Doctor updated successfully !');
      dispatch(resetUpdatedDoctor());
      history.back();
    }
    if (!isLoadingUpdateDoctor && errorUpdateDoctor) {
      errorToast(errorUpdateDoctor);
      dispatch(resetUpdatedDoctor());
    }
  }, [isLoadingUpdateDoctor, successUpdateDoctor, errorUpdateDoctor, resetUpdatedDoctor]);

  useEffect(() => {
    if (!isLoadingAddDoctor && successAddDoctor) {
      successToast('Doctor added successfully !');
      dispatch(resetAddDoctor());
      history.back();
    }
    if (!isLoadingAddDoctor && errorAddDoctor) {
      errorToast('Error adding doctor');
      dispatch(resetAddDoctor());
    }
  }, [isLoadingAddDoctor, successAddDoctor, errorAddDoctor]);

  const isLetterInput = (e: any) => {
    const letterRegex = /^[A-Za-z',\s]*$/;
    if (e.target.value === '' || letterRegex.test(e.target.value)) {
      return true;
    }
    return false;
  };

  return (
    <Form id="addDoctor" onSubmit={handleSubmit}>
      <>
        <Row>
          <Col lg="3">
            <h2 className="m-0 align-self-center text-nowrap">
              <b className="text-nowrap sbl24">{'New doctor registration'}</b>
            </h2>
          </Col>
          <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1"></Col>
          <Col lg="4" className="text-end text-nowrap">
            <WhiteButton width="71px" text={'Dismiss'} HandleClick={() => history.back()} />
            <BlueButton
              HandleClick={handleSubmit}
              width="105px"
              loading={isLoadingAddDoctor || isLoadingUpdateDoctor}
              disabled={isLoadingAddDoctor || isLoadingUpdateDoctor}
              text={'Save changes'}
            />
          </Col>
        </Row>
        <div className="horizontal-line mt-3 mb-3"></div>

        <div
          className="ps-1"
          style={{
            maxHeight: `${height - 215}px`,
            overflowX: 'hidden',
            scrollbarWidth: 'thin'
          }}
        >
          <DoctorFormInputs
            edit={false}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            isLetterInput={isLetterInput}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </div>
      </>
    </Form>
  );
};

export default AddDoctorForm;
