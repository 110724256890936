import { createSlice } from '@reduxjs/toolkit';
import {
  addNewDoctor,
  archiveDoctor,
  deleteDoctor,
  fetchDoctorById,
  GetDoctors,
  unarchiveDoctor,
  updateDoctor
} from './thunks';
import { doctorManagement } from './types';

const initialState: doctorManagement = {
  //get by id
  doctor: null,
  isLoadingFetchDoctorById: false,
  successFetchDoctorById: false,
  errorFetchDoctorById: null,
  //get all doctors
  doctorLoading: false,
  doctorSuccess: false,
  doctorError: '',
  doctors: [],
  //add
  addedDoctor: null,
  isLoadingAddDoctor: false,
  errorAddDoctor: null,
  successAddDoctor: false,
  //update
  updatedDoctor: null,
  isLoadingUpdateDoctor: false,
  errorUpdateDoctor: null,
  successUpdateDoctor: false,
  //delete
  deletedDoctor: null,
  isLoadingDeleteDoctor: false,
  errorDeleteDoctor: null,
  successDeleteDoctor: false,
  //archive
  archivedDoctor: null,
  isLoadingArchiveDoctor: false,
  errorArchiveDoctor: null,
  successArchiveDoctor: false,

  //unarchive
  unarchivedDoctor: null,
  isLoadingUnarchiveDoctor: false,
  errorUnarchiveDoctor: '',
  successUnarchiveDoctor: false
};
const doctorSlice = createSlice({
  name: 'doctorManagement',
  initialState,
  extraReducers: (builder) => {
    //get by id
    builder.addCase(fetchDoctorById.pending, (state) => {
      state.doctor = null;
      state.isLoadingFetchDoctorById = true;
      state.errorFetchDoctorById = null;
      state.successFetchDoctorById = false;
    });
    builder.addCase(fetchDoctorById.rejected, (state, action) => {
      state.doctor = null;
      state.isLoadingFetchDoctorById = true;
      state.successFetchDoctorById = false;

      if (action.error) {
        state.errorFetchDoctorById = action.error.message;
      } else {
        state.errorFetchDoctorById = 'Could not fetch doctors';
      }
    });
    builder.addCase(fetchDoctorById.fulfilled, (state, action) => {
      state.doctor = action.payload;
      state.isLoadingFetchDoctorById = false;
      state.errorFetchDoctorById = null;
      state.successFetchDoctorById = true;
    });
    //get all
    builder.addCase(GetDoctors.pending, (state) => {
      state.doctorLoading = true;
      state.doctorError = '';
      state.doctorSuccess = false;
    });
    builder.addCase(GetDoctors.fulfilled, (state, { payload }) => {
      state.doctorLoading = false;
      state.doctorError = '';
      state.doctors = payload;
      state.doctorSuccess = true;
    });
    builder.addCase(GetDoctors.rejected, (state, action) => {
      state.doctorLoading = false;
      state.doctorSuccess = false;
      if (action.error) {
        state.doctorError = action.error.message as string;
      } else {
        state.doctorError = 'Error fetching doctors';
      }
    });
    //add
    builder.addCase(addNewDoctor.pending, (state) => {
      state.addedDoctor = null;
      state.isLoadingAddDoctor = true;
      state.errorAddDoctor = null;
      state.successAddDoctor = false;
    });
    builder.addCase(addNewDoctor.rejected, (state, action) => {
      state.addedDoctor = null;
      state.isLoadingAddDoctor = false;
      state.successAddDoctor = false;
      if (action.error) {
        state.errorAddDoctor = action.error.message;
      } else {
        state.errorAddDoctor = 'Could not add new doctor';
      }
    });
    builder.addCase(addNewDoctor.fulfilled, (state, action) => {
      state.addedDoctor = action.payload;
      state.isLoadingAddDoctor = false;
      state.errorAddDoctor = null;
      state.successAddDoctor = true;
    });
    //update
    builder.addCase(updateDoctor.pending, (state) => {
      state.updatedDoctor = null;
      state.isLoadingUpdateDoctor = true;
      state.errorUpdateDoctor = null;
      state.successUpdateDoctor = false;
    });
    builder.addCase(updateDoctor.rejected, (state, action) => {
      state.updatedDoctor = null;
      state.isLoadingUpdateDoctor = true;
      state.successUpdateDoctor = false;

      if (action.error) {
        state.errorUpdateDoctor = action.error.message;
      } else {
        state.errorUpdateDoctor = 'Could not update doctor';
      }
    });
    builder.addCase(updateDoctor.fulfilled, (state, action) => {
      state.updatedDoctor = action.payload;
      state.isLoadingUpdateDoctor = false;
      state.errorUpdateDoctor = null;
      state.successUpdateDoctor = true;
    });
    //delete
    builder.addCase(deleteDoctor.pending, (state) => {
      state.deletedDoctor = null;
      state.isLoadingDeleteDoctor = true;
      state.errorDeleteDoctor = null;
      state.successDeleteDoctor = false;
    });
    builder.addCase(deleteDoctor.rejected, (state, action) => {
      state.deletedDoctor = null;
      state.isLoadingDeleteDoctor = false;
      state.successDeleteDoctor = false;

      if (action.error) {
        state.errorDeleteDoctor = action.error.message;
      } else {
        state.errorDeleteDoctor = 'Could not delete doctor';
      }
    });
    builder.addCase(deleteDoctor.fulfilled, (state, action) => {
      state.deletedDoctor = action.payload;
      state.isLoadingDeleteDoctor = false;
      state.errorDeleteDoctor = null;
      state.successDeleteDoctor = true;
    });
    //archive
    builder.addCase(archiveDoctor.pending, (state) => {
      state.archivedDoctor = null;
      state.isLoadingArchiveDoctor = true;
      state.errorArchiveDoctor = null;
      state.successArchiveDoctor = false;
    });
    builder.addCase(archiveDoctor.rejected, (state, action) => {
      state.archivedDoctor = null;
      state.isLoadingArchiveDoctor = false;
      state.successArchiveDoctor = false;

      if (action.error) {
        state.errorArchiveDoctor = action.error.message;
      } else {
        state.errorArchiveDoctor = 'Could not archive doctor';
      }
    });
    builder.addCase(archiveDoctor.fulfilled, (state, action) => {
      state.archivedDoctor = action.payload;
      state.isLoadingArchiveDoctor = false;
      state.errorArchiveDoctor = null;
      state.successArchiveDoctor = true;
    });
    //unarchive
    builder.addCase(unarchiveDoctor.pending, (state) => {
      state.unarchivedDoctor = null;
      state.isLoadingUnarchiveDoctor = true;
      state.errorUnarchiveDoctor = '';
      state.successUnarchiveDoctor = false;
    });
    builder.addCase(unarchiveDoctor.rejected, (state, action) => {
      state.unarchivedDoctor = null;
      state.isLoadingUnarchiveDoctor = false;
      state.successUnarchiveDoctor = false;

      if (action.error) {
        state.errorUnarchiveDoctor = action.error.message as string;
      } else {
        state.errorUnarchiveDoctor = 'Could not archive doctor';
      }
    });
    builder.addCase(unarchiveDoctor.fulfilled, (state, action) => {
      state.unarchivedDoctor = action.payload;
      state.isLoadingUnarchiveDoctor = false;
      state.errorUnarchiveDoctor = '';
      state.successUnarchiveDoctor = true;
    });
  },
  reducers: {
    resetGetDoctor(state) {
      state.doctorLoading = false;
      state.doctorError = '';
      state.doctorSuccess = false;
    },
    resetAddDoctor: (state) => {
      state.addedDoctor = null;
      state.isLoadingAddDoctor = false;
      state.errorAddDoctor = null;
      state.successAddDoctor = false;
    },
    resetUpdatedDoctor: (state) => {
      state.isLoadingUpdateDoctor = false;
      state.errorUpdateDoctor = null;
      state.successUpdateDoctor = false;
    },
    resetFetchDoctorById: (state) => {
      state.isLoadingFetchDoctorById = false;
      state.errorFetchDoctorById = null;
      state.successFetchDoctorById = false;
    },
    resetArchiveDoctorsState: (state) => {
      state.isLoadingArchiveDoctor = false;
      state.errorArchiveDoctor = null;
      state.successArchiveDoctor = false;
    },
    resetUnrchiveDoctorsState: (state) => {
      state.isLoadingUnarchiveDoctor = false;
      state.errorUnarchiveDoctor = '';
      state.successUnarchiveDoctor = false;
    }
  }
});

// // Action creators are generated for each case reducer function
export const {
  resetGetDoctor,
  resetAddDoctor,
  resetArchiveDoctorsState,
  resetFetchDoctorById,
  resetUnrchiveDoctorsState,
  resetUpdatedDoctor
} = doctorSlice.actions;
export default doctorSlice.reducer;
