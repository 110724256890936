import React from 'react';
import { Col, Row } from 'reactstrap';
import ExportPdf from '~/assets/images/png/exportPdf.png';
import {
  Investigation,
  InvestigationRowProp,
  PanelInvestigationBody,
  PanelInvestigationProps
} from '~/models/modalsInterfaces.model';
import Async from '~/utils/Async';
import {
  convertIsoDateTimeFormatToDateTimeFormatWithAmPm,
  convertServerDateFormatToDateFormatWithoutHyphen,
  getAgeFromDate
} from '~/utils/dateUtility';
import './labTestResults.scss';
import RenderRow from './Row';
type LabTestResultsProps = {
  innerRef: any;
  encounterDetails: any;
  data: any;
  dataType: string;
};
export type DataFieldProps = {
  field: string;
  value: string;
  style?: any;
};
type TableHeaderProps = {
  encounterDetails: any;
};
export const DataField = ({ field, value, style }: DataFieldProps) => {
  return (
    <div className="d-flex flex-column" style={{ ...style }}>
      <span className="pdf-key-text">{field}</span>
      <span className="pdf-comment-text">{value}</span>
    </div>
  );
};
export const TableHeader = ({ encounterDetails }: TableHeaderProps) => {
  return (
    <thead className="page-header">
      {/* <tr className="d-flex justify-content-between align-items-center"> */}
      <th className="pdf-th bg-white">
        <div className="d-flex bg-white justify-content-between align-items-center">
          <div className="d-flex">
            <img src={ExportPdf} style={{ width: '262px', height: '77px' }} />
            <div className="ms-4" style={{ minWidth: '100px', maxWidth: '150px' }}>
              <p className="pdf-key-text mt-2" style={{ maxWidth: 'fit-content' }}>
                {encounterDetails.doctor?.clinicAddress}
              </p>
              <p className={'pdf-key-text'}>Tel: {encounterDetails?.doctor?.phoneNumber}</p>
            </div>
          </div>

          <div className="text-center d-flex flex-column">
            <span className="sbl24 me-3">Lab Test Results</span>
            {/* <span className="text-center font-size-18px">Page 1/5</span> */}
          </div>
        </div>
        <div className="d-flex justify-content-between bg-white">
          <div>
            <div className={'d-flex px-3 mx-3 page-number'}>
              <DataField
                field={'Encounter ID'}
                value={encounterDetails.name}
                style={{ width: '130px' }}
              />
              <DataField
                field={'Referral doctor'}
                value={`${encounterDetails?.doctor?.title}
                     ${encounterDetails?.doctor?.firstName[0].toLocaleUpperCase()}.${
                  encounterDetails.doctor?.lastName
                }`}
                style={{ width: '110px' }}
              />
              <DataField
                field={'Lab Operator'}
                value={`${Async.getItem('@auth')?.firstName} ${Async.getItem('@auth')?.lastName}`}
                style={{ width: '110px' }}
              />
              <DataField
                field={'Date posted'}
                value={convertIsoDateTimeFormatToDateTimeFormatWithAmPm(encounterDetails.createdAt)}
                style={{ width: '150px' }}
              />
              <DataField
                field={'Patient ID'}
                value={
                  encounterDetails.patient?.uniqueId &&
                  encounterDetails.patient?.uniqueId?.length !== 0
                    ? encounterDetails.patient?.uniqueId
                    : '-'
                }
                style={{ width: '140px' }}
              />
            </div>
            <div className={'d-flex rounded border-0 px-3 mx-3 mb-3'}>
              <DataField
                field={'Name'}
                style={{ width: '130px' }}
                value={`${encounterDetails.patient?.firstName} ${encounterDetails.patient?.lastName}`}
              />
              <DataField
                field={'Gender'}
                value={`${
                  encounterDetails.patient?.gender === 'M'
                    ? 'Male'
                    : encounterDetails.patient?.gender === 'F'
                    ? 'Female'
                    : encounterDetails.patient?.gender === 'O'
                    ? 'Others'
                    : '-'
                }`}
                style={{ width: '110px' }}
              />
              <DataField
                field={'Date of birth'}
                value={`${convertServerDateFormatToDateFormatWithoutHyphen(
                  encounterDetails.patient?.dateOfBirth
                )}`}
                style={{ width: '110px' }}
              />
              <DataField
                field={'Patient Contact'}
                value={
                  encounterDetails.patient?.phoneNumber &&
                  encounterDetails.patient?.phoneNumber?.length !== 0
                    ? encounterDetails.patient?.phoneNumber
                    : '-'
                }
                style={{ width: '150px' }}
              />
              <DataField
                field={'Age'}
                value={`${getAgeFromDate(encounterDetails.patient?.dateOfBirth)}`}
                style={{ width: '130px' }}
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            {/* <span className="pdf-comment-text ">Doctor&apos;s signature</span>
                  <span className="pdf-key-text mb-2" style={{ marginTop: '33px' }}>
                    Powered by Expand Health
                  </span> */}
          </div>
        </div>{' '}
        <hr
          className="bg-white"
          style={{
            width: '92.5%',
            marginLeft: '10px',
            marginTop: '0px',
            marginBottom: '-12px',
            borderTop: '1px solid #707070'
          }}
        />
      </th>
      {/* </tr> */}
    </thead>
  );
};
export const TableFooter = () => {
  return (
    <tfoot>
      <tr>
        <td className="page-footer">
          <hr
            className="mx-auto bg-white"
            style={{
              width: '97.4%',
              marginTop: '-20px',
              marginBottom: '50px',
              borderTop: '1px solid #707070'
            }}
          />
          <div className="d-flex flex-column">
            <span className="pdf-comment-text me-3">Doctor&apos;s signature</span>
            <span className="pdf-key-text me-3 mb-2" style={{ marginTop: '33px' }}>
              Powered by Expand Health
            </span>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};
export const checkPanelInvestigationsPresent = (panel: PanelInvestigationBody) => {
  const allInvestigations =
    panel.investigations.bk1000.length +
    panel.investigations.bk200.length +
    panel.investigations.bk6190.length +
    panel.investigations.cbs300.length +
    panel.investigations.other.length;
  return allInvestigations !== 0;
};
const LabTestResults: React.FC<LabTestResultsProps> = ({
  innerRef,
  encounterDetails,
  data,
  dataType
}) => {
  const RenderInvestigationRow: React.FC<InvestigationRowProp> = ({ data }) => (
    <div>
      {data.investigations &&
        data.investigations?.bk1000?.length > 0 &&
        data.investigations?.bk1000?.map((investigation: Investigation, index: number) => (
          <RenderRow key={index} general={investigation} encounterDetails={encounterDetails} />
        ))}
      {data?.investigations &&
        data?.investigations?.bk200?.length > 0 &&
        data?.investigations?.bk200.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {data?.investigations &&
        data?.investigations?.bk6190?.length > 0 &&
        data?.investigations?.bk6190.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {data?.investigations &&
        data?.investigations?.cbs300?.length > 0 &&
        data?.investigations?.cbs300.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {data?.investigations &&
        data?.investigations?.other?.length > 0 &&
        data?.investigations?.other.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
    </div>
  );
  const RenderPanelRow: React.FC<PanelInvestigationProps> = ({ panel }) => (
    <div>
      {panel?.investigations &&
        panel?.investigations?.bk1000?.length > 0 &&
        panel?.investigations?.bk1000.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.bk200?.length > 0 &&
        panel?.investigations?.bk200.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.bk6190?.length > 0 &&
        panel?.investigations?.bk6190.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.cbs300?.length > 0 &&
        panel?.investigations?.cbs300.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
      {panel?.investigations &&
        panel?.investigations?.other?.length > 0 &&
        panel?.investigations?.other.map(
          (generalPanelInvestigation: Investigation, investigationIndex: number) => (
            <RenderRow
              key={investigationIndex}
              general={generalPanelInvestigation}
              encounterDetails={encounterDetails}
            />
          )
        )}
    </div>
  );
  const checkGeneralInvestigationsPresent = () => {
    const allInvestigations =
      encounterDetails.investigations.bk1000.length +
      encounterDetails.investigations.bk200.length +
      encounterDetails.investigations.bk6190.length +
      encounterDetails.investigations.cbs300.length +
      encounterDetails.investigations.other.length;
    return allInvestigations !== 0;
  };

  return (
    <div ref={innerRef} className={'p-4 page-container'}>
      <table className="pdf-table">
        <thead>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position header--> */}
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {/* ENCOUNTER LEVEL INVESTIGATION */}
              {dataType.includes('encounter') && checkGeneralInvestigationsPresent() && (
                <>
                  <div
                    className={'d-flex rounded border-0 px-4 py-1 m-2'}
                    style={{ backgroundColor: '#E0E0E0' }}
                  >
                    <h5>General Investigations</h5>
                  </div>{' '}
                  <div className="px-4 py-1 mx-2 pdf-data">
                    <p className="text-center">Individual Investigations</p>{' '}
                    <Row xs={12} className={'py-1'}>
                      <Col className="col-4 text-start font-size-12 text-black">Investigation</Col>
                      <Col className="col text-center font-size-12 text-black">Result value</Col>
                      <Col className="col text-center font-size-12 text-black">Unit</Col>
                      <Col className="col text-center font-size-12 text-black">Range</Col>
                      <Col className="col text-center font-size-12 text-black">Flag</Col>
                    </Row>
                    {dataType.includes('bk6190') ? (
                      data?.investigations &&
                      data?.investigations?.bk6190?.length > 0 &&
                      data?.investigations?.bk6190.map(
                        (generalPanelInvestigation: Investigation, investigationIndex: number) => (
                          <RenderRow
                            key={investigationIndex}
                            general={generalPanelInvestigation}
                            encounterDetails={encounterDetails}
                          />
                        )
                      )
                    ) : (
                      <RenderInvestigationRow data={data} />
                    )}
                    <span className="font-size-12" style={{ color: '#2c3242' }}>
                      Comments:
                    </span>
                    <hr className="solid" />
                    {dataType.includes('bk6190') ? (
                      data?.investigations.bk6190.length > 0 && (
                        <div>{data?.investigations.bk6190[0].encounterMeta?.additionalNotes}</div>
                      )
                    ) : (
                      <span className={'pdf-comment-text'}>
                        {data?.investigations.bk1000.length > 0 && (
                          <div>
                            {data?.investigations.bk1000[0].encounterMeta?.additionalNotes !== '' &&
                            data?.investigations.bk1000[0].encounterMeta?.additionalNotes !== null
                              ? `- ${data?.investigations.bk1000[0].encounterMeta?.additionalNotes}`
                              : ''}
                          </div>
                        )}
                        {data?.investigations.bk200.length > 0 && (
                          <div>
                            {data?.investigations.bk200[0].encounterMeta?.additionalNotes !== '' &&
                            data?.investigations.bk200[0].encounterMeta?.additionalNotes !== null
                              ? `- ${data?.investigations.bk200[0].encounterMeta?.additionalNotes}`
                              : ''}
                          </div>
                        )}
                        {data?.investigations.bk6190.length > 0 && (
                          <div>
                            {data?.investigations.bk6190[0].encounterMeta?.additionalNotes !== '' &&
                            data?.investigations.bk6190[0].encounterMeta?.additionalNotes !== null
                              ? `- ${data?.investigations.bk6190[0].encounterMeta?.additionalNotes}`
                              : ''}
                          </div>
                        )}
                        {data?.investigations.cbs300.length > 0 && (
                          <div>
                            {data?.investigations.cbs300[0].encounterMeta?.additionalNotes !== '' &&
                            data?.investigations.cbs300[0].encounterMeta?.additionalNotes !== null
                              ? `- ${data?.investigations.cbs300[0].encounterMeta?.additionalNotes}`
                              : ''}
                          </div>
                        )}
                        {data?.investigations.other.length > 0 && (
                          <div>
                            {data?.investigations.other[0].encounterMeta?.additionalNotes !== '' &&
                            data?.investigations.other[0].encounterMeta?.additionalNotes !== null
                              ? `- ${data?.investigations.other[0].encounterMeta?.additionalNotes}`
                              : ''}
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                </>
              )}
              {/* ENCOUNTER LEVEL PANEL INVESTIGATION */}
              <>
                {dataType !== 'investigation' && !dataType.includes('bk6190')
                  ? data?.investigations &&
                    data?.investigations?.panelInvestigation?.length > 0 &&
                    data?.investigations?.panelInvestigation?.map(
                      (panel: PanelInvestigationBody, index: number) => (
                        <div key={index}>
                          <div
                            className={'d-flex rounded border-0 px-4 py-1 m-2'}
                            style={{ backgroundColor: '#E0E0E0' }}
                          >
                            <h5>{panel.name}</h5>
                          </div>
                          {/* GENERAL PANEL INVESTIGATIONS */}
                          {checkPanelInvestigationsPresent(panel) && (
                            <div className="px-4 py-1 m-2 pdf-data">
                              <p className="text-center">Individual Investigations</p>{' '}
                              <Row xs={12} className={'py-1'}>
                                <Col className="col-4 text-start font-size-12 text-black">
                                  Investigation
                                </Col>
                                <Col className="col text-center font-size-12 text-black">
                                  Result value
                                </Col>
                                <Col className="col text-center font-size-12 text-black">Unit</Col>
                                <Col className="col text-center font-size-12 text-black">Range</Col>
                                <Col className="col text-center font-size-12 text-black">Flag</Col>
                              </Row>
                              <RenderPanelRow panel={panel} />
                              {/* {panel?.encounterMeta?.additionalNotes !== '' &&
                           ( */}
                              <>
                                {' '}
                                <span className="font-size-12 text-black">Comments:</span>
                                <hr className="solid" />
                                <span className={'pdf-comment-text'}>
                                  {panel?.encounterMeta?.additionalNotes}
                                </span>
                              </>
                              {/* )} */}
                            </div>
                          )}
                          {/* NESTED FIRST INVESTIGATIONS */}
                          {panel?.investigations &&
                            panel?.investigations?.panelInvestigation?.length > 0 &&
                            panel?.investigations?.panelInvestigation.map(
                              (nestedFirst: PanelInvestigationBody, nestedFirstIndex: number) => (
                                <div key={nestedFirstIndex}>
                                  {checkPanelInvestigationsPresent(nestedFirst) && (
                                    <div className="px-4 py-1 m-2 pdf-data">
                                      <p className="text-center">{nestedFirst?.name}</p>{' '}
                                      <Row xs={12} className={'py-1'}>
                                        <Col className="col-4 text-start font-size-12 text-black">
                                          Investigation
                                        </Col>
                                        <Col className="col text-center font-size-12 text-black">
                                          Result value
                                        </Col>
                                        <Col className="col text-center font-size-12 text-black">
                                          Unit
                                        </Col>
                                        <Col className="col text-center font-size-12 text-black">
                                          Range
                                        </Col>
                                        <Col className="col text-center font-size-12 text-black">
                                          Flag
                                        </Col>
                                      </Row>
                                      <RenderPanelRow panel={nestedFirst} />
                                      {/* {panel?.encounterMeta?.additionalNotes !== '' && ( */}
                                      <>
                                        {' '}
                                        <span className="font-size-12 text-black">Comments:</span>
                                        <hr className="solid" />
                                        <span className={'pdf-comment-text'}>
                                          {panel?.encounterMeta?.additionalNotes}
                                        </span>
                                      </>
                                      {/* )} */}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          {/* NESTED SECOND PANEL INVESTIGATIONS */}
                          {panel?.investigations &&
                            panel?.investigations?.panelInvestigation?.length > 0 &&
                            panel?.investigations?.panelInvestigation.map(
                              (nestedFirst: PanelInvestigationBody) => {
                                return (
                                  nestedFirst?.investigations &&
                                  nestedFirst?.investigations?.panelInvestigation?.length > 0 &&
                                  nestedFirst?.investigations?.panelInvestigation.map(
                                    (
                                      nestedSecond: PanelInvestigationBody,
                                      nestedSecondIndex: number
                                    ) => (
                                      <div key={nestedSecondIndex}>
                                        {checkPanelInvestigationsPresent(nestedSecond) && (
                                          <div className="px-4 py-1 m-2">
                                            <p className="text-center">{nestedSecond?.name}</p>{' '}
                                            <Row xs={12} className={'py-1'}>
                                              <Col className="col-4 text-start font-size-12 text-black">
                                                Investigation
                                              </Col>
                                              <Col className="col text-center font-size-12 text-black">
                                                Result value
                                              </Col>
                                              <Col className="col text-center font-size-12 text-black">
                                                Unit
                                              </Col>
                                              <Col className="col text-center font-size-12 text-black">
                                                Range
                                              </Col>
                                              <Col className="col text-center font-size-12 text-black">
                                                Flag
                                              </Col>
                                            </Row>
                                            <RenderPanelRow panel={nestedSecond} />
                                            {/* {panel?.encounterMeta?.additionalNotes !== '' && ( */}
                                            <>
                                              {' '}
                                              <span className="font-size-12 text-black">
                                                Comments:
                                              </span>
                                              <hr className="solid" />
                                              <span className={'pdf-comment-text'}>
                                                {panel?.encounterMeta?.additionalNotes}
                                              </span>
                                            </>
                                            {/* )} */}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )
                                );
                              }
                            )}
                        </div>
                      )
                    )
                  : !dataType.includes('bk6190') && (
                      <>
                        <div
                          className={'d-flex rounded border-0 px-4 py-1 m-2'}
                          style={{ backgroundColor: '#E0E0E0' }}
                        >
                          <h5>General Investigations</h5>
                        </div>
                        <div className="px-4 py-1 m-2" style={{ border: '1px solid #e0e0e0' }}>
                          <p className="text-center">Individual Investigations</p>{' '}
                          <Row xs={12} className={'py-1'}>
                            <Col className="col-4 text-start font-size-12 text-black">
                              Investigation
                            </Col>
                            <Col className="col text-center font-size-12 text-black">
                              Result value
                            </Col>
                            <Col className="col text-center font-size-12 text-black">Unit</Col>
                            <Col className="col text-center font-size-12 text-black">Range</Col>
                            <Col className="col text-center font-size-12 text-black">Flag</Col>
                          </Row>
                          <RenderRow general={data} encounterDetails={encounterDetails} />
                          <span className="font-size-12" style={{ color: '#2c3242' }}>
                            Comments:
                          </span>
                          <hr className="solid" />
                          <span className={'pdf-comment-text'}>
                            {data?.encounterMeta?.additionalNotes}
                          </span>
                        </div>
                      </>
                    )}
              </>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position footer--> */}
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
      <TableHeader encounterDetails={encounterDetails} />
      <TableFooter />
    </div>
  );
};

export default LabTestResults;
