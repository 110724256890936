import moment from 'moment';
const SERVER_DATE_FORMAT = 'DD-MM-YYYY'; //15-01-2000
const DATE_INPUT_FORMAT = 'MM-DD-YYYY'; //15-01-2000
const DATE_FORMAT = 'YYYY-MM-DD'; //2000-01-15
const SLASH_DATE_FORMAT = 'DD/MM/YYYY'; //15/01/2000
const SLASH_DATE_TIME_FORMAT = "DD/MM/YYYY 'at' hh:mm a"; //15/01/2000
const DATE_FORMAT_WITHOUT_HYPHEN = 'DD MMM YYYY'; //15 Jan 2000
const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ';
const SERVER_DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss'; //15-01-2000 23:22:21
const DATE_TIME_FORMAT_WITH_AM_PM = 'DD MMM YYYY · hh:mm a'; //07 Nov 2022 · 11:41 pm
const DATE_TIME_FORMAT_WITH_AM_PM_AND_DASH = 'DD-MM-YYYY hh:mm a'; //07-11-2022 11:41 pm
const SERVER_DATE_TIME_FORMAT_WITH_AM_PM = 'YYYY-MM-DD hh:mm a'; //2022-11-07 11:41 pm
const SLASH_DATE_TIME_FORMAT_BARCODE = 'HH:mm on DD.MM.YY  '; //15:49 on 12.09.22

export const convertUTCServerDateTimeFormatToLocalDateTimeFormatWithAmPmAndDash = (
  date: string | Date
) => {
  return moment
    .utc(date, SERVER_DATE_TIME_FORMAT)
    .local()
    .format(DATE_TIME_FORMAT_WITH_AM_PM_AND_DASH);
};

export const convertUTCServerDateTimeFormatToLocalDateTimeFormatWithAmPm = (
  date: string | Date
) => {
  return moment.utc(date, SERVER_DATE_TIME_FORMAT).local().format(DATE_TIME_FORMAT_WITH_AM_PM);
};
export const convertServerDateTimeFormatWithAmPmToServerDateTimeFormat = (date: string | Date) => {
  return moment(date, SERVER_DATE_TIME_FORMAT_WITH_AM_PM).format(SERVER_DATE_TIME_FORMAT);
};
export const convertLocalServerDateTimeFormatWithAmPmToUTCServerDateTimeFormat = (
  date: string | Date
) => {
  return moment(date, SERVER_DATE_TIME_FORMAT_WITH_AM_PM).utc().format(SERVER_DATE_TIME_FORMAT);
};
export const convertServerDateFormatToSlashDateFormat = (date: string | Date) => {
  return moment(date, SERVER_DATE_FORMAT).format(SLASH_DATE_FORMAT);
};

export const convertToServerDateFormat = (date: string | Date) => {
  return moment(date).format(SERVER_DATE_FORMAT);
};

export const convertServerDateFormatToDateFormat = (date: string | Date) => {
  return moment(date, SERVER_DATE_FORMAT).format(DATE_FORMAT);
};

export const convertServerDateFormatToDateFormatWithoutHyphen = (date: string | Date) => {
  return moment(date, SERVER_DATE_FORMAT).format(DATE_FORMAT_WITHOUT_HYPHEN);
};

export const convertServerDateFormatToIsoDateTimeFormat = (date: string | Date) => {
  return moment(date, SERVER_DATE_FORMAT).format(ISO_DATE_TIME_FORMAT);
};
export const getCurrentDateInIsoFormat = () => {
  return moment().toISOString();
};

export const differenceInDays = (date1: string, date2: string) => {
  return moment(date1).diff(moment(date2), 'days') + '';
};

export const differenceInDays1 = (date1: string, date2: string) => {
  let dateOne: any = date1?.split('-');
  let dateTwo: any = date2?.split('-');

  if (dateOne?.length < 3 || dateTwo?.length < 3) return 0;

  dateOne = [dateOne[1], dateOne[0], dateOne[2]]?.join('-');
  dateTwo = [dateTwo[1], dateTwo[0], dateTwo[2]]?.join('-');

  return moment(dateOne).diff(moment(dateTwo), 'day') + '';
};

export const daysToDate = (date1: string | Date, date2: number | string) => {
  // let date = moment(date1, 'DD-MM-YYYY')
  return moment(date1).subtract(date2, 'days').format(SERVER_DATE_FORMAT);
};

export const daysToDate1 = (date1: string | Date, date2: number | string) => {
  let date = moment(date1, 'DD-MM-YYYY');
  return moment(date).subtract(date2, 'days').format(SERVER_DATE_FORMAT);
};
export const CurrentDateAndPrediction = (date1: string | Date, date2: number | string) => {
  let date = moment(date1, 'DD-MM-YYYY');
  return moment(date).add(date2, 'days').format(SERVER_DATE_FORMAT);
};

export const convertIsoDateTimeFormatToDateTimeFormatWithAmPm = (date: string | Date) => {
  return moment(date, ISO_DATE_TIME_FORMAT).format(DATE_TIME_FORMAT_WITH_AM_PM);
};

export const convertIsoDateTimeFormatToDateTimeFormatWithAmPmAndDash = (date: string | Date) => {
  return moment(date, ISO_DATE_TIME_FORMAT).format(DATE_TIME_FORMAT_WITH_AM_PM_AND_DASH);
};

export const getAgeFromDate = (date: string | Date) => {
  const age = moment.duration(moment().diff(moment(date, SERVER_DATE_FORMAT)));
  const years = age.years();

  if (years === 0) {
    const months = moment().diff(moment(date, SERVER_DATE_FORMAT), 'months');
    if (months === 0) {
      const days = moment().diff(moment(date, SERVER_DATE_FORMAT), 'days');
      return days + ' days';
    }
    return months + ' months';
  }

  return years + ' yrs';
};
export const isDateTodayOrPast = (date: string | Date) => {
  return moment(date, SERVER_DATE_TIME_FORMAT).isSameOrBefore();
};

export const getCurrentDateTime = () => {
  return moment().format(SLASH_DATE_TIME_FORMAT);
};

export const getCurrentDateTimeInBarcodeFormat = () => {
  return moment().format(SLASH_DATE_TIME_FORMAT_BARCODE);
};
