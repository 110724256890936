// import { useFormik } from 'formik';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import BlueButton from '~/components/BlueButton';
import CustomInput from '~/components/CustomInput';
import WhiteButton from '~/components/WhiteButton';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { resetUserPasswordReset } from '~/store/reducers/user';
import { resetUserPassword } from '~/store/reducers/user/thunks';
import Async from '~/utils/Async';
import { AUTHORITY_ROLE } from '~/utils/constants';
import { errorToast, successToast } from '~/utils/toast';

interface FilterProps {
  isOpen: boolean;
  toggle: () => void;
  userData: any;
}

const ResetPasswordModal: FC<FilterProps> = ({ isOpen, toggle, userData }) => {
  const dispatch = useAppDispatch();
  const { isLoadingResetUserPassword, errorResetUserPassword, successResetUserPassword } =
    useAppSelector((state) => state.UserReducer);
  const [showPassPrev, setShowPassPrev] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  //ADD USER
  useEffect(() => {
    if (!isLoadingResetUserPassword && successResetUserPassword) {
      successToast('Password reset successfully!');
      dispatch(resetUserPasswordReset());
      resetModalStates();
    }
    if (!isLoadingResetUserPassword && errorResetUserPassword) {
      errorToast(errorResetUserPassword);
      dispatch(resetUserPasswordReset());
    }
  }, [isLoadingResetUserPassword, successResetUserPassword, errorResetUserPassword]);

  const { handleSubmit, handleChange, values, touched, errors, setErrors, setTouched } = useFormik({
    enableReinitialize: true,

    initialValues: {
      prevPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      prevPassword:
        Async.getItem('@auth')?.role !== AUTHORITY_ROLE.ADMIN
          ? Yup.string().required('required*')
          : Yup.string().notRequired(),
      newPassword: Yup.string()
        .min(6, 'Password should atleast have six characters!')
        .required('required*'),
      confirmPassword: Yup.string().required('required*')
    }),
    onSubmit: (values) => {
      if (values.newPassword === values.confirmPassword) {
        if (userData) {
          dispatch(
            resetUserPassword({
              username: userData.username,
              newPassword: values.newPassword,
              prevPassword: values.prevPassword
            })
          );
        } else {
          errorToast('Error fetching username. Please try again later.');
        }
      } else {
        errorToast('Password does not match');
      }
    }
  });

  const resetModalStates = () => {
    toggle();
    values.confirmPassword = '';
    values.newPassword = '';
    values.prevPassword = '';
    setErrors({});
    setTouched({});
    setShowPassConfirm(false);
    setShowPass(false);
    setShowPassPrev(false);
  };
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop="static"
    >
      {/* <Form
        className="form-horizontal"
        id="loginForm"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      > */}
      <div className="modal-body ms-2">
        <h4 className="modal-title mt-0 sb font-size-18" id="myLargeModalLabel">
          Reset user password
        </h4>
        <button
          onClick={resetModalStates}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {Async.getItem('@auth')?.role !== AUTHORITY_ROLE.ADMIN && (
          <div className="filtersBox mt-2 justify-content-around" style={{ height: '80px' }}>
            <div className="col-md-12 " style={{ whiteSpace: 'nowrap' }}>
              <div>
                <CustomInput
                  label={'Current password'}
                  type={showPassPrev ? 'text' : 'password'}
                  placeholder="Current password"
                  name={'prevPassword'}
                  autoComplete="new-password"
                  value={values.prevPassword}
                  onChange={handleChange}
                  invalid={touched.prevPassword && errors.prevPassword ? true : false}
                  errors={errors.prevPassword}
                />
              </div>
              {errors.prevPassword ? (
                <></>
              ) : showPassPrev ? (
                <i
                  style={{ position: 'relative', left: '93%', bottom: '30%' }}
                  onClick={() => setShowPassPrev(false)}
                  className="fas fa-eye-slash eyeIcon"
                />
              ) : (
                <i
                  style={{ position: 'relative', left: '93%', bottom: '30%' }}
                  onClick={() => setShowPassPrev(true)}
                  className="fas fa-eye eyeIcon"
                />
              )}
            </div>
          </div>
        )}
        <div className="filtersBox mt-3 justify-content-around" style={{ height: '80px' }}>
          <div className="col-md-12 " style={{ whiteSpace: 'nowrap' }}>
            <div>
              <CustomInput
                label={'New password'}
                type={showPass ? 'text' : 'password'}
                placeholder="New password"
                name={'newPassword'}
                autoComplete="new-password"
                value={values.newPassword}
                onChange={handleChange}
                invalid={touched.newPassword && errors.newPassword ? true : false}
                errors={errors.newPassword}
              />
            </div>
            {errors.newPassword ? (
              <></>
            ) : showPass ? (
              <i
                style={{ position: 'relative', left: '93%', bottom: '30%' }}
                onClick={() => setShowPass(false)}
                className="fas fa-eye-slash eyeIcon"
              />
            ) : (
              <i
                style={{ position: 'relative', left: '93%', bottom: '30%' }}
                onClick={() => setShowPass(true)}
                className="fas fa-eye eyeIcon"
              />
            )}
          </div>
        </div>
        <div className="filtersBox mt-3 mb-2 justify-content-around" style={{ height: '80px' }}>
          <div className="col-md-12" style={{ whiteSpace: 'nowrap' }}>
            <CustomInput
              label={'Confirm password'}
              type={showPassConfirm ? 'text' : 'password'}
              placeholder="Confirm password"
              name={'confirmPassword'}
              autoComplete="new-password"
              value={values.confirmPassword}
              onChange={handleChange}
              invalid={touched.confirmPassword && errors.confirmPassword ? true : false}
              errors={errors.confirmPassword}
            />
            {errors.confirmPassword ? (
              <></>
            ) : showPassConfirm ? (
              <i
                style={{ position: 'relative', left: '93%', bottom: '30%' }}
                onClick={() => setShowPassConfirm(false)}
                className="fas fa-eye-slash eyeIcon"
              />
            ) : (
              <i
                style={{ position: 'relative', left: '93%', bottom: '30%' }}
                onClick={() => setShowPassConfirm(true)}
                className="fas fa-eye eyeIcon"
              />
            )}
          </div>
        </div>
      </div>
      <div className="modal-footer px-3  pt-3">
        <WhiteButton text="Dismiss" HandleClick={resetModalStates} />

        <div className="ms-auto">
          <BlueButton
            text={'Save changes'}
            loading={isLoadingResetUserPassword}
            disabled={isLoadingResetUserPassword}
            width={'109px'}
            HandleClick={handleSubmit}
          />
        </div>
      </div>
      {/* </Form> */}
    </Modal>
  );
};
export default ResetPasswordModal;
