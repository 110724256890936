import { min } from 'moment';
import React, { FC } from 'react';
import { FormFeedback, Input } from 'reactstrap';
interface CustomInputProps {
  label: string;
  onChange: any;
  value: any;
  invalid: boolean;
  errors: any;
  name: string;
  options?: any;
  type: any;
  min?:any;
  placeholder?: string;
  labelClass?: string;
  getIdInValues?: boolean;
  selected?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  pattern?:any;
}
const CustomInput: FC<CustomInputProps> = (props) => {
  const {
    label,
    onChange,
    value,
    invalid,
    errors,
    name,
    options,
    type,
    placeholder,
    labelClass,
    getIdInValues,
    selected,
    pattern,min,
    autoComplete,
    disabled
  } = props;
  return (
    <>
      {label.length > 0 && (
        <label className={labelClass ? labelClass : 'col-md-5 col-form-label im gray'}>
          {label}
        </label>
      )}
      {type === 'select' ? (
        <Input
          type="select"
          name={name}
          className={
            value === ''
              ? invalid
                ? 'input-error form-control placeholder-color'
                : 'form-control placeholder-color'
              : invalid
              ? 'input-error form-control'
              : 'form-control'
          }
          onChange={onChange}
          value={value}
          invalid={invalid ? true : false}
        >
          <>
            {placeholder && placeholder?.length > 0 && <option value="">{placeholder}</option>}

            {options?.length > 0 &&
              options.map((val: any, index: number) =>
                selected && index === 0 ? (
                  <option value={getIdInValues ? val?.id : val?.value} key={index} selected>
                    {val.name}
                  </option>
                ) : (
                  <option value={getIdInValues ? val?.id : val?.value} key={index}>
                    {val.name}
                  </option>
                )
              )}
          </>
        </Input>
      ) : (
        <Input
          type={type}
          name={name}
          autoComplete={autoComplete}
          className={invalid ? 'input-error form-control' : 'form-control'}
          onChange={onChange}
          value={value}
          invalid={invalid ? true : false}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          min={min}
        />
      )}

      {invalid && errors ? <FormFeedback type="invalid">{errors}</FormFeedback> : null}
    </>
  );
};

export default CustomInput;
