// import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
// import * as Yup from 'yup';
import BlueButton from '~/components/BlueButton';
import CustomInput from '~/components/CustomInput';
import WhiteButton from '~/components/WhiteButton';
import {
  MuiltiSelectedInvestigations,
  PanelInvestigationBody,
  Investigation
} from '~/models/modalsInterfaces.model';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { resetAddPanel, resetGetPanel, resetUpdatePanel } from '~/store/reducers/panel';
import { AddPanel, GetInvestigations, GetPanel, UpdatePanel } from '~/store/reducers/panel/thunks';
import { errorToast, successToast } from '~/utils/toast';
import MultiSelectInput from '../../MultiSelectInput';
import './addPanelModal.scss';

interface FilterProps {
  isOpen: boolean;
  toggle: () => void;
  bk1000: MuiltiSelectedInvestigations[];
  bk200: MuiltiSelectedInvestigations[];
  bk6190: MuiltiSelectedInvestigations[];
  cbs300: MuiltiSelectedInvestigations[];
  other: MuiltiSelectedInvestigations[];
  panelInvestigationsList: MuiltiSelectedInvestigations[];
  panelSuccess: boolean;
  panelError: string | null;
  panelLoading: boolean;
  modalType: string;
  panelName: string;
  panelId: number;
  isInvestigationPresent: any;
}

const AddPanelModal: FC<FilterProps> = ({
  isOpen,
  toggle,
  bk1000,
  bk200,
  bk6190,
  cbs300,
  other,
  panelInvestigationsList,
  panelError,
  panelSuccess,
  panelLoading,
  modalType,
  panelName,
  panelId,
  isInvestigationPresent
}) => {
  const [tempPanelInvestigationsList, setTempPanelInvestigationsList] = useState<
    MuiltiSelectedInvestigations[]
  >([]);
  const [tempbk1000, setTempBk1000] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempbk200, setTempBk200] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempbk6190, setTempBk6190] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempcbs300, setTempcbs300] = useState<MuiltiSelectedInvestigations[]>([]);
  const [tempOther, setTempOther] = useState<MuiltiSelectedInvestigations[]>([]);

  const [checkedBk1000, setCheckedBk1000] = useState<Investigation[]>([]);
  const [checkedBk200, setCheckedBk200] = useState<Investigation[]>([]);
  const [checkedBk6190, setCheckedBk6190] = useState<Investigation[]>([]);
  const [checkedCbs300, setCheckedCbs300] = useState<Investigation[]>([]);
  const [checkedOther, setCheckedOther] = useState<Investigation[]>([]);
  const [panelNameVal, setPanelNameVal] = useState<string>('');
  const [panelNameError, setPanelNameError] = useState<string>('');

  const [checkedPanelInvestigationsList, setCheckedPanelInvestigationsList] = useState<
    PanelInvestigationBody[]
  >([]);

  const dispatch = useAppDispatch();
  const {
    //add panel
    isLoadingAddPanel,
    errorAddPanel,
    successAddPanel,
    //update panel
    isLoadingUpdatePanel,
    errorUpdatePanel,
    successUpdatePanel
  } = useAppSelector((state) => state.PanelManagement);

  useEffect(() => {
    if (panelName && modalType === 'edit') {
      setPanelNameVal(panelName);
    } else if (modalType === 'add') {
      setPanelNameVal('');
    }
  }, [panelName, modalType]);

  const resetModalStates = () => {
    setPanelNameVal('');
    setPanelNameError('');
    setCheckedBk1000([]);
    setCheckedBk200([]);
    setCheckedBk6190([]);
    setCheckedCbs300([]);
    setCheckedPanelInvestigationsList([]);
    setCheckedOther([]);
    setTempBk6190(bk6190);
    setTempBk1000(bk1000);
    setTempBk200(bk200);
    setTempOther(other);
    setTempPanelInvestigationsList(panelInvestigationsList);
    setTempcbs300(cbs300);
    toggle();
  };

  //RESET GET PANELS STATES FOR DROPDOWNS
  useEffect(() => {
    if (!panelLoading && panelSuccess) {
      dispatch(resetGetPanel());
    } else if (!panelLoading && panelError) {
      errorToast(panelError);
      dispatch(resetGetPanel());
    }
  }, [panelError, panelSuccess]);

  //RESET STATES AFTER ADDING PANEL
  useEffect(() => {
    if (!isLoadingAddPanel && successAddPanel) {
      successToast('Panel added!');
      dispatch(resetAddPanel());
      dispatch(GetPanel());
      resetModalStates();
      setPanelNameVal('');
    }
    if (!isLoadingAddPanel && errorAddPanel) {
      errorToast(errorAddPanel);
      dispatch(resetAddPanel());
    }
  }, [isLoadingAddPanel, successAddPanel, resetAddPanel]);

  //RESET STATES AFTER UPDATING PANEL
  useEffect(() => {
    if (!isLoadingUpdatePanel && successUpdatePanel) {
      successToast('Panel updated!');
      dispatch(resetUpdatePanel());
      dispatch(GetPanel());
      dispatch(GetInvestigations());
      resetModalStates();
    }
    if (!isLoadingUpdatePanel && errorUpdatePanel) {
      errorToast(errorUpdatePanel);
      dispatch(resetUpdatePanel());
    }
  }, [isLoadingUpdatePanel, successUpdatePanel, resetUpdatePanel]);

  useEffect(() => {
    setTempBk1000(bk1000);
  }, [bk1000]);

  useEffect(() => {
    setTempBk200(bk200);
  }, [bk200]);

  useEffect(() => {
    setTempBk6190(bk6190);
  }, [bk6190]);

  useEffect(() => {
    setTempcbs300(cbs300);
  }, [cbs300]);

  useEffect(() => {
    setTempOther(other);
  }, [other]);

  useEffect(() => {
    setTempPanelInvestigationsList(panelInvestigationsList);
  }, [panelInvestigationsList]);

  const HandleSave = () => {
    if (panelNameVal === '') {
      setPanelNameError('Please enter panel name');
    } else {
      if (
        isInvestigationPresent(
          checkedPanelInvestigationsList.length,
          checkedBk1000.length,
          checkedBk200.length,
          checkedBk6190.length,
          checkedCbs300.length,
          checkedOther.length
        )
      ) {
        if (checkedOther.length !== 0) {
          //Anion Gap
          if (checkedOther.find((other) => other.id === 37)) {
            //All cbs-300 Investigation
            if (checkedCbs300.length !== 3) {
              errorToast('For Anion Gap, all CBS-300 investigations are mandatory');
              return;
            }
            //Bicarbonate Investigation
            else if (
              !(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 33))
            ) {
              errorToast('For Anion Gap, Bicarbonate (BK-200) Investigation is mandatory');
              return;
            }
          }
          //Corrected Calcium
          if (checkedOther.find((other) => other.id === 38)) {
            //Albumin Investigation
            if (!(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 42))) {
              errorToast('For Corrected Calcium, Albumin (BK-200) Investigation is mandatory');
              return;
            }
            //Calcium Investigation
            else if (
              !(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 46))
            ) {
              errorToast('For Corrected Calcium, Calcium (BK-200) Investigation is mandatory');
              return;
            }
          }
          //GFR
          if (
            checkedOther.find((other) => other.id === 39) ||
            checkedOther.find((other) => other.id === 40)
          ) {
            //Serum Creatinine Investigation
            if (!(checkedBk200.length !== 0 && checkedBk200.find((bk200) => bk200.id === 47))) {
              errorToast(
                'For GFR Investigations, Serum Creatinine (BK-200) Investigation is mandatory'
              );
              return;
            }
          }
          //Apolipo Ratio
          if (checkedOther.find((other) => other.id === 84)) {
            //Serum Creatinine Investigation
            if (!(checkedBk1000.length !== 0 && checkedBk1000.find((bk1000) => bk1000.id === 63))) {
              errorToast(
                'For Apolipoprotein B : Apolipoprotein A1 ratio, Apolipoprotein A1 (BK-1000) Investigation is mandatory'
              );
              return;
            } //Calcium Investigation
            if (!(checkedBk1000.length !== 0 && checkedBk1000.find((bk1000) => bk1000.id === 64))) {
              errorToast(
                'For Apolipoprotein B : Apolipoprotein A1 ratio, Apolipoprotein B (BK-1000) Investigation is mandatory'
              );
              return;
            }
          }
        }
      } else {
        errorToast('No investigation selected');
        return;
      }
      const body = {
        name: panelNameVal,
        investigations: {
          panelInvestigation: checkedPanelInvestigationsList,
          bk1000: checkedBk1000,
          bk200: checkedBk200,
          bk6190: checkedBk6190,
          cbs300: checkedCbs300,
          other: checkedOther
        }
      };
      if (modalType === 'add') {
        dispatch(AddPanel(body));
      } else {
        const updatedData = { ...body, id: panelId };
        dispatch(UpdatePanel(updatedData));
      }
    }
  };
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop="static"
    >
      {/* <Form
        className="form-horizontal"
        id="loginForm"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      > */}
      <div className="modal-body ms-2">
        <h4 className="modal-title mt-0 sb font-size-18" id="myLargeModalLabel">
          {modalType === 'add' ? 'Add new panel' : 'Edit panel'}
        </h4>
        <button
          onClick={resetModalStates}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="filtersBox mt-2">
          <div className="col-md-5 me-1 ">
            <CustomInput
              name="panelNameVal"
              type="text"
              onChange={(e: any) => {
                setPanelNameVal(e.target.value);
              }}
              value={panelNameVal}
              invalid={panelNameError !== '' ? true : false}
              label="Panel name"
              errors={panelNameError}
              placeholder="Panel name"
            />
          </div>

          <div className="col-md-5 offset-md-1" style={{ whiteSpace: 'nowrap' }}>
            <MultiSelectInput
              label="Panel investigations"
              errors={'Select investigations'}
              placeholder="Select investigations"
              options={tempPanelInvestigationsList}
              setOptions={setTempPanelInvestigationsList}
              setChecked={setCheckedPanelInvestigationsList}
              checkedOptions={checkedPanelInvestigationsList}
              modalType={modalType}
            />
          </div>
        </div>
        <div className="filtersBox">
          <div className="col-md-5 me-1 ">
            <MultiSelectInput
              label="BK-1000"
              errors={'Select investigations'}
              placeholder="Select investigations"
              options={tempbk1000}
              setOptions={setTempBk1000}
              setChecked={setCheckedBk1000}
              checkedOptions={checkedBk1000}
              modalType={modalType}
            />
          </div>

          <div className="col-md-5 offset-md-1">
            <MultiSelectInput
              label="BK-200"
              placeholder="Select investigations"
              options={tempbk200}
              setOptions={setTempBk200}
              setChecked={setCheckedBk200}
              checkedOptions={checkedBk200}
              modalType={modalType}
            />
          </div>
        </div>
        <div className="filtersBox">
          <div className="col-md-5 me-1 ">
            <MultiSelectInput
              label="BK-6190"
              placeholder="Select investigations"
              options={tempbk6190}
              setOptions={setTempBk6190}
              setChecked={setCheckedBk6190}
              checkedOptions={checkedBk6190}
              modalType={modalType}
            />
          </div>

          <div className="col-md-5 offset-md-1">
            <MultiSelectInput
              label="CBS-300"
              placeholder="Select investigations"
              options={tempcbs300}
              setOptions={setTempcbs300}
              setChecked={setCheckedCbs300}
              checkedOptions={checkedCbs300}
              modalType={modalType}
            />
          </div>
        </div>
        <div className="filtersBox">
          <div className="col-md-5 me-1 ">
            <MultiSelectInput
              label="Other"
              placeholder="Select investigations"
              options={tempOther}
              setOptions={setTempOther}
              setChecked={setCheckedOther}
              checkedOptions={checkedOther}
              modalType={modalType}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer px-3 mt-2 pt-5">
        <WhiteButton text="Dismiss" HandleClick={resetModalStates} />

        <div className="ms-auto">
          <BlueButton
            text={modalType === 'add' ? '+ Add panel' : '+ Update Panel'}
            loading={isLoadingAddPanel || isLoadingUpdatePanel}
            disabled={isLoadingAddPanel || isLoadingUpdatePanel}
            width={modalType === 'add' ? '109px' : '120px'}
            HandleClick={HandleSave}
          />
        </div>
      </div>
      {/* </Form> */}
    </Modal>
  );
};
export default AddPanelModal;
