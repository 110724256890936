import React, { FC } from 'react';
import { FormFeedback, Input } from 'reactstrap';
interface CustomInputProps {
  label: string;
  onChange: any;
  value: any;
  invalid: boolean;
  errors: any;
  name: string;
  labelClass?: string;
}
const DateTimeCalendarInput: FC<CustomInputProps> = (props) => {
  const { label, onChange, value, invalid, errors, name, labelClass } = props;
  return (
    <>
      <label className={labelClass ? labelClass : 'col-md-5 col-form-label im gray'}>{label}</label>
      <Input
        type="datetime-local"
        name={name}
        className={
          value === ''
            ? invalid
              ? 'input-error form-control placeholder-color'
              : 'form-control placeholder-color'
            : invalid
            ? 'input-error form-control'
            : 'form-control'
        }
        onChange={onChange}
        value={value}
        invalid={invalid ? true : false}
      ></Input>
      {/* <Input
        type="select"
        name={name}
        className={
          value === ''
            ? invalid
              ? 'input-error form-control placeholder-color'
              : 'form-control placeholder-color'
            : invalid
            ? 'input-error form-control'
            : 'form-control'
        }
        onChange={onChange}
        value={value}
        invalid={invalid ? true : false}
      >
        <>
          <option value="">{placeholder}</option>

          {options?.length > 0 &&
            options.map((val: any, index: number) => (
              <option value={val.value} key={index}>
                {val.name}
              </option>
            ))}
        </>
      </Input> */}

      {invalid && errors ? <FormFeedback type="invalid">{errors}</FormFeedback> : null}
    </>
  );
};

export default DateTimeCalendarInput;
