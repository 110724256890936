import React, { FC } from 'react';
import Loader from '../Common/Loader';
interface Btn {
  Icon?: any;
  text?: string;
  HandleClick?: any;
  type?: string;
  loading?: boolean;
  height?: string;
  width?: string;
  disabled?: boolean;
  style?: any;
}
const BlueButton: FC<Btn> = (props) => {
  const { Icon, text, HandleClick, type, loading, height, width, disabled, style } = props;
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={HandleClick}
      className="btn btn-primary"
      disabled={disabled}
      style={{
        color: 'white',
        height: height,
        fontSize: '12px',
        fontFamily: 'Satoshi-Bold',
        width: width,
        ...style
      }}
    >
      {!loading ? (
        <>
          {Icon}
          {text}
        </>
      ) : (
        <Loader height="12px" width="12px" color="white" />
      )}
    </button>
  );
};
BlueButton.defaultProps = {
  loading: false,
  disabled: false,
  height: '33px'
};
export default BlueButton;
