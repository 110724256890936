import React, { FC, useEffect, useState } from 'react';
import SecondarySideBarContent from './SecondarySideBarContent';
const SecondarySidebar: FC = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', HandleWidth);
    return () => window.removeEventListener('resize', HandleWidth);
  }, []);
  const HandleWidth = () => {
    setHeight(window.innerHeight);
  };

  return (
    <React.Fragment>
      {height !== 0 && (
        <div
          id="second-sidebar"
          className="second-vertical-menu"
          style={{
            maxHeight: `${height}px`
          }}
        >
          <div className="second-side-bar-top ms-3 me-3">
            <span className="font-size-18 sbl" style={{ fontWeight: 'bold' }}>
              Admin
            </span>
            <br />
            <p className="menu-title mt-2 mb-5">
              Manage your users, test types, parameters and create packages
            </p>
          </div>
          <div className="mt-4 width-open">
            <SecondarySideBarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SecondarySidebar;
