import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError } from '~/models/Error.model';
import { postRequest, getRequest, putRequest } from '~/store/apiHelper';
import { AddEncounterBody, EncounterBody, FetchEncounterProps } from './types';

export const fetchAllEncounters = createAsyncThunk<
  any[],
  FetchEncounterProps,
  { rejectValue: MyKnownError }
>('encounter/all', async (data, thunkApi) => {
  const url = data.patientId
    ? `encounter?patientId=${data.patientId}`
    : data?.timeframe !== undefined
    ? `encounter?timeframe=${data.timeframe}`
    : '/encounter';
  return getRequest(url, true, thunkApi);
});

export const archiveEncounter = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'encounter/archive',
  async (id, thunkApi) => {
    return putRequest(`/encounter/archive/${id}`, null, true, thunkApi);
  }
);

export const unarchiveEncounter = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'encounter/unarchive',
  async (id, thunkApi) => {
    return putRequest(`/encounter/unarchive/${id}`, null, true, thunkApi);
  }
);

export const updateEncounter = createAsyncThunk<any, EncounterBody, { rejectValue: MyKnownError }>(
  'encounter/update',
  async (data, thunkApi) => {
    return putRequest(`/encounter`, data, true, thunkApi);
  }
);

export const fetchEncounterById = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'encounter/id',
  async (id, thunkApi) => {
    return getRequest(`/encounter/${id}`, true, thunkApi);
  }
);
export const fetchEncounterStatistics = createAsyncThunk<
  any,
  number,
  { rejectValue: MyKnownError }
>('encounter/stats', async (timeframe, thunkApi) => {
  return getRequest(`/encounter/statistics?timeframe=${timeframe}`, true, thunkApi);
});

export const addNewEncounter = createAsyncThunk<
  any,
  AddEncounterBody,
  { rejectValue: MyKnownError }
>('encounter/add', async (data, thunkApi) => {
  return postRequest('/encounter', data, true, thunkApi);
});
