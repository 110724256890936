import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Form, Input, Label } from 'reactstrap';
// import LogoSvg from '../../assets/icons/Logo.svg';
// import logo from '~/assets/images/LandingLogo.svg';
import backgroundSvg from '../../assets/images/png/background.png';
import './login.scss';
// Formik validation
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { SignInUser } from '~/store/reducers/AuthSlice/thunks';
import { setPreloader } from '~/store/reducers/loader';

const Login: FC = () => {
  const history = useHistory();
  const { token, error, loading } = useAppSelector((state) => state.AuthReducer);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token !== '') {
      history.push('/dashboard');
    }
    if (error === '') {
      dispatch(setPreloader(false));
    }
  }, [token, error]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(5, 'Too short!')
        .max(25, 'Too long!')
        // .matches(
        //   /^(?!\d)(?!.*-.*-)(?!.*-$)(?!-)[a-zA-Z0-9-]{5,20}$/,
        //   'Username must start with a letter. Contains only letters, numbers, and dashes or hyphens.'
        // )
        .required('Please enter username'),
      password: Yup.string().min(6, 'Password too Short!').required('Please enter your password')
    }),
    onSubmit: (values) => {
      dispatch(setPreloader(true));
      const data = {
        username: values.email.trim(),
        password: values.password.trim(),
        remember: rememberMe
      };
      dispatch(SignInUser(data));
    }
  });

  return (
    <React.Fragment>
      <img src={backgroundSvg} alt="" className="bg-img" />
      <div className="col-md-10 mt-4 offset-1 card-container">
        <div className="content">
          <div>
            <div className="logoContainer">
              <div className="logoContent">
                {/* <img src={logo} height="36" width="186px" alt="" /> */}
                <p className="logoText align-self-center sbl" style={{ whiteSpace: 'nowrap' }}>
                  EXPAND HEALTH
                </p>
              </div>
            </div>
          </div>

          <div className="right-text">
            <h2 className="text sbold font-size-24">
              A proprietary<span className="text-primary ms-1">Lab Management</span>
            </h2>
            <h2 className="text sbold font-size-24">
              system built with <span className="text-primary me-1 ms-1"> automation</span> at
            </h2>
            <h2 className="text sbold font-size-24">the heart off its design</h2>
          </div>
        </div>
        <div className="col-sm-4 pe-5">
          <Card className="p-3 shadow-lg ">
            <h5 className="mt-2 sbold text-head">Log In</h5>
            <h6 className="text-muted mb-3">Enter your credentials to access the admin panel</h6>
            <Form
              className="form-horizontal"
              id="loginForm"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <Label className="form-label im">Username</Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Enter Username"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  invalid={validation.touched.email && validation.errors.email ? true : false}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className="error-container mt-1 mb-1">
                    <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                    <h6 className="text-danger mt-2 ms-2">{validation.errors.email}</h6>
                  </div>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label im">Password</Label>
                <div className="passwordInput">
                  <Input
                    name="password"
                    value={validation.values.password || ''}
                    type={showPass ? 'text' : 'password'}
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    className={
                      validation.touched.password && validation.errors.password
                        ? 'form-control border-danger'
                        : 'form-control'
                    }
                  />
                  {showPass ? (
                    <i onClick={() => setShowPass(false)} className="fas fa-eye-slash eyeIcon" />
                  ) : (
                    <i onClick={() => setShowPass(true)} className="fas fa-eye eyeIcon" />
                  )}
                </div>
                {validation.touched.password && validation.errors.password ? (
                  <div className="error-container mt-1 mb-1">
                    <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                    <h6 className="text-danger mt-2 ms-2">{validation.errors.password}</h6>
                  </div>
                ) : null}
              </div>

              {error && error !== '' && (
                <div className="error-container mt-1 mb-1">
                  <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                  <h6 className="text-danger mt-2 ms-2">{error}</h6>
                </div>
              )}
              <Label check className="im">
                <Input
                  type="checkbox"
                  id="checkbox2"
                  defaultChecked={rememberMe}
                  onChange={(val) => {
                    const { checked } = val.target;
                    setRememberMe(checked);
                  }}
                />
                <span className="ms-2">Remember me</span>
              </Label>
              <div className="mt-3 d-grid">
                <button className="btn btn-primary btn-block " type="submit">
                  Log In
                  {loading && <i className="bx bx-loader bx-spin font-size-16 loader" />}
                </button>
              </div>
              {/* <div className="d-flex justify-content-center mt-4">
                <button className="bg-transparent border-0" type="button">
                  <h6 className="text-primary im">Forgot your password?</h6>
                </button>
              </div> */}
            </Form>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
