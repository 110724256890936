import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError } from '~/models/Error.model';
import { deleteRequest, getRequest, postRequest, putRequest } from '~/store/apiHelper';
import { AddPanelBody, UpdatePanelBody } from './types';

export const GetInvestigations = createAsyncThunk<any, undefined, { rejectValue: MyKnownError }>(
  'panelManagement/get',
  async (_, thunkApi) => {
    return getRequest('/investigation', true, thunkApi);
  }
);

export const GetPanel = createAsyncThunk<any, undefined, { rejectValue: MyKnownError }>(
  'panel/get',
  async (_, thunkApi) => {
    return getRequest('/panel', true, thunkApi);
  }
);

export const AddPanel = createAsyncThunk<any, AddPanelBody, { rejectValue: MyKnownError }>(
  'panel/add',
  async (data, thunkApi) => {
    return postRequest('/panel', data, true, thunkApi);
  }
);

export const DeletePanel = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'panel/delete',
  async (id, thunkApi) => {
    return deleteRequest(`/panel/${id}`, null, true, thunkApi);
  }
);

export const UpdatePanel = createAsyncThunk<any, UpdatePanelBody, { rejectValue: MyKnownError }>(
  'panel/update',
  async (data, thunkApi) => {
    return putRequest('/panel', data, true, thunkApi);
  }
);
