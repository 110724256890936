import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError } from '~/models/Error.model';
import { deleteRequest, getRequest, postRequest, putRequest } from '~/store/apiHelper';
import { AddDocterBody, DocterBody } from './types';

export const GetDoctors = createAsyncThunk<any, undefined, { rejectValue: MyKnownError }>(
  'doctors/GetDoctors',
  async (_, thunkApi) => {
    return getRequest('/doctor', true, thunkApi);
  }
);

export const archiveDoctor = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'doctor/archive',
  async (data, thunkApi) => {
    return putRequest(`/doctor/archive/${data}`, null, true, thunkApi);
  }
);

export const unarchiveDoctor = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'doctor/unarchive',
  async (data, thunkApi) => {
    return putRequest(`/doctor/unarchive/${data}`, null, true, thunkApi);
  }
);

export const updateDoctor = createAsyncThunk<any, DocterBody, { rejectValue: MyKnownError }>(
  'doctor/update',
  async (data, thunkApi) => {
    return putRequest(`/doctor`, data, true, thunkApi);
  }
);

export const deleteDoctor = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'doctor/delete',
  async (id, thunkApi) => {
    return deleteRequest(`/doctor/${id}`, null, true, thunkApi);
  }
);

export const fetchDoctorById = createAsyncThunk<any, number, { rejectValue: MyKnownError }>(
  'doctor/id',
  async (id, thunkApi) => {
    return getRequest(`/doctor/${id}`, true, thunkApi);
  }
);

export const addNewDoctor = createAsyncThunk<any, AddDocterBody, { rejectValue: MyKnownError }>(
  'doctor/add',
  async (data, thunkApi) => {
    return postRequest('/doctor', data, true, thunkApi);
  }
);
