/* eslint-disable no-prototype-builtins */
import moment from 'moment';
import { AUTHORITY_ROLE } from './constants';
import { daysToDate, differenceInDays1 } from './dateUtility';
import { errorToast } from './toast';

export const getNumberOfRequests = (
  panelInvestigation: number,
  bk1000: number,
  bk200: number,
  bk6190: number,
  cbs300: number,
  other: number
) => {
  return panelInvestigation + bk1000 + bk200 + bk6190 + cbs300 + other;
};

export const getRole = (role: string) => {
  return role === AUTHORITY_ROLE.ADMIN
    ? 'Admin'
    : role === AUTHORITY_ROLE.LAB_TECH
    ? 'Lab Tech'
    : role === AUTHORITY_ROLE.LAB_MANAGER
    ? 'Lab Manager'
    : role === AUTHORITY_ROLE.DOCTOR
    ? 'Doctor'
    : role === AUTHORITY_ROLE.PHLEBOTOMIST
    ? 'Phlebotomst'
    : role;
};

export const isLetterInput = (e: any) => {
  const letterRegex = /^[A-Za-z',\s]*$/;
  if (e.target.value === '' || letterRegex.test(e.target.value)) {
    return true;
  }
  return false;
};

export const isPositiveInput = (e: any) => {
  const letterRegex = /^[0-9]*$/;
  if (e.target.value === '' || letterRegex.test(e.target.value)) {
    return true;
  }
  return false;
};
export const isPositiveDecimalInput = (e: any) => {
  const letterRegex = /^\d*\.?\d{0,5}$/;
  if (e.target.value === '' || letterRegex.test(e.target.value)) {
    return true;
  }
  return false;
};
export const containsDecimal = (text: any) => {
  const decimalRegex = /\./;
  return decimalRegex.test(text);
};
export const displayTwoDecimals = (num: any) => {
  return num.toFixed(2);
};

export const compareDates = (expiryDate: any, firstWarningDate: any, secondWarningDate: any) => {
  // let compare=daysToDate(firstWarningDate,secondWarningDate)
  const fdate = daysToDate(expiryDate, firstWarningDate);
  const sdate = daysToDate(expiryDate, secondWarningDate);
  const c = differenceInDays1(fdate, sdate).toString();
  const char = '-';
  const ret = c.includes(char) || c.includes('0');

  if (ret == true && c !== '0') {
    return sdate;
  } else {
    // return sdate;
    errorToast("Second warning shouldn't be greater or equal to first warning date");

    return false;
  }
};

export const checkDatesFirstWarning = (expiryDate: any, firstWarningDate: any) => {
  const expDate = daysToDate(expiryDate, firstWarningDate);
  const firstDate = moment(new Date()).format('DD-MM-YYYY').toString();
  const c = differenceInDays1(expDate, firstDate).toString();
  const char = '-';
  const ret = c.includes(char);

  if (ret == true) {
    errorToast('First warning date has passed!');

    return false;
  } else {
    return expDate;
  }
};

export const checkDatesSecondWarning = (expiryDate: any, secondWarningDate: any) => {
  const expDate = daysToDate(expiryDate, secondWarningDate);
  const secondDate = moment(new Date()).format('DD-MM-YYYY').toString();
  const c = differenceInDays1(expDate, secondDate).toString();
  const char = '-';
  const ret = c.includes(char);

  if (ret == true) {
    errorToast('Second warning date has passed!');

    return false;
  } else {
    return secondDate;
  }
};
